import React from "react";

const ShowIntakeQA = (props) => {
  return (
    <>
      <div className="box">
        <p className="subsubtitle">{props.question}</p>
        <div className="divider"></div>
        <p className="subsubtitle" style={{ wordWrap: "break-word" }}>
          {props.answer}
        </p>
      </div>
    </>
  );
};

export default ShowIntakeQA;
