import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "./config/firebase-config";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {
  collection,
  where,
  query,
  getDocs,
  updateDoc,
  doc,
  getDoc,
  addDoc,
} from "firebase/firestore";
import { useEffect } from "react";

import Disclaimer from "./pages/Disclaimer";
import CaseDetails from "./pages/CaseDetails";
import CaseDetailsSpecific from "./pages/CaseDetailsSpecific";
import DocumentsUpload from "./pages/DocumentsUpload";
import ReviewAndSecurity from "./pages/ReviewAndSecurity";
import SubmissionConfirmation from "./pages/SubmissionConfirmation";
import SelectField from "./pages/SelectField";
import GeneralInformation from "./pages/GeneralInformation";
//import FollowUpQuestionsFromDocs from './pages/FollowUpQuestionsFromDocs';
import Dashboard from "./pages/Dashboard";
import ClientInfo from "./pages/ClientInfo";
import SignUpPage from "./pages/SignUpPage";
import LoginPage from "./pages/LoginPage";
import Navbar from "./components/Navbar";
import LogoutPage from "./pages/LogoutPage";
import LoginPageLawyer from "./pages/LoginPageLawyer";
import SignUpPageLawyer from "./pages/SignUpPageLawyer";
import LogoutLawyer from "./pages/LogoutPageLawyer";
import Home from "./pages/Home";
import TermsOfService from "./pages/TermsOfService";
import NavbarFooter from "./components/NavbarFooter";
import WelcomeIntake from "./pages/WelcomeIntake";
import HalfwayScreen from "./pages/HalfwayScreen";
import LawyerSettings from "./pages/LawyerSettings";
import ClientDetailsQuestions from "./pages/ClientDetailsQuestions";
import LegalSectorConfirmation from "./pages/LegalSectorConfirmation";
import FinalQuestions from "./pages/FinalQuestions";
import IntakeSessions from "./pages/IntakeSessions";
import OnboardingLawyer from "./pages/OnboardingLawyer";
import CostPrediction from "./pages/CostPrediction";
import LawyerIntakeSimulation from "./pages/LawyerIntakeSimulation";
import ErrorBoundary from "./components/ErrorBoundary";
import LogRocket from "logrocket";
import ScheduleDemo from "./pages/ScheduleDemo";
import LandingPageHTML from "./pages/LandingPageHTML";
import LegalCoPilot from "./pages/LegalCoPilot"

LogRocket.init("gd8syp/caddiapp");

function App() {
  const storage = getStorage();
  const [isSignedIn, setIsSignedIn] = useState(
    localStorage.getItem("isSignedIn") || false,
  );
  const [isLawyer, setIsLawyer] = useState(
    localStorage.getItem("isLawyer") || false,
  );
  const [isOnboarded, setIsOnboarded] = useState(
    localStorage.getItem("isOnboarded") || false,
  );
  const [disclaimer, setDisclaimer] = useState("");

  // Check if the user is signed in and the email has been verified
  useEffect(() => {
    console.log("Loading App.js");
  }, []);
  

  const onLogin = (user_id) => {
    localStorage.setItem("userId", user_id);
    localStorage.setItem("isSignedIn", true);
    setIsSignedIn(true);
    // setUserId(user_id);
    console.log("On Login activated. User ID: ", user_id);
  };

  const onLoginLawyer = (user_id, user_isOnboarded) => {
    localStorage.setItem("userId", user_id);
    localStorage.setItem("isSignedIn", true);
    localStorage.setItem("isLawyer", true);
    localStorage.setItem("isOnboarded", user_isOnboarded);
    setIsSignedIn(true);
    // setUserId(user_id);
    setIsLawyer(true);
    setIsOnboarded(user_isOnboarded);
    console.log("On Login Lawyer activated: ", user_id);

    // Read all the information from Firestore and store it in local storage
    const firestoreId = localStorage.getItem("firestoreId");
    const query = doc(db, "lawyers", firestoreId);
    getDoc(query).then((doc) => {
      console.log("Lawyer Data: ", doc.data());

      localStorage.setItem("lawyerName", doc.data().lawyerName || "");
      localStorage.setItem("selectedLegalSectors", doc.data().selectedLegalSectors || "");
      //lawyerLawfirm was already set in the login page
      localStorage.setItem("lawyerEmail", doc.data().email || "");
      localStorage.setItem("lawfirmSize", doc.data().lawfirmSize || "");
 
      let jurisdictionJSON = JSON.stringify(doc.data().jurisdiction || []);
      localStorage.setItem("jurisdiction", jurisdictionJSON);
      let listLegalSectorsJSON = JSON.stringify(
        doc.data().listLegalSectors || [],
      );
      localStorage.setItem("listLegalSectors", listLegalSectorsJSON);

      localStorage.setItem("hourlyRate", doc.data().hourlyRate || "");

      // TODO: convert flatFees and contingencyFees to maps structure of form [{concept: "LLC Formation", fee: "$2500"}]
      let flatFeesDict = doc.data()?.flatFees || {};
      let flatFees = Object.keys(flatFeesDict || {}).map((concept) => ({
        concept,
        price: flatFeesDict[concept],
      }));
      let flatFeesJSON = JSON.stringify(flatFees);
      localStorage.setItem("flatFees", flatFeesJSON);

      let contingencyFeesDict = doc.data()?.contingencyFees || {};
      let contingencyFees = Object.keys(contingencyFeesDict || {}).map(
        (concept) => ({ concept, price: contingencyFeesDict[concept] }),
      );
      let contingencyFeesJSON = JSON.stringify(contingencyFees);
      localStorage.setItem("contingencyFees", contingencyFeesJSON);

      let requiredQuestionsLawfirmJSON = JSON.stringify(
        doc.data().requiredQuestionsLawfirm || [],
      );
      localStorage.setItem(
        "requiredQuestionsLawfirm",
        requiredQuestionsLawfirmJSON,
      );

      let finalQuestionsLawfirmJSON = JSON.stringify(
        doc.data().finalQuestionsLawfirm || [],
      );  
      localStorage.setItem("finalQuestionsLawfirm", finalQuestionsLawfirmJSON);

      localStorage.setItem(
        "idealClientDescription",
        doc.data().idealClientDescription || "",
      );
      localStorage.setItem(
        "worstClientDescription",
        doc.data().worstClientDescription || "",
      );

      let acceptHeaderValue = `[Lawfirm Name] reviewed your case!`;
      let acceptHeaderJSON = JSON.stringify(acceptHeaderValue);
      localStorage.setItem("acceptEmailHeader", acceptHeaderJSON);

      let initialValue = `Hi [Name],
Thank you for reaching out to [Lawfirm Name]. We have reviewed your information and would like to move forward with your case. Please find attached an engagement letter. [downloadURL]
We look forward to working with you.
Best,
[Lawfirm Name]`;
      let initialValueJson = JSON.stringify(initialValue);
      localStorage.setItem("acceptEmailBody", initialValueJson);

      let referEmailHeaderValue = `[Lawfirm Name] referred your case!`;
      let referEmailHeaderJSON = JSON.stringify(referEmailHeaderValue);
      localStorage.setItem("referEmailHeader", referEmailHeaderJSON);

      let referEmailBodyValue = `Hi [Name],
Thank you for reaching out to [Lawfirm Name]. We have reviewed your information and would like to refer you to another lawyer. Please respond to this email to confirm if you would like us to forward your information.
Best,
[Lawfirm Name]`;
      let referEmailBodyJSON = JSON.stringify(referEmailBodyValue);
      localStorage.setItem("referEmailBody", referEmailBodyJSON);

      let rejectEmailHeaderValue = `[Lawfirm Name] declined your case.`;
      let rejectEmailHeaderJSON = JSON.stringify(rejectEmailHeaderValue);
      localStorage.setItem("rejectEmailHeader", rejectEmailHeaderJSON);

      let rejectEmailBodyValue = `Hi [Name],
Thank you for reaching out to [Lawfirm Name]. We have reviewed your information and unfortunately, we won't be able to take your case.
Best,
[Lawfirm Name]`;


      
      let rejectEmailBodyJSON = JSON.stringify(rejectEmailBodyValue);

      localStorage.setItem("rejectEmailBody", rejectEmailBodyJSON);
      // TODO: add here sessions so dashboard doesn't have to read it each time (FIRST: check what happens with reload screen and eliminating them)
      
      let acceptEmailOnlyHeaderValue = `[Lawfirm Name] reviewed your case!`;
      let acceptEmailOnlyHeaderJSON = JSON.stringify(acceptEmailOnlyHeaderValue);
      localStorage.setItem("acceptEmailOnlyHeader", acceptEmailOnlyHeaderJSON);



      let acceptEmailOnlyBodyValue = `Hi [Name],
Thank you for reaching out to [Lawfirm Name]. We have reviewed your information and would like to move forward with your case. 
We look forward to working with you.


Best,
[Lawfirm Name]`;
      let acceptEmailOnlyBodyJSON = JSON.stringify(acceptEmailOnlyBodyValue);
      localStorage.setItem("acceptEmailOnlyBody", acceptEmailOnlyBodyJSON);


      let acceptEmailCalendarHeaderValue = `[Lawfirm Name] reviewed your case!`;
      let acceptEmailCalendarJSON = JSON.stringify(acceptEmailCalendarHeaderValue);
      localStorage.setItem("acceptEmailCalendarHeader", acceptEmailCalendarJSON);


      let acceptEmailCalendarBodyValue = `Hi [Name],
Thank you for reaching out to [Lawfirm Name]. We have reviewed your information and would like to move forward with your case. Please schedule a meeting with the following calendar link: [calendarURL].
We look forward to working with you.
Best,
[Lawfirm Name]`;
      let acceptEmailCalendarBodyJSON = JSON.stringify(acceptEmailCalendarBodyValue);
      localStorage.setItem("acceptEmailCalendarBody", acceptEmailCalendarBodyJSON);


    });
  };

  const requiresLoginLawyer = (Component) => {
    //console.log("isSignedIn: ", isSignedIn, "isLawyer: ", isLawyer)
    // let isOnboarded = localStorage.getItem('isOnboarded')
    if (isSignedIn && isLawyer) {
      console.log("isOnboarded: ", isOnboarded);
      if (isOnboarded) {
        return Component;
      } else {
        return <Navigate to="/onboardingLawyer" />;
      }
    } else {
      return <Navigate to="/loginLawyer" />;
    }
  };

  const onFirestoreSessionCreated = (newFirestoreId) => {
    // setFirestoreId(newFirestoreId);
    localStorage.setItem("firestoreId", newFirestoreId);
    console.log("Firestore ID created: ", newFirestoreId);
  };

  const getSessionInfo = (firestoreId) => {
    // Get info from Firestore and set it in the state
    console.log("Firestore ID in getSessionInfo: ", firestoreId);
    localStorage.setItem("firestoreId", firestoreId);

    

    
    let lawyerLawfirm = localStorage.getItem("lawyerLawfirm");

    
    console.log("Lawfirm name TESTING:", lawyerLawfirm);

    const lawyerQuery = query(
      collection(db, "lawyers"),
      where("lawyerLawfirm", "==", lawyerLawfirm),
    );

    getDocs(lawyerQuery).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // Persist disclaimer and hero statement from the lawyer's document
        localStorage.setItem("disclaimer", doc.data().disclaimer || "");
        setDisclaimer(doc.data().disclaimer || "");
  
    // Only one iteration over the querySnapshot is required
    return;
  });
});


    const docRef = doc(db, "leads", firestoreId);
    getDoc(docRef).then((doc) => {
      // IMPORTANT: json.stringify arrays and maps before saving them to localStorage

      localStorage.setItem("name", doc.data().name || "");
      localStorage.setItem("LastName", doc.data().LastName || "");
      localStorage.setItem("company", doc.data().company || "");
      localStorage.setItem("email", doc.data().email || "");
      localStorage.setItem("phone", doc.data().phone || "");
      localStorage.setItem("address", doc.data().address || "");
      localStorage.setItem("zipCode", doc.data().zipCode || "");
      localStorage.setItem("selectedLawfirmSize", doc.data().selectedLawfirmSize || "");
      localStorage.setItem("selectedStates", doc.data().selectedStates || "");
      
      localStorage.setItem("generalCaseAnswer", doc.data().issue || "");
      localStorage.setItem("idealResult", doc.data().intent || "");

      localStorage.setItem("lawyerLawfirm", doc.data().lawfirm || "");

      
      localStorage.setItem("legalSector", doc.data().legalSector || "");
      localStorage.setItem("legalSubsector", doc.data().legalSubsector || "");
      localStorage.setItem(
        "legalSubsubsector",
        doc.data().legalSubsubsector || "",
      );

      let clientDetailsQuestionsJson = JSON.stringify(
        doc.data().clientDetailsQuestions || [],
      );
      let clientDetailsAnswersJson = JSON.stringify(
        doc.data().clientDetailsAnswers || [],
      );
      localStorage.setItem(
        "clientDetailsQuestions",
        clientDetailsQuestionsJson,
      );
      localStorage.setItem("clientDetailsAnswers", clientDetailsAnswersJson);


      let finalQuestionsJson = JSON.stringify(doc.data().finalQuestionsLawfirm || []);
      let finalQuestionsAnswersJson = JSON.stringify(
        doc.data().finalQuestionsAnswers || [],
      );
      localStorage.setItem("finalQuestions", finalQuestionsJson);
      localStorage.setItem("finalQuestionsAnswers", finalQuestionsAnswersJson);



      let specificQuestionsJson = JSON.stringify(
        doc.data().specificLegalQuestions || [],
      );
      let specificAnswersJson = JSON.stringify(
        doc.data().specificLegalAnswers || [],
      );
      localStorage.setItem("specificQuestions", specificQuestionsJson);
      localStorage.setItem("specificAnswers", specificAnswersJson);

      let docsListJson = JSON.stringify(doc.data().docsNamesList || []);
      let docsTypeJson = JSON.stringify(doc.data().UploadedDocsType || []);
      let docsListAddedUserJson = JSON.stringify(
        doc.data().docsListAddedUser || [],
      );
      localStorage.setItem("docsList", docsListJson);
      localStorage.setItem("docsType", docsTypeJson);
      localStorage.setItem("docsListAddedUser", docsListAddedUserJson);

      localStorage.setItem(
        "userUploadedDocs",
        doc.data().userUploadedDocs || false,
      );

      let specificQuestionsWithAIJson = JSON.stringify(
        doc.data().specificQuestionsWithAI || [],
      );
      let specificAnswersWithAIJson = JSON.stringify(
        doc.data().specificAnswersWithAI || [],
      );
      localStorage.setItem(
        "specificQuestionsWithAI",
        specificQuestionsWithAIJson,
      );
      localStorage.setItem("specificAnswersWithAI", specificAnswersWithAIJson);

      

      localStorage.setItem("hourlyRate", doc.data().hourlyRate || "");
      localStorage.setItem(
        "flatFeeMatterChosen",
        doc.data().flatFeeMatterChosen || "",
      );
      localStorage.setItem("flatFeeValue", doc.data().flatFeeValue || "");

      let missingInfoListJson = JSON.stringify(doc.data().missingInfo || []);
      let pendingDocsListJson = JSON.stringify(doc.data().pendingDocs || []);
      let followUpQuestionsDocsJson = JSON.stringify(
        doc.data().followUpQuestionsDocs || [],
      );
      localStorage.setItem("missingInfoList", missingInfoListJson);
      localStorage.setItem("pendingDocsList", pendingDocsListJson);
      localStorage.setItem("followUpQuestionsDocs", followUpQuestionsDocsJson);

      localStorage.setItem(
        "submissionStatus",
        doc.data().submissionStatus || "Incomplete",
      );

      localStorage.setItem(
        "followUpQuestionsReady",
        JSON.stringify(doc.data().followUpQuestionsReady || false),
      );
      localStorage.setItem(
        "docsToUploadReady",
        JSON.stringify(doc.data().docsToUploadReady || false),
      );
      localStorage.setItem(
        "finalQuestionsReady",
        JSON.stringify(doc.data().finalQuestionsReady || true),
      );

      localStorage.setItem("disclaimer", doc.data().disclaimer || "");
      console.log("Local Storage: ", localStorage);
    });
  };

  const clearStates = () => {
    // Empty localStorage (with the exception of lawyerLawfirm, isLawyer, isSignedIn, userId, shortIntake, personalizedIntake)

    let lawyerLawfirm = localStorage.getItem("lawyerLawfirm");
    // let isLawyer = localStorage.getItem('isLawyer');
    let isSignedIn = localStorage.getItem("isSignedIn");
    let userId = localStorage.getItem("userId");
    let shortIntake = localStorage.getItem("shortIntake");
    let personalizedIntake = localStorage.getItem("personalizedIntake");

    let disclaimer = localStorage.getItem("disclaimer");
    localStorage.clear();

    localStorage.setItem("lawyerLawfirm", lawyerLawfirm);
    // localStorage.setItem('isLawyer', isLawyer);
    localStorage.setItem("disclaimer", disclaimer);
    localStorage.setItem("isSignedIn", isSignedIn);
    localStorage.setItem("userId", userId);
    localStorage.setItem("shortIntake", shortIntake);
    localStorage.setItem("personalizedIntake", personalizedIntake);
  };


  const onSessionSelected = (firestoreId) => {
    
    clearStates();

    let lawyerLawfirm = localStorage.getItem("lawyerLawfirm");

    let disclaimer = localStorage.getItem("disclaimer");
//     console.log("Lawfirm name TESTING:", lawyerLawfirm);

//     const lawyerQuery = query(
//       collection(db, "lawyers"),
//       where("lawyerLawfirm", "==", lawyerLawfirm),
//     );

//     getDocs(lawyerQuery).then((querySnapshot) => {
//       querySnapshot.forEach((doc) => {
//         // Persist disclaimer and hero statement from the lawyer's document
//         localStorage.setItem("disclaimer", doc.data().disclaimer || "");
//         setDisclaimer(doc.data().disclaimer || "");
  
//     // Only one iteration over the querySnapshot is required
//     return;
//   });
// });

    console.log("TESTING DISCLAMER 12345:", disclaimer);
    getSessionInfo(firestoreId);
  };

  const onNewIntake = async () => {
    // clear all client states

    

    clearStates();

    
    let lawyerLawfirm = localStorage.getItem("lawyerLawfirm");
    let userId = localStorage.getItem("userId");
    
    console.log("Lawfirm name:", lawyerLawfirm);

    // Create new document in Firestore
    addDoc(collection(db, "leads"), {
      userID: userId,
      lawfirm: lawyerLawfirm,
      createdDate: new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }),
      submissionStatus: "Incomplete",
    })
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        // setFirestoreId(docRef.id);
        localStorage.setItem("firestoreId", docRef.id);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });

    // TODO: Set clientDetailsQuestions (requiredquestions from lawyer that matches the lawfirm)
    // Initialize Firestore query to retrieve lawyer documents by law firm identifier
    const lawyerQuery = query(
      collection(db, "lawyers"),
      where("lawyerLawfirm", "==", lawyerLawfirm),
    );

    // Execute the query against Firestore
    await getDocs(lawyerQuery).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // Extract and persist required questions from the lawyer's document
        const requiredQuestionsLawfirm = doc.data().requiredQuestionsLawfirm || [];
        localStorage.setItem("clientDetailsQuestions", JSON.stringify(requiredQuestionsLawfirm));
        const finalQuestionsLawfirm = doc.data().finalQuestionsLawfirm || [];
        localStorage.setItem("finalQuestionsLawfirm", JSON.stringify(finalQuestionsLawfirm ));

        // Persist disclaimer and hero statement from the lawyer's document
        localStorage.setItem("disclaimer", doc.data().disclaimer || "");
        
        localStorage.setItem("heroStatement", doc.data().heroStatement || "");

        try {
          const lawyerFirestoreId = doc.id;
          const logoExtensions = ['png', 'jpg', 'jpeg'];

          const retrieveLogo = async () => {
            for (const extension of logoExtensions) {
              const logoStoragePath = `lawfirms/${lawyerFirestoreId}/personalization/logo.${extension}`;
              const logoRef = ref(storage, logoStoragePath);

              try {
                const url = await getDownloadURL(logoRef);
                localStorage.setItem("logoURL", url);
                console.log(`Logo found: ${url}`);
                return;
              } catch (error) {
                console.error(`Logo with .${extension} extension not found. Trying next extension.`);
              }
            }
            console.error('No logo found with any of the expected extensions.');
          };

           retrieveLogo().catch((error) => {
            console.error('Error retrieving logo:', error);
          });
        } catch (error) {
          console.error("Error processing new intake: ", error);
        }


    // Only one iteration over the querySnapshot is required
    return;
  });
});
  };

  const onLogout = () => {
    //Empty localStorage
    clearStates();
    localStorage.removeItem("userId");
    localStorage.removeItem("isSignedIn");
  };

  const onLogoutLawyer = () => {
    //Empty localStorage
    clearStates();
    localStorage.removeItem("userId");
    localStorage.removeItem("isSignedIn");
    localStorage.removeItem("isLawyer");
    localStorage.removeItem("lawyerSessions");
    localStorage.removeItem("lawyerSessionsFirestoreId");
    localStorage.removeItem("lawyerLawfirm");
  };

  return (
    <ErrorBoundary>
      <Router>
        {/* Update to 3 Navbars: One for welcome intake, one for intake and one for home and Lawyer /> */}
        {/* {<Navbar isSignedIn={isSignedIn} isLawyer = {isLawyer} lawyerLawfirm = {lawyerLawfirm}/>} */}

        <Routes>
          <Route path="/intake/:lawfirm" element={<WelcomeIntake />} />
          <Route
            path="/intake/:lawfirm/:personalized"
            element={<WelcomeIntake />}
          />
          <Route
            path="/intake/sessions"
            element={
              <IntakeSessions
                onSessionSelected={onSessionSelected}
                onNewIntake={onNewIntake}
              />
            }
          />
          <Route path="/intake/disclaimer/" element={<Disclaimer disclaimer = {disclaimer}/>} />
          {/*<Route path="/intake/selectfield" element={<SelectField/>} /> */}
          <Route
            path="/intake/generalinformation"
            element={<GeneralInformation />}
          />
          <Route path="/intake/casedetails" element={<CaseDetails />} />
          <Route
            path="/intake/clientdetails"
            element={<ClientDetailsQuestions />}
          />
          <Route
            path="/intake/legalSector"
            element={<LegalSectorConfirmation />}
          />
          <Route
            path="/intake/casedetailsspecific"
            element={<CaseDetailsSpecific />}
          />
          <Route path="/intake/documentsupload" element={<DocumentsUpload />} />
          <Route path="/intake/halfway" element={<HalfwayScreen />} />
          {/* <Route path="/intake/followUpQuestions" element={<CaseDetailsSpecificFollowUp />} /> */}
          <Route path="/intake/costprediction" element={<CostPrediction />} />
          <Route path="/intake/finalquestions" element={<FinalQuestions />} />
          <Route
            path="/intake/reviewandsecurity"
            element={<ReviewAndSecurity />}
          />
          <Route
            path="/intake/submissionconfirmation"
            element={<SubmissionConfirmation />}
          />
          <Route
            path="/intake/signup"
            element={
              <SignUpPage
                onFirestoreSessionCreated={onFirestoreSessionCreated}
                onLogin={onLogin}
              />
            }
          />
          <Route
            path="/intake/login"
            element={<LoginPage onLogin={onLogin} />}
          />
          <Route
            exact
            path="/logout"
            element={<LogoutPage onLogout={onLogout} />}
          />

          <Route path="/landingPage" element={<Home />} />
          <Route exact path="/TermsOfService" element={<TermsOfService />} />
          {/* <Route exact path="/FullDisclaimer" element={<FullDisclaimer/>} /> */}

          <Route
            path="/dashboard"
            element={requiresLoginLawyer(<Dashboard />)}
          />
          <Route
            path="/clientInfo/:firebaseId"
            element={requiresLoginLawyer(<ClientInfo />)}
          />
          <Route
            exact
            path="/settings"
            element={requiresLoginLawyer(<LawyerSettings />)}
          />
          <Route
            exact
            path="/simulation"
            element={requiresLoginLawyer(<LawyerIntakeSimulation />)}
          />
          <Route
            exact
            path="/loginLawyer"
            element={
              <LoginPageLawyer
                onLoginLawyer={onLoginLawyer}
                onLogoutLawyer={onLogoutLawyer}
              />
            }
          />
          <Route
            exact
            path="/logoutLawyer"
            element={<LogoutLawyer onLogoutLawyer={onLogoutLawyer} />}
          />
          <Route
            exact
            path="/signupLawyer"
            element={
              <SignUpPageLawyer
                onLoginLawyer={onLoginLawyer}
                onFirestoreSessionCreated={onFirestoreSessionCreated}
              />
            }
          />
          <Route
            exact
            path="/onboardingLawyer"
            element={<OnboardingLawyer />}
          />

          <Route
            exact
            path="/scheduleDemo"
            element={<ScheduleDemo />}
          />

        <Route path="/" element={<LandingPageHTML />} />

        <Route
            exact
            path="/legalCoPilot"
            element={requiresLoginLawyer(<LegalCoPilot />)}
          />

        </Routes>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
