// InlinePastIntake.js
import React, { useEffect, useState } from "react";
import { db } from "../config/firebase-config";
import { doc, updateDoc } from "firebase/firestore";

const SimInlinePastIntake = ({ sessionInfo, firestoreId, onSessionSelected, onEliminateSession }) => {
  const [status, setStatus] = useState("");
  const [intakeSummary, setIntakeSummary] = useState("");

  useEffect(() => {
    setStatus(sessionInfo.submissionStatus === "Submitted" ? "Submitted" : "Incomplete");
  }, [sessionInfo.submissionStatus]);

  useEffect(() => {
    const summary = sessionInfo.issue ? sessionInfo.issue.split(".")[0] + "..." : "";
    setIntakeSummary(summary);
  }, [sessionInfo.issue]);

  const handleSelectIntake = () => {
    onSessionSelected(sessionInfo, firestoreId);
  };

  const handleEliminate = () => {
    if (window.confirm("Are you sure you want to eliminate this intake?")) {
      const docRef = doc(db, "leads", firestoreId);
      updateDoc(docRef, { eliminatedByUser: "eliminated" })
        .then(() => {
          onEliminateSession(firestoreId);
        })
        .catch((error) => {
          console.error("Error eliminating intake: ", error);
        });
    }
  };

  return (
    <div className="mb-1 container">
      <button
        className="delete"
        style={{ position: "absolute", top: "5px", right: "5px" }}
        onClick={handleEliminate}
      ></button>
      <div
        className="box"
        onClick={handleSelectIntake}
        style={{ cursor: "pointer" }}
      >
        <div className="is-6">Issue: {intakeSummary}</div>
        <div className="columns">
          <div className="column"> - Created: {sessionInfo.createdDate}</div>
          <div className="column"> - Status: {status}</div>
        </div>
      </div>
    </div>
  );
};

export default SimInlinePastIntake;
