// Disclaimer.js
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import IntakeSideMenu from "../components/IntakeSideMenu";
import NavbarFooter from "../components/NavbarFooter";
import NavbarIntake from "../components/NavbarIntake";
import FooterIntake from "../components/FooterIntake";
//import './WelcomeIntake.css';
import LogRocket from "logrocket";

import useLocalStorage from "../components/useLocalStorage";
import { db } from "../config/firebase-config";
import {
  collection,
  where,
  query,
  getDocs,
  updateDoc,
  doc,
  getDoc,
  addDoc,
} from "firebase/firestore";
//initialize logrocket for session tracking
LogRocket.init("gd8syp/caddiapp");

LogRocket.getSessionURL(function (sessionURL) {
  mixpanel.track("LogRocket", { sessionURL: sessionURL });
});

function Disclaimer(props) {
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);
  const [disclaimer, setDisclaimer] = useLocalStorage("disclaimer", "");

  

    
    console.log("Lawfirm name TESTING:", lawyerLawfirm);

    const lawyerQuery = query(
      collection(db, "lawyers"),
      where("lawyerLawfirm", "==", lawyerLawfirm),
    );

    getDocs(lawyerQuery).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
      
        localStorage.setItem("disclaimer", doc.data().disclaimer || "");
        setDisclaimer(doc.data().disclaimer || "");
  
    // Only one iteration over the querySnapshot is required
    return;
  });
});

  console.log("Full Local storage", localStorage);
  console.log("lawyerLawfirm", lawyerLawfirm);
  console.log("disclaimer", disclaimer);

  let disclaimerText = disclaimer.replace(/\${lawfirm}/g, lawyerLawfirm);

  console.log("lawyerLawfirm", lawyerLawfirm);
  console.log("disclaimerText", disclaimerText);
  //Replace ${lawfirm}

//   let disclaimerText = `All information provided to the firm by you as part of this packet and at the Initial Consultation is protected by the Attorney Client Privilege. Please understand, however, that by filling out this form or meeting with you to discuss your legal concerns, ${lawyerLawfirm} has not undertaken your representation. Meeting with the firm for an Initial Consultation does not bind this firm or the potential client (you) in any way to provide further work or legal services.

// Should this firm undertake your representation, our arrangement must be formalized in writing and consideration must be paid for our services beyond the cost of the Initial Consultation.
    
// Essentially, the Initial Consultation and/or the intake form is an opportunity for the firm to meet you and gather information about your legal concerns. It is also an opportunity for you and the firm to determine whether you are likely to have a strong, productive working relationship.`;

  // Add this additional disclaimer for ShanzePartners
  // if (lawyerLawfirm === "ShanzePartners") {
  //   setDisclaimer(disclaimer => disclaimerText + `Thank you for contacting Shanze Partners. Before any legal advice can be offered, ethical rules require us to run a conflict check to ensure your interests are not adverse or in conflict with our other clients. This process is necessary to protect both your and our other clients' interests.`);
  // }

  useEffect(() => {
    // Disable Next Button (TODO: CHANGE THIS FOR setIsValid() like in the next few screens)
    const nextButton = document.querySelector("#nextButton");
    nextButton.disabled = true;
  }, []);

  // TODO: Finish this logic to personalize intake
  let personalizedIntake = localStorage.getItem("personalizedIntake");
  if (personalizedIntake) {
    console.log("This is a personalized intake");
    // TODO: Add here logic to personalize the intake form. Parameters are in Firestore.
  }

  const handleNextClick = () => {
    console.log("Next Button Clicked");

    // Save information from user and navigate to the next page
    navigate("/intake/generalinformation");
    mixpanel.track("User accepted intake disclaimer");
  };

  const handleBackClick = () => {
    //navigate('/intake/selectfield');
    navigate("/intake/sessions");
  };

  const checkboxHandler = () => {
    const checkbox = document.querySelector("#agree");
    const nextButton = document.querySelector("#nextButton");
    checkbox.checked = true;
    // Enable Next Button
    //nextButton.disabled = false;
    setIsFormValid(true);
  };

  const handleCaddiClicked = () => {
    console.log("Caddi Button Clicked");
    navigate("/");
  };

  return (
    <>
      <div className="box dark-green-box">
        <div className="columns is-mobile">
          <div className="box white-box column is-3">
            <NavbarIntake
              isSignedIn={isSignedIn}
              isLawyer={isLawyer}
              lawyerLawfirm={lawyerLawfirm} 
            />
          </div>
          <div className="box light-green-box column">
            <div className="columns is-centered">
              <div className="column is-2"></div>
              <div className="column">
                <div className="title is-4 has-text-centered">Disclaimer</div>
                <div className="subtitle is-6 has-text-centered">
                   Disclaimer
                </div>
                <div className="mx-4 my-3">
                  <div
                    className="box has-background-white"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {disclaimerText || props.disclaimerText}
                  </div>
                  <br></br>
                  <div className="has-text-centered">
                    <input
                      type="checkbox"
                      id="agree"
                      onChange={checkboxHandler}
                    />
                    <label htmlFor="agree">
                      {" "}
                      I acknowledge that I have read this entire disclaimer, and
                      agree and understand the limited nature of the initial
                      intake form.
                    </label>
                  </div>
                </div>
              </div>
              <div className="column is-2"></div>
            </div>

            <NavbarFooter
              handleBackClick={handleBackClick}
              handleNextClick={handleNextClick}
              isFormValid={isFormValid}
            />
          </div>
        </div>
        <FooterIntake />
      </div>
    </>
  );
}

export default Disclaimer;
