// Disclaimer.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  getFirestore,
  getDoc,
} from "firebase/firestore";
import { db } from "../config/firebase-config";
import IntakeSideMenu from "../components/IntakeSideMenu";
import mixpanel from "mixpanel-browser";
import NavbarIntake from "../components/NavbarIntake";
import NavbarFooter from "../components/NavbarFooter";
import FooterIntake from "../components/FooterIntake";
import LogRocket from "logrocket";
import "./WelcomeIntake.css";

import useLocalStorage from "../components/useLocalStorage";

function GeneralInformation(props) {
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const [firestoreId, setFirestoreId] = useLocalStorage("firestoreId", "");
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);
  const [submissionStatus, setSubmissionStatus] = useLocalStorage(
    "submissionStatus",
    "Incomplete",
  );

  const [name, setName] = useLocalStorage("name", "");
  const [LastName, setLastName] = useLocalStorage("LastName", "");
  const [company, setCompany] = useLocalStorage("company", "");
  const [email, setEmail] = useLocalStorage("email", "");
  const [phone, setPhone] = useLocalStorage("phone", "");
  const [address, setAddress] = useLocalStorage("address", "");
  const [zipCode, setZipCode] = useLocalStorage("zipCode", "");

  const isReadOnly = submissionStatus === "Submitted";

  //initialize logrocket for session tracking
  LogRocket.init("gd8syp/caddiapp");

  LogRocket.getSessionURL(function (sessionURL) {
    mixpanel.track("LogRocket", { sessionURL: sessionURL });
  });

  useEffect(() => {
    console.log("Loading GeneralInformation");

    document.getElementById("FirstName").value = name || "";
    document.getElementById("LastName").value = LastName || "";
    document.getElementById("company").value = company || "";
    document.getElementById("email").value = email || "";
    document.getElementById("phone").value = phone || "";
    document.getElementById("address").value = address || "";
    document.getElementById("zipCode").value = zipCode || "";

    // Set form to valid if required fields are filled out
    if (name && LastName && email) {
      setIsFormValid(true);
    }
  }, []);

  const slackIntakeIntitiated = async (status) => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlClientPostAnalysis = apiHostname
        ? `https://${apiHostname}/api/intakeInitiated`
        : "http://localhost:5001/api/intakeInitiated";

      const response = await fetch(apiUrlClientPostAnalysis, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firestoreId: firestoreId,
          clientLawfirm: lawyerLawfirm,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response:");
      } else {
        console.log("Got bad response");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const nameText = String(document.getElementById("FirstName").value);
    const LastNameText = String(document.getElementById("LastName").value);
    const companyText = String(document.getElementById("company").value);
    const emailText = String(document.getElementById("email").value);
    const phoneText = String(document.getElementById("phone").value);
    const addressText = String(document.getElementById("address").value);
    const zipCodeText = String(document.getElementById("zipCode").value);

    // console.log("Name: ", nameText, "Company: ", companyText, "Email: ", emailText, "Phone: ", phoneText, "Address: ", addressText);
    // props.onGeneralInformation(nameText, LastNameText, companyText, emailText, phoneText, addressText);
    console.log("FirebaseID at submitting GeneralInformation: ", firestoreId);

    navigate("/intake/casedetails");

    if (!isReadOnly) {
      // Save info in firestore only if info is not there
      if (firestoreId) {
        const docRef = doc(db, "leads", firestoreId);
        updateDoc(docRef, {
          name: nameText,
          LastName: LastNameText,
          email: emailText,
          company: companyText,
          phone: phoneText,
          address: addressText,
          zipCode: zipCodeText,
          lawfirm: lawyerLawfirm,
          createdDate: new Date().toLocaleDateString("en-US", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }),
          submissionStatus: "Incomplete",
        }).then(() => {
          console.log("Contact information updated with ID: ", firestoreId);
          mixpanel.track("User Submitted General Information in Intake", {
            "Firebase User Id": firestoreId,
          });
          slackIntakeIntitiated(
            `Client ${firestoreId} has started their intake with Caddi.`,
          );
          LogRocket.identify(firestoreId, {
            name: nameText + " " + LastNameText,
            company: companyText,
            firestoreId: firestoreId,
            email: emailText,
            phone: phoneText,
          });
        });
      }
    }
  };

  // Next button should be disable until all fields are filled out (except for company)
  const handleInputChange = () => {
    const nameInput = document.getElementById("FirstName").value;
    const LastnameInput = document.getElementById("LastName").value;
    const companyInput = document.getElementById("company").value;
    const emailInput = document.getElementById("email").value;
    const phoneInput = document.getElementById("phone").value;
    const addressInput = document.getElementById("address").value;
    const zipCodeInput = document.getElementById("zipCode").value;

    // Update the state variables
    setName(nameInput);
    setLastName(LastnameInput);
    setCompany(companyInput);
    setEmail(emailInput);
    setPhone(phoneInput);
    setAddress(addressInput);
    setZipCode(zipCodeInput);

    // Verify emailInput has the right format
    if (emailInput.includes("@")) {
      console.log("Email has the right format");
      // Update the form validity based on the input values
      setIsFormValid(nameInput && LastnameInput && emailInput && phoneInput);
    } else {
      console.log("Email does not have the right format");
    }
  };

  const handleCaddiClicked = () => {
    console.log("Caddi Button Clicked");
    navigate("/");
  };

  const handleBackClick = () => {
    navigate("/intake/disclaimer");
  };

  return (
    <>
      <div className="box dark-green-box">
        <div className="columns is-mobile">
          <div className="box white-box column is-3">
            <NavbarIntake
              isSignedIn={isSignedIn}
              isLawyer={isLawyer}
              lawyerLawfirm={lawyerLawfirm}
            />
          </div>
          <div className="box light-green-box column">
            <div className="columns is-centered">
              <div className="column is-2"></div>
              <div className="column ">
                <div className="title is-4 has-text-centered mt-2">
                  General Information
                </div>
                <div className="subtitle is-6 has-text-centered">
                  Enter your details below
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="field is-horizontal">
                    <div className="field-label">
                      <label
                        className="label has-text-left"
                        htmlFor="FirstName"
                      >
                        Legal First Name*
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control ">
                          <input
                            name="name"
                            className="input mb-2"
                            id="FirstName"
                            onChange={handleInputChange}
                            readOnly={isReadOnly}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label">
                      <label className="label has-text-left" htmlFor="LastName">
                        Last Name*
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            name="name"
                            className="input mb-2"
                            id="LastName"
                            onChange={handleInputChange}
                            readOnly={isReadOnly}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label">
                      <label className="label has-text-left" htmlFor="company">
                        Company
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            name="company"
                            className="input mb-2"
                            id="company"
                            onChange={handleInputChange}
                            readOnly={isReadOnly}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label">
                      <label className="label has-text-left" htmlFor="email">
                        Email*
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            name="email"
                            className="input mb-2"
                            id="email"
                            onChange={handleInputChange}
                            readOnly={isReadOnly}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label">
                      <label className="label has-text-left" htmlFor="phone">
                        Phone*
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            name="phone"
                            className="input mb-2"
                            id="phone"
                            onChange={handleInputChange}
                            readOnly={isReadOnly}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label">
                      <label className="label has-text-left" htmlFor="address">
                        Address*
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            name="address"
                            className="input mb-2"
                            id="address"
                            onChange={handleInputChange}
                            readOnly={isReadOnly}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label">
                      <label className="label has-text-left" htmlFor="zipCode">
                        Zip Code*
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            name="zipCode"
                            className="input mb-2"
                            id="zipCode"
                            onChange={handleInputChange}
                            readOnly={isReadOnly}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="column is-2"></div>
            </div>
            <NavbarFooter
              handleBackClick={handleBackClick}
              handleNextClick={handleSubmit}
              isFormValid={isFormValid}
            />
          </div>
        </div>
        <FooterIntake />
      </div>
    </>
  );
}

export default GeneralInformation;
