import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  getFirestore,
  getDoc,
} from "firebase/firestore";
import { db } from "../config/firebase-config";
import NavbarFooter from "../components/NavbarFooter";
import IntakeSideMenu from "../components/IntakeSideMenu";
import FooterIntake from "../components/FooterIntake";
import NavbarIntake from "../components/NavbarIntake";
import mixpanel from "mixpanel-browser";

import useLocalStorage from "../components/useLocalStorage";

//import './ReviewAndSecurity.css';

function AgreementsSecurity(props) {
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(true);
  const [firestoreId, setFirestoreId] = useLocalStorage("firestoreId", "");
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);
  const [submissionStatus, setSubmissionStatus] = useLocalStorage(
    "submissionStatus",
    "Incomplete",
  );

  const [docsList, setDocsList] = useLocalStorage("docsList", []);
  const [pendingDocsList, setPendingDocsList] = useLocalStorage(
    "pendingDocsList",
    [],
  );
  const [hourlyRate, setHourlyRate] = useLocalStorage("hourlyRate", "");
  const [flatFeeMatterChosen, setFlatFeeMatterChosen] = useLocalStorage(
    "flatFeeMatterChosen",
    "",
  );
  const [flatFeeValue, setFlatFeeValue] = useLocalStorage("flatFeeValue", "");

  // TODO: Guardarails to make sure that is something fails, do not display it

  const generateIntakeDocs = async (status) => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlClientSubmittedIntake = apiHostname
        ? `https://${apiHostname}/api/clientSubmittedIntake`
        : "http://localhost:5001/api/clientSubmittedIntake";

      const response = await fetch(apiUrlClientSubmittedIntake, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firestoreId: firestoreId,
          status: status,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response:");
      } else {
        console.log("Got bad response");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const generateSummary = async () => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlGenerateClientSummary = apiHostname
        ? `https://${apiHostname}/api/generateClientSummary`
        : "http://localhost:5001/api/generateClientSummary";

      const response = await fetch(apiUrlGenerateClientSummary, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firestoreId: firestoreId,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response:");
      } else {
        console.log("Got bad response");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const generateSuitabilityScore = async () => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlGenerateSuitabilityScore = apiHostname
        ? `https://${apiHostname}/api/suitabilityScore`
        : "http://localhost:5001/api/suitabilityScore";

      const response = await fetch(apiUrlGenerateSuitabilityScore, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firestoreId: firestoreId,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response:");
      } else {
        console.log("Got bad response");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const generatePointsOfInterest = async () => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlGeneratePointsOfInterest = apiHostname
        ? `https://${apiHostname}/api/pointsOfInterest`
        : "http://localhost:5001/api/pointsOfInterest";

      const response = await fetch(apiUrlGeneratePointsOfInterest, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firestoreId: firestoreId,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response:");
      } else {
        console.log("Got bad response");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const generateSummaryDocs = async () => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlGenerateDocsSummaries = apiHostname
        ? `https://${apiHostname}/api/documentTagsSummary`
        : "http://localhost:5001/api/documentTagsSummary";

      const response = await fetch(apiUrlGenerateDocsSummaries, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firestoreId: firestoreId,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response:");
      } else {
        console.log("Got bad response");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const generatePostAnalysis = async (status) => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlClientPostAnalysis = apiHostname
        ? `https://${apiHostname}/api/postAnalysis`
        : "http://localhost:5001/api/postAnalysis";

      const response = await fetch(apiUrlClientPostAnalysis, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firestoreId: firestoreId,
          status: status,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response:");
      } else {
        console.log("Got bad response");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleSubmit = () => {
    navigate("/intake/submissionconfirmation");

    // TODO: Analyze whether we want to show in dashboard leads that haven't finished submission
    if (submissionStatus === "Incomplete") {
      // Save submission in Firebase
      const docRef = doc(db, "leads", firestoreId);
      updateDoc(docRef, {
        status: "Waiting for review",
        submissionStatus: "Submitted",
        createdDate: new Date().toLocaleDateString("en-US", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }),
      }).then(() => {
        console.log("User has submitted the form");
      });

      // Alert backend a submission has been made in order to alert user and run all the analysis needed
      let status = "submitted";
      // generateIntakeDocs(status);
      generateSummary();
      // generateScores();
      // generateSummaryDocs();
      generatePostAnalysis(status);

      mixpanel.track("User Reviewed and Submitted Intake", {
        "Firebase User Id": firestoreId,
      });
    }
  };

  const handleBackClick = () => {
    navigate("/intake/finalquestions");
  };

  const handleCaddiClicked = () => {
    console.log("Caddi Button Clicked");
    navigate("/");
  };

  return (
    <>
      <div className="box dark-green-box">
        <div className="columns is-mobile">
          <div className="box white-box column is-3">
            <NavbarIntake
              isSignedIn={isSignedIn}
              isLawyer={isLawyer}
              lawyerLawfirm={lawyerLawfirm}
            />
          </div>
          <div className="box light-green-box column">
            <div className="columns is-centered">
              <div className="column is-2"></div>

              <div
                className="column has-text-centered"
                style={{ marginTop: "10%" }}
              >
                <figure>
                  <img src="/Final.png" alt="Placeholder image" width="64" />
                </figure>

                <div className="title is-4 my-5">
                  Taking a final look over your submission!
                </div>
              </div>

              {/* TODO: Show pricing here and let the user accept it */}

              <div className="column is-3"></div>
            </div>
            <br></br>
            <br></br>

            <NavbarFooter
              handleBackClick={handleBackClick}
              handleNextClick={handleSubmit}
              isFormValid={isFormValid}
            />
          </div>
        </div>
        <FooterIntake />
      </div>
    </>
  );
}

export default AgreementsSecurity;
