import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faCog,
  faDashboard,
  faArrowLeft,
  faArrowRight,
  faHeadset
} from "@fortawesome/free-solid-svg-icons";
import "./LateralMenu.css";
import useLocalStorage from "./useLocalStorage";

const LateralMenu = (props) => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useLocalStorage("isCollapsed", false);

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleHover = (e) => {
    e.currentTarget.classList.add("has-text-success");
    e.currentTarget.classList.remove("has-text-white");
  };

  const handleLeave = (e) => {
    e.currentTarget.classList.remove("has-text-success");
    e.currentTarget.classList.add("has-text-white");
  };

  return (
    <div
      className={`column is-1`}
      style={{ width: isCollapsed ? "70px" : "168px" }}
    >
      <div className={`menu ${isCollapsed ? "collapsed" : ""}`}>
        <div>
          <div
            className={`collapse-toggle ${isCollapsed ? "is-pulled-left" : "is-pulled-right"} mx-4 mt-4`}
            onClick={handleCollapseToggle}
          >
            <FontAwesomeIcon icon={isCollapsed ? faArrowRight : faArrowLeft} />
          </div>

          <br></br>

          <ul className="menu-list mt-5">
            <li
              onClick={(e) => {
                e.preventDefault();
                navigate("/dashboard");
              }}
            >
              <a
                className="has-text-white"
                onMouseEnter={handleHover}
                onMouseLeave={handleLeave}
              >
                <span className="has-text-success">
                  <FontAwesomeIcon icon={faDashboard} />
                </span>
                {isCollapsed ? "" : " Dashboard"}
              </a>
            </li>
            <li
              onClick={(e) => {
                e.preventDefault();
                navigate("/simulation")
              }}
            >
              <a
                className="has-text-white"
                onMouseEnter={handleHover}
                onMouseLeave={handleLeave}
              >
                <span className="has-text-success">
                  <FontAwesomeIcon icon={faComments} />
                </span>
                {isCollapsed ? "" : " Simulation"}
              </a>
            </li>
          {/* </ul>
          <ul className="menu-list"> */}
            
             {/*Legal Copilot*/}

             <li
              onClick={(e) => {
                e.preventDefault();
                navigate("/LegalCoPilot")
              }}
            >
              <a
                className="has-text-white"
                onMouseEnter={handleHover}
                onMouseLeave={handleLeave}
              >
                <span className="has-text-success">
                  <FontAwesomeIcon icon={faHeadset} />
                </span>
                {isCollapsed ? "" : " AI Copilot"}
              </a>
            </li>
          </ul>
          {/* Spacer div to push settings to the bottom */}
        <div className="menu-spacer"></div>

{/* Settings menu at the bottom */}
<ul className="menu-list settings-menu">
  <li
    onClick={(e) => {
      e.preventDefault();
      navigate("/settings");
    }}
  >
    <a
      className="has-text-white"
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
    >
      <span className="has-text-success">
        <FontAwesomeIcon icon={faCog} />
      </span>
      {isCollapsed ? "" : " Settings"}
    </a>
  </li>
</ul>

        </div>
      </div>
    </div>
  );
};

export default LateralMenu;
