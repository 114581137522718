import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LateralMenu from "../components/LateralMenu";
import { getAuth, signOut } from "firebase/auth";
import { db } from "../config/firebase-config";
import {
  collection,
  where,
  query,
  getDocs,
  updateDoc,
  doc,
  addDoc,
  getDoc,
} from "firebase/firestore";
import { uploadBytes } from "firebase/storage";

import mixpanel from "mixpanel-browser";
import ScoreComponent from "../components/ScoreComponent";
import ClientDetails from "../components/ClientDetails";
import CaseContext from "../components/CaseContext";
import OverallValue from "../components/OverallValue";
import DocumentList from "../components/DocumentList";
import ConflictCheckComponent from "../components/ConflictCheckComponent";
import QuestionsAnsweredByClient from "../components/QuestionsAnsweredByClient";
import QuestionsAnsweredByAI from "../components/QuestionsAnsweredByAI";
import ScoreBreakdownModal from "../components/ScoreBreakdownModal";
import NavbarFooter from "../components/NavbarFooter";
import Navbar from "../components/Navbar";
import ListQuestionsIntake from "../components/ListQuestionsIntake";
import PointsOfInterest from "../components/PointsOfInterest";
import LinkedInProfile from "../components/LinkedInProfile";
import ListDocsSummaries from "../components/ListDocsSummaries";
import "./ClientInfo.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { faFileAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import LiveSearchModal from "../components/LiveSearchModal";
import AdditionalLegalServices from "../components/AdditionalLegalServices";

import useLocalStorage from "../components/useLocalStorage";
import AcceptModal from "../components/acceptModal";
function ClientInfo(props) {
  const navigate = useNavigate();
  const { firebaseId } = useParams();
  // const [clientInfo, setClientInfo] = useState({});
  const [clientInfo, setClientInfo] = useLocalStorage(
    `clientInfo.${firebaseId}`,
    {},
  );
  const [showScoreBreakdown, setShowScoreBreakdown] = useState(false);
  const [selectedScore, setSelectedScore] = useState(null);

  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [firestoreId, setFirestoreId] = useLocalStorage("firestoreId", "");
  const [calendarLink, setCalendarLink] = useState("");
  useEffect(() => {
    // Assuming lawyerFirestoreId is available
    const lawyerDocRef = doc(db, "lawyers", firestoreId);
    getDoc(lawyerDocRef)
      .then((doc) => {
        if (doc.exists()) {
          // Assuming the calendar link is stored under 'calendarLink' in the lawyer's document
          setCalendarLink(doc.data().calendarLink);
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, [firestoreId]); // Add lawyerFirestoreId to dependency array if it's dynamically determined
  
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);

  const [scoreInfo, setScoreInfo] = useState({});
  const [selectedButton, setSelectedButton] = useState("Summary");

  const [searchQuery, setSearchQuery] = useState("");
  const [liveSearchResults, setLiveSearchResults] = useState("");
  const [searchModalIsOpen, setSearchModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [acceptEmailBody, setAcceptEmailBody] = useLocalStorage('acceptEmailBody');

  const [acceptEmailHeader, setAcceptEmailHeader] = useState(
    localStorage.getItem("acceptEmailHeader"),
  );
  // const [acceptEmailBody, setAcceptEmailBody] = useState(
  //   localStorage.getItem("acceptEmailBody"),
  // );
  const [referEmailHeader, setReferEmailHeader] = useState(
    localStorage.getItem("referEmailHeader"),
  );
  const [referEmailBody, setReferEmailBody] = useState(
    localStorage.getItem("referEmailBody"),
  );
  const [rejectEmailHeader, setRejectEmailHeader] = useState(
    localStorage.getItem("rejectEmailHeader"),
  );
  const [rejectEmailBody, setRejectEmailBody] = useState(
    localStorage.getItem("rejectEmailBody"),
  );

  const [headerBackground, setHeaderBackground] = useState("#D9F6D8");

  const [showAcceptModal, setShowAcceptModal] = useState(false); // State to control the visibility of the AcceptModal

  // With useEffect, load all the info from the client
  useEffect(() => {
    console.log("Load Client Info, firebaseId: ", firebaseId);
    if (firebaseId) {
      const docRef = doc(db, "leads", firebaseId);
      getDoc(docRef)
        .then((doc) => {
          if (doc.exists()) {
            console.log("Document data:", doc.data());
            // Set the state of the fields (this should contain the scores and breakdowns, client info, docs, conflict check, case context, etc)
            setClientInfo(doc.data());
            console.log(
              "Overral Decision: ",
              doc.data().suitabilityScore?.overallDecision,
            );
            setHeaderBackground(
              doc.data().suitabilityScore?.overallDecision === false
                ? "rgba(235, 20, 41, 0.12)"
                : "#D9F6D8",
            );
          }
        })
        .catch((error) => {
          console.log("Error getting client info from Firestore:", error);
        });
    }
  }, []);

  const handleButtonClick = (title) => {
    console.log("score clicked: ", title);
    //mixpanel.track("User chose client in Dashboard", {"ClientID": client});
    // TODO: Show a pop up with the breakdown of how the score was calculated (for now only suitability)
    if (title === "Suitability") {
      setSelectedScore(title);
      setShowScoreBreakdown(true);
      setScoreInfo(clientInfo.suitabilityScore);
    }
  };

  const closeScoreBreakdown = () => {
    setShowScoreBreakdown(false);
  };

 


  const toggleAcceptModal = () => {
    setShowAcceptModal(!showAcceptModal);
  };


  const handleAcceptClick = () => {
    toggleAcceptModal();
  };
  
  // Function to handle the action based on modal selection
const handleModalOption = async (option) => {
  if (option === "schedule") {
    // Send an email with a calendar link
    // You would replace the below code with your actual email sending logic
    console.log("Sending calendar link email...");
  } else if (option === "sendEmail") {
    // Check for a document in Firebase and proceed accordingly
    console.log("Checking for Letter of Engagement...");

    //TODO: Uncomment this code when the check is ready
    // Assuming you have a function to check Firebase and get the document
    // const hasDocument = await checkForDocumentInFirebase();
    // if (hasDocument) {
    //   // If document exists, make backend call to fill out PDF and get download link
    //   console.log("Document exists. Making backend call...");
    //   // Backend call logic here
    // }
    // Send email with the header and body
    console.log("Sending email...");
  }
  // Close the modal after handling the option
  toggleAcceptModal();
};


  const handleStatusUpdate = async (status) => {
    console.log(`Button ${status} pressed`);
    mixpanel.track("Lawyer took a decision about the client", {
      Status: status,
      ClientFirebaseID: firebaseId,
    });

    let subject = "";
    let message = "";
    let email = clientInfo.email;
    let name = clientInfo.name;
    let lastName = clientInfo.LastName;
    const fullName = `${name} ${lastName}`;

    console.log("fullName: ", fullName);
    console.log("firebaseId: ", firebaseId);
    let lawyerLawfirmName = lawyerLawfirm.replace(/([A-Z])/g, " $1").trim();

    if (status === "Accepted") {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlLetterOfEngagement = apiHostname
        ? `https://${apiHostname}/api/letterOfEngagement`
        : "http://localhost:5001/api/letterOfEngagement";

      try {
        const response = await fetch(apiUrlLetterOfEngagement, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firestoreId: firebaseId,
          }),
        });

        if (response.ok) {
          console.log(`Downloading: LOE`);
          const storage = getStorage();
          let fileRef = ref(
            storage,
            `leads/${firebaseId}/${fullName} EngagementLetter.pdf`,
          );

          try {
            // Get the download URL for the document
            const downloadURL = await getDownloadURL(fileRef);

            
            // Open the download URL in a new window or tab
            window.open(downloadURL,'_blank');
            let name = clientInfo.name;
            let lawyerLawfirmName = lawyerLawfirm
              .replace(/([A-Z])/g, " $1")
              .trim();
            subject = acceptEmailHeader
              .replace("[Name]", name)
              .replace("[Lawfirm Name]", lawyerLawfirmName)
              .replace(/\n/g, '%0D%0A')
              .replace(/"/g, "")

         
            message = acceptEmailBody
              .replace(/"/g, "")
              .replace("[downloadURL]", downloadURL)
              .replace("[Lawfirm Name]", lawyerLawfirmName)
              .replace("[Name]", name)
              .replace("[Lawfirm Name]", lawyerLawfirmName)
            

            
            console.log("Message: ", message);
          } catch (error) {
            console.error("Error downloading the document:", error);
            // Handle errors (e.g., display an error message to the user)
          }
        } else {
          console.error("Error generating the letter of engagement");
          return; // Stop execution if the response is not OK
        }
      } catch (error) {
        console.error("Error calling the letter of engagement API:", error);
        return; // Stop execution if there is an error
      }
    } else {
      if (status === "Referred") {
        subject = referEmailHeader
              .replace("[Name]", name)
              .replace("[Lawfirm Name]", lawyerLawfirmName)
              .replace(/\n/g, '%0D%0A')
              .replace(/"/g, "")

         
            message = referEmailBody
              .replace(/"/g, "")
              .replace(/\n/g, '%0D%0A')
              .replace("[Lawfirm Name]", lawyerLawfirmName)
              .replace("[Name]", name)
              .replace("[Lawfirm Name]", lawyerLawfirmName)

      } else if (status === "Declined") {
        subject = rejectEmailHeader
              .replace("[Name]", name)
              .replace("[Lawfirm Name]", lawyerLawfirmName)
              .replace(/\n/g, '%0D%0A')
              .replace(/"/g, "")

         
            message = rejectEmailBody
              .replace(/"/g, "")
              .replace(/\n/g, '%0D%0A')
              .replace("[Lawfirm Name]", lawyerLawfirmName)
              .replace("[Name]", name)
              .replace("[Lawfirm Name]", lawyerLawfirmName)
      }
    }

    // Construct the mailto URL
    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
    // Open the email client in a new window
    window.open(mailtoUrl, '_blank');

    // Update status in Firebase for the client
    const docRef = doc(db, "leads", firebaseId);
    updateDoc(docRef, { submissionStatus: status })
      .then(() => {
        console.log(`Status updated to ${status}`);
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  };

  const handleSectionClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const handleSearch = async (query) => {
    // TODO: this should also be called in case user presses enter
    console.log("Search bar input: ", query);
    mixpanel.track("User searched for something in client info", {
      Search: query,
      ClientID: firebaseId,
    });

    // Show that it is loading ();
    setSearchQuery(query);
    setIsLoading(true);

    // TODO: Make the call to the backend to search for the input in the client info
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlLiveSearch = apiHostname
        ? `https://${apiHostname}/api/liveSearch`
        : "http://localhost:5001/api/liveSearch";

      const response = await fetch(apiUrlLiveSearch, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: query,
          firestoreId: firebaseId,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response from search bar:", data);
        setLiveSearchResults(data[0]);

        setIsLoading(false);

        // Open the modal
        setSearchModalIsOpen(true);

        // Clear the search bar input
        const searchBarInput = document.getElementById("searchBarInput");
        searchBarInput.value = "";

        // TODO: Update query and result to localStorage

        // TODO: Update query and result to Firebase
      } else {
        console.log("Search bar API went wrong");
      }
    } catch (error) {
      console.log("error: ", error);
    }

    // TODO: eliminate what the user wrote in search bar
    // e.target.value = "";
  };

  const closeSearchModal = () => {
    setSearchModalIsOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const searchBarInput = document.getElementById("searchBarInput");
      handleSearch(searchBarInput.value);
    }
  };

  const handleSearchButtonClick = () => {
    // Handle search logic here
    const searchBarInput = document.getElementById("searchBarInput");
    handleSearch(searchBarInput.value);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(clientInfo.intakeLink)
      .then(() => {
        console.log("Invitation link copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
        // Handle error, such as showing an error message to the user
      });
  };

  const handleDocumentDownload = async (documentName) => {
    let document = "Complete Intake Form";
    document = "intake_form.pdf";

    console.log(`Downloading: ${documentName}`);
    const storage = getStorage();
    let fileRef = ref(storage, `leads/${firebaseId}/${document}`);

    try {
      // Get the download URL for the document
      const downloadURL = await getDownloadURL(fileRef);
      // Open the download URL in a new window or tab
      window.open(downloadURL, "_blank");
    } catch (error) {
      console.error("Error downloading the document:", error);
      // Handle errors (e.g., display an error message to the user)
    }
  };

  // TODO: Read from clientInfo analysis about Jurisdiction and whether client is in the right legal sector or not. Pass this info to the specific scores.

  return (
    <div className="columns is-mobile">
      <LateralMenu />
      <div className="column">
        <Navbar
          isSignedIn={isSignedIn}
          isLawyer={isLawyer}
          lawyerLawfirm={lawyerLawfirm}
        />
        <div className=" dashboardMargins">
          <div className="columns">
            <div className="column">
              <div className="box clientInfo">
                <div
                  className="columns is-vcentered colorTitle"
                  style={{ background: headerBackground }}
                >
                  <div className="column is-flex my-4">
                    <p className="title is-3 ml-2 mr-5">
                      {clientInfo.name} {clientInfo.LastName}
                    </p>
                  </div>

                  {/* {clientInfo.intakeLink && (
                <div className="column is-flex is-justify-content-flex-end">
                  <div className="is-pulled-right">
                    <button className="button is-success is-rounded is-small ml-3" onClick={copyToClipboard}>
                      <FontAwesomeIcon icon={faCopy} />
                    </button>
                    <span className='ml-2 mt-5'>
                      Copy Link
                    </span>
                  </div>
                </div>
                )} */}

                  <div className="column is-flex is-justify-content-flex-end">
                    <div className="is-pulled-right">
                    <button
  className={`button is-rounded mr-3 button-download-intake ${
    headerBackground === "#D9F6D8" ? "button-download-intake-accept" : headerBackground === "rgba(235, 20, 41, 0.12)" ? "button-download-intake-reject" : ""
  }`}
  onClick={() => handleDocumentDownload(document)}
>
  <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: "10px" }} />
  Download Intake
</button>


                    </div>
                  </div>
                </div>

                <div className="columns is-vcentered mb-1">
                  {/* Buttons */}
                  <div className="column">
                    <div className="buttons">
                      <div
                        className={`button is-rounded mx-2`}
                        style={{
                          border: "none",
                          backgroundColor:
                            selectedButton === "Summary" ? "#D9F6D8" : "",
                        }}
                        onClick={() => handleSectionClick("Summary")}
                      >
                        Summary
                      </div>
                      <div
                        className={`button is-rounded mx-2`}
                        style={{
                          border: "none",
                          backgroundColor:
                            selectedButton === "Intake" ? "#D9F6D8" : "",
                        }}
                        onClick={() => handleSectionClick("Intake")}
                      >
                        Intake
                      </div>
                      <div
                        className={`button is-rounded mx-2`}
                        style={{
                          border: "none",
                          backgroundColor:
                            selectedButton === "Documents" ? "#D9F6D8" : "",
                        }}
                        onClick={() => handleSectionClick("Documents")}
                      >
                        Docs
                      </div>
                    </div>
                  </div>

                  <div className="column">
                    {/* Search bar */}
                    <div className="field has-addons">
                      <div className="control is-expanded">
                        <input
                          id="searchBarInput"
                          type="text"
                          className="input "
                          placeholder="Ask any question about this client..."
                          onKeyDown={handleKeyDown}
                          disabled={isLoading}
                        />
                      </div>
                      <div className="control">
                        <button
                          className={`button ${isLoading ? "is-loading" : ""}`}
                          style={{ backgroundColor: "#D9F6D8" }}
                          onClick={handleSearchButtonClick}
                          disabled={isLoading}
                        >
                          <FontAwesomeIcon icon={faSearch} />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="column is-flex is-justify-content-flex-end">
                    <div className="buttons">
                    {
  showAcceptModal && (
    <>
      <AcceptModal
        isOpen={showAcceptModal}
        toggleModal={toggleAcceptModal}
        handleModalOption={handleModalOption}
        isLoading={isLoading}
        calendarLink={ calendarLink} 
      />
    </>
  )
}



                   
                    <div
                      className="button mx-1 acceptButton"
                      onClick={handleAcceptClick} // Updated to show the modal
                    >
                      <span className="acceptButtonText">Accept</span>
                    </div>
                      <div
                        className="button mx-1 referButton"
                        onClick={() => {
                          handleStatusUpdate("Referred");
                        }}
                      >
                        <span className="referButtonText"> Refer </span>
                      </div>
                      <div
                        className="button mx-1 declineButton"
                        onClick={() => {
                          handleStatusUpdate("Declined");
                        }}
                      >
                        <span className="declineButtonText">Decline</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divider mt-1" />
                <br></br>

                {selectedButton === "Summary" && (
                  <div className="container">
                    <div className="columns">
                      <div className="column is-8 mr-5">
                        <CaseContext caseSummary={clientInfo.summary} />
                        
                        <OverallValue overallValue={clientInfo.suitabilityScore?.overallValue} />

                        <AdditionalLegalServices
                          additionalServices={
                            clientInfo.suitabilityScore?.additionalServices
                          }
                        />
                      </div>

                      <div className="column">
                        {/* <ConflictCheckComponent 
                        conflictStatus={false}
                      /> */}

                        <PointsOfInterest
                          PointsOfInterests={clientInfo.pointsOfInterests}
                        />

                        {/* Take into account edge case where no LinkedIn is found */}
                        <LinkedInProfile
                          linkedInInfo={
                            clientInfo.suitabilityScore?.linkedInInformation
                          }
                        />
                        <br></br>

                       
                        <br></br>

                        {/* <div className="mb-6">
                        <p className="subtitle mb-3">Caddi AI Analysis</p>

                        <ScoreComponent
                        title="Suitability"
                        score="-/10"
                        description="Suitable jurisdiction, intent and legal matter."
                        handleButtonClick={handleButtonClick}
                        />

                        <ScoreComponent
                        title="Trust"
                        score="-/10"
                        description="Verified the email domain and media accounts."
                        handleButtonClick={handleButtonClick}
                        />

                        <ScoreComponent
                        title="Validity"
                        score="-/10"
                        description="Given documents and details, it is most likely a valid case."
                        handleButtonClick={handleButtonClick}
                        />

                        <ScoreComponent
                          title="Value"
                          score=""
                          description="Estimated contract value given previous clients."
                          handleButtonClick={handleButtonClick}
                        /> 
                      </div> */}

                        <ClientDetails
                          name={clientInfo.name + " " + clientInfo.LastName}
                          company={clientInfo.company}
                          email={clientInfo.email}
                          phone={clientInfo.phone}
                          address={clientInfo.address}
                        />

                        {/* < QuestionsAnsweredByAI 
                        firebaseId = {firebaseId}
                      /> */}
                      </div>
                    </div>
                    <br></br>
                  </div>
                )}

                {selectedButton === "Intake" && (
                  <div className="container">
                    <div className="columns">
                      <div className="column"> </div>
                      <div className="column is-8">
                        <ListQuestionsIntake
                          issueQuestion="Describe your issue"
                          issueAnswer={clientInfo.issue || ""}
                          intentQuestion="What is your desired outcome?"
                          intentAnswer={clientInfo.intent || ""}
                          clientDetailsQuestions={
                            clientInfo.clientDetailsQuestions || []
                          }
                          clientDetailsAnswers={
                            clientInfo.clientDetailsAnswers || []
                          }
                          specificLegalQuestions={
                            clientInfo.specificLegalQuestions || []
                          }
                          specificLegalAnswers={
                            clientInfo.specificLegalAnswers || []
                          }
                          finalQuestions={clientInfo.finalQuestions || []}
                          finalAnswers={clientInfo.finalQuestionsAnswers || []}
                        />
                      </div>
                      <div className="column"> </div>

                      {/* <div className='column'>

                      <ConflictCheckComponent 
                        conflictStatus={false}
                      />

                      <QuestionsAnsweredByClient 
                        firebaseId = {firebaseId}
                      />
                      
                      <DocumentList 
                        // documentsList = {['Eviction notice.pdf', 'Lease Agreement.pdf']}
                        documentsList = {clientInfo.UploadedDocsName}
                        firebaseId = {firebaseId}
                      />

                      
                      </div> */}
                    </div>
                  </div>
                )}

                {selectedButton === "Documents" && (
                  <div className="container">
                    <div className="columns">
                      <div className="column"> </div>
                      <div className="column is-8">
                        <ListDocsSummaries
                          documentsList={clientInfo.UploadedDocsName}
                          documentsSummaries={clientInfo.documentAnalysis}
                          firebaseId={firebaseId}
                        />
                      </div>
                      <div className="column"> </div>

                      {/* <div className='column'>

                      <ConflictCheckComponent 
                        conflictStatus={false}
                      />

                      <QuestionsAnsweredByClient 
                        firebaseId = {firebaseId}
                      />
                      
                      <DocumentList 
                        // documentsList = {['Eviction notice.pdf', 'Lease Agreement.pdf']}
                        documentsList = {clientInfo.UploadedDocsName}
                        firebaseId = {firebaseId}
                      />

                    
                      </div> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <ScoreBreakdownModal
            showScoreBreakdown={showScoreBreakdown}
            closeScoreBreakdown={closeScoreBreakdown}
            selectedScore={selectedScore}
            scoreInfo={scoreInfo}
          />

          <LiveSearchModal
            isOpen={searchModalIsOpen}
            onRequestClose={closeSearchModal}
            searchResult={liveSearchResults}
            searchQuery={searchQuery}
          />
        </div>
      </div>
    </div>
  );
}

export default ClientInfo;