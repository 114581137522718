import React from "react";
//import '../pages/Dashboard.css';
import { Navigate } from "react-router-dom";
import "./ClientCard.css";

const ClientsHeader = (props) => {
  return (
    <div className="has-text-weight-bold">
      <div>
        <div className={`box card header`}>
          <div className="columns is-mobile">
            <div className="column">
              <p className="">Name</p>
            </div>
            <div className="column">
              <p className="">Company</p>
            </div>
            <div className="column">
              <p className="">Sector</p>
            </div>
            <div className="column">
              <p className="">Status</p>
            </div>
            <div className="column">
              <p className="">Date</p>
            </div>
            <div className="column">
              <p className="">Caddi Analysis</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsHeader;
