import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import IntakeSideMenu from "../components/IntakeSideMenu";
import NavBarFooter from "../components/NavbarFooter";
import NavbarWelcomeIntake from "../components/NavbarWelcomeIntake";
import FooterIntake from "../components/FooterIntake";
import "./WelcomeIntake.css";

import useLocalStorage from "../components/useLocalStorage";
import { getDoc, getDocs, query, doc, collection } from "firebase/firestore";
import { db } from "../config/firebase-config";

function WelcomeIntake(props) {
  const navigate = useNavigate();
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);

  // TODO: If not in this array, check firestore for the lawfirm
  const [lawfirmsVerified, setLawfirmsVerified] = [
    "TestLawyer",
    "ShanzePartners",
    "OpseraLaw",
    "SeattleEvictionServices",
    "AdvocatesLawGroup",
    "MoradoLaw",
    "moradoLaw",
    "AnnaJohnsenLaw",
  ];

  // TODO: move all the lawfirm from useParams to the first screen of the intake process
  const { lawfirm, personalized } = useParams();
  console.log("Lawfirm: ", lawfirm, "Personalized Intake: ", personalized);

  if (personalized) {
    console.log("Personalized intake");
    if (personalized === "short") {
      console.log("Short intake:", personalized);
      localStorage.setItem("shortIntake", personalized);
    } else {
      // AI Personalized intake
      localStorage.setItem("personalizedIntake", personalized);
    }

    // TODO: If personalized == short, then reduce lawyers of questions from 3 to 1 and skip docs

    // TODO: Show in Lawyers's dashboard that this is a short intake
  } else {
    console.log("Not personalized intake");
  }

  // TODO: modify lawfirm to put a space before capital letter, and then put it in all caps
  const lawfirmName = lawfirm.replace(/([A-Z])/g, " $1"); //.toUpperCase();

  // TODO: Replace this with lawfirm hero
  const lawfirmHero = "THE RIGHT LAWYER FOR YOU";

  useEffect(() => {
    // If lawfirm is not in the list of lawfirms, send them to the home page
    if (!lawfirmsVerified.includes(lawfirm)) {
      // Download updated list in firestore and then check
      const query = doc(db, "lawfirms", "list");
      getDoc(query).then((document) => {
        let updatedLawfirms = document.data().lawfirms;
        console.log("Updated Lawfirms: ", updatedLawfirms);
        if (updatedLawfirms.includes(lawfirm)) {
          console.log("Intake belongs to lawfirm: ", lawfirm);
          localStorage.setItem("lawyerLawfirm", lawfirm);
        } else {
          window.alert("Error. Page could not be found. Redirecting to home.");
          navigate("/");
        }
      });
    } else {
      console.log("Intake belongs to lawfirm: ", lawfirm);
      localStorage.setItem("lawyerLawfirm", lawfirm);
    }
  }, [lawfirm]);

  useEffect(() => {
    // If isLawyer is true, show user to log out and navigate to lawyer dashboard
    if (isLawyer) {
      console.log("User is a lawyer, needs to sign out first");
      window.alert(
        "We have detected an open session for a lawyer. We are signing out before filling the intake form.",
      );
      navigate("/logoutLawyer");
    }
  }, [isLawyer]);

  const handleNextClick = () => {
    console.log("Next Button Clicked");
    // Save information from user
    //navigate('/intake/disclaimer');
    navigate("/intake/signup");

    mixpanel.track("User went from welcome screen to intake disclaimer");
  };

  const handleCaddiClicked = () => {
    console.log("Caddi Button Clicked");
    navigate("/");
  };

  const handleSignIn = () => {
    console.log("Sign In Button Clicked");
    navigate("/intake/login");
  };

  return (
    <>
      <div className="box dark-green-box">
        <div className="box light-green-box has-text-centered welcome-box">
          {/* <NavbarWelcomeIntake /> */}
          <div className="title is-2 mt-2">{lawfirmName}</div>
          <br></br>

          <div className="columns is-centered">
            <div className="column is-1"></div>

            <div className="column box mx-2">
              <div className="mx-4 my-5">
                <figure>
                  <img src="/Timer.png" alt="Placeholder image" width="128" />
                </figure>
                <br></br>
                <br></br>
                <div className="title is-4">
                  Less billable hours means cost savings
                </div>
                <div className="subtitle is-6 mt-2">
                  By going through the Caddi intake system, users save an
                  average of 20% in time.
                </div>
              </div>
            </div>
            <div className="column box mx-2">
              <div className="mx-4  my-5">
                <figure>
                  <img
                    src="/SmartForms.png"
                    alt="Placeholder image"
                    width="128"
                  />
                </figure>
                <br></br>
                <div className="title is-4">Get faster legal solutions</div>
                <div className="subtitle is-6 mt-2">
                  Caddi puts you at the forefront with lawyers. Allowing them to
                  get back to you faster and with more accuracy.
                </div>
              </div>
            </div>
            <div className="column box mx-2">
              <div className="mx-4  my-5">
                <figure>
                  <img src="/Doc.png" alt="Placeholder image" width="128" />
                </figure>
                <br></br>
                <div className="title is-4">Documents? Checked.</div>
                <div className="subtitle is-6 mt-2">
                  We detect which documents you will be needing throughout this
                  process. Please have them ready.
                </div>
              </div>
            </div>

            <div className="column is-1"></div>
          </div>

          <div className="buttons is-centered">
            <button
              className="button has-text-success mx-1"
              id="nextButton"
              onClick={handleSignIn}
            >
              {" "}
              Sign In{" "}
            </button>
            <button
              className="button is-success has-text-white mx-1"
              id="nextButton"
              onClick={handleNextClick}
            >
              {" "}
              Create Account{" "}
            </button>
          </div>
          <p className=""> Estimated Time to Complete: 15 Minutes</p>
        </div>
        <FooterIntake />
      </div>
    </>
  );
}

export default WelcomeIntake;
