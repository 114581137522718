import React from "react";
import { Navigate } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show a fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.reportErrorToBackend(error.toString(), errorInfo.componentStack);
    // Optionally, set errorInfo to state if you want to render error details in UI
    this.setState({ errorInfo });
  }

  reportErrorToBackend = async (error, errorInfo) => {
    const apiHostname = process.env.REACT_APP_API_HOSTNAME;
    const apiUrlFrontEndError = apiHostname
      ? `https://${apiHostname}/api/frontEndError`
      : "http://localhost:5001/api/frontEndError";

    try {
      const response = await fetch(apiUrlFrontEndError, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          error: error,
          errorInfo: errorInfo,
        }),
      });

      if (!response.ok) {
        console.error("Failed to report error to backend");
      }
    } catch (error) {
      console.error("Error reporting to backend: ", error);
    }
  };

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return (
        <div>
          <h2>Something went wrong.</h2>
          <button onClick={() => Navigate("/")}>Go Home</button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
