import React, { useState } from 'react';

function ExpandableBox({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="box" style={{ marginBottom: '1rem' }}>
      <div onClick={toggleOpen} style={{ cursor: 'pointer' }}>
        <strong>{title}</strong>
      </div>
      {isOpen && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}

export default ExpandableBox;
