import React, { useState } from "react";
import axios from "axios";
//import logo from "./logo.svg"
//import "./App.css"
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendEmailVerification,
  onAuthStateChanged,
  getIdTokenResult,
  getIdToken,
} from "firebase/auth";
import Popup from "reactjs-popup";
import { db } from "../config/firebase-config";
import {
  collection,
  where,
  query,
  getDocs,
  updateDoc,
  doc,
  addDoc,
} from "firebase/firestore";
import mixpanel from "mixpanel-browser";
import "./GoogleButton.css";
import "./WelcomeIntake.css";
import useLocalStorage from "../components/useLocalStorage";

/**
 * SignUp Page
 */
const SignUpPageLawyer = (props) => {
  const navigate = useNavigate();
  const auth = getAuth();
  //state = {seen: false};
  const [showPopup, setShowPopup] = useState(false);
  const [loginType, setLoginType] = useState("");
  const [showPopupError, setShowPopupError] = useState("");
  const [firestoreId, setFirestoreId] = useLocalStorage("firestoreId", "");
  const terms_and_conditions = "";

  // TODO: This has to be manually set up us or the client (make sure there are no spaces)
  // const lawyerLawfirm = ""

  // UPDATE TERMS AND CONDITIONS

  const checkboxHandler = () => {
    const checkbox = document.querySelector("#agree");
    checkbox.checked = false;
    setShowPopup(!showPopup);
  };

  const checkboxHandlerAlert = () => {
    setShowPopupError("");
  };

  const checkboxHandlerGoogle = () => {
    // TODO: Make them agree to T&Cs  (not for now)
    // const checkbox = document.querySelector("#agree");
    // checkbox.checked = false;
    // setShowPopup(!showPopup);
    // setLoginType("google")

    loginWithGoogle();
  };

  const agreedToTerms = () => {
    setShowPopup(!showPopup);
    const signUpButton = document.querySelector("#signUpButton");
    const checkbox = document.querySelector("#agree");
    signUpButton.disabled = false;
    checkbox.checked = true;

    if (loginType === "google") {
      loginWithGoogle();
    }
  };

  const sendIdTokenToBackendForLawyer = async (idtoken, emailText) => {
    // Send the idtoken to backend to decode and create cookie
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlLoginLawyer = apiHostname
        ? `https://${apiHostname}/api/loginLawyer`
        : "http://localhost:5001/api/loginLawyer";

      const response = await fetch(apiUrlLoginLawyer, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idtoken: idtoken,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response");
        console.log("data from idToken: ", data);
        console.log("user_id: ", data.user_id);
        props.onLoginLawyer(data.user_id, false);

        //console.log("firestore id: ", props.firestoreId);

        // Add userID in Firestore to show his info next time he logs in and save his firestoreId
        addDoc(collection(db, "lawyers"), {
          userID: data.user_id,
          userType: "lawyer",
          // lawyerLawfirm: lawyerLawfirm,
          email: emailText,
        })
          .then((docRef) => {
            console.log("Lawyer UserId updated in Firestore: ", docRef.id);
            props.onFirestoreSessionCreated(docRef.id);
            setFirestoreId(docRef.id);
            // Navigate lawyer to onboarding
            navigate("/onboardingLawyer");
          })
          .catch((error) => {
            console.error("Error updating Lawyer UserID in Firestore: ", error);
          });

        mixpanel.track("Lawyer Signed up", {
          "Firebase User Id": props.firestoreId,
        });
      } else {
        console.log("Login went wrong");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const signUpWithEmail = (e) => {
    e.preventDefault();
    // I should update this with "StyledFirebaseAuth" so I don't have to read the password manually
    const emailText = String(e.target.email.value);
    const passwordText = String(e.target.password.value);
    e.target.email.value = "";
    e.target.password.value = "";

    createUserWithEmailAndPassword(auth, emailText, passwordText)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        const idtoken = user.getIdToken().then((idtoken) => {
          console.log("Firebase idtoken: ", idtoken);

          // Send the idtoken to backend to decode, set user as a Lawyer and create cookie
          sendIdTokenToBackendForLawyer(idtoken, emailText);

          // Check if user has Lawyer role (TODO: fix this)
          const auth = getAuth();
          const currentUser = auth.currentUser;
          getIdTokenResult(currentUser)
            .then((idTokenResult) => {
              // Confirm the user is a Lawyer. (TODO: fix this)
              if (!!idTokenResult.claims.lawyer) {
                console.log("User is a lawyer");
              } else {
                console.log("User is NOT a lawyer");
              }
            })
            .catch((error) => {
              console.log(error);
            });

          // Send verification email (TODO: fix the logic here)
          sendEmailVerification(user).then(() => {
            console.log("Email verification sent");
            //window.alert("Verify your account by clicking on the link sent to your email.");
            const errorMessage =
              "Verify your account by clicking on the link sent to your email.";
            // setShowPopupError(errorMessage);

            // Wait for 3 seconds and then navigate to the login page
            // setTimeout(() => {
            //   navigate("/loginLawyer");
            // }, 5000);
          });
        });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("error: ", errorCode, errorMessage);
        //window.alert(errorMessage);
        setShowPopupError(errorMessage);
      });
  };

  const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const accesstoken = credential.accessToken;

        // The signed-in user info.
        const user = result.user;
        console.log("user log in: ", user.uid);

        // Send Firebase ID token to backend
        const idtoken = user.getIdToken().then((idtoken) => {
          console.log("idtoken: ", idtoken);

          // Send the idtoken to backend to decode, set as a lawyer and create cookie
          sendIdTokenToBackendForLawyer(idtoken, user.email);

          // Check if user has Lawyer role (TODO: fix this)
          getIdTokenResult(user)
            .then((idTokenResult) => {
              // Confirm the user is an Admin.
              if (!!idTokenResult.claims.lawyer) {
                console.log("User is a lawyer");
              } else {
                console.log("User is NOT a lawyer");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("error: ", errorCode, errorMessage);
        //window.alert(errorMessage);
        setShowPopupError(errorMessage);
      });
  };

  return (
    <>
      <div className="box dark-green-box">
        <div className="columns is-mobile">
          <div
            className="box white-box column is-4"
            style={{
              background: `url('/SignUpImageCaddi.png')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>

          <div
            className="box light-green-box column"
            style={{ height: "100vh" }}
          >
            <div className="columns">
              <div className="column is-3"></div>
              <div className="column has-text-centered">
                <div className="subtitle is-2 has-text-centered">
                  <img
                    src="/CaddiLogo.png"
                    alt="Caddi Logo"
                    className="mr-2"
                    style={{ height: "30px", width: "auto" }}
                  />
                  Caddi
                </div>
                <br></br>
                <div className="title is-4">
                  Sign up to <span className="has-text-success">Caddi</span>
                </div>

                <button
                  type="button"
                  className="login-with-google-btn is-clickable"
                  onClick={checkboxHandlerGoogle}
                >
                  <div className="my-1">
                    <img
                      src="https://img.icons8.com/color/48/000000/google-logo.png"
                      alt="google icon"
                      className="mr-2"
                      style={{
                        height: "20px",
                        width: "auto",
                        verticalAlign: "middle",
                        marginBottom: "5px",
                      }}
                    />
                    <span className="">Sign up with Google</span>
                  </div>
                </button>

                <br></br>
                <br></br>
                <div className="divider mb-1"></div>

                <form onSubmit={signUpWithEmail}>
                  <br></br>
                  <p className="subtitle"> or sign up with email</p>
                  <label className="label has-text-left" htmlFor="username">
                    Email
                  </label>
                  <input name="email" className="input" />
                  <label
                    className="label has-text-left mt-2"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input name="password" className="input" type="password" />
                  {/* <div>
                  <input type="checkbox" id="agree" onChange={checkboxHandler} />
                  <label htmlFor="agree"> I agree to <b>terms and conditions</b></label>
                </div> */}
                  <div>
                    <br></br>
                    <input
                      type="submit"
                      className="button is-success is-fullwidth"
                      id="signUpButton"
                      value="Sign up with email"
                    />
                  </div>
                </form>
              </div>

              <div className="column is-3"></div>
            </div>
          </div>
        </div>
        {/* <FooterIntake /> */}
      </div>

      {showPopup && (
        <div className="modal is-active">
          <div className="modal-background" onClick={checkboxHandler}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Terms and Conditions</p>
              <button
                className="delete"
                aria-label="close"
                onClick={checkboxHandler}
              ></button>
            </header>
            <section className="modal-card-body">
              {terms_and_conditions}
              To see our full Terms of Service, please click{" "}
              <a href="/TermsOfService"> here</a>.
            </section>
            <footer className="modal-card-foot">
              <button className="button is-info" onClick={agreedToTerms}>
                Agree
              </button>
            </footer>
          </div>
        </div>
      )}

      {showPopupError && (
        <div className="modal is-active">
          <div className="modal-background" onClick={checkboxHandler}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Alert</p>
              <button
                className="delete"
                aria-label="close"
                onClick={checkboxHandlerAlert}
              ></button>
            </header>
            <section className="modal-card-body">{showPopupError}</section>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUpPageLawyer;
