import React from "react";
import { useNavigate } from "react-router-dom";
import NavbarFooter from "../components/NavbarFooter";
import IntakeSideMenu from "../components/IntakeSideMenu";
import NavbarIntake from "../components/NavbarIntake";
import FooterIntake from "../components/FooterIntake";
import mixpanel from "mixpanel-browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import useLocalStorage from "../components/useLocalStorage";

function SubmissionConfirmation(props) {
  const navigate = useNavigate();
  const [firestoreId, setFirestoreId] = useLocalStorage("firestoreId", "");
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);

  const handleCaddiClicked = () => {
    console.log("Caddi Button Clicked");
    navigate("/");
  };

  const handleExitForm = () => {
    console.log("Exit Form");
    navigate("/logout");
    // mixpanel.track("User went from submission confirmation to sign up to Caddie");
  };

  return (
    <>
      <div className="box dark-green-box">
        <div className="columns is-mobile">
          <div className="box white-box column is-3">
            <NavbarIntake
              isSignedIn={isSignedIn}
              isLawyer={isLawyer}
              lawyerLawfirm={lawyerLawfirm}
            />
          </div>
          <div className="box light-green-box column">
            <div className="columns is-centered">
              <div className="column is-2"></div>

              <div
                className="column has-text-centered"
                style={{ marginTop: "10%" }}
              >
                <div className="title is-4 my-5">
                  All done! Submitted
                  <FontAwesomeIcon
                    icon={faCheck}
                    size="1x"
                    style={{ color: "green" }}
                    className="ml-2"
                  />
                </div>
                <div className="subsubtitle ">
                  {lawyerLawfirm ? lawyerLawfirm : "The incubator"} will be
                  reaching out to you shortly. As a reminder, this form is for
                  intake purposes only. There is no acceptance to your
                  application by going through this form. The firm will send a
                  letter of acceptance if they wish to invite you to their
                  program.
                </div>
                <br></br>
                <button
                  className="button is-success my-5"
                  id="nextButton"
                  onClick={handleExitForm}
                >
                  Exit
                </button>
              </div>
              <div className="column is-2"></div>
            </div>

            <br></br>
          </div>
        </div>
        <FooterIntake />
      </div>
    </>
  );
}

export default SubmissionConfirmation;
