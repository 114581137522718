import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import Navbar from "../components/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import FooterIntake from "../components/FooterIntake";
import NavbarIntake from "../components/NavbarIntake";
import "./OnboardingLawyer.css";
import "./WelcomeIntake.css";
import LogRocket from "logrocket";

import useLocalStorage from "../components/useLocalStorage";
import { updateDoc, doc, arrayUnion } from "firebase/firestore";
import { db } from "../config/firebase-config";
import { isJSDocLinkLike } from "typescript";
//initialize logrocket for session tracking

LogRocket.getSessionURL(function (sessionURL) {
  mixpanel.track("LogRocket", { sessionURL: sessionURL });
});

const slackLawyerOnboarded = async (lawyerName) => {
  try {
    const apiHostname = process.env.REACT_APP_API_HOSTNAME;
    const apiLawyerOnboarding = apiHostname
      ? `https://${apiHostname}/api/lawyerOnboarded`
      : "http://localhost:5001/api/lawyerOnboarded";

    const response = await fetch(apiLawyerOnboarding, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lawyerName: lawyerName,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Got back ok response:");
    } else {
      console.log("Got bad response");
    }
  } catch (error) {
    console.log("error: ", error);
  }
};

const OnboardingLawyer = (props) => {
  const navigate = useNavigate();
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage("lawyerLawfirm", "");
  const [firestoreId] = useLocalStorage("firestoreId", ""); // Assuming firestoreId does not change in this component
  const [lawyerName, setLawyerName] = useLocalStorage("lawyerName", "");
  const [lawfirmName, setLawfirmName] = useLocalStorage("lawfirmName", "");
  const [selectedLegalSectors, setSelectedLegalSectors] = useLocalStorage("selectedLegalSectors", []);
  const [hourlyRate, setHourlyRate] = useLocalStorage("hourlyRate", "");
  const [flatFees, setFlatFees] = useLocalStorage("flatFees", [{ concept: "", price: "" }]);
  const [contingencyFees, setContingencyFees] = useLocalStorage("contingencyFees", [{ concept: "", price: "" }]);
  const [selectedLawfirmSize, setSelectedLawfirmSize] = useLocalStorage("selectedLawfirmSize", []);
  const [selectedStates, setSelectedStates] = useLocalStorage("selectedStates", []);

  const [isFormValid, setIsFormValid] = useState(false);
  
  const [disclaimer, setDisclaimer] = useLocalStorage("disclaimer", []);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const legalSectors = [
    "Real Estate Law",
    "Business Law",
    "Immigration Law",
    "Family Law",
    "Estate Planning",
    "Intellectual Property",
    "Employment and Labor",
    "Personal Injury",
    "Entertainment",
    "Lawsuits and Disputes",
    "Consumer Protection",
    "Civil Rights",
    "Criminal Defense",
  ]; /* Add more legal sectors as needed */
  const lawfirmSizes = ["1-5", "6-10", "11-50", "50+"];

  const stateNameToAbbreviation = {
    "Alabama": "AL",
    "Alaska": "AK",
    "Arizona": "AZ",
    "Arkansas": "AR",
    "California": "CA",
    "Colorado": "CO",
    "Connecticut": "CT",
    "Delaware": "DE",
    "Florida": "FL",
    "Georgia": "GA",
    "Hawaii": "HI",
    "Idaho": "ID",
    "Illinois": "IL",
    "Indiana": "IN",
    "Iowa": "IA",
    "Kansas": "KS",
    "Kentucky": "KY",
    "Louisiana": "LA",
    "Maine": "ME",
    "Maryland": "MD",
    "Massachusetts": "MA",
    "Michigan": "MI",
    "Minnesota": "MN",
    "Mississippi": "MS",
    "Missouri": "MO",
    "Montana": "MT",
    "Nebraska": "NE",
    "Nevada": "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    "Ohio": "OH",
    "Oklahoma": "OK",
    "Oregon": "OR",
    "Pennsylvania": "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    "Tennessee": "TN",
    "Texas": "TX",
    "Utah": "UT",
    "Vermont": "VT",
    "Virginia": "VA",
    "Washington": "WA",
    "West Virginia": "WV",
    "Wisconsin": "WI",
    "Wyoming": "WY",
  };

  // Convert the states array to include full names for filtering
  const fullStates = [
    { name: "Alabama", abbreviation: "AL" },
    { name: "Alaska", abbreviation: "AK" },
    { name: "Arizona", abbreviation: "AZ" },
    { name: "Arkansas", abbreviation: "AR" },
    { name: "California", abbreviation: "CA" },
    { name: "Colorado", abbreviation: "CO" },
    { name: "Connecticut", abbreviation: "CT" },
    { name: "Delaware", abbreviation: "DE" },
    { name: "Florida", abbreviation: "FL" },
    { name: "Georgia", abbreviation: "GA" },
    { name: "Hawaii", abbreviation: "HI" },
    { name: "Idaho", abbreviation: "ID" },
    { name: "Illinois", abbreviation: "IL" },
    { name: "Indiana", abbreviation: "IN" },
    { name: "Iowa", abbreviation: "IA" },
    { name: "Kansas", abbreviation: "KS" },
    { name: "Kentucky", abbreviation: "KY" },
    { name: "Louisiana", abbreviation: "LA" },
    { name: "Maine", abbreviation: "ME" },
    { name: "Maryland", abbreviation: "MD" },
    { name: "Massachusetts", abbreviation: "MA" },
    { name: "Michigan", abbreviation: "MI" },
    { name: "Minnesota", abbreviation: "MN" },
    { name: "Mississippi", abbreviation: "MS" },
    { name: "Missouri", abbreviation: "MO" },
    { name: "Montana", abbreviation: "MT" },
    { name: "Nebraska", abbreviation: "NE" },
    { name: "Nevada", abbreviation: "NV" },
    { name: "New Hampshire", abbreviation: "NH" },
    { name: "New Jersey", abbreviation: "NJ" },
    { name: "New Mexico", abbreviation: "NM" },
    { name: "New York", abbreviation: "NY" },
    { name: "North Carolina", abbreviation: "NC" },
    { name: "North Dakota", abbreviation: "ND" },
    { name: "Ohio", abbreviation: "OH" },
    { name: "Oklahoma", abbreviation: "OK" },
    { name: "Oregon", abbreviation: "OR" },
    { name: "Pennsylvania", abbreviation: "PA" },
    { name: "Rhode Island", abbreviation: "RI" },
    { name: "South Carolina", abbreviation: "SC" },
    { name: "South Dakota", abbreviation: "SD" },
    { name: "Tennessee", abbreviation: "TN" },
    { name: "Texas", abbreviation: "TX" },
    { name: "Utah", abbreviation: "UT" },
    { name: "Vermont", abbreviation: "VT" },
    { name: "Virginia", abbreviation: "VA" },
    { name: "Washington", abbreviation: "WA" },
    { name: "West Virginia", abbreviation: "WV" },
    { name: "Wisconsin", abbreviation: "WI" },
    { name: "Wyoming", abbreviation: "WY" },
  ];

  const [isOnboarded, setIsOnboarded] = useLocalStorage("isOnboarded", false);
  const [requiredQuestions, setRequiredQuestions] = useState([
    "Have you sought any legal advice or taken any legal action prior with any other attorney?",
    "Have you dealt with this kind of legal issues before?",
    "Are there any other parties involved? If yes, please provide their names and contact information",
    "How urgent is your issue?",
    "How did you hear from this law firm?",
  ]);
  const [finalQuestions, setFinalQuestions] = useState([
    "Is there any other information you would like to submit?",
    "Do you have any questions for the lawyer?"
  ]);
  const [idealClientDescription, setIdealClientDescription] = useState("");
  const [worstClientDescription, setWorstClientDescription] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    console.log(
      "Loading Onboarding Lawyer Screen for Firestore ID: ",
      firestoreId,
    );
  }, []);

  useEffect(() => {
    // If all the fields are filled, enable the submit button
    setIsFormValid(
      lawyerName.trim() !== "" &&
        lawfirmName.trim() !== "" &&
        hourlyRate.trim() !== "" &&
        selectedLawfirmSize.length > 0 &&
        selectedStates.length > 0 &&
        selectedLegalSectors.length > 0,
    );
  }, [
    lawyerName,
    lawfirmName,
    hourlyRate,
    selectedLegalSectors,
    selectedLawfirmSize,
    selectedStates,
    isFormValid,
  ]);

  // Show pop up when submitting the form
  useEffect(() => {
    if (redirecting) {
      setTimeout(() => {
        navigate("/");
      }, 3000); // Redirect after 3 seconds
    }
  }, [redirecting, navigate]);

  const handleLegalSectorClick = (sector) => {
    // Toggle the selected state of the legal sector
    setSelectedLegalSectors((prevSelected) =>
      prevSelected.includes(sector)
        ? prevSelected.filter((selected) => selected !== sector)
        : [...prevSelected, sector],
    );
  };

  const handleSizeClick = (size) => {
    setSelectedLawfirmSize(size);
  };


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setDropdownActive(!!e.target.value); // Show dropdown only if there's a search query
  };

  const filteredStates = fullStates.filter((state) =>
    state.name.toLowerCase().includes(searchQuery.toLowerCase()) || state.abbreviation.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleStateChange = (stateAbbreviation) => {
    // Determine if the input is an abbreviation or full name and convert to abbreviation if necessary
    const abbreviation = stateNameToAbbreviation[stateAbbreviation] || stateAbbreviation;

    if (selectedStates.includes(abbreviation)) {
      setSelectedStates(
        selectedStates.filter((selectedState) => selectedState !== abbreviation),
      );
    } else {
      setSelectedStates([...selectedStates, abbreviation]);
    }
    setSearchQuery(""); // Clear search query when selecting a state
    setDropdownActive(false); // Close the dropdown when a state is selected
  };

  
  const handleFlatFeeChange = (index, field, value) => {
    const updatedFlatFees = [...flatFees];
    updatedFlatFees[index][field] = value;
    setFlatFees(updatedFlatFees);
  };

  const addFlatFee = () => {
    setFlatFees([...flatFees, { concept: "", price: "" }]);
  };

  const removeFlatFee = (index) => {
    const updatedFlatFees = [...flatFees];
    updatedFlatFees.splice(index, 1);
    setFlatFees(updatedFlatFees);
  };

  const handleContingencyFeeChange = (index, field, value) => {
    const updatedContingencyFees = [...contingencyFees];
    updatedContingencyFees[index][field] = value;
    setContingencyFees(updatedContingencyFees);
  };

  const addContingencyFee = () => {
    setContingencyFees([...contingencyFees, { concept: "", price: "" }]);
  };

  const removeContingencyFee = (index) => {
    const updatedContingencyFees = [...contingencyFees];
    updatedContingencyFees.splice(index, 1);
    setContingencyFees(updatedContingencyFees);
  };

  const handleRequiredQuestionChange = (index, field, value) => {
    const updatedQuestions = [...requiredQuestions];
    updatedQuestions[index] = value;
    setRequiredQuestions(updatedQuestions);
  };

  const addRequiredQuestion = () => {
    setRequiredQuestions([...requiredQuestions, ""]);
  };

  const removeRequiredQuestion = (index) => {
    const updatedQuestions = [...requiredQuestions];
    updatedQuestions.splice(index, 1);
    setRequiredQuestions(updatedQuestions);
  };



  const handleFinalQuestionChange = (index, field, value) => {
    const updatedQuestions = [...finalQuestions];
    updatedQuestions[index] = value;
    setFinalQuestions(updatedQuestions);
  };

  const addFinalQuestion = () => {
    setFinalQuestions([...finalQuestions, ""]);
  };

  const removeFinalQuestion = (index) => {
    const updatedQuestions = [...finalQuestions];
    updatedQuestions.splice(index, 1);
    setFinalQuestions(updatedQuestions);
  };

  const handleDisclaimerChange = (e) => {
    setDisclaimer(e.target.value);
  };


  const handleInputChange = (e) => {
    // TODO: Read the input values from the form
    const form = e.target.form; // Get the form element
    const formData = new FormData(form);
    const formValues = Object.fromEntries(formData.entries());

    // TODO: Save everything to localStorage
    Object.entries(formValues).forEach(([key, value]) => {
      console.log(`${key}: ${value}`);
      if (key === "name") {
        setLawyerName(value);
      } else if (key === "LawfirmName") {
        setLawfirmName(value);
      } else if (key === "HourlyRate") {
        setHourlyRate(value);
      } else if (key === "idealClient") {
        setIdealClientDescription(value);
      } else if (key === "worstClient") {
        setWorstClientDescription(value);
      }
    });
  };

  const handleSubmit = async (e) => {
    console.log("localStorage:", localStorage)
    e.preventDefault();

    // Read all the values from the form
    // const form = e.target;
    // const formData = new FormData(form);
    // const formValues = Object.fromEntries(formData.entries());
    // console.log("Form Values: ", formValues);

    setIsOnboarded(true);

    // TODO: Add $ to the hourly rate if it's missing and to the flat fees
    let hourlyRateWithDollar = hourlyRate;
    if (!hourlyRate.includes("$")) {
      hourlyRateWithDollar = "$" + hourlyRate;
    }

    let flatFeesDict = {};
    flatFees.forEach((fee, index) => {
      // Check if fee.concept is not empty or undefined
      if (fee.concept && fee.concept.trim() !== "") {
        if (!fee.price.includes("$")) {
          flatFeesDict[`${fee.concept}`] = "$" + fee.price;
        } else {
          flatFeesDict[`${fee.concept}`] = fee.price;
        }
      }
    });

    let contingencyFeesDict = {};
    contingencyFees.forEach((fee, index) => {
      // Check if fee.concept is not empty or undefined
      if (fee.concept && fee.concept.trim() !== "") {
        if (!fee.price.includes("%")) {
          contingencyFeesDict[`${fee.concept}`] = fee.price + "%";
        } else {
          contingencyFeesDict[`${fee.concept}`] = fee.price;
        }
      }
    });

    // Eliminate any empty strings from the required questions
    let filteredQuestions = requiredQuestions.filter(
      (question) => question.trim() !== "",
    );

    // Save the lawfirm name without spaces
    let lawfirmNameNoSpace = lawfirmName.replace(/\s/g, "");
    localStorage.setItem("lawyerLawfirm", lawfirmNameNoSpace);

    // TODO: Read and Save info about this lawyer in Firestore
    const docRef = doc(db, "lawyers", firestoreId);
    updateDoc(docRef, {
      lawyerName: lawyerName,
      lawyerLawfirm: lawfirmNameNoSpace,
      hourlyRate: hourlyRateWithDollar,
      lawfirmSize: selectedLawfirmSize,
      listLegalSectors: selectedLegalSectors,
      jurisdiction: selectedStates,
      flatFees: flatFeesDict || {},
      contingencyFees: contingencyFeesDict || {},
      requiredQuestionsLawfirm: filteredQuestions || [],
      idealClientDescription: idealClientDescription || "",
      worstClientDescription: worstClientDescription || "",
      isOnboarded: true,
    }).then(() => {
      console.log("Lawyer information saved with ID: ", firestoreId);
      setIsSuccess(true);
      setRedirecting(true);
      // navigate('/');
      slackLawyerOnboarded(
        `Lawyer ${lawyerName} has been processed and is now onboarded.`,
      );
      LogRocket.identify(firestoreId, {
        name: lawyerName,
        lawfirm: lawfirmNameNoSpace,
      });
    });

    // TODO: Add lawyerLawfirm to list of lawfirms to Firestore
    const lawfirmsRef = doc(db, "lawfirms", "list");
    updateDoc(lawfirmsRef, {
      lawfirms: arrayUnion(lawfirmNameNoSpace),
    }).then(() => {
      console.log("Lawfirm added to list of lawfirms: ", lawfirmNameNoSpace);
    });

    // }).then (() => {
    //   console.log("Document successfully written!");
    // }).catch((error) => {
    //   console.error("Error writing document: ", error);
    // });

    // TODO: Set them as unverified until we manually verify them and define the logic for this

    // TODO: Sent a notification to us to verify this lawyer
    // Example lawyerInfo, replace with actual form data
    const lawyerInfo = {
      name: "Lawyer Name", // Replace with actual data
      lawFirm: "Law Firm Name", // Replace with actual data
    };

    if (!disclaimer) {
      handleDisclaimerChange("All information provided to the firm by you as part of this packet and at the Initial Consultation is protected by the Attorney Client Privilege. Please understand, however, that by filling out this form or meeting with you to discuss your legal concerns, ${lawyerLawfirm} has not undertaken your representation. Meeting with the firm for an Initial Consultation does not bind this firm or the potential client (you) in any way to provide further work or legal services. Should this firm undertake your representation, our arrangement must be formalized in writing and consideration must be paid for our services beyond the cost of the Initial Consultation. Essentially, the Initial Consultation and/or the intake form is an opportunity for the firm to meet you and gather information about your legal concerns. It is also an opportunity for you and the firm to determine whether you are likely to have a strong, productive working relationship.");
    }
  };

  const handleKeyDown = (e) => {
    const focusedElement = document.activeElement;
    let nextElement;
    
    switch(e.key) {
      case "ArrowDown":
        e.preventDefault(); // Prevent scrolling
        nextElement = focusedElement.nextSibling || focusedElement.parentElement.firstChild;
        nextElement.focus(); // Move focus to the next item
        break;
      case "ArrowUp":
        e.preventDefault();
        nextElement = focusedElement.previousSibling || focusedElement.parentElement.lastChild;
        nextElement.focus(); // Move focus to the previous item
        break;
      case "Enter":
        e.preventDefault();
        // Assuming state.abbreviation is the value you need
        const stateAbbreviation = focusedElement.getAttribute("data-abbreviation");
        handleStateChange(stateAbbreviation);
        setDropdownActive(false); // Close the dropdown
        break;
      default:
        break;
    }
  };
  
  return (
    <>
      <div className="box dark-green-box">
        <div className="columns is-mobile">
          <div
            className="box white-box column is-4"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="columns is-centered is-vcentered">
              <div className="column is-1"></div>
              <div className="column">
                <div className="subtitle is-2 has-text-centered">
                  <img
                    src="/CaddiLogo.png"
                    alt="Caddi Logo"
                    className="mr-2"
                    style={{ height: "30px", width: "auto" }}
                  />
                  Caddi
                </div>
                <div className="subtitle is-4 has-text-centered">
                  Welcome to Caddi, let's get you set up
                </div>
              </div>
              <div className="column is-1"></div>
            </div>
          </div>

          <div className="box light-green-box column">
            <section className="columns">
              <div className="column is-2"></div>
              <div className="column has-text-centered">
                <br></br>
                <h1 className="title">
                  <span className="has-text-success mx-2">Caddi</span>
                  onboarding
                </h1>

                <form onSubmit={handleSubmit}>
                  <br></br>

                  <div className="mb-2">
                    <label className="label has-text-left" htmlFor="name">
                      What's your name?*
                    </label>
                    <input
                      name="name"
                      className="input"
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="mb-2">
                    <label className="label has-text-left" htmlFor="name">
                      What's your law firm's name?*
                    </label>
                    <input
                      name="LawfirmName"
                      className="input"
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="divider my-5"></div>

                  {/* Select law firm size */}
                  <div className="mb-4">
                    <label className="label has-text-left" htmlFor="name">
                      How many lawyers are in your firm?*
                    </label>
                    <div className="columns is-multiline">
                      {lawfirmSizes.map((sector) => (
                        <div key={sector} className="column">
                          <div
                            className={`box button-box ${selectedLawfirmSize === sector ? "selected" : ""}`}
                            onClick={() => handleSizeClick(sector)}
                          >
                            <p className="has-text-centered">{sector}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="divider my-5"></div>

                  {/* State Selector with Search */}
                  <div className="mb-6">
                    <div className="field">
                      <label className="label has-text-left">
                        Select the jurisdictions you practice in*
                      </label>
                      <div
                        className={`dropdown ${dropdownActive ? "is-active" : ""}`}
                      >
                        <div className="dropdown-trigger">
                          <div className="control is-expanded">
                            <input
                              className="input"
                              type="text"
                              placeholder="Search states"
                              value={searchQuery}
                              onChange={handleSearchChange}
                            />
                            {/* <FontAwesomeIcon icon={faSearch} /> */}
                          </div>
                        </div>

                        {/* Dropdown */}
                        {dropdownActive && (
                          <div
                            className="dropdown-menu"
                            id="dropdown-menu"
                            role="menu"
                          >
                            <div className="dropdown-content">
                            {filteredStates.map((state, index) => (
                              <a
                                key={state.abbreviation}
                                tabIndex="0"
                                data-abbreviation={state.abbreviation}
                                onKeyDown={handleKeyDown}
                                onClick={() => handleStateChange(state.abbreviation)}
                                className={`dropdown-item ${
                                  selectedStates.includes(state.abbreviation) ? "is-active" : ""
                                }`}
                              >
                                {state.name}
                              </a>
                            ))}

                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Selected States as Boxes */}
                    <div className="columns is-multiline">
                      {selectedStates.map((state) => (
                        <div key={state} className="column is-2">
                          <div className="button-box">
                            {state}
                            <button
                              className="delete is-small ml-2 mt-1"
                              onClick={() => handleStateChange(state)}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="divider my-5"></div>

                  {/* Select legal sectors */}
                  <div className="mb-2">
                    <label className="label has-text-left">
                      Select your legal sectors*
                    </label>
                    <div className="columns is-multiline">
                      {legalSectors.map((sector) => (
                        <div key={sector} className="column is-half">
                          <div
                            className={`box button-box ${selectedLegalSectors.includes(sector) ? "selected" : ""}`}
                            onClick={() => handleLegalSectorClick(sector)}
                          >
                            <p className="has-text-centered">{sector}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="divider my-5"></div>

                  {/* Hourly Rate */}
                  <div className="my-5">
                    <label className="label has-text-left" htmlFor="name">
                      Hourly Rate ($)*
                    </label>
                    <input
                      name="HourlyRate"
                      className="input"
                      onChange={handleInputChange}
                    />
                  </div>

                  {/* Flat fees */}
                  <div className="mb-2">
                    <div className="columns is-mobile">
                      <div className="column is-7">
                        <label className="label has-text-left" htmlFor="name">
                          Flat Fee Concept
                        </label>
                      </div>
                      <div className="column is-5">
                        <label className="label has-text-left" htmlFor="name">
                          Price ($)
                        </label>
                      </div>
                    </div>

                    {flatFees.map((fee, index) => (
                      <div className="columns mb-1 is-mobile" key={index}>
                        <div className="column is-7">
                          <input
                            name={`concept-${index}`}
                            className="input"
                            placeholder="Example: LLC Formation"
                            value={fee.concept}
                            onChange={(e) =>
                              handleFlatFeeChange(
                                index,
                                "concept",
                                e.target.value,
                              )
                            }
                          />
                        </div>
                        <div className="column is-4">
                          <input
                            name={`price-${index}`}
                            className="input"
                            placeholder="2500"
                            value={fee.price}
                            onChange={(e) =>
                              handleFlatFeeChange(
                                index,
                                "price",
                                e.target.value,
                              )
                            }
                          />
                        </div>
                        <div className="column">
                          <button
                            type="button"
                            className="button is-small is-light is-round mt-1"
                            onClick={() => removeFlatFee(index)}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="button is-success"
                      onClick={addFlatFee}
                    >
                      Add Flat Fee
                    </button>
                  </div>

                  {/* Contingency fees */}
                  <div className="mb-2">
                    <div className="columns is-mobile">
                      <div className="column is-7">
                        <label className="label has-text-left" htmlFor="name">
                          Contingency Fee Concept
                        </label>
                      </div>
                      <div className="column is-5">
                        <label className="label has-text-left" htmlFor="name">
                          Percentage (%)
                        </label>
                      </div>
                    </div>

                    {contingencyFees.map((fee, index) => (
                      <div className="columns mb-1 is-mobile" key={index}>
                        <div className="column is-7">
                          <input
                            name={`concept-${index}`}
                            className="input"
                            placeholder="Example: Medical malpractice"
                            value={fee.concept}
                            onChange={(e) =>
                              handleContingencyFeeChange(
                                index,
                                "concept",
                                e.target.value,
                              )
                            }
                          />
                        </div>
                        <div className="column is-4">
                          <input
                            name={`price-${index}`}
                            className="input"
                            placeholder="30"
                            value={fee.price}
                            onChange={(e) =>
                              handleContingencyFeeChange(
                                index,
                                "price",
                                e.target.value,
                              )
                            }
                          />
                        </div>
                        <div className="column">
                          <button
                            type="button"
                            className="button is-small is-light is-round mt-1"
                            onClick={() => removeContingencyFee(index)}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="button is-success"
                      onClick={addContingencyFee}
                    >
                      Add Contingency Fee
                    </button>
                  </div>

                  <div className="divider my-5"></div>

                  {/* Ideal Client */}
                  <div className="mb-2">
                    <label
                      className="label has-text-left"
                      htmlFor="idealClient"
                    >
                      Describe your ideal client
                    </label>
                    <textarea
                      name="idealClient"
                      className="textarea"
                      onChange={handleInputChange}
                      placeholder="Example: I like working with experienced clients that have dealt with this type of issues in the past."
                    />
                  </div>

                  {/* Worst Client */}
                  <div className="mb-2">
                    <label
                      className="label has-text-left"
                      htmlFor="idealClient"
                    >
                      Describe the type of client you don't like working with
                    </label>
                    <textarea
                      name="idealClient"
                      className="textarea"
                      onChange={handleInputChange}
                      placeholder="Example: I don't like working with clients that don't have a clear understanding of their problem."
                    />
                  </div>

                {/* Disclaimer */}  
                <div className="mb-2">
                <label className="label has-text-left" htmlFor="name">
                 Disclaimer
                </label>
                <textarea
                  name="disclaimer"
                  className="textarea"
                  value={disclaimer}
                  onChange={handleDisclaimerChange}
                  placeholder= "All information provided to the firm by you as part of this packet and at the Initial Consultation is protected by the Attorney Client Privilege. Please understand, however, that by filling out this form or meeting with you to discuss your legal concerns, ${lawyerLawfirm} has not undertaken your representation. Meeting with the firm for an Initial Consultation does not bind this firm or the potential client (you) in any way to provide further work or legal services. Should this firm undertake your representation, our arrangement must be formalized in writing and consideration must be paid for our services beyond the cost of the Initial Consultation. Essentially, the Initial Consultation and/or the intake form is an opportunity for the firm to meet you and gather information about your legal concerns. It is also an opportunity for you and the firm to determine whether you are likely to have a strong, productive working relationship."

                />
                </div>

                  {/* Required questions */}
                  <div className="">
                    <div className="columns">
                      <div className="column">
                        <label className="label has-text-left" htmlFor="name">
                          Add any required questions for your intake forms
                        </label>
                      </div>
                    </div>

                    {requiredQuestions.map((question, index) => (
                      <div className="columns mb-1 is-mobile" key={index}>
                        <div className="column is-11">
                          <input
                            name={`question-${index}`}
                            className="input"
                            placeholder="Example: What is the tenant's military status?"
                            value={question}
                            onChange={(e) =>
                              handleRequiredQuestionChange(
                                index,
                                "question",
                                e.target.value,
                              )
                            }
                          />
                        </div>
                        <div className="column">
                          <button
                            type="button"
                            className="button is-small is-light is-round mt-1"
                            onClick={() => removeRequiredQuestion(index)}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="button is-success"
                      onClick={addRequiredQuestion}
                    >
                      Add More Questions
                    </button>
                  </div>


                  {/* Final questions */}
                  <div className="">
                    <div className="columns">
                      <div className="column">
                        <label className="label has-text-left" htmlFor="name">
                          Add any final questions for your intake forms
                        </label>
                      </div>
                    </div>

                    {finalQuestions.map((question, index) => (
                      <div className="columns mb-1 is-mobile" key={index}>
                        <div className="column is-11">
                          <input
                            name={`question-${index}`}
                            className="input"
                            placeholder="Example: Do you have any questions for the lawyer?"
                            value={question}
                            onChange={(e) =>
                              handleFinalQuestionChange(
                                index,
                                "question",
                                e.target.value,
                              )
                            }
                          />
                        </div>
                        <div className="column">
                          <button
                            type="button"
                            className="button is-small is-light is-round mt-1"
                            onClick={() => removeFinalQuestion(index)}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="button is-success"
                      onClick={addFinalQuestion}
                    >
                      Add More Questions
                    </button>
                  </div>



                </form>

                <br></br>

                {isSuccess && (
                  <div className="notification is-success my-2">
                    Setup was successful! Redirecting to the home page...
                  </div>
                )}

                <button
                  className="button is-success is-pulled-right"
                  onClick={handleSubmit}
                  disabled={!isFormValid}
                >
                  Submit
                </button>
                <br></br>
              </div>
              <div className="column is-2"></div>
            </section>
          </div>
        </div>
        {/* <FooterIntake /> */}
      </div>
    </>
  );
};

export default OnboardingLawyer;
