import ShowIntakeQA from "./ShowIntakeQA";
import { useState, useEffect } from "react";

const SimListQuestion = (props) => {
  // Concat props.issueQuestion, props.intentQuestion, clientDetailsQuestions, specificLegalQuestions, finalQuestions
  let allQuestions = [
    props.issueQuestion,
    props.intentQuestion,
    // props.legalSubsubsector,
    ...props.specificLegalQuestions,

  ];
  console.log("allQuestions", allQuestions);

  let allAnswers = [
    props.issueAnswer,
    props.intentAnswer,
    ...props.specificLegalAnswers,

  ];
  console.log("allAnswers", allAnswers);

  return (
    <>
      <div className="has-text-centered mb-5">
        {allQuestions.length > 0 && <h1 className="title is-4">Simluation Results</h1>}
        {allQuestions.length === 0 && <h1 className="title is-4">No Intake</h1>}
      </div>
      <div>
        {allQuestions.length > 0 &&
          allQuestions.map((question, index) => {
            return (
              <ShowIntakeQA
                question={question}
                answer={allAnswers[index]}
                key={index}
              />
            );
          })}
      </div>
    </>
  );
};
      

export default SimListQuestion;
