import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../config/firebase-config";
import { doc, updateDoc } from "firebase/firestore";
import useLocalStorage from "./useLocalStorage";

const ShowPastIntake = (props) => {
  const [status, setStatus] = useState(["Incomplete"]);
  const [intakeSummary, setIntakeSummary] = useState([""]);
  const [disclaimer, setDisclaimer] = useLocalStorage("disclaimer", "");
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Status: ", props.sessionInfo.submissionStatus);
    if (props.sessionInfo.submissionStatus === "Submitted") {
      setStatus("Submitted");
    } else {
      setStatus("Incomplete");
    }
  }, [props.sessionInfo.submissionStatus]);

  useEffect(() => {
    // Get the first sentence of the props.sessionInfo.issue
    if (props.sessionInfo.issue !== undefined) {
      let intakeSummaryText = props.sessionInfo.issue.split(".")[0] + "...";
      setIntakeSummary(intakeSummaryText);
    }
  }, [props.sessionInfo.issue]);

  const loadPastIntake = (firestoreID) => {
    console.log("Load past intake with firestoreID: ", firestoreID);
    console.log("TESTING DISCLAIMER SHOW PAST INTAKE: ", disclaimer);
    props.onSessionSelected(firestoreID);
    navigate("/intake/disclaimer");
  };

  const handleEliminate = () => {
    // Alert the user of the elimination and ask him to confirm
    if (window.confirm("Are you sure you want to eliminate this intake?")) {
      // TODO: Soft delete
      let docRef = doc(db, "leads", props.firestoreId);
      updateDoc(docRef, {
        eliminatedByUser: "eliminated",
      })
        .then(() => {
          console.log("eliminate intake with session:", props.firestoreId);
          props.onEliminateSession(props.firestoreId);
        })
        .catch((error) => {
          console.error("Error soft deleting intake in Firestore: ", error);
        });
    }
  };

  return (
    <div className="mb-1 container">
      <button
        className="delete"
        style={{ position: "absolute", top: "5px", right: "5px" }}
        onClick={handleEliminate}
      ></button>
      <div
        className="box"
        onClick={() => loadPastIntake(props.firestoreId)}
        style={{ cursor: "pointer" }}
      >
        <div className="is-6">Issue: {intakeSummary}</div>
        <div className="columns">
          <div className="column">
            {" "}
            - Created: {props.sessionInfo.createdDate}
          </div>
          <div className="column"> - Status: {status}</div>
        </div>
      </div>
    </div>
  );
};
export default ShowPastIntake;
