import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FileUploader from "../components/FileUploader";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  getFirestore,
  getDoc,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../config/firebase-config";
import mixpanel from "mixpanel-browser";
import NavbarFooter from "../components/NavbarFooter";
import IntakeSideMenu from "../components/IntakeSideMenu";
import NavbarIntake from "../components/NavbarIntake";
import FooterIntake from "../components/FooterIntake";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

//import './DocumentsUpload.css';

import useLocalStorage from "../components/useLocalStorage";

function DocumentsUpload(props) {
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(true);
  const [firestoreId, setFirestoreId] = useLocalStorage("firestoreId", "");
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);

  const [generalCaseAnswer, setGeneralCaseAnswer] = useLocalStorage(
    "generalCaseAnswer",
    "",
  );
  const [idealResult, setIdealResult] = useLocalStorage("idealResult", "");
  const [specificQuestions, setSpecificQuestions] = useLocalStorage(
    "specificQuestions",
    [],
  );
  const [specificAnswers, setSpecificAnswers] = useLocalStorage(
    "specificAnswers",
    [],
  );
  const [legalSector, setLegalSector] = useLocalStorage("legalSector", "");
  const [legalSubSector, setLegalSubSector] = useLocalStorage(
    "legalSubSector",
    "",
  );
  const [legalSubsubsector, setLegalSubsubsector] = useLocalStorage(
    "legalSubsubsector",
    "",
  );

  // const [docsList, setDocsList] = useLocalStorage('docsList', []);
  const [docsList, setDocsList] = useState(
    JSON.parse(localStorage.getItem("docsList")) || [],
  );
  const [docsListAddedUser, setDocsListAddedUser] = useLocalStorage(
    "docsListAddedUser",
    [],
  );
  const [userUploadedDocs, setUserUploadedDocs] = useLocalStorage(
    "userUploadedDocs",
    false,
  );
  const [finalQuestions, setFinalQuestions] = useLocalStorage(
    "finalQuestions",
    [],
  );

  const [docsToUploadReady, setDocsToUploadReady] = useLocalStorage(
    "docsToUploadReady",
    true,
  );
  const [docsType, setDocsType] = useLocalStorage("docsType", []);

  // const [docsListAddedUser, setDocsListAddedUser] = useState([]);
  const [noDocs, setNoDocs] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const noMoreFollowUpQuestions = false;

  // TODO: make sure to display the documents that have been uploaded
  useEffect(() => {
    console.log("Load Docs Uploaded");
    console.log("User FirestoreId: ", firestoreId);
    if (docsListAddedUser.length > 0) {
      setUserUploadedDocs(true);
    }
  }, [docsList]);

  // guardarail for when user has no documents to upload (check that docsType.length === 0) (this is in case user does not have docs)
  useEffect(() => {
    setNoDocs(!userUploadedDocs);
  }, [userUploadedDocs]);

  // Hook to update questions when they change in localStorage in another screen
  useEffect(() => {
    let variableToTrack = "docsList";

    const handleStorageChange = (event) => {
      setDocsList(event.detail.value);
      console.log(
        `Storage event received for key: ${variableToTrack}. Updated value:`,
        event.detail.value,
      );
    };
    // Listen for changes in localStorage specific to the 'myVariable' key
    window.addEventListener(`storage_${variableToTrack}`, handleStorageChange);
    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener(
        `storage_${variableToTrack}`,
        handleStorageChange,
      );
    };
  }, []);

  const handleAddDoc = () => {
    setDocsListAddedUser([
      ...docsListAddedUser,
      `New Document ${docsListAddedUser.length + 1}`,
    ]);
  };

  const continueNoDocs = () => {
    setShowPopup(!showPopup);
    navigate("/intake/halfway");
  };

  const handleSubmit = () => {
    // Let the user continue without docs but alert them about not doing so (this noDocs is being set whenever the user's doc has finished being analyzed. Change this)
    if (noDocs) {
      console.log("No docs uploaded");
      setShowPopup(true);
    } else {
      // Save additional docs added by user to Firestore
      console.log(
        "Firestore ID to update followUpQuestionsDocs: ",
        firestoreId,
      );
      let sessionToUpdateRef = doc(db, "leads", firestoreId);
      updateDoc(sessionToUpdateRef, {
        docsListAddedUser: docsListAddedUser,
      })
        .then(() => {
          console.log("Additional docs added by user in Firestore");
        })
        .catch((error) => {
          console.error(
            "Error additional docs added by user in Firestore: ",
            error,
          );
        });

      // props.onDocsListAddedUser(docsListAddedUser);

      // Save docsListAddedUser to localStorage
      let newDocsListAddedUser = docsListAddedUser;
      newDocsListAddedUser.push(docsListAddedUser);
      setDocsListAddedUser(newDocsListAddedUser);
      navigate("/intake/halfway");
      mixpanel.track("User Submitted Uploaded Documents in Intake", {
        "Firebase User Id": firestoreId,
      });
    }
  };

  const checkboxHandler = () => {
    setShowPopup(!showPopup);
  };

  const handleBackClick = () => {
    navigate("/intake/casedetailsspecific");
  };

  const handleCaddiClicked = () => {
    console.log("Caddi Button Clicked");
    navigate("/");
  };

  const onFormIsValid = (isValid) => {
    setIsFormValid(isValid);
  };

  const onDocsUploadedByUser = (newDocsUploaded) => {
    let newDocsType = docsType;
    if (newDocsUploaded.length > 0) {
      newDocsType.push(newDocsUploaded);
    }
    setDocsType(newDocsType);
  };

  const onUserUploadedDocs = () => {
    //Set it to true
    setUserUploadedDocs(true);
  };

  const onFollowUpQuestionsDocs = (param) => {
    // TODO: For future dev when followUp questions to doc
    localStorage.setItem("followUpQuestionsReady", true);
  };

  const onInfoMissingDocs = (param) => {
    // TODO: For future dev when followUp questions to doc
  };

  return (
    <>
      <div className="box dark-green-box">
        <div className="columns is-mobile">
          <div className="box white-box column is-3">
            <NavbarIntake
              isSignedIn={isSignedIn}
              isLawyer={isLawyer}
              lawyerLawfirm={lawyerLawfirm}
            />
          </div>
          <div className="box light-green-box column">
            <div className="columns is-centered">
              <div className="column is-2"></div>

              <div className="column">
                <div className="title is-4 has-text-centered">
                  Upload Documents
                </div>
                <div className="subtitle is-6 has-text-centered">
                  We required the following documents related to your issue.
                  Please add any other documents you think are relevant.
                </div>

                <div className="columns is-centered">
                  <div className="column is-8 has-text-centered is-centered">
                    {/* Map over docsList and generate FileUploader components */}

                    {docsList.length ? (
                      docsList.map((document, index) => (
                        <div key={index}>
                          <FileUploader
                            firestoreId={firestoreId}
                            DocumentTitle={document}
                            generalCaseAnswer={generalCaseAnswer}
                            specificQuestions={specificQuestions}
                            specificAnswers={specificAnswers}
                            onFollowUpQuestionsDocs={onFollowUpQuestionsDocs}
                            onInfoMissingDocs={onInfoMissingDocs}
                            docsList={docsList}
                            noMoreFollowUpQuestions={noMoreFollowUpQuestions}
                            onFormIsValid={onFormIsValid}
                            onDocsUploadedByUser={onDocsUploadedByUser}
                            legalSector={legalSector}
                            legalSubSector={legalSubSector}
                            legalSubsubsector={legalSubsubsector}
                            onUserUploadedDocs={onUserUploadedDocs}
                          />
                        </div>
                      ))
                    ) : docsToUploadReady ? (
                      <div>
                        <br></br>
                        <br></br>
                        <div className="box has-text-centered">
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="mr-4"
                          />
                          Analyzing documents needed
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                      </div>
                    ) : (
                      <div>
                        <br></br>
                        <br></br>
                        <div className="box has-text-centered">
                          <p className="">No documents needed for this case.</p>
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                      </div>
                    )}

                    {docsListAddedUser.length ? (
                      docsListAddedUser.map((document, index) => (
                        <div key={index}>
                          <FileUploader
                            firestoreId={firestoreId}
                            DocumentTitle={document}
                            generalCaseAnswer={generalCaseAnswer}
                            specificQuestions={specificQuestions}
                            specificAnswers={specificAnswers}
                            onFollowUpQuestionsDocs={onFollowUpQuestionsDocs}
                            onInfoMissingDocs={onInfoMissingDocs}
                            docsList={docsList}
                            noMoreFollowUpQuestions={noMoreFollowUpQuestions}
                            onFormIsValid={onFormIsValid}
                            onDocsUploadedByUser={onDocsUploadedByUser}
                            legalSector={legalSector}
                            legalSubSector={legalSubSector}
                            legalSubsubsector={legalSubsubsector}
                            onUserUploadedDocs={onUserUploadedDocs}
                          />
                        </div>
                      ))
                    ) : (
                      <br></br>
                    )}

                    {/*  If docsList.length === 0 && props.docsToUploadReady, show a message telling user there is no need to upload documents  */}

                    {/* {docsList.length === 0 && props.docsToUploadReady && (
                              <div>
                                <br></br>
                                <br></br>
                                <div className='box has-text-centered'>                   
                                  <p className=''>No documents needed for this case.</p>
                                </div>
                                <br></br>
                                <br></br>
                                <br></br>
                              </div>
                            )} */}

                    <div className="">
                      {/* Add button to add more documents */}
                      <button
                        className="button is-rounded has-background-success has-text-white has-text-weight-bold"
                        onClick={handleAddDoc}
                      >
                        Add More Documents
                      </button>
                    </div>
                    {/* <div className=''>
                            <button className='button is-rounded has-background-success has-text-white has-text-weight-bold' onClick = {handleSkip}>
                              Skip
                            </button>
                          </div> */}
                  </div>
                </div>
              </div>

              <div className="column is-2"></div>
            </div>

            <NavbarFooter
              handleBackClick={handleBackClick}
              handleNextClick={handleSubmit}
              isFormValid={isFormValid}
            />
          </div>
        </div>

        <FooterIntake />

        {showPopup && (
          <div className="modal is-active">
            <div className="modal-background" onClick={checkboxHandler}></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">No documents submitted</p>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={checkboxHandler}
                ></button>
              </header>
              <section className="modal-card-body">
                Uploading documents helps us understand your case better. Please
                confirm if you want to continue without uploading any documents.
              </section>
              <footer className="modal-card-foot">
                <div className="columns">
                  <div className="column">
                    <button
                      className="button has-text-success"
                      onClick={checkboxHandler}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="column">
                    <button
                      className="button is-success"
                      onClick={continueNoDocs}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default DocumentsUpload;
