import React from "react";

const ScoreBreakdownModal = ({
  showScoreBreakdown,
  closeScoreBreakdown,
  selectedScore,
  scoreInfo,
}) => {
  console.log("scoreInfo?", scoreInfo);

  const renderScoreBreakdown = () => {
    switch (selectedScore) {
      case "Trust":
        // TODO: get all the varibles from Firestore instead of hard coding them
        let subtitles = [
          "Trusted Domain",
          "LinkedIn",
          "Past Cases",
          "Verified LLC",
        ];
        let trustedDomain =
          "We have verified that the email domain is in good standing and not marked as spam.";
        let linkedIn =
          "We have found Andy’s profile on LinkedIn, which has a history of 8 years.";
        let courtCases =
          "We have found 3 court cases in the last 5 years. All of them were settled.";
        let verifiedLLC =
          "We have verified that the LLC is in good standing and not marked as spam.";

        return (
          <>
            <div className="columns">
              <div className="column">
                <div className="box">
                  <p className="subtitle">Trusted Domain</p>
                  <p>{trustedDomain}</p>
                </div>
              </div>
              <div className="column">
                <div className="box">
                  <p className="subtitle">LinkedIn</p>
                  <p>{linkedIn}</p>
                  <br></br>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="box">
                  {/* Check whether to use referls or linkedin searches or what*/}
                  <p className="subtitle">Past Cases</p>
                  {courtCases}
                </div>
              </div>
              <div className="column">
                <div className="box">
                  <p className="subtitle">Verified LLC</p>
                  <p>{verifiedLLC}</p>
                </div>
              </div>
            </div>
          </>
        );

      case "Suitability":
        // TODO: get all the varibles from Firestore instead of hard coding them

        let state = "Washington";
        let county = "King County";
        let legalSector = "Landlord-tenant";
        let legalSubsector = "Landlord-tenant";
        let legalSubsubsector = "Eviction";
        let referal = "Mr. Axel Wilson";
        let intent = "settle";

        let intentReasoning = scoreInfo?.intentCategory?.reasoning || "N/A";
        intentReasoning = intentReasoning
          ? intentReasoning.split(/[.!?]/)[0]
          : "N/A";

        let experienceReasoning =
          scoreInfo?.experienceCategory?.reasoning || "N/A";
        experienceReasoning = experienceReasoning
          ? experienceReasoning.split(/[.!?]/)[0]
          : "N/A";

        return (
          <>
            <div className="columns">
              <div className="column">
                <div className="box">
                  <p className="subtitle">
                    Jurisdiction:{" "}
                    {String(
                      scoreInfo?.jurisdictionInformation?.validJurisdiction ||
                        "N/A",
                    )}
                  </p>
                  <ul>
                    <li>
                      {" "}
                      - City:{" "}
                      {scoreInfo?.jurisdictionInformation?.city || "N/A"}
                    </li>
                    <li>
                      {" "}
                      - County:{" "}
                      {scoreInfo?.jurisdictionInformation?.county || "N/A"}
                    </li>
                    <li>
                      {" "}
                      - State:{" "}
                      {scoreInfo?.jurisdictionInformation?.state || "N/A"}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column">
                <div className="box">
                  <p className="subtitle">
                    Legal Sector:{" "}
                    {String(
                      scoreInfo?.legalSectorInformation?.validSector || "N/A",
                    )}
                  </p>
                  <ul>
                    <li>
                      {" "}
                      - Sector:{" "}
                      {scoreInfo?.legalSectorInformation?.legalSector || "N/A"}
                    </li>
                    <li>
                      {" "}
                      - Subsector:{" "}
                      {scoreInfo?.legalSectorInformation?.legalSubSector ||
                        "N/A"}
                    </li>
                    <li>
                      {" "}
                      - Subsubsector:{" "}
                      {scoreInfo?.legalSectorInformation?.legalSubSubSector ||
                        "N/A"}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column">
                <div className="box">
                  <p className="subtitle">
                    Intent: {scoreInfo?.intentCategory?.clientIntent || "N/A"}
                  </p>
                  <ul>
                    <li>{intentReasoning || "N/A"}.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="box">
                  {/* Check whether to use referls or linkedin searches or what*/}
                  <p className="subtitle">Referral Source:</p>
                  <ul>
                    <li>
                      {" "}
                      - {scoreInfo?.referralCategory?.isReferred || "N/A"}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column">
                <div className="box">
                  {/* Check whether to use referls or linkedin searches or what*/}
                  <p className="subtitle">
                    Experience:{" "}
                    {scoreInfo?.experienceCategory?.experienceLevel || "N/A"}
                  </p>
                  <ul>
                    <li>{experienceReasoning || "N/A"}.</li>
                  </ul>
                </div>
              </div>
              <div className="column">
                <div className="box">
                  <p className="subtitle">LinkedIn Profile: </p>
                  <ul>
                    <li>
                      - Current company:{" "}
                      {scoreInfo?.linkedInInformation?.experiences[0]
                        ?.company || "N/A"}
                    </li>
                    <li>
                      - Highest Degree:{" "}
                      {scoreInfo?.linkedInInformation?.education[0]
                        ?.degree_name || "N/A"}
                    </li>
                    <li>
                      - School:{" "}
                      {scoreInfo?.linkedInInformation?.education[0]?.school ||
                        "N/A"}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        );

      case "Validity":
        let docsRequired =
          "The client has provided the corresponding documents required to analyze the case.";
        let evidenceProvided =
          "We have verified that the client has evidence to support his/her case.";
        let statueOfLimitation =
          "Client is within deadline to pursue legal action";
        let redFlags = "No major red flags detected";

        return (
          <>
            <div className="columns">
              <div className="column">
                <div className="box">
                  <p className="subtitle">Documents Required</p>
                  <p>{docsRequired}</p>
                </div>
              </div>
              <div className="column">
                <div className="box">
                  <p className="subtitle">Evidence Provided</p>
                  <p>{evidenceProvided}</p>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="box">
                  {/* Check whether to use referls or linkedin searches or what*/}
                  <p className="subtitle">Statue of Limitations</p>
                  {statueOfLimitation}
                </div>
              </div>
              <div className="column">
                <div className="box">
                  <p className="subtitle">Red Flags</p>
                  <p>{redFlags}</p>
                </div>
              </div>
            </div>
          </>
        );

      case "Value":
        return (
          <div>
            <p>
              Explanation for the Value score to be provided when sync with law
              firm's billing history...
            </p>
            {/* Additional HTML specific to 'Accept' score */}
          </div>
        );
      default:
        return "No breakdown available.";
    }
  };

  return (
    <>
      {showScoreBreakdown && (
        <div className="modal is-active is-clipped">
          <div className="modal-background" onClick={closeScoreBreakdown}></div>
          <div
            className="modal-content"
            style={{ width: "70%", maxHeight: "90%", margin: "auto" }}
          >
            <div className="box" style={{ height: "100%" }}>
              <p className="title is-4">{selectedScore} Score Breakdown</p>
              {renderScoreBreakdown()}
            </div>
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={closeScoreBreakdown}
          ></button>
        </div>
      )}
    </>
  );
};

export default ScoreBreakdownModal;
