import React from "react";
//import '../pages/Dashboard.css';
import { Navigate } from "react-router-dom";
import "./ClientCard.css";

const SectorCard = ({ sector, selectedSector, handleButtonClick }) => {
  let isSelected = false;

  // TODO: If sector is equal to selectedSector, add a border around the card
  if (sector === selectedSector) {
    isSelected = true;
  }

  return (
    <div className="">
      <div
        className={`box card button-box ${isSelected ? "is-selected" : ""}`}
        onClick={() => handleButtonClick(sector)}
      >
        <p className="subtitle has-text-centered">{sector}</p>
      </div>
    </div>
  );
};

export default SectorCard;
