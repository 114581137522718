import React, { useState, useEffect } from "react";
import axios from "axios";
//import logo from "./logo.svg"
//import "./App.css"
import { getAuth, signOut } from "firebase/auth";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";

const LogoutLawyer = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();

    // Sign out from Firebase
    signOut(auth)
      .then(() => {
        console.log("Signed out from Firebase");
        //mixpanel.track("User logged out");
      })
      .catch((error) => {
        // An error happened.
        console.log("Error signing out from Firebase");
      });

    // Sign out from backend and destroy session cookie
    const apiHostname = process.env.REACT_APP_API_HOSTNAME;
    const apiUrlLogout = apiHostname
      ? `https://${apiHostname}/api/logout`
      : "http://localhost:5001/api/logout";

    fetch(apiUrlLogout).then((res) => {
      if (res.ok) {
        props.onLogoutLawyer();
        navigate("/");
        console.log("Logging out lawyer");
      } else {
        console.log("Error logging out");
      }
    });
  }, []);

  return <div>Logging out</div>;
};

export default LogoutLawyer;
