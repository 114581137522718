import React from "react";

const SpecificQuestionsCoPilot = ({
  question,
  id,
  handleInputChange,
  value,
  submissionStatus
}) => {
  //console.log("Specific Question: ", question, "id: ", id, "value: ", value)
  const isReadOnly = submissionStatus === "Submitted";

  return (
    <div className="">
      <form>
      <div className="field is-horizontal">
          <div className="field-label">
    <div key={id}>
      <label className="label has-text-left" htmlFor={`question-${id}`}>
        {question}
      </label>
      </div>
      <div className="field-body">
            <div className="field">
              <div className="control ">
              <input
            type="text"
            name={`question-${id}`}
            className="input mb-2 legal-copilot-specific-question-input"
            id={`question-${id}`}
            onChange={(e) => handleInputChange(id, e.target.value)}
            value={value}
            readOnly={isReadOnly}
          />
      </div>
      </div>
      </div>
    </div>
    </div>
    </form>
   
    </div>
    
  );
};

export default SpecificQuestionsCoPilot;
