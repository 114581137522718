import React, { useState } from 'react';

const CaseContext = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  let caseSummaryText = props.caseSummary;
  if (!caseSummaryText) {
    caseSummaryText = 'Waiting for client to finish intake to generate case summary';
  }

  // Splitting the case summary into paragraphs
  const paragraphs = caseSummaryText.split('\n\n').map((paragraph, index) => (
    <p key={index} style={{ whiteSpace: 'pre-wrap', marginBottom: '1em', lineHeight: '1.6' }}>
      {paragraph}
    </p>
  ));

  // Toggling the expanded state
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Inline styles for the "more/less" text
  const linkStyle = {
    fontWeight: 'bold',
    cursor: 'pointer',
    color: '#4CAF50', // Replace with your preferred shade of green
    display: 'block', // Ensures that the "more/less" link is on its own line
    textAlign: 'right', // Aligns the "more/less" link to the right
    marginTop: '0.5em', // Adds a little space above the "more/less" link
  };

  return (
    <>
      <div className="subtitle mb-3">Caddi AI Case Summary</div>
      <div className="box">
        {/* Always render the first paragraph */}
        {paragraphs[0]}
        {/* Render additional paragraphs and the toggle link only if there's more than one paragraph */}
        {paragraphs.length > 1 && (
          <>
            {isExpanded && paragraphs.slice(1)}
            <span onClick={toggleExpand} style={linkStyle}>
              {isExpanded ? 'less' : 'more'}
            </span>
          </>
        )}
      </div>
    </>
  );
};

export default CaseContext;
