import ShowDoc from "./ShowDoc";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const ListDocsSummaries = (props) => {
  const [docsNamesToShow, setDocsNamesToShow] = useState([]);
  const [docsSummariesToShow, setDocsSummariesToShow] = useState([]);

  useEffect(() => {
    // console.log("Documents Names: ", props.documentsList)
    console.log("Documents Names and Summaries: ", props.documentsSummaries);
    console.log("Firebase ID to download docs", props.firebaseId);

    let names = [];
    let summaries = [];

    // TODO: Ignore documents which names starts with "document"
    if (props.documentsSummaries !== undefined) {
      Object.entries(props.documentsSummaries).forEach(([key, value]) => {
        // console.log(`Doc Name: ${key}, Summary: ${value["Summary"]}`);

        // If the document name starts with "document", ignore it
        if (key.startsWith("document")) {
          return;
        }
        names.push(key);
        summaries.push(value["Summary"]);
      });
    }
    console.log("Names: ", names);
    console.log("Summaries: ", summaries);
    setDocsNamesToShow(names);
    setDocsSummariesToShow(summaries);
  }, []);

  // console.log("Documents Names and Summaries: ", props.documentsSummaries)

  return (
    <>
      <div className="has-text-centered mb-5">
        {docsNamesToShow.length > 0 && (
          <h1 className="title is-4">Documents Uploaded by Client</h1>
        )}
        {docsNamesToShow.length === 0 && (
          <h1 className="title is-4">No Documents Uploaded</h1>
        )}
      </div>
      <div>
        {docsNamesToShow.length > 0 &&
          docsNamesToShow.map((docName, index) => {
            return (
              <ShowDoc
                docName={docName}
                summary={docsSummariesToShow[index]}
                firebaseId={props.firebaseId}
                key={index}
              />
            );
          })}
      </div>
    </>
  );
};

export default ListDocsSummaries;
