// CaseDetails.js

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  doc,
  where,
  query,
  setDoc,
  getDocs,
  updateDoc,
  getFirestore,
  getDoc,
  arrayUnion,
} from "firebase/firestore";
// import {
//   collection,
//   where,
//   query,
//   getDocs,
//   updateDoc,
//   doc,
//   getDoc,
//   addDoc,
// } from "firebase/firestore";
import { db } from "../config/firebase-config";
import FileUploader from "../components/FileUploader";
import SpecificQuestion from "../components/SpecificQuestion";
import NavbarFooter from "../components/NavbarFooter";
import IntakeSideMenu from "../components/IntakeSideMenu";
import NavbarIntake from "../components/NavbarIntake";
import FooterIntake from "../components/FooterIntake";
import mixpanel from "mixpanel-browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import useLocalStorage from "../components/useLocalStorage";

function FinalQuestions(props) {
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const [firestoreId, setFirestoreId] = useLocalStorage("firestoreId", "");
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);
  const [submissionStatus, setSubmissionStatus] = useLocalStorage(
    "submissionStatus",
    "Incomplete",
  );

  const [questions, setQuestions] = useLocalStorage("finalQuestionsLawfirm", []);

  const [answers, setAnswers] = useLocalStorage("finalQuestionsAnswers", []);

  const lawyerQuery = query(
    collection(db, "lawyers"),
    where("lawyerLawfirm", "==", lawyerLawfirm),
  );

  // Execute the query against Firestore
  getDocs(lawyerQuery).then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      // Extract and persist required questions from the lawyer's document
      const requiredQuestionsLawfirm = doc.data().requiredQuestionsLawfirm || [];
      localStorage.setItem("clientDetailsQuestions", JSON.stringify(requiredQuestionsLawfirm));
      const finalQuestionsLawfirm = doc.data().finalQuestionsLawfirm || [];
      localStorage.setItem("finalQuestionsLawfirm", JSON.stringify(finalQuestionsLawfirm ));
      return;
    });
  });


  // TODO: Update so when log back in, questions and answers are there
  useEffect(() => {
    
    console.log("Load Follow Up Questions to Docs");
    console.log("User FirestoreId: ", firestoreId);

    // Check props to set questions and answers have data (TODO: change to questions.length > 0 and answers.length > 0)
    if (questions) {
      console.log("Final Questions: ", questions);
      if (answers) {
        console.log("Final Questions Answers: ", answers);
        setIsFormValid(true);
      }
    }
  }, []);

  // Function to handle changes in textareas
  const handleInputChange = (id, value) => {
    console.log("handleInputChange: ", id, value);
    // Update the state of answers
    const updatedAnswers = [...answers];
    updatedAnswers[id] = value;
    setAnswers(updatedAnswers);

    setIsFormValid(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // If there is an answer that is not filled, set it to empty string
    let newAnswers = answers;
    for (let i = 0; i < questions.length; i++) {
      if (newAnswers[i] === undefined) {
        newAnswers[i] = "";
      }
    }
    setAnswers(newAnswers);

    const allAnswers = answers;
    console.log("All newAnswers: ", allAnswers);

    // TODO: Only do this when submissionStatus is Incomplete
    // Save information from user
    const docRef = doc(db, "leads", firestoreId);
    updateDoc(docRef, {
      finalQuestionsAnswers: allAnswers,
    }).then(() => {
      console.log("Final questions answers updated with ID: ", firestoreId);
    });

    navigate("/intake/reviewandsecurity");
    mixpanel.track("User Submitted Final Questions in Intake", {
      "Firebase User Id": firestoreId,
    });
  };

  const handleBackClick = () => {
    navigate("/intake/costprediction");
  };

  const onFormIsValid = (isValid) => {
    setIsFormValid(isValid);
  };

  const handleCaddiClicked = () => {
    console.log("Caddi Button Clicked");
    navigate("/");
  };

  return (
    <>
      <div className="box dark-green-box">
        <div className="columns is-mobile">
          <div className="box white-box column is-3">
            <NavbarIntake
              isSignedIn={isSignedIn}
              isLawyer={isLawyer}
              lawyerLawfirm={lawyerLawfirm}
            />
          </div>
          <div className="box light-green-box column">
            <div className="columns is-centered">
              <div className="column is-2"></div>

              <div className="column">
                <div className="title is-4 has-text-centered">
                  A few final questions ...
                </div>
                <div className="subtitle is-6 has-text-centered">
                  This will help us evaluate your case better.
                </div>

                <form onSubmit={handleSubmit}>
                  {questions.length ? (
                    questions.map((question, index) => (
                      <SpecificQuestion
                        key={index}
                        id={index}
                        question={question}
                        handleInputChange={handleInputChange}
                        value={answers[index] || ""}
                        submissionStatus={submissionStatus}
                      />
                    ))
                  ) : (
                    <div>
                      <br></br>
                      <br></br>
                      <div className="box has-text-centered">
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          size="2x"
                          className="mr-4"
                        />
                        Loading final questions about your case.
                      </div>
                      <br></br>
                      <br></br>
                      <br></br>
                    </div>
                  )}
                </form>
                <br></br>
              </div>
              <div className="column is-2"></div>
            </div>

            <NavbarFooter
              handleBackClick={handleBackClick}
              handleNextClick={handleSubmit}
              isFormValid={isFormValid}
            />
          </div>
        </div>
        <FooterIntake />
      </div>
    </>
  );
}

export default FinalQuestions;
