import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faMinusCircle,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import mixpanel from "mixpanel-browser";
import "./Navbar.css";

import useLocalStorage from "./useLocalStorage";

const NavBar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [lawfirm, setLawfirm] = useLocalStorage("lawyerLawfirm", "");
  // const [firstScreen, setFirstScreen] = useState(true);
  //console.log("current location: ", location.pathname)

  // useEffect(() => {
  //   //console.log("NavBar props: ", props)
  //   let first = location.pathname === `/intake/${props.lawyerLawfirm}`; //TODO: CORRECT THIS AS IT HAS THE LAWFIRM NAME ON IT
  //   console.log("firstScreen: ", first)
  //   setFirstScreen(first);
  // }, [location.pathname, props.lawyerLawfirm]);

  // const secondScreen = location.pathname === "/intake/selectfield";
  // const thirdScreen = location.pathname === "/intake/generalinformation";
  const homeScreen = location.pathname === "/";
  // const logInScreenLawyer = location.pathname === "/loginLawyer";

  let loginScreen = "/intake/login";
  let logoutScreen = "/logout";
  let signInText = "Continue Intake";

  let signInTextIntake = "Continue Intake";
  let loginScreenIntake = "/intake/AI2Incubator";

  // If location.pathname is home or loginLawyer, signInText = "Sign In"
  if (location.pathname === "/" || location.pathname === "/loginLawyer") {
    signInText = "Sign In Lawyer";
    loginScreen = "/loginLawyer";
  }

  if (props.isLawyer) {
    logoutScreen = "/logoutLawyer";
  }

  const handleClickBurger = (e) => {
    const dropDownMenu = Array.prototype.slice.call(
      document.querySelectorAll(".navbar-menu"),
      0,
    );
    dropDownMenu.forEach((el) => {
      el.classList.toggle("is-active");
    });
  };

  // const handleSaveForLater = (e) => {
  //   e.preventDefault();
  //   navigate("/intake/signUp");
  // }

  const handleCaddiClick = () => {
    if (homeScreen) {
      // If you're already on the home screen, no need to navigate
      return;
    }
    navigate("/"); // Replace with the path you want to navigate to
  };

  const navbarStyles = {
    navbar: {
      color: homeScreen ? "white" : "black", // Set text color based on the home page
    },
  };

  const logOutButtonStyles = {
    color: homeScreen ? "white" : "black",
    backgroundColor: homeScreen ? "black" : "white",
  };

  const caddiStyles = {
    // fontWeight: "bold",
    cursor: "pointer",
  };

  const buttonClass = "is-success";

  return (
    <nav
      className={`navbar ${homeScreen ? "" : "has-shadow"} ${homeScreen ? "has-background-black" : ""}`}
      style={navbarStyles.navbar}
    >
      <div className="container">
        <div className="navbar-brand">
          <div className="navbar-item is-size-3 ml-5">
            <span
              className={`${homeScreen ? "has-text-white" : "has-text-success-dark"}`}
              style={caddiStyles}
              onClick={handleCaddiClick}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src="/CaddiLogo.png" alt="Caddi Logo" className="mr-2" />
                Caddi
              </div>
            </span>
          </div>
          <a
            role="button"
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarMenu"
            onClick={handleClickBurger}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
      </div>

      {!props.isSignedIn && (
        <div id="navbarMenu" className="navbar-menu">
          <div className="navbar-end" style={{ paddingRight: "32px" }}>
            {homeScreen && lawfirm && (
              <div className="navbar-item mr-2">
                <a className="button is-success" href={`/intake/${lawfirm}`}>
                  Back to Intake
                </a>
              </div>
            )}
            {/* <div className="navbar-item mr-2">
                <a href={loginScreenIntake} className={`button ${buttonClass}`}>
                  {signInTextIntake}
                </a>
              </div> */}

            <div className="navbar-item mr-6">
              <a href={loginScreen} className={`button ${buttonClass}`}>
                {signInText}
              </a>
            </div>
          </div>
        </div>
      )}

      {props.isSignedIn && (
        <div id="navbarMenu" className="navbar-menu">
          <div className="navbar-end">
            {homeScreen && props.isLawyer && (
              <div className="navbar-item">
                <a className="button is-success" href="/dashboard">
                  Dashboard
                </a>
              </div>
            )}
            {homeScreen && !props.isLawyer && (
              <div className="navbar-item">
                <a className="button is-success" href={`/intake/sessions`}>
                  Continue Intake
                </a>
              </div>
            )}
            <div className="navbar-item">
              <a
                className="button mr-6"
                style={logOutButtonStyles}
                href={logoutScreen}
              >
                Logout
              </a>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
