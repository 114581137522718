// CaseDetails.js

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  getFirestore,
  getDoc,
} from "firebase/firestore";
import { db } from "../config/firebase-config";
import mixpanel from "mixpanel-browser";
import NavbarFooter from "../components/NavbarFooter";
import IntakeSideMenu from "../components/IntakeSideMenu";
import NavbarIntake from "../components/NavbarIntake";
import SpecificQuestion from "../components/SpecificQuestion";
import FooterIntake from "../components/FooterIntake";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import useLocalStorage from "../components/useLocalStorage";

function ClientDetailsQuestions(props) {
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const [firestoreId, setFirestoreId] = useLocalStorage("firestoreId", "");
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);
  const [submissionStatus, setSubmissionStatus] = useLocalStorage(
    "submissionStatus",
    "Incomplete",
  );

  const [questions, setQuestions] = useLocalStorage(
    "clientDetailsQuestions",
    [],
  );
  const [answers, setAnswers] = useLocalStorage("clientDetailsAnswers", []);

  const getClientDetailsQuestions = async () => {
    // Read questions from Firebase
    const docRef = doc(db, "qualitativeDB", "legal");
    getDoc(docRef)
      .then((document) => {
        if (document.exists()) {
          console.log("Document data:", document.data());
          // Set the state of the fields
          if (document.data().questions) {
            console.log(
              "Sector Questions Required exists: ",
              document.data().questions,
            );
            setQuestions(document.data().questions);
          }
        }
      })
      .catch((error) => {
        console.log("Error getting info from Firestore:", error);
      });

    // TODO: for when we want to add AI modifications to this part
    // try {
    //     const apiHostname = process.env.REACT_APP_API_HOSTNAME;
    //     const apiUrlGetClientDetailsQuestions = apiHostname
    //       ? `https://${apiHostname}/api/getClientDetailsQuestions`
    //       : "http://localhost:5001/api/getClientDetailsQuestions";

    //     const response = await fetch(apiUrlGetClientDetailsQuestions, {
    //       method: "GET",
    //       headers: {
    //         "Content-Type": "application/json"
    //       },
    //     })

    //     if (response.ok) {
    //       const data = await response.json();
    //       console.log("Got back ok response:", data);
    //       console.log("List of client details questions: ", data.questions)
    //       setQuestions(data.questions);
    //     } else {
    //       console.log("Client details questions went wrong");
    //     }
    //   } catch (error) {
    //     console.log("error: ", error)
    //   }
  };

  useEffect(() => {
    console.log("Load Client Details");
    console.log("User FirestoreId: ", firestoreId);

    if (questions.length) {
      console.log("Questions already loaded: ", questions);
      if (answers.length) {
        console.log("Answers already loaded: ", answers);
        // If at least one answer is filled, enable form
        if (answers.some((answer) => answer !== "")) {
          setIsFormValid(true);
        } else {
          setIsFormValid(false);
        }
      }
    } else {
      // TODO: this shouldn't be neccessary anymore
      getClientDetailsQuestions();
    }
  }, [questions, answers]);

  const handleBackClick = () => {
    navigate("/intake/casedetails");
  };

  // Function to handle changes in textareas
  const handleInputChange = (id, value) => {
    console.log("handleInputChange: ", id, value);
    // Update the state of answers
    const updatedAnswers = [...answers];
    updatedAnswers[id] = value;
    setAnswers(updatedAnswers);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // If submitted, skip
    if (submissionStatus === "Incomplete") {
      // If there is an answer that is not filled, set it to empty string
      let newAnswers = answers;
      for (let i = 0; i < questions.length; i++) {
        console.log("Going through question: ", i);
        if (!answers[i]) {
          console.log("Answer not filled: ", i);
          newAnswers[i] = "";
        }
      }
      setAnswers(newAnswers);

      // Access all the answers from the 'answers' state
      const clientDetailsAnswers = newAnswers;
      console.log("All Answers: ", clientDetailsAnswers);

      // Set states for specific questions and answers
      console.log(
        "Client Details Questions and Answers to update globally: ",
        questions,
        clientDetailsAnswers,
      );
      // props.onClientDetailsQuestions(questions, clientDetailsAnswers);

      // Save information from user
      const docRef = doc(db, "leads", firestoreId);
      updateDoc(docRef, {
        clientDetailsQuestions: questions,
        clientDetailsAnswers: clientDetailsAnswers,
      }).then(() => {
        console.log("Client details answers updated with ID: ", firestoreId);
      });

      mixpanel.track("User Submitted Client Details in Intake", {
        "Firebase User Id": firestoreId,
      });
    }

    navigate("/intake/legalSector");
  };

  const handleCaddiClicked = () => {
    console.log("Caddi Button Clicked");
    navigate("/");
  };

  return (
    <>
      <div className="box dark-green-box">
        <div className="columns is-mobile">
          <div className="box white-box column is-3">
            <NavbarIntake
              isSignedIn={isSignedIn}
              isLawyer={isLawyer}
              lawyerLawfirm={lawyerLawfirm}
            />
          </div>
          <div className="box light-green-box column">
            <div className="columns is-centered">
              <div className="column is-2"></div>
              <div className="column">
                <div className="title is-4 has-text-centered">
                  Client Details
                </div>
                <div className="subtitle is-6 has-text-centered">
                  Please answer the follow questions to the best of your
                  ability.
                </div>
                <div className="">
                  <form onSubmit={handleSubmit}>
                    {questions.length ? (
                      questions.map((question, index) => (
                        <SpecificQuestion
                          key={index}
                          id={index}
                          question={question}
                          handleInputChange={handleInputChange}
                          value={answers[index] || ""}
                          submissionStatus={submissionStatus}
                        />
                      ))
                    ) : (
                      <div>
                        <br></br>
                        <br></br>
                        <div className="box has-text-centered">
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="mr-4"
                          />
                          Loading questions...
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                      </div>
                    )}
                  </form>
                </div>
              </div>
              <div className="column is-2"></div>
            </div>

            <NavbarFooter
              handleBackClick={handleBackClick}
              handleNextClick={handleSubmit}
              isFormValid={isFormValid}
            />
          </div>
        </div>
        <FooterIntake />
      </div>
    </>
  );
}

export default ClientDetailsQuestions;
