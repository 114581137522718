import { useState, useEffect } from "react";
import "./PointsOfInterest.css";

const PointsOfInterest = (props) => {
  console.log("Points of Interest", props.PointsOfInterests);
  console.log(
    "OVERALL DECISION ABOUT CLIENT: ",
    props.PointsOfInterests?.overallDecision,
  );

  // Read values from scoreInfo
  // const listPoints = [props.PointsOfInterests?.legalSector.value[0], props.PointsOfInterests?.legalSector.value[2], props.PointsOfInterests?.Jurisdiction?.value[0] + ", " + props.PointsOfInterests?.Jurisdiction?.value[2], props.PointsOfInterests?.Reference.value, props.PointsOfInterests?.clientIdentity.value[0], props.PointsOfInterests?.intent.value, props.PointsOfInterests?.legalExperience.value]

  const jurisdictionValues = props.PointsOfInterests?.Jurisdiction.value;

  const listPoints = [
    props.PointsOfInterests?.legalSector.value[0],
    props.PointsOfInterests?.legalSector.value[2],
    jurisdictionValues && jurisdictionValues[0] && jurisdictionValues[2]
      ? `${jurisdictionValues[0]}, ${jurisdictionValues[2]}`
      : null,
    props.PointsOfInterests?.Reference.value,
    props.PointsOfInterests?.clientIdentity.value[0],
    props.PointsOfInterests?.intent.value,
    props.PointsOfInterests?.legalExperience.value,
  ];

  const listPointsFlag = [
    props.PointsOfInterests?.legalSector.decision,
    props.PointsOfInterests?.legalSector.decision,
    props.PointsOfInterests?.Jurisdiction.decision,
    props.PointsOfInterests?.Reference.decision,
    props.PointsOfInterests?.clientIdentity.decision,
    props.PointsOfInterests?.intent.decision,
    props.PointsOfInterests?.legalExperience.decision,
  ];

  // Add color depending on truth value (// Green code: #C4ECD6, Red code: #D83434, Grey code: #F5F5F5)
  const getColorStyle = (flag) => {
    if (flag) {
      return { backgroundColor: "#C4ECD6" }; // Green color
    } else if (flag === null) {
      return { backgroundColor: "#F5F5F5" }; // Grey color
    } else {
      return { backgroundColor: "#FFC2C2" }; // Red color
    }
  };

  // TODO: Let them be clickable to show the reasoning
  // const reasoning = []

  return (
    <div className="mb-6">
      <p className="subtitle mb-1">Points of Interest</p>
      <div className="">
        {listPoints.map((point, index) => {
          const colorStyle = getColorStyle(listPointsFlag[index]);

          return (
            point && (
              <div
                className="button is-rounded is-small mt-2 mr-2 pointsInterest"
                style={colorStyle}
              >
                {point}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default PointsOfInterest;
