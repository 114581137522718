import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faGraduationCap,
  faSchool,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";

const LinkedInProfile = (props) => {
  console.log("LinkedInProfile", props?.linkedInInfo);
  const [currentProfileIndex, setCurrentProfileIndex] = useState(0);
  const profiles = Object.values(props?.linkedInInfo || {});
  const numberProfilesFound = profiles.length;

  console.log("Number of LinkedIn profiles:", numberProfilesFound);

  const handleNextProfile = () => {
    setCurrentProfileIndex(
      (prevIndex) => (prevIndex + 1) % numberProfilesFound,
    );
  };

  const handlePreviousProfile = () => {
    setCurrentProfileIndex(
      (prevIndex) =>
        (prevIndex - 1 + numberProfilesFound) % numberProfilesFound,
    );
  };

  const handleCopyLink = (urlToCopy) => {
    navigator.clipboard.writeText(urlToCopy);
    // alert("URL copied to clipboard!");
  };

  return (
    <>
      {numberProfilesFound === 0 && (
        <>
          <p className="subtitle mb-3">LinkedIn Profile</p>
          <div className="box">
            <p className="">No LinkedIn Profile found.</p>
          </div>
        </>
      )}

      {numberProfilesFound > 0 && (
        <div>
          <div className="columns is-mobile mb-1">
            <div className="column is-8">
              <p className="subtitle">LinkedIn Profile</p>
            </div>
            <div className="column is-flex is-align-items-center">
              
            {numberProfilesFound > 1 && (
              <div className="is-flex is-align-items-center" style={{ justifyContent: 'flex-start' }}>
                <div className="is-flex-direction-column has-text-centered" style={{ fontSize: '1.2em', marginRight: '10px' }}>
                  <span>{currentProfileIndex + 1}</span>
                  <span>/</span>
                  <span>{numberProfilesFound}</span>
                </div>
                <button
                  onClick={handlePreviousProfile}
                  className="button is-small"
                  style={{ marginRight: '0px', flexShrink: 0 }}
                >
                  -
                </button>
                <button
                  onClick={handleNextProfile}
                  className="button is-small"
                  style={{ marginLeft: '0px', flexShrink: 0 }}
                >
                  +
                </button>
              </div>
            )}
            </div>
          </div>

          <div className="box">
            <ul>
              <li>
                <img
                  src="/linkedInLogo.png"
                  alt="in"
                  style={{ height: "20px", width: "auto", cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      profiles[currentProfileIndex].LinkedInURL,
                      "_blank",
                    )
                  }
                />
                <button
                  onClick={() =>
                    handleCopyLink(profiles[currentProfileIndex].LinkedInURL)
                  }
                  className="button is-small is-info is-rounded is-pulled-right"
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              </li>

              <li>
                <FontAwesomeIcon
                  icon={faBuilding}
                  className="ml-1 has-text-grey-light"
                />
                <span className="ml-3">
                  {profiles[currentProfileIndex].experiences?.[0]?.company ||
                    "N/A"}
                </span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faGraduationCap}
                  className="has-text-grey-light"
                />
                <span className="ml-2">
                  {profiles[currentProfileIndex].education?.[0]?.degree_name ||
                    "N/A"}
                </span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faSchool}
                  className="has-text-grey-light"
                />
                <span className="ml-2">
                  {profiles[currentProfileIndex].education?.[0]?.school ||
                    "N/A"}
                </span>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default LinkedInProfile;
