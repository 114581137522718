import React, { useState } from "react";
import axios from "axios";
//import logo from "./logo.svg"
//import "./App.css"
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendEmailVerification,
  onAuthStateChanged,
} from "firebase/auth";
import Popup from "reactjs-popup";
import { db } from "../config/firebase-config";
import {
  collection,
  where,
  query,
  getDocs,
  updateDoc,
  doc,
  addDoc,
} from "firebase/firestore";
import mixpanel from "mixpanel-browser";
import "./GoogleButton.css";

import useLocalStorage from "../components/useLocalStorage";

/**
 * SignUp Page
 */
const SignUpPage = (props) => {
  const navigate = useNavigate();
  const auth = getAuth();
  //state = {seen: false};
  const [showPopup, setShowPopup] = useState(false);
  const [loginType, setLoginType] = useState("");
  const [showPopupError, setShowPopupError] = useState("");
  const [firestoreId, setFirestoreId] = useLocalStorage("firestoreId", "");
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );

  const terms_and_conditions =
    "The information provided on this website does not, and is not intended to, constitute legal advice; instead, all information, content, and materials available on this site are for general informational purposes only. Readers of this website should contact their attorney to obtain advice with respect to any particular legal matter.  No reader, user, or browser of this site should act or refrain from acting on the basis of information on this site without first seeking legal advice from counsel in the relevant jurisdiction.  Only your individual attorney can provide assurances that the information contained herein – and your interpretation of it – is applicable or appropriate to your particular situation.  Use of, and access to, this website or any of the links or resources contained within the site do not create an attorney-client relationship between the reader, user, or browser and website authors, and their respective employers.";

  // UPDATE TERMS AND CONDITIONS

  const checkboxHandler = () => {
    const checkbox = document.querySelector("#agree");
    checkbox.checked = false;
    setShowPopup(!showPopup);
  };

  const checkboxHandlerAlert = () => {
    setShowPopupError("");
  };

  const checkboxHandlerGoogle = () => {
    // Show Popup
    const checkbox = document.querySelector("#agree");
    checkbox.checked = false;
    setShowPopup(!showPopup);
    setLoginType("google");
  };

  const agreedToTerms = () => {
    setShowPopup(!showPopup);
    const signUpButton = document.querySelector("#signUpButton");
    const checkbox = document.querySelector("#agree");
    signUpButton.disabled = false;
    checkbox.checked = true;

    if (loginType === "google") {
      loginWithGoogle();
    }
  };

  const sendIdTokenToBackend = async (idtoken) => {
    // Send the idtoken to backend to decode and create cookie
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlLogin = apiHostname
        ? `https://${apiHostname}/api/login`
        : "http://localhost:5001/api/login";

      const response = await fetch(apiUrlLogin, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idtoken: idtoken,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response");
        console.log("user_id: ", data.user_id);

        // Read from localStorage and if personalized === true, use that value as firestoreId
        const personalizedIntake = localStorage.getItem("personalizedIntake");
        if (personalizedIntake) {
          console.log("personalizedIntake?: ", personalizedIntake);
          setFirestoreId(personalizedIntake);
        }

        if (firestoreId || personalizedIntake) {
          console.log("firestore id: ", firestoreId);
          // Add userID in Firestore to show his info next time he logs in
          let sessionToUpdateRef = doc(
            db,
            "leads",
            firestoreId || personalizedIntake,
          );
          updateDoc(sessionToUpdateRef, {
            userID: data.user_id,
            lawfirm: lawyerLawfirm,
          })
            .then(() => {
              console.log("UserID updated in Firestore");
            })
            .catch((error) => {
              console.error("Error updating UserID in Firestore: ", error);
            });
        } else {
          console.log("No firestore id found. Creating one");
          addDoc(collection(db, "leads"), {
            userID: data.user_id,
            lawfirm: lawyerLawfirm,
          })
            .then((docRef) => {
              console.log("Lead UserId updated in Firestore: ", docRef.id);
              props.onFirestoreSessionCreated(docRef.id);
            })
            .catch((error) => {
              console.error("Error updating Lead UserID in Firestore: ", error);
            });
        }

        // Show pop up to let the user know his intake form has been saved. TODO: Improve UI of this message
        const errorMessage =
          "Your intake form has been saved. Log in to continue.";
        //window.alert(errorMessage);

        props.onLogin(data.user_id);
        navigate("/intake/disclaimer"); //TODO: check if this works (cannot go to intake because I don't have lawfirm)

        mixpanel.track("User Signed up", { "Firebase User Id": firestoreId });
      } else {
        console.log("Login went wrong");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const signUpWithEmail = (e) => {
    e.preventDefault();
    // I should update this with "StyledFirebaseAuth" so I don't have to read the password manually
    const emailText = String(e.target.email.value);
    const passwordText = String(e.target.password.value);
    e.target.email.value = "";
    e.target.password.value = "";

    createUserWithEmailAndPassword(auth, emailText, passwordText)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        const idtoken = user.getIdToken().then((idtoken) => {
          console.log("Firebase idtoken: ", idtoken);

          // Send the idtoken to backend to decode and create cookie
          sendIdTokenToBackend(idtoken);

          // Send verification email
          sendEmailVerification(user).then(() => {
            console.log("Email verification sent");
            //window.alert("Verify your account by clicking on the link sent to your email.");
            const errorMessage =
              "Verify your account by clicking on the link sent to your email.";
            setShowPopupError(errorMessage);

            // Wait for 3 seconds and then navigate to the login page
            // setTimeout(() => {
            //   navigate("/intake/login");
            // }, 5000);
          });
        });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("error: ", errorCode, errorMessage);
        //window.alert(errorMessage);
        setShowPopupError(errorMessage);
      });
  };

  const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const accesstoken = credential.accessToken;

        // The signed-in user info.
        const user = result.user;
        console.log("user log in: ", user.uid);

        // Send Firebase ID token to backend
        const idtoken = user.getIdToken().then((idtoken) => {
          console.log("idtoken: ", idtoken);

          // Send the idtoken to backend to decode and create cookie
          sendIdTokenToBackend(idtoken);
        });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("error: ", errorCode, errorMessage);
        //window.alert(errorMessage);
        setShowPopupError(errorMessage);
      });
  };

  return (
    <>
      <div className="box dark-green-box">
        <div className="columns is-mobile">
          <div
            className="box white-box column is-4"
            style={{
              background: `url('/SignUpImageCaddi.png')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>

          <div
            className="box light-green-box column"
            style={{ height: "100vh" }}
          >
            <div className="columns">
              <div className="column is-3"></div>
              <div className="column has-text-centered">
                <div className="subtitle is-2 has-text-centered">
                  <img
                    src="/CaddiLogo.png"
                    alt="Caddi Logo"
                    className="mr-2"
                    style={{ height: "30px", width: "auto" }}
                  />
                  Caddi
                </div>
                <br></br>
                <div className="title is-4">
                  Sign up to <span className="has-text-success">Caddi</span>
                </div>

                <button
                  type="button"
                  className="login-with-google-btn is-clickable"
                  onClick={checkboxHandlerGoogle}
                >
                  <div className="my-1">
                    <img
                      src="https://img.icons8.com/color/48/000000/google-logo.png"
                      alt="google icon"
                      className="mr-2"
                      style={{
                        height: "20px",
                        width: "auto",
                        verticalAlign: "middle",
                        marginBottom: "5px",
                      }}
                    />
                    <span className="">Sign up with Google</span>
                  </div>
                </button>

                <br></br>
                <br></br>
                <div className="divider mb-1"></div>

                <form onSubmit={signUpWithEmail}>
                  <br></br>
                  <p className="subtitle"> or sign up with email</p>
                  <label className="label has-text-left" htmlFor="username">
                    Email
                  </label>
                  <input name="email" className="input" />
                  <label
                    className="label has-text-left mt-2"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input name="password" className="input" type="password" />
                  <div>
                    <input
                      type="checkbox"
                      id="agree"
                      onChange={checkboxHandler}
                    />
                    <label htmlFor="agree">
                      {" "}
                      I agree to <b>terms and conditions</b>
                    </label>
                  </div>
                  <div>
                    <br></br>
                    <input
                      type="submit"
                      className="button is-success is-fullwidth"
                      id="signUpButton"
                      value="Sign up with email"
                      disabled="true"
                    />
                  </div>
                </form>
              </div>

              <div className="column is-3"></div>
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <div className="modal is-active">
          <div className="modal-background" onClick={checkboxHandler}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Terms and Conditions</p>
              <button
                className="delete"
                aria-label="close"
                onClick={checkboxHandler}
              ></button>
            </header>
            <section className="modal-card-body">
              {terms_and_conditions}
              To see our full Terms of Service, please click{" "}
              <a href="/TermsOfService"> here</a>.
            </section>
            <footer className="modal-card-foot">
              <button className="button is-info" onClick={agreedToTerms}>
                Agree
              </button>
            </footer>
          </div>
        </div>
      )}

      {showPopupError && (
        <div className="modal is-active">
          <div className="modal-background" onClick={checkboxHandler}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Alert</p>
              <button
                className="delete"
                aria-label="close"
                onClick={checkboxHandlerAlert}
              ></button>
            </header>
            <section className="modal-card-body">{showPopupError}</section>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUpPage;
