import React from "react";

const SpecificQuestion = ({
  question,
  id,
  handleInputChange,
  value,
  submissionStatus,
}) => {
  //console.log("Specific Question: ", question, "id: ", id, "value: ", value)
  const isReadOnly = submissionStatus === "Submitted";

  return (
    <div key={id}>
      <label className="label has-text-left" htmlFor={`question-${id}`}>
        {question}
      </label>
      <textarea
        name={`question-${id}`}
        className="textarea mb-2"
        id={`question-${id}`}
        onChange={(e) => handleInputChange(id, e.target.value)}
        value={value}
        readOnly={isReadOnly}
      />
    </div>
  );
};

export default SpecificQuestion;
