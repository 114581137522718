import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import mixpanel from "mixpanel-browser";

const FooterIntake = (props) => {
  const navigate = useNavigate();

  const handleCaddiClicked = () => {
    console.log("Caddi Button Clicked");
    navigate("/");
  };

  return (
    <div
      className="buttons"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <button className="button is-rounded has-text-weight-bold ml-1">
        <FontAwesomeIcon icon={faLock} className="mr-2" />
        Secured
      </button>
      <button
        className="button is-rounded has-text-weight-bold"
        id="nextButton"
        onClick={handleCaddiClicked}
      >
        Powered by <span className="has-text-success ml-1 "> Caddi</span>
      </button>
    </div>
  );
};

export default FooterIntake;
