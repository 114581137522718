import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import "./Dashboard.css";
import { useNavigate, useParams } from "react-router-dom";
import LateralMenu from "../components/LateralMenu";
import {
  collection,
  where,
  query,
  getDocs,
  getDoc,
  updateDoc,
  doc,
  addDoc,
} from "firebase/firestore";
import mixpanel from "mixpanel-browser";
import {
  getAuth,
  updatePassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import Navbar from "../components/Navbar";
import LateralMenuSettings from "../components/LateralMenuSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { db } from "../config/firebase-config";

import useLocalStorage from "../components/useLocalStorage";
import { getStorage, ref, uploadBytes } from "firebase/storage";

import LOEUploader from "../components/LOEUploader";
import ExpandableBox from "../components/ExapndableBox";

function LawyerSettings(props) {
  const [email, setEmail] = useState("");
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);

  const [logo, setLogo] = useState(null);
  const [heroStatement, setHeroStatement] = useState("");
  const [calendarLink, setCalendarLink] = useState("");
  const [disclaimer, setDisclaimer] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);
  const [firestoreId, setFirestoreId] = useLocalStorage("firestoreId", "");

  const [selectedSectionSettings, setSelectedSectionSettings] = useLocalStorage(
    "selectedSectionSettings",
    "intakeCustomization",
  );
  const [selectedButtonIntake, setSelectedButtonIntake] = useLocalStorage(
    "selectedButtonIntake",
    "Questions",
  );
  const [selectedButtonAccount, setSelectedButtonAccount] = useLocalStorage(
    "selectedButtonAccount",
    "legalSectors",
  );

  const [requiredQuestionsLawfirm, setrequiredQuestionsLawfirm] =
    useLocalStorage("requiredQuestionsLawfirm", []);
  const [finalQuestionsLawfirm, setFinalQuestionsLawfirm] = useLocalStorage("finalQuestionsLawfirm", []);

  console.log("Final Questions: ", finalQuestionsLawfirm);
  const [jurisdiction, setJurisdiction] = useLocalStorage("jurisdiction", []);
  const [listLegalSectors, setListLegalSectors] = useLocalStorage(
    "listLegalSectors",
    [],
  );
  const [hourlyRate, setHourlyRate] = useLocalStorage("hourlyRate", "");
  // Need to break down the flat fee into two fields: matter and value
  const [flatFees, setFlatFees] = useLocalStorage("flatFees", {});
  const [contingencyFees, setContingencyFees] = useLocalStorage(
    "contingencyFees",
    {},
  );
  // TODO: Evaluate adding reatiner to onboarding too
  // const [retainer, setRetainer] = useLocalStorage('retainer', '');
  const [idealClientDescription, setIdealClientDescription] = useLocalStorage(
    "idealClientDescription",
    "",
  );
  const [worstClientDescription, setWorstClientDescription] = useLocalStorage(
    "worstClientDescription",
    "",
  );

  const legalSectors = [
    "Real Estate Law",
    "Business Law",
    "Immigration Law",
    "Family Law",
    "Estate Planning",
    "Intellectual Property",
    "Employment and Labor",
    "Personal Injury",
    "Entertainment",
    "Lawsuits and Disputes",
    "Consumer Protection",
    "Civil Rights",
    "Criminal Defense",
  ]; /* Add more legal sectors as needed */
  const [selectedLegalSectors, setSelectedLegalSectors] = useState(
    JSON.parse(localStorage.getItem("listLegalSectors")),
  );

  const [dropdownActive, setDropdownActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStates, setSelectedStates] = useLocalStorage(
    "jurisdiction",
    [],
  );
  const states = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  const roles = ["Senior Lawyer", "Associate Lawyer", "Paralegal", "Legal Secretary"];

// Placeholder function for sending data to the backend
const sendTeamMembersToBackend = async (lawyerLawfirm, teamMembers) => {
  console.log("Sending updated team members to backend:", teamMembers);
  const apiHostname = process.env.REACT_APP_API_HOSTNAME;
  const apiUrlLetterOfEngagement = apiHostname
    ? `https://${apiHostname}/api/lawyerAddition`
    : "http://localhost:5001/api/lawyerAddition";

  try {
    const response = await fetch(apiUrlLetterOfEngagement, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        teamMembers: teamMembers,
        lawyerLawfirm: lawyerLawfirm,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Success updating team members:", data);
      setShowConfirmationPopup(true);
      setTimeout(() => setShowConfirmationPopup(false), 3000); // Hide popup after 3 seconds
    } else {
      throw new Error('Failed to update team members');
    }
  } catch (error) {
    console.error("Error sending data to backend:", error);
  }
};



  //TODO: Have this be the Lawyer's actual information
  const [teamMembersLawfirm, setTeamMembersLawfirm] = useState([
    { name: "Current Lawyer", email: "lawyer@example.com", role: "Senior Lawyer", legalSector: "Family Law" }, // Default lawyer's information
    { name: "", email: "", role: "", legalSector: "" }, // Blank row for new member
  ]);

  
  const [isFormValid, setIsFormValid] = useState(false);

  // const [docsList, setDocsList] = useLocalStorage('docsList', []);
  const [docsList, setDocsList] = useState(
    JSON.parse(localStorage.getItem("docsList")) || [],
  );
  const [docsListAddedUser, setDocsListAddedUser] = useLocalStorage(
    "docsListAddedUser",
    [],
  );
  const [userUploadedDocs, setUserUploadedDocs] = useLocalStorage(
    "userUploadedDocs",
    false,
  );
  const [docsType, setDocsType] = useLocalStorage("docsType", []);

  let lawyerLawfirmName = lawyerLawfirm.replace(/([A-Z])/g, " $1").trim();

  
  const [acceptEmailHeader, setAcceptEmailHeader] =
    useLocalStorage("acceptEmailHeader");
  const [acceptEmailBody, setAcceptEmailBody] =
    useLocalStorage("acceptEmailBody");

  const [referEmailHeader, setReferEmailHeader] =
    useLocalStorage("referEmailHeader");
  const [referEmailBody, setReferEmailBody] = useLocalStorage("referEmailBody");
  const [rejectEmailHeader, setRejectEmailHeader] =
    useLocalStorage("rejectEmailHeader");
  const [rejectEmailBody, setRejectEmailBody] =
    useLocalStorage("rejectEmailBody");


  const [acceptEmailCalendarHeader, setAcceptEmailCalendarHeader] =
    useLocalStorage("acceptEmailCalendarHeader");
  const [acceptEmailCalendarBody, setAcceptEmailCalendarBody] =
    useLocalStorage("acceptEmailCalendarBody");
    const [acceptEmailOnlyHeader, setAcceptEmailOnlyHeader] = useLocalStorage(
      "acceptEmailOnlyHeader",
    );
    const [acceptEmailOnlyBody, setAcceptEmailOnlyBody] = useLocalStorage(
      "acceptEmailOnlyBody",
    );

  //TODO: fix reading flatFees and contingencyFees from local storage

  const emailBodyRef = useRef(null);

  useEffect(() => {
    console.log("Loading Lawyer Settings");
    console.log("Selected Section: ", selectedSectionSettings);
    console.log("Selected Button: ", selectedButtonIntake);
    console.log("Required Questions: ", requiredQuestionsLawfirm);
    console.log("Legal Sectors: ", listLegalSectors);
    console.log("Hourly Rate: ", hourlyRate);
    console.log("Flat Fees: ", flatFees);
    console.log("Flat Fees keys: ", Object.keys(flatFees));
    console.log("Contingency Fees: ", contingencyFees);
    console.log("Contingency Fee data type: ");

    console.log("Ideal Client Description: ", idealClientDescription);
    console.log("Worst Client Description: ", worstClientDescription);
    console.log("Selected Legal Sectors: ", selectedLegalSectors);
    console.log("Accept Email Header: ", acceptEmailHeader);
    console.log("Accept Email Body: ", acceptEmailBody);
  }, []);

  const onChangeSection = (section) => {
    setSelectedSectionSettings(section);
  };

  const handleSectionClick = (section) => {
    setSelectedButtonIntake(section);
  };

  const handleSectionClickAccount = (section) => {
    setSelectedButtonAccount(section);
  };

  const handleRequiredQuestionChange = (index, field, value) => {
    const updatedQuestions = [...requiredQuestionsLawfirm];
    updatedQuestions[index] = value;
    setrequiredQuestionsLawfirm(updatedQuestions);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };



  const handleFinalQuestionChange = (index, field, value) => {
    const updatedQuestions = [...finalQuestionsLawfirm];
    updatedQuestions[index] = value;
    setFinalQuestionsLawfirm(updatedQuestions);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

 const addFinalQuestion = () => {
  setFinalQuestionsLawfirm([...finalQuestionsLawfirm, ""]);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const removeFinalQuestion = (index) => {
    const updatedQuestions = [...finalQuestionsLawfirm];
    updatedQuestions.splice(index, 1);
    setFinalQuestionsLawfirm(updatedQuestions);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const addRequiredQuestion = () => {
    setrequiredQuestionsLawfirm([...requiredQuestionsLawfirm, ""]);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const removeRequiredQuestion = (index) => {
    const updatedQuestions = [...requiredQuestionsLawfirm];
    updatedQuestions.splice(index, 1);
    setrequiredQuestionsLawfirm(updatedQuestions);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const handleHourlyRateChange = (e) => {
    setHourlyRate(e.target.value);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const handleFlatFeeChange = (index, field, value) => {
    const updatedFlatFees = [...flatFees];
    updatedFlatFees[index][field] = value;
    setFlatFees(updatedFlatFees);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const addFlatFee = () => {
    setFlatFees([...flatFees, { concept: "", price: "" }]);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const removeFlatFee = (index) => {
    const updatedFlatFees = [...flatFees];
    updatedFlatFees.splice(index, 1);
    setFlatFees(updatedFlatFees);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const handleContingencyFeeChange = (index, field, value) => {
    const updatedContingencyFees = [...contingencyFees];
    updatedContingencyFees[index][field] = value;
    setContingencyFees(updatedContingencyFees);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const addContingencyFee = () => {
    setContingencyFees([...contingencyFees, { concept: "", price: "" }]);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const removeContingencyFee = (index) => {
    const updatedContingencyFees = [...contingencyFees];
    updatedContingencyFees.splice(index, 1);
    setContingencyFees(updatedContingencyFees);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const handleFlagsChange = (e) => {
    if (e.target.name === "idealClient") {
      setIdealClientDescription(e.target.value);
    } else if (e.target.name === "worstClient") {
      setWorstClientDescription(e.target.value);
    }
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const handleTeamMemberChange = (index, field, value) => {
    const updatedTeamMembers = [...teamMembersLawfirm];
    updatedTeamMembers[index] = value;
    setTeamMembersLawfirm(updatedTeamMembers);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const addTeamMember = () => {
    setTeamMembersLawfirm([...teamMembersLawfirm, ""]);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const removeTeamMember = (index) => {
    const updatedTeamMembers = [...teamMembersLawfirm];
    updatedTeamMembers.splice(index, 1);
    setTeamMembersLawfirm(updatedTeamMembers);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const handleAcceptBodyChange = (e) => {
    setAcceptEmailBody(e.target.value);
    let bodyText = e.target.value;
    let bodyTextJson = JSON.stringify(bodyText);
    localStorage.setItem("acceptEmailBody", bodyTextJson);
    console.log("Accept Email Body: ", e.target.value);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const handleAcceptHeaderChange = (e) => {
    setAcceptEmailHeader(e.target.value);
    let headerText = e.target.value;
    let headerTextJson = JSON.stringify(headerText);
    localStorage.setItem("acceptEmailHeader", headerTextJson);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const handleAcceptCalendarBodyChange = (e) => {
    setAcceptEmailCalendarBody(e.target.value);
    let bodyText = e.target.value;
    let bodyTextJson = JSON.stringify(bodyText);
    localStorage.setItem("acceptEmailCalendarBody", bodyTextJson);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const handleAcceptCalendarHeaderChange = (e) => {
    setAcceptEmailCalendarHeader(e.target.value);
    let headerText = e.target.value;
    let headerTextJson = JSON.stringify(headerText);
    localStorage.setItem("acceptEmailCalendarHeader", headerTextJson);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const handleAcceptEmailOnlyBodyChange = (e) => {
    setAcceptEmailOnlyBody(e.target.value);
    let bodyText = e.target.value;
    let bodyTextJson = JSON.stringify(bodyText);
    localStorage.setItem("acceptEmailOnlyBody", bodyTextJson);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const handleAcceptEmailOnlyHeaderChange = (e) => {
    setAcceptEmailOnlyHeader(e.target.value);
    let headerText = e.target.value;
    let headerTextJson = JSON.stringify(headerText);
    localStorage.setItem("acceptEmailOnlyHeader", headerTextJson);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };


  const handleReferBodyChange = (e) => {
    setReferEmailBody(e.target.value);
    let bodyText = e.target.value;
    let bodyTextJson = JSON.stringify(bodyText);
    localStorage.setItem("referEmailBody", bodyTextJson);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const handleReferHeaderChange = (e) => {
    setReferEmailHeader(e.target.value);
    let headerText = e.target.value;
    let headerTextJson = JSON.stringify(headerText);
    localStorage.setItem("referEmailHeader", headerTextJson);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const handleRejectBodyChange = (e) => {
    setRejectEmailBody(e.target.value);
    let bodyText = e.target.value;
    let bodyTextJson = JSON.stringify(bodyText);
    localStorage.setItem("rejectEmailBody", bodyTextJson);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const handleRejectHeaderChange = (e) => {
    setRejectEmailHeader(e.target.value);
    let headerText = e.target.value;
    let headerTextJson = JSON.stringify(headerText);
    localStorage.setItem("rejectEmailHeader", headerTextJson);
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };
  const handleLegalSectorClick = (sector) => {
    // Toggle the selected state of the legal sector
    setSelectedLegalSectors((prevSelected) =>
      prevSelected.includes(sector)
        ? prevSelected.filter((selected) => selected !== sector)
        : [...prevSelected, sector],
    );
    // If there is any modification, addition or elimination, the form is valid
    setIsFormValid(true);
  };

  const handleStateChange = (state) => {
    if (selectedStates.includes(state)) {
      setSelectedStates(
        selectedStates.filter((selectedState) => selectedState !== state),
      );
    } else {
      setSelectedStates([...selectedStates, state]);
    }
    setSearchQuery(""); // Clear search query when selecting a state
    setDropdownActive(false); // Close the dropdown when a state is selected
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setDropdownActive(!!e.target.value); // Show dropdown only if there's a search query
  };

  const filteredStates = states.filter((state) =>
    state.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const handleChangePassword = () => {
    const auth = getAuth();

    signInWithEmailAndPassword(auth, email, currentPassword)
      .then((userCredential) => {
        const user = userCredential.user;

        // Change the password
        updatePassword(user, newPassword)
          .then(() => {
            setSuccessMessage("Password changed successfully!");

            // Clear the form after successful password change
            setCurrentPassword("");
            setNewPassword("");
            setConfirmNewPassword("");
          })
          .catch((error) => {
            setError(`Error changing password: ${error.message}`);
          });
      })
      .catch((error) => {
        setError(`Error verifying user in: ${error.message}`);
      });
  };

  // TODO: HandleSubmit has to update Firestore with the new information
  const handleSubmitQuestions = () => {
    // Save information from user
    const docRef = doc(db, "lawyers", firestoreId);
    updateDoc(docRef, {
      requiredQuestionsLawfirm: requiredQuestionsLawfirm,
      finalQuestionsLawfirm: finalQuestionsLawfirm,
    }).then(() => {
      console.log("Required and final questions updated with ID: ", firestoreId);
    });
  };

  const handleSubmitFees = () => {
    // Save information from user

    let flatFeesDict = {};
    flatFees.forEach((fee, index) => {
      // Check if fee.concept is not empty or undefined
      if (fee.concept && fee.concept.trim() !== "") {
        if (!fee.price.includes("$")) {
          flatFeesDict[`${fee.concept}`] = "$" + fee.price;
        } else {
          flatFeesDict[`${fee.concept}`] = fee.price;
        }
      }
    });

    let contingencyFeesDict = {};
    contingencyFees.forEach((fee, index) => {
      // Check if fee.concept is not empty or undefined
      if (fee.concept && fee.concept.trim() !== "") {
        if (!fee.price.includes("%")) {
          contingencyFeesDict[`${fee.concept}`] = fee.price + "%";
        } else {
          contingencyFeesDict[`${fee.concept}`] = fee.price;
        }
      }
    });

    const docRef = doc(db, "lawyers", firestoreId);
    updateDoc(docRef, {
      hourlyRate: hourlyRate,
      flatFees: flatFeesDict,
      contingencyFees: contingencyFeesDict,
    }).then(() => {
      console.log("Fees updated with ID: ", firestoreId);
    });
  };

  const handleSubmitFlags = () => {
    // Save information from user
    const docRef = doc(db, "lawyers", firestoreId);
    updateDoc(docRef, {
      idealClientDescription: idealClientDescription,
      worstClientDescription: worstClientDescription,
    }).then(() => {
      console.log("Flags updated with ID: ", firestoreId);
    });
  };

  const onFormIsValid = (isValid) => {
    setIsFormValid(isValid);
  };

  const onDocsUploadedByUser = (newDocsUploaded) => {
    let newDocsType = docsType;
    if (newDocsUploaded.length > 0) {
      newDocsType.push(newDocsUploaded);
    }
    setDocsType(newDocsType);
  };

  const onUserUploadedDocs = () => {
    //Set it to true
    setUserUploadedDocs(true);
  };

  const handleSubmitSectors = () => {
    // Save information from user
    const docRef = doc(db, "lawyers", firestoreId);
    updateDoc(docRef, {
      listLegalSectors: selectedLegalSectors,
    }).then(() => {
      console.log("Legal Sectors updated with ID: ", firestoreId);
    });
  };

  const handleSubmitLOE = () => {
    // Save information from user
      try { 
        setShowSaveConfirmation(true); // Show save confirmation
        setTimeout(() => setShowSaveConfirmation(false), 3000); // Hide after 3 seconds
      } catch (error) {
        console.error("Error updating document: ", error);
        // Optionally handle errors here, e.g., show a different notification
      };
  };
  
// Function to handle input change for editable rows
  const handleInputChange = (index, field, value) => {
    const updatedMembers = teamMembersLawfirm.map((member, i) => {
      if (i === index) {
        return { ...member, [field]: value };
      }
      return member;
    });
    setTeamMembersLawfirm(updatedMembers);
  };

  // Function to add a new blank row
  const addNewMember = () => {
    setTeamMembersLawfirm([
      ...teamMembersLawfirm,
      { name: "", email: "", role: "", legalSector: "" },
    ]);
  };

  // Function to remove a row by index
  const removeMember = (index) => {
    const updatedMembers = teamMembersLawfirm.filter((_, i) => i !== index);
    setTeamMembersLawfirm(updatedMembers);
  };

  // Placeholder function for confirming changes
  const confirmChanges = () => {
    console.log("Creating accounts for new lawyers...", teamMembersLawfirm);
    // Implement your logic to update the database here
  };

  // Handle file input for the logo
const handleLogoChange = (event) => {
  const file = event.target.files[0];
  const renamedFile = new File([file], 'logo');
  setLogo(renamedFile);
};

// Save personalization to Firebase
const savePersonalization = async () => {
 

  // Assume `firestoreId` is available in your component's scope
  const storageRef = ref(getStorage(), `lawfirms/${firestoreId}/personalization/logo`);
  const docRef = doc(db, `lawyers/${firestoreId}`);

  try {
    // Upload logo
    await uploadBytes(storageRef, logo);
    // Save statements
    await updateDoc(docRef, { heroStatement, disclaimer });

    setShowConfirmation(true);
    setTimeout(() => setShowConfirmation(false), 3000); // Hide banner after 3 seconds
  } catch (error) {
    console.error("Error saving personalization:", error);
    alert("Failed to save personalization.");
  }
};


const saveIntegrations = async () => {
 
  const docRef = doc(db, `lawyers/${firestoreId}`);

  try {
    // Save integration links
    await updateDoc(docRef, { calendarLink });

    setShowConfirmation(true);
    setTimeout(() => setShowConfirmation(false), 3000); // Hide banner after 3 seconds
  } catch (error) {
    console.error("Error saving personalization:", error);
    alert("Failed to save personalization.");
  }
};


//Calls the lawyer's previous links
useEffect(() => {
  const fetchLawyerData = async () => {
    const docRef = doc(db, `lawyers/${firestoreId}`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // Check if heroStatement and disclaimer exist and are not empty
      const data = docSnap.data();
      setCalendarLink(data.calendarLink || "");
    } else {
      // Document does not exist
      console.log("No such link availible!");
    }
  };


  

  fetchLawyerData();
}, [firestoreId]);
  

useEffect(() => {
  const fetchLawyerData = async () => {
    const docRef = doc(db, `lawyers/${firestoreId}`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // Check if heroStatement and disclaimer exist and are not empty
      const data = docSnap.data();
      setHeroStatement(data.heroStatement || "");
      setDisclaimer(data.disclaimer || "");
    } else {
      // Document does not exist
      console.log("No such document!");
    }
  };


  

  fetchLawyerData();
}, [firestoreId]);
  return (
    <div className="columns is-mobile">
      <LateralMenu />
      <div className="column">
        <Navbar
          isSignedIn={isSignedIn}
          isLawyer={isLawyer}
          lawyerLawfirm={lawyerLawfirm}
        />
        {showSaveConfirmation && (
  <div className="notification is-success">
    Your changes have been saved successfully!
  </div>
)}
        <div className="dashboardMargins has-background-light">
          <div className="columns is-mobile">
            <LateralMenuSettings onChangeSection={onChangeSection} />

            <div className="column tableSection">
              <div className="mx-6 mt-5">
                {/* TODO: use components to make it easier to review and modify */}

                {selectedSectionSettings === "intakeCustomization" && (
                  <>
                    <div>
                      <p className="title">Intake Customization</p>
                    </div>
                    <br></br>

                    <div className="">
                      {/* Buttons */}
                      <div className="buttons">
                        <div
                          className={`button is-rounded mx-2`}
                          style={{
                            border: "none",
                            backgroundColor:
                              selectedButtonIntake === "Questions"
                                ? "#D9F6D8"
                                : "",
                          }}
                          onClick={() => handleSectionClick("Questions")}
                        >
                          Questions
                        </div>
                        <div
                          className={`button is-rounded mx-2`}
                          style={{
                            border: "none",
                            backgroundColor:
                              selectedButtonIntake === "Fees" ? "#D9F6D8" : "",
                          }}
                          onClick={() => handleSectionClick("Fees")}
                        >
                          Fees
                        </div>
                        <div
                          className={`button is-rounded mx-2`}
                          style={{
                            border: "none",
                            backgroundColor:
                              selectedButtonIntake === "Flags" ? "#D9F6D8" : "",
                          }}
                          onClick={() => handleSectionClick("Flags")}
                        >
                          Flags
                        </div>
                      </div>

                      {selectedButtonIntake === "Questions" && (
                        <>
                          {/* Required questions */}
                          <div className="box">
                            <div className="columns">
                              <div className="column">
                                <label
                                  className="label has-text-left"
                                  htmlFor="name"
                                >
                                  Required questions
                                </label>
                              </div>
                            </div>

                            {requiredQuestionsLawfirm.map((question, index) => (
                              <div className="columns mb-1" key={index}>
                                <div className="column is-11">
                                  <input
                                    name={`question-${index}`}
                                    className="input"
                                    placeholder="Example: What is the tenant's military status?"
                                    value={question}
                                    onChange={(e) =>
                                      handleRequiredQuestionChange(
                                        index,
                                        "question",
                                        e.target.value,
                                      )
                                    }
                                  />
                                </div>
                                <div className="column">
                                  <button
                                    type="button"
                                    className="button is-small is-light is-round mt-1"
                                    onClick={() =>
                                      removeRequiredQuestion(index)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faTimes} />
                                  </button>
                                </div>
                              </div>
                            ))}
                            <button
                              type="button"
                              className="button is-success"
                              onClick={addRequiredQuestion}
                            >
                              Add More Questions
                            </button>

                            <br></br>
                            

                          </div>

                          <div className="box">

                          <div className="columns">
                              <div className="column">
                                <label
                                  className="label has-text-left"
                                  htmlFor="name"
                                >
                                  Final Questions
                                </label>
                              </div>
                            </div>

                            {finalQuestionsLawfirm.map((question, index) => (
                              <div className="columns mb-1" key={index}>
                                <div className="column is-11">
                                  <input
                                    name={`question-${index}`}
                                    className="input"
                                    placeholder="Do you have any other questions?"
                                    value={question}
                                    onChange={(e) =>
                                      handleFinalQuestionChange(
                                        index,
                                        "question",
                                        e.target.value,
                                      )
                                    }
                                  />
                                </div>
                                <div className="column">
                                  <button
                                    type="button"
                                    className="button is-small is-light is-round mt-1"
                                    onClick={() =>
                                      removeFinalQuestion(index)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faTimes} />
                                  </button>
                                </div>
                              </div>
                            ))} 
                            <button
                              type="button"
                              className="button is-success"
                              onClick={addFinalQuestion}
                            >
                              Add More Questions
                            </button>

                            <br></br>
                            


                          </div>

                          <button
                            className="button is-success is-pulled-right"
                            onClick={handleSubmitQuestions}
                            disabled={!isFormValid}
                          >
                            Confirm Changes
                          </button>
                        </>
                      )}

                      {selectedButtonIntake === "Fees" && (
                        <>
                          {/* Hourly Rate */}
                          <div className="my-5 box">
                            <label
                              className="label has-text-left"
                              htmlFor="name"
                            >
                              Hourly Rate ($)*
                            </label>
                            <input
                              name="HourlyRate"
                              className="input"
                              onChange={handleHourlyRateChange}
                              value={hourlyRate}
                            />
                          </div>

                          {/* Flat fees */}
                          <div className="mb-5 box">
                            <div className="columns">
                              <div className="column is-7">
                                <label
                                  className="label has-text-left"
                                  htmlFor="name"
                                >
                                  Flat Fee Concept
                                </label>
                              </div>
                              <div className="column is-5">
                                <label
                                  className="label has-text-left"
                                  htmlFor="name"
                                >
                                  Price ($)
                                </label>
                              </div>
                            </div>

                            {flatFees.map((fee, index) => (
                              <div className="columns mb-1" key={index}>
                                <div className="column is-7">
                                  <input
                                    name={`concept-${index}`}
                                    className="input"
                                    placeholder="Example: LLC Formation"
                                    value={fee.concept}
                                    onChange={(e) =>
                                      handleFlatFeeChange(
                                        index,
                                        "concept",
                                        e.target.value,
                                      )
                                    }
                                  />
                                </div>
                                <div className="column is-4">
                                  <input
                                    name={`price-${index}`}
                                    className="input"
                                    placeholder="2500"
                                    value={fee.price}
                                    onChange={(e) =>
                                      handleFlatFeeChange(
                                        index,
                                        "price",
                                        e.target.value,
                                      )
                                    }
                                  />
                                </div>
                                <div className="column">
                                  <button
                                    type="button"
                                    className="button is-small is-light is-round mt-1"
                                    onClick={() => removeFlatFee(index)}
                                  >
                                    <FontAwesomeIcon icon={faTimes} />
                                  </button>
                                </div>
                              </div>
                            ))}
                            <button
                              type="button"
                              className="button is-success"
                              onClick={addFlatFee}
                            >
                              Add Flat Fee
                            </button>
                          </div>

                          {/* Contingency fees */}
                          <div className="mb-5 box">
                            <div className="columns">
                              <div className="column is-7">
                                <label
                                  className="label has-text-left"
                                  htmlFor="name"
                                >
                                  Contingency Fee Concept
                                </label>
                              </div>
                              <div className="column is-5">
                                <label
                                  className="label has-text-left"
                                  htmlFor="name"
                                >
                                  Percentage (%)
                                </label>
                              </div>
                            </div>

                            {contingencyFees.map((fee, index) => (
                              <div
                                className="columns mb-1 is-mobile"
                                key={index}
                              >
                                <div className="column is-7">
                                  <input
                                    name={`concept-${index}`}
                                    className="input"
                                    placeholder="Example: Medical malpractice"
                                    value={fee.concept}
                                    onChange={(e) =>
                                      handleContingencyFeeChange(
                                        index,
                                        "concept",
                                        e.target.value,
                                      )
                                    }
                                  />
                                </div>
                                <div className="column is-4">
                                  <input
                                    name={`price-${index}`}
                                    className="input"
                                    placeholder="30"
                                    value={fee.price}
                                    onChange={(e) =>
                                      handleContingencyFeeChange(
                                        index,
                                        "price",
                                        e.target.value,
                                      )
                                    }
                                  />
                                </div>
                                <div className="column">
                                  <button
                                    type="button"
                                    className="button is-small is-light is-round mt-1"
                                    onClick={() => removeContingencyFee(index)}
                                  >
                                    <FontAwesomeIcon icon={faTimes} />
                                  </button>
                                </div>
                              </div>
                            ))}
                            <button
                              type="button"
                              className="button is-success"
                              onClick={addContingencyFee}
                            >
                              Add Contingency Fee
                            </button>
                          </div>

                          <button
                            className="button is-success is-pulled-right"
                            onClick={handleSubmitFees}
                            disabled={!isFormValid}
                          >
                            Confirm Changes
                          </button>
                        </>
                      )}

                      {selectedButtonIntake === "Flags" && (
                        <>
                          <div className="box">
                            {/* Ideal Client */}
                            <div className="mb-2 ">
                              <label
                                className="label has-text-left"
                                htmlFor="idealClient"
                              >
                                Describe your ideal client
                              </label>
                              <textarea
                                name="idealClient"
                                className="textarea"
                                onChange={handleFlagsChange}
                                placeholder="Example: I like working with experienced clients that have dealt with this type of issues in the past."
                                value={idealClientDescription}
                              />
                            </div>

                            {/* Worst Client */}
                            <div className="mb-2">
                              <label
                                className="label has-text-left"
                                htmlFor="idealClient"
                              >
                                Describe the type of client you don't like
                                working with
                              </label>
                              <textarea
                                name="idealClient"
                                className="textarea"
                                onChange={handleFlagsChange}
                                placeholder="Example: I don't like working with clients that don't have a clear understanding of their problem."
                                value={worstClientDescription}
                              />
                            </div>
                          </div>

                          <button
                            className="button is-success is-pulled-right"
                            onClick={handleSubmitFlags}
                            disabled={!isFormValid}
                          >
                            Confirm Changes
                          </button>
                        </>
                      )}
                    </div>
                  </>
                )}

                {selectedSectionSettings === "engagementLetter" && (
                  <>
                    <div>
                      <p className="title">Client Messaging</p>
                    </div>
                    <br></br>

                    <div className="">
                      {/* Buttons */}
                      <div className="buttons">
                        <div
                          className={`button is-rounded mx-2`}
                          style={{
                            border: "none",
                            backgroundColor:
                              selectedButtonIntake === "Accept"
                                ? "#D9F6D7"
                                : "",
                          }}
                          onClick={() => handleSectionClick("Accept")}
                        >
                          Accept
                        </div>
                        <div
                          className={`button is-rounded mx-2`}
                          style={{
                            border: "none",
                            backgroundColor:
                              selectedButtonIntake === "Refer" ? "#D9F6D8" : "",
                          }}
                          onClick={() => handleSectionClick("Refer")}
                        >
                          Refer
                        </div>
                        <div
                          className={`button is-rounded mx-2`}
                          style={{
                            border: "none",
                            backgroundColor:
                              selectedButtonIntake === "Reject"
                                ? "#D9F6D8"
                                : "",
                          }}
                          onClick={() => handleSectionClick("Reject")}
                        >
                          Reject
                        </div>
                      </div>

                      {selectedButtonIntake === "Accept" && (
                        <>
                        <ExpandableBox title="Accept with Engagment Letter">
                         <br></br>
                            {/* Subject */}
                            <div className="mb-2">
                              <label
                                className="label has-text-left"
                                htmlFor="acceptEmailHeader"
                              >
                                Modify the email header
                              </label>
                              <textarea
                                name="acceptEmailHeader"
                                className="textarea"
                                onChange={handleAcceptHeaderChange}
                                value={acceptEmailHeader}
                                rows={1} // Adjust the number of rows as needed
                                style={{ whiteSpace: "pre-wrap" }}
                              />
                            </div>

                            {/* Body */}
                            <div className="mb-2">
                              <label
                                className="label has-text-left"
                                htmlFor="acceptEmailBody"
                              >
                                Modify the email body
                              </label>
                              <textarea
                                ref={emailBodyRef}
                                name="acceptEmailBody"
                                className="textarea"
                                onChange={handleAcceptBodyChange}
                                value={acceptEmailBody}
                                style={{ minHeight: "300px" }}
                              />
                            </div>

                            {/* Attachments */}

                            <div className="mb-2">
                              <label
                                className="label has-text-left"
                                htmlFor="idealClient"
                              >
                                Include the letter of engagement you would like
                                to send in a .docx format
                              </label>
                              {/* Conditionally render the LOEUploader based on a state or user action */}
                              <LOEUploader
                                firestoreId={firestoreId}
                                onFormIsValid={onFormIsValid}
                                onDocsUploadedByUser={onDocsUploadedByUser}
                                onUserUploadedDocs={onUserUploadedDocs}
                              />
                            </div>
                 

                          </ExpandableBox>

                          <ExpandableBox title="Accept with Email">
                          <br></br>
                            {/* Subject */}
                            <div className="mb-2">
                              <label
                                className="label has-text-left"
                                htmlFor="acceptEmailOnlyHeader"
                              >
                                Modify the email header
                              </label>
                              <textarea
                                name="acceptEmailHeader"
                                className="textarea"
                                onChange={handleAcceptEmailOnlyHeaderChange}
                                value={acceptEmailOnlyHeader}
                                rows={1} // Adjust the number of rows as needed
                                style={{ whiteSpace: "pre-wrap" }}
                              />
                            </div>

                            {/* Body */}
                            <div className="mb-2">
                              <label
                                className="label has-text-left"
                                htmlFor="acceptEmailBody"
                              >
                                Modify the email body
                              </label>
                              <textarea
                                ref={emailBodyRef}
                                name="acceptEmailOnlyBody"
                                className="textarea"
                                onChange={handleAcceptEmailOnlyBodyChange}
                                value={acceptEmailOnlyBody}
                                style={{ minHeight: "300px" }}
                              />
                            </div>
                          </ExpandableBox>
                         <ExpandableBox title="Accept with a Calendar Link">
                         <br></br>
                            {/* Subject */}
                            <div className="mb-2">
                              <label
                                className="label has-text-left"
                                htmlFor="acceptEmaiCalendarHeader"
                              >
                                Modify the email header
                              </label>
                              <textarea
                                name="acceptEmailCalendarHeader"
                                className="textarea"
                                onChange={handleAcceptCalendarHeaderChange}
                                value={acceptEmailCalendarHeader}
                                rows={1} // Adjust the number of rows as needed
                                style={{ whiteSpace: "pre-wrap" }}
                              />
                            </div>

                            {/* Body */}
                            <div className="mb-2">
                              <label
                                className="label has-text-left"
                                htmlFor="acceptEmailBody"
                              >
                                Modify the email body
                              </label>
                              <textarea
                                ref={emailBodyRef}
                                name="acceptEmailCalendarBody"
                                className="textarea"
                                onChange={handleAcceptCalendarBodyChange}
                                value={acceptEmailCalendarBody}
                                style={{ minHeight: "300px" }}
                              />
                            </div>
                          

                          </ExpandableBox>
                          <button
                            className="button is-success is-pulled-right"
                            onClick={handleSubmitLOE}
                            disabled={!isFormValid}
                          >
                            Confirm Changes
                          </button>
                        </>
                      )}

                      {selectedButtonIntake === "Refer" && (
                        <>
                          <div className="box" style={{ minHeight: "500px" }}>
                            {/* Subject */}
                            <div className="mb-2">
                              <label
                                className="label has-text-left"
                                htmlFor="acceptEmailHeader"
                              >
                                Modify the email header
                              </label>
                              <textarea
                                name="referEmailHeader"
                                className="textarea"
                                onChange={handleReferHeaderChange}
                                value={referEmailHeader}
                                rows={1} // Adjust the number of rows as needed
                                style={{ whiteSpace: "pre-wrap" }}
                              />
                            </div>

                            {/* Body */}
                            <div className="mb-2">
                              <label
                                className="label has-text-left"
                                htmlFor="acceptEmailBody"
                              >
                                Modify the email body
                              </label>
                              <textarea
                                name="referEmailBody"
                                className="textarea"
                                onChange={handleReferBodyChange}
                                value={referEmailBody}
                                ref={emailBodyRef}
                                style={{ minHeight: "300px" }}
                              />
                            </div>
                          </div>

                          <button
                            className="button is-success is-pulled-right"
                            onClick={handleSubmitLOE}
                            disabled={!isFormValid}
                          >
                            Confirm Changes
                          </button>
                        </>
                      )}

                      {selectedButtonIntake === "Reject" && (
                        <>
                          <div className="box" style={{ minHeight: "500px" }}>
                            {/* Subject */}
                            <div className="mb-2">
                              <label
                                className="label has-text-left"
                                htmlFor="acceptEmailHeader"
                              >
                                Modify the email header
                              </label>
                              <textarea
                                name="acceptEmailHeader"
                                className="textarea"
                                onChange={handleRejectHeaderChange}
                                value={rejectEmailHeader}
                                rows={1} // Adjust the number of rows as needed
                                style={{ whiteSpace: "pre-wrap" }}
                              />
                            </div>

                            {/* Body */}
                            <div className="mb-2">
                              <label
                                className="label has-text-left"
                                htmlFor="acceptEmailBody"
                              >
                                Modify the email body
                              </label>
                              <textarea
                                name="acceptEmailBody"
                                className="textarea"
                                onChange={handleRejectBodyChange}
                                value={rejectEmailBody}
                                ref={emailBodyRef}
                                style={{ minHeight: "300px" }}
                              />
                            </div>
                          </div>

                          <button
                            className="button is-success is-pulled-right"
                            onClick={handleSubmitLOE}
                            disabled={!isFormValid}
                          >
                            Confirm Changes
                          </button>
                        </>
                      )}
                    </div>
                  </>
                )}


{showConfirmationPopup && (
        <div className="notification is-success">
          This person will be processed and added to the team promptly.
        </div>
      )}

{selectedSectionSettings === "team" && (
  <>
    <div>
      <p className="title">Team</p>
    </div>
    <div className="box">
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Legal Sector</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {teamMembersLawfirm.map((member, index) => (
            <tr key={index}>
              <td>
                {index === 0 ? (
                  <span>{member.name}</span>
                ) : (
                  <input
                    className="input"
                    type="text"
                    value={member.name}
                    onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                  />
                )}
              </td>
              <td>
                {index === 0 ? (
                  <span>{member.email}</span>
                ) : (
                  <input
                    className="input"
                    type="email"
                    value={member.email}
                    onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                  />
                )}
              </td>
              <td>
                {index === 0 ? (
                  <span>{member.role}</span>
                ) : (
                  <div className="select is-fullwidth">
                    <select
                      value={member.role}
                      onChange={(e) => handleInputChange(index, 'role', e.target.value)}
                    >
                      {roles.map((role) => (
                        <option key={role} value={role}>{role}</option>
                      ))}
                    </select>
                  </div>
                )}
              </td>
              <td>
                {index === 0 ? (
                  <span>{member.legalSector}</span>
                ) : (
                  <input
                    className="input"
                    type="text"
                    value={member.legalSector}
                    onChange={(e) => handleInputChange(index, 'legalSector', e.target.value)}
                  />
                )}
              </td>
              <td>
                {index !== 0 && (
                  <button className="delete" onClick={() => removeMember(index)}></button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="button is-success" onClick={addNewMember}>Add Member</button>
      <button className="button is-success is-pulled-right" onClick={() => sendTeamMembersToBackend(lawyerLawfirm, teamMembersLawfirm)} style={{marginLeft: '10px'}}>Confirm Changes</button>
   </div>
  </>
)}


{selectedSectionSettings === "clients" && (
  <>
    <div>
      <p className="title">Clients</p>
    </div>
    {/* Current Clients Box */}
    <div className="box">
      <p className="subtitle">Current Clients</p>
      <table className="table is-fullwidth is-striped">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Date</th>
            <th>Sector</th>
          </tr>
        </thead>
        <tbody>
          {/* Dynamically populated rows */}
          <tr>
            <td>John</td>
            <td>Doe</td>
            <td>2024-02-20</td>
            <td>Real Estate Law</td>
          </tr>
          {/* Additional rows */}
        </tbody>
      </table>
    </div>

    {/* Upload New Clients Box, adjusted for width */}
    <div className="box" style={{ maxWidth: '100%' }}>
      <p className="subtitle">Upload New Clients</p>
      {/* File Upload */}
      <div className="file has-name is-fullwidth is-boxed">
        <label className="file-label">
          <input className="file-input" type="file" accept=".docx,.txt" />
          <span className="file-cta">
            <span className="file-icon">
              <i className="fas fa-upload"></i>
            </span>
            <span className="file-label">
              Choose a file…
            </span>
          </span>
          <span className="file-name">
            {/* File name dynamically displayed here */}
          </span>
        </label>
      </div>
      {/* Manual Input Fields */}
      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field">
            <p className="control is-expanded">
              <input className="input" type="text" placeholder="First Name" />
            </p>
          </div>
          <div className="field">
            <p className="control is-expanded">
              <input className="input" type="text" placeholder="Last Name" />
            </p>
          </div>
          <div className="field">
            <p className="control is-expanded">
              <input className="input" type="date" />
            </p>
          </div>
          <div className="field">
            <div className="control is-expanded">
              <div className="select is-fullwidth">
                <select>
                  <option>Select sector</option>
                  {/* Populate options dynamically */}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className="button is-success is-fullwidth">Upload Client</button>
    </div>
  </>
)}




                {selectedSectionSettings === "account" && (
                  <>
                    <div>
                      <p className="title">Account Settings</p>
                    </div>
                    <br></br>

                    {/* Buttons */}
                    <div className="buttons">
                      <div
                        className={`button is-rounded mx-2`}
                        style={{
                          border: "none",
                          backgroundColor:
                            selectedButtonAccount === "legalSectors"
                              ? "#D9F6D8"
                              : "",
                        }}
                        onClick={() =>
                          handleSectionClickAccount("legalSectors")
                        }
                      >
                        Legal Sectors
                      </div>
                      <div
                        className={`button is-rounded mx-2`}
                        style={{
                          border: "none",
                          backgroundColor:
                            selectedButtonAccount === "Jurisdiction"
                              ? "#D9F6D8"
                              : "",
                        }}
                        onClick={() =>
                          handleSectionClickAccount("Jurisdiction")
                        }
                      >
                        Jurisdiction
                      </div>
                      <div
                        className={`button is-rounded mx-2`}
                        style={{
                          border: "none",
                          backgroundColor:
                            selectedButtonAccount === "Password"
                              ? "#D9F6D8"
                              : "",
                        }}
                        onClick={() => handleSectionClickAccount("Password")}
                      >
                        Change Password
                      </div>

                      <div
                        className={`button is-rounded mx-2`}
                        style={{
                          border: "none",
                          backgroundColor:
                            selectedButtonAccount === "Personalization"
                              ? "#D9F6D8"
                              : "",
                        }}
                        onClick={() => handleSectionClickAccount("Personalization")}
                      >
                        Personalization
                      </div>


                      <div
                        className={`button is-rounded mx-2`}
                        style={{
                          border: "none",
                          backgroundColor:
                            selectedButtonAccount === "Integrations"
                              ? "#D9F6D8"
                              : "",
                        }}
                        onClick={() => handleSectionClickAccount("Integrations")}
                      >
                        Integrations
                      </div>


                    </div>
                    



                    {selectedButtonAccount === "legalSectors" && (
                      <>
                        <div className="">
                          {/* Select legal sectors */}
                          <div className="mb-2 box">
                            <label className="label has-text-left">
                              Select your legal sectors*
                            </label>
                            <div className="columns is-multiline">
                              {legalSectors.map((sector) => (
                                <div key={sector} className="column is-half">
                                  <div
                                    className={`box button-box ${selectedLegalSectors.includes(sector) ? "selected" : ""}`}
                                    onClick={() =>
                                      handleLegalSectorClick(sector)
                                    }
                                  >
                                    <p className="has-text-centered">
                                      {sector}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>

                          <button
                            className="button is-success is-pulled-right"
                            onClick={handleSubmitSectors}
                            disabled={!isFormValid}
                          >
                            Confirm Changes
                          </button>
                          <br></br>
                        </div>
                      </>
                    )}

                    {selectedButtonAccount === "Jurisdiction" && (
                      <>
                        {/* State Selector with Search */}
                        <div className="mb-6 box">
                          <div className="field">
                            <label className="label has-text-left">
                              Select the jurisdictions you practice in*
                            </label>
                            <div
                              className={`dropdown ${dropdownActive ? "is-active" : ""}`}
                            >
                              <div className="dropdown-trigger">
                                <div className="control is-expanded">
                                  <input
                                    className="input"
                                    type="text"
                                    placeholder="Search states"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                  />
                                  {/* <FontAwesomeIcon icon={faSearch} /> */}
                                </div>
                              </div>

                              {/* Dropdown */}
                              {dropdownActive && (
                                <div
                                  className="dropdown-menu"
                                  id="dropdown-menu"
                                  role="menu"
                                >
                                  <div className="dropdown-content">
                                    {filteredStates.map((state) => (
                                      <a
                                        key={state}
                                        onClick={() => handleStateChange(state)}
                                        className={`dropdown-item ${
                                          selectedStates.includes(state)
                                            ? "is-active"
                                            : ""
                                        }`}
                                      >
                                        {state}
                                      </a>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          {/* Selected States as Boxes */}
                          <div className="columns is-multiline">
                            {selectedStates.map((state) => (
                              <div key={state} className="column is-2">
                                <div className="button-box has-text-centered">
                                  {state}
                                  <button
                                    className="delete is-small ml-2 mt-1"
                                    onClick={() => handleStateChange(state)}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>

                          <button
                            className="button is-success is-pulled-right"
                            onClick={handleSubmitSectors}
                            disabled={!isFormValid}
                          >
                            Confirm Changes
                          </button>
                          <br></br>
                        </div>
                      </>
                    )}

                    {selectedButtonAccount === "Password" && (
                      <>
                        <div className="">
                          {error && (
                            <div className="notification is-danger">
                              {error}
                            </div>
                          )}

                          {successMessage && (
                            <div className="notification is-success">
                              {successMessage}
                            </div>
                          )}

                          <div className="field">
                            <label className="label">Email</label>
                            <div className="control">
                              <input
                                className="input"
                                type="email"
                                placeholder="Enter email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="field">
                            <label className="label">Current Password</label>
                            <div className="control">
                              <input
                                className="input"
                                type="password"
                                placeholder="Enter current password"
                                value={currentPassword}
                                onChange={(e) =>
                                  setCurrentPassword(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="field">
                            <label className="label">New Password</label>
                            <div className="control">
                              <input
                                className="input"
                                type="password"
                                placeholder="Enter new password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="field">
                            <div className="control">
                              <button
                                className="button is-success is-pulled-right"
                                onClick={handleChangePassword}
                              >
                                Change Password
                              </button>
                            </div>
                          </div>
                          </div>
                          </>
                          )}
                          {/* Personalization Section */}
                          {selectedButtonAccount === "Personalization" && (
                          <div className="box">
                            <p className="subtitle">Personalize Your Account</p>
                            {/* Logo Upload */}
                            <div className="file has-name is-boxed">
                              <label className="file-label">
                              <input className="file-input" type="file" accept=".png, .jpeg, .jpg" onChange={handleLogoChange} />
                                <span className="file-cta">
                                  <span className="file-icon">
                                    <i className="fas fa-upload"></i>
                                  </span>
                                  <span className="file-label">
                                    Upload your logo making sure it has a transparent background and/or shows well on a white background.
                                  </span>
                                </span>
                                <span className="file-name">
                                  {/* Dynamic file name here */}
                                </span>
                              </label>
                            </div>
                            {/* Hero Statement */}
                            <div className="field">
                              <label className="label">Hero Statement</label>
                              <div className="control">
                                <textarea 
                                  className="textarea" 
                                  placeholder="Enter your hero statement here"
                                  value={heroStatement || ""} // Display heroStatement if it's not empty, otherwise display an empty string
                                  onChange={(e) => setHeroStatement(e.target.value)}
                                ></textarea>
                              </div>
                            </div>

                            {/* Disclaimer */}
                            <div className="field">
                              <label className="label">Disclaimer</label>
                              <div className="control">
                                <textarea 
                                  className="textarea" 
                                  placeholder="Enter your disclaimer here"
                                  value={disclaimer || ""} // Display disclaimer if it's not empty, otherwise display an empty string
                                  onChange={(e) => setDisclaimer(e.target.value)}
                                ></textarea>
                              </div>
                            </div>
                            {/* Additional personalization fields as needed */}
                            <button className="button is-success" onClick={savePersonalization}>Save Personalization</button>
                          </div>
                          )}

                          {showSuccessBanner && (
                          <div className="notification is-success">
                            Your personalizations have been saved.
                          </div>
                          )}

    {/* Integrations Section */}
    {selectedButtonAccount === "Integrations" && (
      <div className="box">
        <p className="subtitle">Integrations</p>
        {/* Calendar/Scheduler Integration */}
        <div className="field">
          <label className="label">Calendar Integration</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Enter your calendar link"
              value={calendarLink || ""}
              onChange={(e) => setCalendarLink(e.target.value)}
              pattern="^(https?|ftp)://[^\s/$.?#].[^\s]*$"
              title="Please enter a valid URL"
            />
          </div>
        </div>
  
        
        {/* Additional integration fields as needed */}
        <button className="button is-success" onClick={saveIntegrations}>Save Integrations</button>
      </div>
    )}
 
                  </>
                )}
              </div>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LawyerSettings;
