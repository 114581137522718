import React from "react";
//import '../pages/Dashboard.css';
import { Navigate } from "react-router-dom";
import "./ClientCard.css";

const ClientCard = ({
  handleButtonClick,
  handleMouseEnter,
  handleMouseLeave,
  handleDelete,
  info,
}) => {
  // Get the info from the props. If undefined, set to empty string
  let {
    name,
    company,
    legalSubSector,
    status,
    createdDate,
    caseSummary,
    firestoreId,
    sessionCode,
    score,
    overallDecision,
  } = info;

  const confirmDelete = () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to eliminate this inquiry?",
    );
    if (isConfirmed) {
      handleDelete(firestoreId);
    }
  };

  // Overall Decision
  if (overallDecision === undefined) {
    overallDecision = "-";
  } else if (overallDecision === false) {
    overallDecision = "Reject";
  } else if (overallDecision === true) {
    overallDecision = "Approve";
  }

  const textStyles = {
    color:
      overallDecision === "Reject"
        ? "#EB1429"
        : overallDecision === "Approve"
          ? "#4CAF50"
          : "black",
  };

  return (
    <div>
      <div className={`box card`}>
        <button
          className="delete"
          style={{ position: "absolute", top: "5px", right: "5px" }}
          onClick={confirmDelete}
        ></button>
        <div
          className={`button-box `}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleButtonClick(name, firestoreId)}
        >
          <div className="columns is-mobile">
            <div className="column">
              <p className="">{name}</p>
            </div>
            <div className="column">
              <p className="">{company}</p>
            </div>
            <div className="column">
              <p className="">{legalSubSector}</p>
            </div>
            <div className="column">
              <p className="">{status}</p>
            </div>
            <div className="column">
              <p className="">{createdDate}</p>
            </div>
            <div className="column">
              <p className="" style={textStyles}>
                {overallDecision}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientCard;
