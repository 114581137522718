import ShowPastIntake from "./ShowPastIntake";
import { useState, useEffect } from "react";

const ListPastIntakes = (props) => {
  const [clientSessions, setClientSessions] = useState([]);

  console.log("List Sessions", props.clientSessions);
  console.log("Firestore IDs list:", props.clientFirestoreIds);

  useEffect(() => {
    setClientSessions(props.clientSessions);
  }, [props.clientSessions]);

  return (
    <>
      <div className="has-text-centered mb-5">
        {clientSessions.length > 0 && (
          <h1 className="title is-4">Past Intakes</h1>
        )}
        {clientSessions.length === 0 && (
          <h1 className="title is-4">No Past Intakes</h1>
        )}
      </div>
      <div>
        {clientSessions.length > 0 &&
          clientSessions.map((sessionInfo, index) => {
            console.log("one of the sessionInfo", sessionInfo);
            return (
              <ShowPastIntake
                key={index}
                sessionInfo={sessionInfo}
                firestoreId={props.clientFirestoreIds[index]}
                onSessionSelected={props.onSessionSelected}
                onEliminateSession={props.onEliminateSession}
              />
            );
          })}
      </div>
    </>
  );
};

export default ListPastIntakes;
