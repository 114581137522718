// CaseDetails.js

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  getFirestore,
  getDoc,
} from "firebase/firestore";
import { db } from "../config/firebase-config";
import mixpanel from "mixpanel-browser";
import NavbarFooter from "../components/NavbarFooter";
import IntakeSideMenu from "../components/IntakeSideMenu";
import FooterIntake from "../components/FooterIntake";
import NavbarIntake from "../components/NavbarIntake";

import useLocalStorage from "../components/useLocalStorage";

function CaseDetails(props) {
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const [firestoreId, setFirestoreId] = useLocalStorage("firestoreId", "");
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);
  const [submissionStatus, setSubmissionStatus] = useLocalStorage(
    "submissionStatus",
    "Incomplete",
  );

  const [generalCaseAnswer, setGeneralCaseAnswer] = useLocalStorage(
    "generalCaseAnswer",
    "",
  );
  const [idealResult, setIdealResult] = useLocalStorage("idealResult", "");
  const [legalSector, setLegalSector] = useLocalStorage("legalSector", "");
  const [legalSubsector, setLegalSubsector] = useLocalStorage(
    "legalSubsector",
    "",
  );
  const [legalSubsubsector, setLegalSubsubsector] = useLocalStorage(
    "legalSubsubsector",
    "",
  );
  const [specificQuestions, setSpecificQuestions] = useLocalStorage(
    "specificQuestions",
    {},
  );
  const [DBQuestions, setDBQuestions] = useLocalStorage("DBQuestions", {});

  const isReadOnly = submissionStatus === "Submitted";

  useEffect(() => {
    console.log("Load Case Details");
    console.log("User FirestoreId: ", firestoreId);

    document.getElementById("issue").value = generalCaseAnswer || "";
    document.getElementById("intent").value = idealResult || "";
    if (generalCaseAnswer && idealResult) {
      setIsFormValid(true);
    }
  }, []);

  const getSpecificQuestions = async (
    answer,
    intent,
    legalSubsubsector,
    DBQuestions,
  ) => {
    // Generate sectorSpecificQuestions from backend
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlSelectedDBQuestions = apiHostname
        ? `https://${apiHostname}/api/selectedDBQuestion`
        : "http://localhost:5001/api/selectedDBQuestion";

      const response = await fetch(apiUrlSelectedDBQuestions, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userAnswer: answer,
          userIntent: intent,
          legalSubsubsector: legalSubsubsector,
          previousQuestions: [],
          previousAnswers: [],
          count: 0,
          DBQuestions: DBQuestions,
          clientDetailsQuestions: props.clientDetailsQuestions,
          clientDetailsAnswers: props.clientDetailsAnswers,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response:", data);
        console.log("List of specific questions: ", data.questions);
        // Store data.questions which is an array of strings into a list
        let questions_list = data.questions;

        // Update this info to the parent component
        // props.onLegalSpecificQuestions(questions_list)
        setSpecificQuestions(questions_list);

        // Save information from user
        const docRef = doc(db, "leads", firestoreId);
        updateDoc(docRef, {
          specificLegalQuestions: questions_list,
        }).then(() => {
          console.log(
            "Legal specific questions updated in Firebase with ID: ",
            firestoreId,
          );
        });
        return data;
      } else {
        console.log("Legal specific questions went wrong");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  // Break this function to only get legal sector, subsector and subsubsector
  const getLegalSector = async (answer, intent) => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlGetLegalSectors = apiHostname
        ? `https://${apiHostname}/api/getLegalSector`
        : "http://localhost:5001/api/getLegalSector";

      const response = await fetch(apiUrlGetLegalSectors, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userAnswer: answer,
          userIntent: intent,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response:", data);
        console.log("Legal sector: ", data.legalSector);
        console.log("Legal subsector: ", data.legalSubsector);
        console.log("Legal subsubsector: ", data.legalSubsubsector);
        console.log("DB questions: ", data.DBQuestions);

        // Save sector, subsector, subsubsector and DBQuestions to the local storage
        setLegalSector(data.legalSector);
        setLegalSubsector(data.legalSubsector);
        setLegalSubsubsector(data.legalSubsubsector);
        setDBQuestions(data.DBQuestions);

        // Generate Specific questions from backend
        getSpecificQuestions(
          answer,
          intent,
          data.legalSubsubsector,
          data.DBQuestions,
        );

        // Save information from user
        const docRef = doc(db, "leads", firestoreId);
        updateDoc(docRef, {
          legalSector: data.legalSector,
          legalSubsector: data.legalSubsector,
          legalSubsubsector: data.legalSubsubsector,
        }).then(() => {
          console.log(
            "Legal specific questions, sector and subsector updated in Firebase with ID: ",
            firestoreId,
          );
        });

        return data;
      } else {
        console.log("Legal specific questions went wrong");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const generateIntakeDocs = async (status) => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlClientSubmittedIntake = apiHostname
        ? `https://${apiHostname}/api/clientSubmittedIntake`
        : "http://localhost:5001/api/clientSubmittedIntake";

      const response = await fetch(apiUrlClientSubmittedIntake, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firestoreId: firestoreId,
          status: status,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response:");
      } else {
        console.log("Got bad response");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleBackClick = () => {
    navigate("/intake/generalinformation");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const issueText = String(document.getElementById("issue").value);
    const intentText = String(document.getElementById("intent").value);

    console.log("Issue text: ", issueText, "Intent text: ", intentText);

    // Send answer to backend to get legal sector and sector specific questions. Do this only if it hasn't been generated before
    console.log("about to call backend");
    console.log("specific questions before backend:", specificQuestions);

    // Update this so in case it is empty, it also works
    if (!legalSector) {
      console.log("Answer to send to backend for legal sector:", issueText);
      getLegalSector(issueText, intentText);
    }

    if (!isReadOnly) {
      // Save information from user
      const docRef = doc(db, "leads", firestoreId);
      updateDoc(docRef, {
        issue: issueText,
        intent: intentText,
      }).then(() => {
        console.log("Case details updated with ID: ", firestoreId);
      });

      let status = "started";
      generateIntakeDocs(status);

      mixpanel.track("User Submitted Case Details in Intake", {
        "Firebase User Id": firestoreId,
      });
    }

    navigate("/intake/clientdetails");
  };

  // Next button should be disable until all fields are filled out
  const handleInputChange = () => {
    const issueInput = document.getElementById("issue").value;
    const intentInput = document.getElementById("intent").value;

    setGeneralCaseAnswer(issueInput);
    setIdealResult(intentInput);

    // Update the form validity based on the input values
    setIsFormValid(issueInput && intentInput);
  };

  const handleCaddiClicked = () => {
    console.log("Caddi Button Clicked");
    navigate("/");
  };

  return (
    <>
      <div className="box dark-green-box">
        <div className="columns is-mobile">
          <div className="box white-box column is-3">
            <NavbarIntake
              isSignedIn={isSignedIn}
              isLawyer={isLawyer}
              lawyerLawfirm={lawyerLawfirm}
            />
          </div>
          <div className="box light-green-box column">
            <div className="columns is-centered">
              <div className="column is-2"></div>

              <div className="column">
                <div className="title is-4 has-text-centered">Case Details</div>
                <div className="subtitle is-6 has-text-centered">
                  Please answer the follow questions to the best of your
                  ability.
                </div>
                <div className="">
                  <form onSubmit={handleSubmit}>
                    <label className="label has-text-left" htmlFor="message">
                      Describe your issue
                    </label>
                    <textarea
                      name="message"
                      className="textarea mb-2"
                      id="issue"
                      onChange={handleInputChange}
                      readOnly={isReadOnly}
                    />
                    <label className="label has-text-left" htmlFor="message">
                      What is your desired outcome?
                    </label>
                    <textarea
                      name="intent"
                      className="textarea mb-2"
                      id="intent"
                      onChange={handleInputChange}
                      readOnly={isReadOnly}
                    />
                  </form>
                </div>
              </div>
              <div className="column is-2"></div>
            </div>

            <NavbarFooter
              handleBackClick={handleBackClick}
              handleNextClick={handleSubmit}
              isFormValid={isFormValid}
            />
          </div>
        </div>
        <FooterIntake />
      </div>
    </>
  );
}

export default CaseDetails;
