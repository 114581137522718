import React, { useEffect } from "react";

const LiveSearchModal = ({
  isOpen,
  onRequestClose,
  searchResult,
  searchQuery,
}) => {
  // let searchQueryTest = "What is the address?";
  // let searchResultTest = "1548 NW 62nd St, Seattle, WA 98107";

  return (
    <>
      {isOpen && (
        <div className="modal is-active is-clipped">
          <div
            className="modal-background"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.50)" }}
            onClick={onRequestClose}
          ></div>
          <div
            className="modal-content"
            style={{ width: "50%", maxHeight: "90%", margin: "auto" }}
          >
            <div className="box" style={{ height: "100%" }}>
              <p className="title is-4">Search Results for "{searchQuery}"</p>
              <div className="my-3"></div>
              <p className="subtitle">{searchResult}</p>
            </div>
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={onRequestClose}
          ></button>
        </div>
      )}
    </>
  );
};

export default LiveSearchModal;
