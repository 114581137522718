import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import LateralMenu from "../components/LateralMenu";
import ClientCard from "../components/ClientCard";
import ClientPreview from "../components/ClientPreview";
import { getAuth, signOut } from "firebase/auth";
import { db } from "../config/firebase-config";
import {
  collection,
  where,
  query,
  getDocs,
  updateDoc,
  doc,
  addDoc,
} from "firebase/firestore";
import mixpanel from "mixpanel-browser";
import NavbarFooter from "../components/NavbarFooter";
import InvitationBox from "../components/InvitationBox";
import Navbar from "../components/Navbar";
import FilterButton from "../components/FilterButton";
import ClientsHeader from "../components/ClientsHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faCopy,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";

import useLocalStorage from "../components/useLocalStorage";
import IntakeLinksModal from "../components/IntakeLinksModal";

function Dashboard(props) {
  const navigate = useNavigate();
  const [hoveredInquiry, setHoveredInquiry] = useState(null);

  const [toggleModal, setToggleModal] = useState(false);
  const [isIntakeModalOpen, setIsIntakeModalOpen] = useState(false);
  const closeIntakeModal = () => setIsIntakeModalOpen(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [eliminationStatus, setEliminationStatus] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [filterStatus, setFilterStatus] = useLocalStorage(
    "filterStatus",
    "All"
  );
  const [dropdownActive, setDropdownActive] = useState(false);
  const filterOptions = [
    "All",
    "Incomplete",
    "Submitted",
    "Accepted",
    "Declined",
    "Referred",
    "Waiting",
  ];
  const [dateFilterStatus, setDateFilterStatus] = useLocalStorage(
    "dateFilterStatus",
    "All"
  );
  const [dateDropdownActive, setDateDropdownActive] = useState(false);
  const dateFilterOptions = ["All", "Last day", "Last 7 days", "Last 30 days"];
  const [dropdownActiveLinks, setDropdownActiveLinks] = useState(false);

  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    ""
  );
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);

  const [lawyerSessions, setLawyerSessions] = useLocalStorage(
    "lawyerSessions",
    []
  );
  const [sessionFirestoreId, setSessionFirestoreId] = useLocalStorage(
    "lawyerSessionsFirestoreId",
    []
  );

  const [showClient, setShowClient] = useState(false);
  const [currentClientFirestoreId, setCurrentClientFirestoreId] =
    useState(null);

  let inquiries = [];
  let differentLegalSubSectors = [];

  // Load clients to dashboard from Firebase.
  useEffect(() => {
    const auth = getAuth();

    const q = query(
      collection(db, "leads"),
      where("lawfirm", "==", lawyerLawfirm)
    );
    let lawyerClientSessions = [];
    let firestoreIds = [];

    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        lawyerClientSessions.push(doc.data());
        firestoreIds.push(doc.id);
      });
      console.log("Lawyers' clients: ", lawyerClientSessions);

      setLawyerSessions(lawyerClientSessions);
      setSessionFirestoreId(firestoreIds);
    });
  }, [lawyerLawfirm, eliminationStatus]);

  const handleFilterChange = (status) => {
    setFilterStatus(status);
    setDropdownActive(false);
  };

  const toggleDropdown = () => {
    setDropdownActive(!dropdownActive);
  };

  const toggleDropdownLinks = () => {
    setDropdownActiveLinks(!dropdownActiveLinks);
  };

  const handleDateFilterChange = (status) => {
    setDateFilterStatus(status);
    setDateDropdownActive(false);
  };

  const toggleDateDropdown = () => {
    setDateDropdownActive(!dateDropdownActive);
  };

  const handleButtonClick = (clientName, clientFirestoreId) => {
    console.log("client clicked: ", clientName);
    navigate(`/clientInfo/${clientFirestoreId}`);
  };

  const handleUnlockCaseClick = (client) => {
    console.log("client clicked: ", client);
    if (client) {
      navigate(`/clientInfo/${client.firestoreId}`);
    }
  };

  const handleMouseEnter = (event) => {
    event.currentTarget.classList.add("hovered");
  };

  const handleMouseLeave = (event) => {
    event.currentTarget.classList.remove("hovered");
  };

  const handleShare = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleDelete = async (firestoreId) => {
    console.log("Delete inquiry: ");

    try {
      const inquiryRef = doc(db, "leads", firestoreId);
      await updateDoc(inquiryRef, {
        status: "eliminated",
      });

      setEliminationStatus(!eliminationStatus);
    } catch (error) {
      console.error("Error deleting inquiry:", error);
    }
  };

  lawyerSessions.forEach((client, index) => {
    if (client.status != "eliminated") {
      inquiries.push({
        name: client.name + " " + client.LastName,
        company: client.company,
        legalSubSector: client.legalSubsubsector,
        status: client.submissionStatus,
        createdDate: client.createdDate,
        caseSummary: client.issue,
        firestoreId: sessionFirestoreId[index],
        sessionCode: client.sessionCode,
        score: client.score,
        overallDecision: client.suitabilityScore?.overallDecision,
      });
    }
  });

  inquiries.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));

  let filteredInquiries = inquiries.filter((inquiry) => {
    if (filterStatus === "All") {
      return true;
    }
    return inquiry.status === filterStatus;
  });

  filteredInquiries = filteredInquiries.filter((inquiry) => {
    if (dateFilterStatus === "All") {
      return true;
    }

    const currentDate = new Date();
    const inquiryDate = new Date(inquiry.createdDate);
    if (dateFilterStatus === "Last day") {
      const oneDay = 1 * 24 * 60 * 60 * 1000;
      return currentDate - inquiryDate < oneDay;
    }
    if (dateFilterStatus === "Last 7 days") {
      const oneWeek = 7 * 24 * 60 * 60 * 1000;
      return currentDate - inquiryDate < oneWeek;
    }
    if (dateFilterStatus === "Last 30 days") {
      const oneMonth = 30 * 24 * 60 * 60 * 1000;
      return currentDate - inquiryDate < oneMonth;
    }

    return false;
  });

  const invitationLink = `caddi.app/intake/${lawyerLawfirm}`;
  const invitationLinkShort = `caddi.app/intake/${lawyerLawfirm}/short`;

  const copyToClipboard = (linkToCopy) => {
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        console.log("Invitation link copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  return (
    <div className="columns page is-mobile">
      <LateralMenu />
      <div className="column">
        <Navbar
          isSignedIn={isSignedIn}
          isLawyer={isLawyer}
          lawyerLawfirm={lawyerLawfirm}
        />
        <div className=" dashboardMargins has-background-light">
          <div className="columns is-mobile">
            <InvitationBox
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              lawyerLawfirm={lawyerLawfirm}
            />

            <div className="column tableSection">
              <div className="mx-5 mt-5">
                <div className="mb-3">
                  <div className="columns is-mobile">
                    <div className="column">
                      <p className="title">Inquiries</p>
                    </div>

                    <div className="column">
                      <div className="dropdown is-pulled-right">
                        <div className="dropdown-trigger">
                          <button
                            className="button is-success is-pulled-right ml-auto mt-1"
                            onClick={() => toggleDropdownLinks(true)}
                          >
                            Intake Links
                          </button>
                        </div>
                        <IntakeLinksModal
                          isOpen={dropdownActiveLinks}
                          toggleModal={toggleDropdownLinks}
                          invitationLinkShort={invitationLinkShort}
                          invitationLink={invitationLink}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <FilterButton
                    text={"Filter by Date"}
                    filterStatus={dateFilterStatus}
                    dropdownActive={dateDropdownActive}
                    filterOptions={dateFilterOptions}
                    toggleDropdown={toggleDateDropdown}
                    handleFilterChange={handleDateFilterChange}
                  />

                  <FilterButton
                    text={"Filter by Status"}
                    filterStatus={filterStatus}
                    dropdownActive={dropdownActive}
                    filterOptions={filterOptions}
                    toggleDropdown={toggleDropdown}
                    handleFilterChange={handleFilterChange}
                  />

                  <button
                    className="button is-success is-pulled-right ml-auto mt-1"
                    onClick={handleShare}
                  >
                    New Inquiry
                  </button>
                </div>

                <div className="box clientsTable">
                  <ClientsHeader />

                  <div className="clientCards">
                    {filteredInquiries.length ? (
                      filteredInquiries.map((inquiry, index) => (
                        <ClientCard
                          key={index}
                          handleButtonClick={handleButtonClick}
                          handleMouseEnter={handleMouseEnter}
                          handleMouseLeave={handleMouseLeave}
                          handleDelete={handleDelete}
                          info={inquiry}
                        />
                      ))
                    ) : (
                      <p className="my-3 ml-4">No leads</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;