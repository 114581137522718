import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faCog,
  faDashboard,
  faArrowLeft,
  faArrowRight,
  faUserGroup,
  faFile,
  faTasks,
  faUsers, // Added the faUsers icon for the Clients section
} from "@fortawesome/free-solid-svg-icons";
import "./LateralMenuSettings.css";
import useLocalStorage from "./useLocalStorage";

const LateralMenuSettings = (props) => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useLocalStorage("isCollapsed", false);

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleHover = (e) => {
    e.currentTarget.classList.add("has-text-success");
    // e.currentTarget.classList.remove('');
  };

  const handleLeave = (e) => {
    e.currentTarget.classList.remove("has-text-success");
    // e.currentTarget.classList.add('');
  };

  return (
    <div className={`column is-2`} style={{ width: "168px" }}>
      <div className={`menuSettings`}>
        <div>
          <ul className="menu-list">
            <li
              onClick={(e) => {
                e.preventDefault();
                props.onChangeSection("intakeCustomization");
              }}
            >
              <a
                className=""
                onMouseEnter={handleHover}
                onMouseLeave={handleLeave}
              >
                <span className="has-text-success mr-2">
                  <FontAwesomeIcon icon={faTasks} />
                </span>
                Intake
              </a>
            </li>
            <li
              onClick={(e) => {
                e.preventDefault();
                props.onChangeSection("engagementLetter");
              }}
            >
              <a
                className=""
                onMouseEnter={handleHover}
                onMouseLeave={handleLeave}
              >
                <span className="has-text-success mr-2">
                  <FontAwesomeIcon icon={faFile} />
                </span>
                Messaging
              </a>
            </li>

            <li
              onClick={(e) => {
                e.preventDefault();
                props.onChangeSection("team");
              }}
            >
              <a
                className=""
                onMouseEnter={handleHover}
                onMouseLeave={handleLeave}
              >
                <span className="has-text-success mr-2">
                  <FontAwesomeIcon icon={faUserGroup} />
                </span>
                Team
              </a>
            </li>


            {/* Clients section added here with the faUsers icon */}
            <li
              onClick={(e) => {
                e.preventDefault();
                props.onChangeSection("clients"); // Assuming 'clients' is the identifier for this section
              }}
            >
              <a
                className=""
                onMouseEnter={handleHover}
                onMouseLeave={handleLeave}
              >
                <span className="has-text-success mr-2">
                  <FontAwesomeIcon icon={faUsers} /> {/* Changed to faUsers for Clients */}
                </span>
              Clients
              </a>
            </li>

            <li
              onClick={(e) => {
                e.preventDefault();
                props.onChangeSection("account");
              }}
            >
              <a
                className=""
                onMouseEnter={handleHover}
                onMouseLeave={handleLeave}
              >
                <span className="has-text-success mr-2">
                  <FontAwesomeIcon icon={faCog} />
                </span>
                Account
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LateralMenuSettings;
