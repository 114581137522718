import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faSave,
  faQuestionCircle,
  faTimes,
  faBars,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import mixpanel from "mixpanel-browser";
import "./NavbarIntake.css";
import useLocalStorage from "./useLocalStorage";

const NavbarIntake = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const firstScreen = location.pathname === "/intake/disclaimer";
  const [sectionName, setSectionName] = useState("");
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [logoUrl, setLogoUrl] = useLocalStorage("logoURL", "");
  const [caddiText, setCaddiText] = useState(
    "Hey, I’m Caddi and I will custom tailor this intake form for you",
  );
  const [imagePath, setImagePath] = useState("/Lateral bar.png");

  //check the logo

  // console.log("Is Signed In status: ", props.isSignedIn)
  let logoutScreen = "/logout";
 
  // useEffect(() => {
  //   // Check localStorage for a logo URL and update state
  //   const storedLogoUrl = localStorage.getItem("logoURL");
  //   console.log("Logo URL: ", logoUrl);
  //   if (storedLogoUrl) {
  //     setLogoUrl(storedLogoUrl);
  //   }
  // }, []); // This effect runs only once on component mount
  // TODO: change the text of Caddi and the image based on the screen

  useEffect(() => {
   // setLogolUrl(useLocalStorage("logoURL", ""));
    let currentScreen = location.pathname;
    switch (currentScreen) {
      case "/intake/sessions":
        setCaddiText("Please choose to continue an intake or start a new one.");
        setImagePath("");
        break;
      case "/intake/disclaimer":
        setCaddiText(
          "Hey, I’m Caddi and I will custom tailor this intake form for you",
        );
        setImagePath("/LateralBar1.png");
        break;
      case "/intake/generalinformation":
        setCaddiText("Let's start with some basic contact information");
        setImagePath("/LateralBar1.png");
        break;
      case "/intake/casedetails":
        setCaddiText("Now tells us about your case");
        setImagePath("/LateralBar1.png");
        break;
      case "/intake/clientdetails":
        setCaddiText("Let's continue");
        setImagePath("/LateralBar2.png");
        break;
      case "/intake/legalSector":
        setCaddiText("Legal sector detected!");
        setImagePath("/LateralBar2.png");
        break;
      case "/intake/casedetailsspecific":
        setCaddiText("Plese provide more details about your case");
        setImagePath("/LateralBar3.png");
        break;
      case "/intake/documentsupload":
        setCaddiText("The more information, the better!");
        setImagePath("/LateralBar3.png");
        break;
      case "/intake/halfway":
        setCaddiText("Keep going, you're almost there!");
        setImagePath("/LateralBar3.png");
        break;
      case "/intake/followupquestions":
        setCaddiText("");
        setImagePath("/LateralBar3.png");
        break;
      case "/intake/costprediction":
        setCaddiText("Some cost estimates!");
        setImagePath("/LateralBar4.png");
        break;
      case "/intake/finalquestions":
        setCaddiText("Final questions!");
        setImagePath("/LateralBar4.png");
        break;
      case "/intake/reviewandsecurity":
        setCaddiText("Checking that everything is in order");
        setImagePath("/LateralBar4.png");
        break;
      case "/intake/submissionconfirmation":
        setCaddiText("You are all set!");
        setImagePath("/LateralBar5.png");
        break;
      default:
        setCaddiText(
          "Hey, I’m Caddi and I will custom tailor this intake form for you",
        );
        setImagePath("/LateralBar1.png");
    }
  }, [location.pathname]);

  const handleClickBurger = (e) => {
    // const dropDownMenu = Array.prototype.slice.call(document.querySelectorAll('.navbar-menu'), 0);
    // dropDownMenu.forEach( el => {
    //   el.classList.toggle('is-active');
    // });
    setMenuOpen(!isMenuOpen);
  };

  const handleSaveForLater = (e) => {
    e.preventDefault();

    // TODO: if user is signed in, no need to show sign up screen
    if (props.isSignedIn) {
      console.log("User has saved intake info");
      window.alert("Changes have been saved");
    } else {
      navigate("/intake/signUp");
    }
  };

  const handleHelp = (e) => {
    e.preventDefault();
    console.log("Help Button Clicked");
    // TODO: show modal with help
    setIsHelpModalOpen(true);
  };

  const closeHelpModal = () => {
    setIsHelpModalOpen(false);
  };

  const handleLogOut = (e) => {
    e.preventDefault();
    console.log("Log Out Button Clicked");
    navigate("/logout");
  };

  return (
    <>
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
      {/* Lawyer Logo */}
      {
  logoUrl ? (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100px" }}>
      <img src={logoUrl} alt="lawyer logo" style={{ maxWidth: "120px", maxHeight: "120px" }} />
    </div>
  ) : (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100px" }}>
      <span className="has-text-weight-bold is-size-3 has-text-success ml-2">
        Caddi
      </span>
    </div>
  )
}


        <div
          className="dropdown-trigger"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon
            icon={isMenuOpen ? faTimes : faBars}
            onClick={handleClickBurger}
            style={{ cursor: "pointer" }}
          />

          {isMenuOpen && (
            <div
              className="dropdown"
              style={{
                position: "absolute",
                top: "75%",
                right: 0,
                zIndex: 1000,
              }}
            >
              <div className="dropdown-content">
                <a className="dropdown-item" onClick={handleHelp}>
                  <span className="has-text-black">
                    <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                    Customer Support
                  </span>
                </a>

                {!props.isSignedIn && (
                  <a className="dropdown-item" onClick={handleSaveForLater}>
                    <span className="has-text-black">
                      <FontAwesomeIcon icon={faSave} className="mr-2" />
                      Save
                    </span>
                  </a>
                )}

                {props.isSignedIn && (
                  <a className="dropdown-item" onClick={handleLogOut}>
                    <span className="has-text-black">
                      <FontAwesomeIcon icon={faSignOut} className="mr-2" />
                      Logout
                    </span>
                  </a>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="navigation is-hidden-mobile">
        <div className="mx-5 imageNavbar">
          <fig className="image">
            {imagePath && <img src={imagePath} alt="Placeholder image" />}
          </fig>
        </div>

        <div className="has-text-centered mx-5 mt-5 caddiAssistant">
          <div className="ellipse">
            <fig className="image caddiMark">
              <img src={"/caddiLogoAI.png"} alt="Placeholder image" style={{ maxWidth: "200px", maxHeight: "200px" }}  />
            </fig>
          </div>
          <p className="">{caddiText}</p>
        </div>
      </div>

      {/* Help Modal */}
      <div className={`modal ${isHelpModalOpen ? "is-active" : ""}`}>
        <div className="modal-background" onClick={closeHelpModal}></div>
        <div className="modal-content">
          {/* Customize the modal content as needed */}
          <div className="box">
            <p>
              Contact our Help Center at: <strong>929-606-0056</strong>
            </p>
          </div>
        </div>
        <button
          className="modal-close"
          aria-label="close"
          onClick={closeHelpModal}
        ></button>
      </div>
    </>
  );
};

export default NavbarIntake;
