import React, { useState } from "react";
import { ref, uploadBytes } from "firebase/storage";
import { storage } from "../config/firebase-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";

const LOEUploader = (props) => {
  const [loading, setLoading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [tempFile, setTempFile] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
    setUploadSuccess(false);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setTempFile(file);
      await uploadFile(file);
    }
  };

  const uploadFile = async (file) => {
    if (!file) return;

    setLoading(true);
    try {
      const fileName = file.name;
      const storageRef = ref(
        storage,
        `lawfirms/${props.firestoreId}/LoE/${fileName}`,
      );
      await uploadBytes(storageRef, file);
      console.log("Uploaded a file!");

      setFileUploaded(true);
      setUploadedFileName(fileName);
      setUploadSuccess(true);
    } catch (error) {
      console.error("Error uploading file to Firebase Storage: ", error);
      setUploadSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  const handleFinishUpload = () => {
    setShowModal(false);
    setUploadSuccess(false);
    setFileUploaded(false);
    setUploadedFileName("");
    setTempFile(null);
  };

  const containerStyle = {
    position: "relative",
    textAlign: "center",
    padding: "20px",
  };

  const overlayButtonStyle = (offset) => ({
    position: "absolute",
    top: "110%",
    left: `${offset}%`,
    transform: "translateX(-50%)",
    zIndex: 10, // Ensure the button is above the image
  });

  const overlayTitleStyle = (offset) => ({
    position: "absolute",
    top: "0%", // Position the title above the image
    left: "50%",
    transform: "translateX(-50%)",
    fontSize: "1.5rem", // Adjust the font size as needed
    fontWeight: "bold", // Optional: if you want the title to be bold
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1rem 0",
        }}
      >
        <button className="button is-success" onClick={toggleModal}>
          {fileUploaded
            ? "Modify Letter of Engagement"
            : "Add Letter of Engagement"}
        </button>
      </div>

      {showModal && (
        <div className="modal is-active">
          <div className="modal-background" onClick={toggleModal}></div>
          <div
            className="modal-content"
            style={{
              width: "80vw",
              maxWidth: "none",
              overflow: "hidden",
              background: "#fff",
              borderRadius: "8px",
              padding: "20px",
            }}
          >
            {!uploadSuccess ? (
              <>
                <h2 style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                  Upload Custom Letter of Engagement
                </h2>
                <img
                  src="./LOE_Full_Image.png"
                  alt="Letter of Engagement Steps"
                  style={{
                    width: "100%",
                    height: "auto",
                    marginBottom: "20px",
                  }}
                />
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <button
                    className="button is-success"
                    style={{ marginRight: "10px" }}
                    onClick={toggleModal}
                  >
                    Cancel
                  </button>
                  <div
                    className="file has-name is-fullwidth is-success"
                    style={{ display: "inline-block" }}
                  >
                    <label className="file-label">
                      <input
                        className="file-input"
                        type="file"
                        name="letterOfEngagement"
                        onChange={handleFileChange}
                        accept=".docx"
                      />
                      <span className="file-cta">
                        <span className="file-icon">
                          <FontAwesomeIcon icon={faFileAlt} />
                        </span>
                        <span className="file-label">Choose a file…</span>
                      </span>
                      {tempFile && (
                        <span className="file-name">{tempFile.name}</span>
                      )}
                    </label>
                  </div>
                </div>
              </>
            ) : (
              <div style={{ textAlign: "center", padding: "20px" }}>
                <button
                  className="button is-success"
                  onClick={handleFinishUpload}
                >
                  Finish
                </button>
              </div>
            )}
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={toggleModal}
          ></button>
        </div>
      )}
    </>
  );
};

export default LOEUploader;
