/**
 * Represents a Lawyer Intake Simulation component.
 * @param {Object} props - The props for the component.
 * @returns {JSX.Element} The LawyerIntakeSimulation component.
 */

import React, { useState } from "react";
import "./Dashboard.css";
import { useNavigate, useParams } from "react-router-dom";
import LateralMenu from "../components/LateralMenu";
import mixpanel from "mixpanel-browser";
import Navbar from "../components/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import useLocalStorage from "../components/useLocalStorage";
import SpecificQuestion from "../components/SpecificQuestion";
import { db } from "../config/firebase-config";
import {
  collection,
  where,
  query,
  getDocs,
  updateDoc,
  doc,
  addDoc,
} from "firebase/firestore";
import { useEffect } from "react";
import SimInlinePastIntake from "../components/SimInlinePastIntake";
import SimListQuestion from "../components/simListQuestion";
//import OpenAI from "openai";
import { OpenAI } from "@langchain/openai";
import { RunnableSequence } from "@langchain/core/runnables";
import { CustomListOutputParser } from "@langchain/core/output_parsers";
import { CommaSeparatedListOutputParser } from "@langchain/core/output_parsers";
import { ListOutputParser } from "@langchain/core/output_parsers";
import { PromptTemplate } from "@langchain/core/prompts";
import SimSpecificQuestion from "../components/SimSpecificQuestion";
import CoPilotListQuestion from "../components/copilotlistQuestion";


//read in the openai key
const openAIApiKey = process.env.REACT_APP_OPENAI_API_KEY;
function LawyerIntakeSimulation(props) {
  const [showFinalReview, setShowFinalReview] = useState(false);
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);
  //const [firestoreId, setFirestoreId] = useLocalStorage("firestoreId", "");
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [isLoading, setIsLoading] = useState(false);
  const [pastIntakes, setPastIntakes] = useState([]);
  const [pastIntakeIds, setPastIntakeIds] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [count, setCount] = useState(0);
  // const [questions, setQuestions] = useLocalStorage("questions", []);
  // const [answers, setAnswers] = useLocalStorage("answers", []);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);

  const [questionsToShow, setQuestionsToShow] = useState([]);
  const [answersToShow, setAnswersToShow] = useState([]);
  const [generalCaseAnswer, setGeneralCaseAnswer] = useLocalStorage(
    "generalCaseAnswer",
    "",
  );
  const [idealResult, setIdealResult] = useLocalStorage("idealResult", "");
  const [legalSubsubsector, setLegalSubsubsector] = useLocalStorage(
    "legalSubsubsector",
    "",
  );
  const [DBQuestions, setDBQuestions] = useLocalStorage("DBQuestions", []);
  const [allAnswers, setAllAnswers] = useLocalStorage("allAnswers", []);
  const [simulationFirestoreId, setSimulationFirestoreId] = useLocalStorage(
    "simulationFirestoreId",
    "",
  );
  const navigate = useNavigate();
  const [legalSector, setLegalSector] = useLocalStorage("legalSector", "");
  const [legalSubsector, setLegalSubsector] = useLocalStorage(
    "legalSubsector",
    "",
  );
  const [numScreens, setNumScreens] = useState(4);
  const [numQuestionsPerScreen, setNumQuestionsPerScreen] = useState(3);
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [selectedSessionDetails, setSelectedSessionDetails] = useState(null);
  const [currentScreen, setCurrentScreen] = useState("listIntakes");
  const [isStartScreenVisible, setIsStartScreenVisible] = useState(true);
  const [firstName, setFirstName] = useState("First name");
  const [lastName, setLastName] = useState("Last name");
  //Intialize the simulation here and save it to the firestore. It follows the same structure as the intake form.
  const initializeSimulation = async () => {
    // Example Firestore document creation
    try {
      const newSimulationRef = await addDoc(collection(db, "leads"), {
        // Add your simulation initial data structure here
        lawfirm: lawyerLawfirm,
        created: new Date(),
        isSimulated: true,
        name : "User",
        LastName: "Simulated",
        company: "Example Company",
        createdDate: new Date().toLocaleDateString("en-US", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }),

      });

      console.log("New simulation created with ID: ", newSimulationRef.id);
      // Optionally set state related to the new simulation
      // TODO: save it as something else not to override lawyer
      setSimulationFirestoreId(newSimulationRef.id);
      //clear the questions and answers
      setQuestions([]);
      setAnswers([]);

      console.log("Firestore ID: ", simulationFirestoreId);
      // Proceed to the intake form within the simulation screen
      setIsStartScreenVisible(false); // Hide start screen and show the simulation form/questions
    } catch (error) {
      console.error("Error creating new simulation: ", error);
    }
  };


  const returnToDashboard = () => {
    navigate("/dashboard");
  };


  useEffect(() => {
    // Fetch past intakes similar to before
    const fetchPastIntakes = async () => {
      const lawyerLawfirmName = lawyerLawfirm; // Assuming you have this value
      const q = query(
        collection(db, "leads"),
        where("isSimulated", "==", true),
        where("eliminated", "==", false),
        //where("eliminated", "==", null)
        
        //where ("eliminated", "==", false),
      );
      const querySnapshot = await getDocs(q);
      const intakes = [];
      const intakeIds = [];
      querySnapshot.forEach((doc) => {
        intakes.push(doc.data());
        intakeIds.push(doc.id);
      });
      setPastIntakes(intakes);
      setPastIntakeIds(intakeIds);
    };

    fetchPastIntakes();
  }, [lawyerLawfirm]); // Add dependencies as necessary

  const onSessionSelected = (sessionInfo, firestoreId) => {
    setSelectedSessionDetails(sessionInfo); // Store selected session details
    setCurrentScreen("intakeDetails"); // Change to the screen where you show or edit the details
    // Optionally, log the selected session
    console.log(
      "Selected session details:",
      sessionInfo,
      "Firestore ID:",
      firestoreId,
    );
  };

  const onEliminateSession = async (simulationFirestoreId) => {
    // Ensure sessionId is correctly passed and is a valid Firestore document ID
    if (!simulationFirestoreId) {
      console.error("Invalid session ID provided for elimination.");
      return;
    }

    try {
      //Made simulations to avoid crossover with other collections
      const docRef = doc(db, "leads", simulationFirestoreId);
      await updateDoc(docRef, {
        eliminated: true, // This field marks the session as eliminated
      });
      console.log("Session eliminated with ID:", simulationFirestoreId);

      // Refresh UI: Filter out eliminated session
      const updatedPastIntakes = pastIntakes.filter(
        (intake) => intake.id !== simulationFirestoreId,
      );
      setPastIntakes(updatedPastIntakes);
      const updatedPastIntakeIds = pastIntakeIds.filter(
        (id) => id !== simulationFirestoreId,
      );
      setPastIntakeIds(updatedPastIntakeIds);
    } catch (error) {
      console.error("Error eliminating session: ", error);
    }
  };
  useEffect(() => {
    console.log("Load Case Details Specific Questions");
    console.log("User FirestoreId: ", simulationFirestoreId);
    console.log("Local Storage: ", localStorage);

    // Remember in this version, it just appends them, it is not divided by versions
    if (questions.length > 0) {
      let startIndex = (count - 1) * numQuestionsPerScreen;
      let endIndex = count * numQuestionsPerScreen;
      console.log("Questions array: ", questions);
      console.log("Questions to Show: ", questions.slice(startIndex, endIndex));

      setQuestionsToShow(questions.slice(startIndex, endIndex));

      if (answers) {
        if (answers.length > 0) {
          let newAnswersToShow = answers.slice(startIndex, endIndex);
          for (let i = 0; i < numQuestionsPerScreen; i++) {
            if (newAnswersToShow[i] === undefined) {
              newAnswersToShow[i] = "";
            }
          }
          console.log("Answers to Show: ", newAnswersToShow);
          setAnswersToShow(newAnswersToShow);

          // TODO: If atleast one answer is filled out, let user continue
          if (newAnswersToShow.some((answer) => answer !== "")) {
            setIsFormValid(true);
          } else {
            setIsFormValid(false);
          }
        }
      }
    }
  }, [count, questions]);


  const generateSimulationReport = async (intent, issue, legalSector, legalSubsector, legalSubsubsector,specificQuestions, specificAnswers) => {
    try {
    const apiHostname = process.env.REACT_APP_API_HOSTNAME;
    const apiUrlGetLegalSectors = apiHostname
      ? `https://${apiHostname}/api/generateSimReport`
      : "http://localhost:5001/api/generateSimReport";

    const response = await fetch(apiUrlGetLegalSectors, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userAnswer: issue,
        userIntent: intent,
        legalSector: legalSector,
        legalSubsector: legalSubsector,
        legalSubsubsector: legalSubsubsector,
        specificLegalQuestions: specificQuestions,
        specificLegalAnswers: specificAnswers,
        clientFirestoreId: simulationFirestoreId,
      }),
    });

    if (response.ok) {
      //TODO: Write console logs to see the response of the report generation
      const data = await response.json();
      

     //TODO: Save the report to the local storage and to the firebase bucket for the lawyer to see
      const docRef = doc(db, "leads", simulationFirestoreId);
      updateDoc(docRef, {
       
      }).then(() => {
        console.log(
          "Legal specific questions, sector and subsector updated in Firebase with ID: ",
          simulationFirestoreId,
        );
        console.log("Legal Subsector", legalSubsector);
      });

      return data;
    } else {
      console.log("Legal specific questions went wrong");
    }
  } catch (error) {
    console.log("error: ", error);
  }
};

  const getLegalSector = async (answer, intent) => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlGetLegalSectors = apiHostname
        ? `https://${apiHostname}/api/getLegalSector`
        : "http://localhost:5001/api/getLegalSector";

      const response = await fetch(apiUrlGetLegalSectors, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userAnswer: answer,
          userIntent: intent,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response:", data);
        console.log("Legal sector: ", data.legalSector);
        console.log("Legal subsector: ", data.legalSubsector);
        console.log("Legal subsubsector: ", data.legalSubsubsector);
        console.log("DB questions: ", data.DBQuestions);

        // Save sector, subsector, subsubsector and DBQuestions to the local storage
        setLegalSector(data.legalSector);
        setLegalSubsector(data.legalSubsector);
        setLegalSubsubsector(data.legalSubsubsector);
        setDBQuestions(data.DBQuestions);

        console.log("Legal Subsector", legalSubsector);
        // Generate Specific questions from backend
        getSpecificQuestions(
          answer,
          intent,
          data.legalSubsubsector,
          data.DBQuestions,
        );

        const docRef = doc(db, "leads", simulationFirestoreId);
        updateDoc(docRef, {
          legalSector: data.legalSector,
          legalSubsector: data.legalSubsector,
          legalSubsubsector: data.legalSubsubsector,
        }).then(() => {
          console.log(
            "Legal specific questions, sector and subsector updated in Firebase with ID: ",
            simulationFirestoreId,
          );
          console.log("Legal Subsector", legalSubsector);
        });

        return data;
      } else {
        console.log("Legal specific questions went wrong");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleClientDetailsInputChange = () => {
    const issueInput = document.getElementById("issue").value;
    const intentInput = document.getElementById("intent").value;
    const firstNameInput = document.getElementById("FirstName").value;
    const lastNameInput = document.getElementById("LastName").value;

    setGeneralCaseAnswer(issueInput);
    setIdealResult(intentInput);
    setFirstName(firstNameInput);
    setLastName(lastNameInput);

    // Update the form validity based on the input values
    setIsFormValid(issueInput && intentInput);
  };

  const handleInputChange = (id, value) => {
    // Update the state of answers to show
    let updatedAnswersToShow = [...answersToShow];
    updatedAnswersToShow[id] = value;
    setAnswersToShow(updatedAnswersToShow);

    // Update the state of answers
    let updatedAnswers = answers;
    updatedAnswers[id + (count - 1) * numQuestionsPerScreen] = value;
    setAnswers(updatedAnswers);
    console.log("Updated Answers: ", updatedAnswers);

    // Next button enabled with only one answer
    setIsFormValid(true);
  };

  // TODO: handleSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submit button pressed");

    //isloading boolean to show the loading spinner

    // TODO: Call to selectedDBQuestions
    if (count < numScreens - 1) {
      
      // When count == 0 , we are in the caseDetails equivalent screen. We need to save those values to the local storage, call getLegalSector and then getSpecificQuestions
      if (count == 0) {
        setIsLoading(true);
        // Call to get legalSubsubsector
        await getLegalSector(generalCaseAnswer, idealResult);
        // TODO: Show loading screen until it finishes
        console.log("Legal Subsector: ", legalSubsector);
        // Only when this finshes, continue
        setIsLoading(false);

      }

      let newCount = count + 1;
      setCount(newCount);
      let startIndex = newCount * numQuestionsPerScreen;
      let endIndex = (newCount + 1) * numQuestionsPerScreen;

      // If no questions in this range, generate them
      console.log("Questions length: ", questions.length);
      let comparisonQuestions = (newCount + 1) * numQuestionsPerScreen;
      console.log("Answers length: ", comparisonQuestions);
      setAnswersToShow([]);
      if (questions.length < (newCount + 1) * numQuestionsPerScreen) {
        setQuestionsToShow([]); // Make it load again
        setAnswersToShow([]); // Empty the textarea for the new answers
        setIsFormValid(false);

        // TODO: before calling getSpecificQuestions, make sure legalSubsubsector has been called before
        await getSpecificQuestions(
          generalCaseAnswer,
          idealResult,
          legalSubsubsector,
          questions,
          allAnswers,
          DBQuestions,
          simulationFirestoreId, // Modify this firestoreId as it is the lawyer's
        );

        //TODO: Show that questions are loading until they are loaded
      } else {
        // Set questions to show to the questions already generated
        setQuestionsToShow(questions.slice(startIndex, endIndex));
        if (answers.length >= (newCount + 1) * numQuestionsPerScreen) {
          setAnswersToShow(answers.slice(startIndex, endIndex)); // Show answers already generated
        } else {
          setAnswersToShow([]);
        }
      }
    } else {
      //TODO: Make sure all fields are filled out to allow the user to submit
      // TODO: Save the answers to the local storage
      // Send them to final screen
      setShowFinalReview(true);
      
      const docRef = doc(db, "leads", simulationFirestoreId);
      updateDoc(docRef, {
        issue: generalCaseAnswer,
        intent: idealResult,
        specificLegalQuestions: questions,
        specificLegalAnswers: answers,
        submissionStatus: "Submitted",
        name: firstName,
        LastName: lastName,
      }).then(() => {
        console.log(
          "Legal specific questions, sector and subsector updated in Firebase with ID: ",
          simulationFirestoreId,
        );
        console.log("Legal Subsector", legalSubsector);
      });
    }
  };
  // Start New Intake function
  const startNewIntake = () => {
    // Reset necessary state variables to their initialv   values
    setIsStartScreenVisible(true);
    setShowFinalReview(false);
    setCount(0);
    // Reset other states as needed...
  };

  const getSpecificQuestions = async (
    answer,
    intent,
    legalSubsubsector,
    previousQuestions,
    previousAnswers,
    DBQuestions,
    firestoreId,
  ) => {
    console.log(
      "Body for selectedDBQuestion: ",
      answer,
      intent,
      legalSubsubsector,
      previousQuestions,
      previousAnswers,
      DBQuestions,
      count,
      // clientDetailsQuestions,
      // clientDetailsAnswers,
      simulationFirestoreId,
      numScreens,
    );

    //TODO: Check the implementation of this API call vs an alternative call with langchain
    // const openai = new OpenAI();

    // async function main() {
    //   const completion = await openai.chat.completions.create({
    //     messages: [{ role: "system", content: "You are a helpful assistant." }],
    //     model: "gpt-3.5-turbo",
    //   });

    //   console.log(completion.choices[0]);
    // }

    // main();

    //Defines the parser
    const parser = new CustomListOutputParser({ separator: "\n" });

    //const parser = new CommaSeparatedListOutputParser();
    const chain = RunnableSequence.from([
      PromptTemplate.fromTemplate(`
            ###BACKGROUND###:
            
            You are an experienced lawyer specializing in {legal_subsubsector}. 
            Your task is to quickly onboard a new client by developing 3 specific intake questions. 
            These initial questions should be crafted to gather essential information efficiently, 
            leveraging your expertise to guide the onboarding process effectively. 
            
            
            ###Relevant Information###: 

            Consider the following details to inform your questions: 
            - **Client's Issue:** A description in their own words ({clientAnswer}). 
            - **Client's Intent:** What the client wishes to achieve ({clientIntent}).
            - **Client Details:** Insights from the client details section.

            ### Objective: 
            
            Generate 3 targeted questions to facilitate client onboarding. 
            Follow these guidelines: - Incorporate any specific instructions in {aiCustomization} and {focusCustomization}.
            - Focus on the legal area needed ({legal_subsubsector}), the client's situation ({clientAnswer}), 
                and their desired outcome ({clientIntent}). 
            - Avoid overlap with existing database questions ({lawyer_questions})
                and previously answered questions. 
            - Ensure questions are relevant, of high quality, and contribute effectively to 
                understanding the client's case. 
            - Assume the client has no legal expertise. 
            - Build on previous interactions for a cohesive question flow, focusing on unaddressed or evasively answered areas. 
            
            
            ### Guidelines: 
            
            - Aim for comprehensive questions that allow for attorney feedback before a second, more in-depth round of questioning. 
            - Do not number or categorize questions. - Use neutral, direct language suitable for lawyer-client interaction. 
            - Exclude questions on legal knowledge, laws, or statutes, and avoid implying representation. 
            - Focus on areas most relevant to the client's issue and intent. 
            
            
            ### Rules ###: 
            
            Strictly adhere to these rules to ensure effective client onboarding: 
            - DO NOT GIVE YOUR THOUGHTS OR CONTEXTUAL INFORMATION.
            - Questions must be instrumental for the onboarding process. 
            - Avoid redundancy with previously provided or answered information. 
            - Do not request document uploads or discuss legal strategies. 
            - Ensure questions are appropriate and of the highest quality for a legal context. 
            - Exclude redundant, niche, or legally complex inquiries irrelevant to the client's understanding or situation. 
            - Never ask questions with the same starting word or phrase, make these questions unique.
            - NEVER ask compound, two part or multi-part questions.
            - Always make the questions as clear and concise as possible and in a structured format.
            
            
            ### Output ###: 
            
            Give the top 3 questions as comma seperated values only no double quotes i.e. question 1? \n question2? \n question 3? and do not use numbers, 
            or any other indicators other than the newline characters \n for seperating and labelling the questions 
            
            You must always return the three results and make sure they are all serparated by newline characters \n.
            
            DO NOT PROVIDE ANY OTHER INFORMATION OTHER THAN THE QUESTIONS AND ESPECIALLY DO NOT PROVIDE ANY CONTEXTUAL INFORMATION OR YOUR THOUGHT PROCESS.`),

      //TODO: Specify the model for gpt-4
      //TODO: Fix the formatting of the questions to not have the double quotes.
      new OpenAI({
        temperature: 0,
        openAIApiKey: openAIApiKey,
        modelName: "gpt-4-0125-preview",
      }),
      parser,
    ]);

    console.log(parser.getFormatInstructions());

    const response = await chain.invoke({
      legal_subsubsector: legalSubsubsector,
      clientAnswer: answer,
      clientIntent: intent,
      aiCustomization: "customization",
      focusCustomization: "focus",
      lawyer_questions: previousQuestions,
      format_instructions: parser.getFormatInstructions(),
    });

    console.log("Response from LLM", response);

    let questions_list = response;
    let oldQuestions = questions;
    let newQuestions = oldQuestions.concat(questions_list);
    setQuestions(newQuestions);
    setAnswersToShow([]);

    // try {
    //   const apiHostname = process.env.REACT_APP_API_HOSTNAME;
    //   const apiUrlSelectedDBQuestions = apiHostname
    //     ? `https://${apiHostname}/api/selectedDBQuestion`
    //     : "http://localhost:5001/api/selectedDBQuestion";

    //   const response = await fetch(apiUrlSelectedDBQuestions, {
    //     method: "post",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       userAnswer: answer,
    //       userIntent: intent,
    //       legalSubsubsector: legalSubsubsector,
    //       previousQuestions: previousQuestions,
    //       previousAnswers: previousAnswers,
    //       count: count,
    //       DBQuestions: "", // Check how to handle this
    //       firestoreId: "", // Check how to handle this
    //       clientDetailsQuestions: "", // Check how to handle this
    //       clientDetailsAnswers: "", // Check how to handle this
    //     }),
    //   });

    //   if (response.ok) {
    //     const data = await response.json();
    //     console.log("Got back ok response:", data);
    //     console.log("List of new specific questions: ", data.questions);
    //     let questions_list = data.questions;

    //     save questions to localStorage
    //     let oldQuestions = questions;
    //     let newQuestions = oldQuestions.concat(questions_list);
    //     setQuestions(newQuestions);
    //     setAnswersToShow([]);

    //     TODO: Save information from in Firestore for us to see
    //   } else {
    //     console.log("Legal specific questions went wrong");
    //   }
    // } catch (error) {
    //   console.log("error: ", error);
    // }
  };
  //TODO: Make this all in the return statment to make it cleaner
  if (isStartScreenVisible) {
    return (
      <div className="columns is-mobile">
        <LateralMenu />
        <div className="column">
          <Navbar
            isSignedIn={isSignedIn}
            isLawyer={isLawyer}
            lawyerLawfirm={lawyerLawfirm}
          />
          <div className="dashboardMargins has-background-light">
            <div className="columns is-mobile">
              <div className="column tableSection">
                <div className="mx-6 mt-5">
                  <div className="box has-text-centered">
                    <h1 className="title is-4">Intake Simulator</h1>

                    <div className="has-text-left"> 
                      The legal case simulator enables lawyers to input client case details, 
                      including the issue at hand and desired outcomes. Subsequently, there will be 
                      three screens of tailored questions aimed at uncovering critical facts,
                      assessing legal considerations, and exploring potential strategies. 
                      Following completion of the question series, a 
                      final review screen summarizes key insights for analysis and action, 
                      facilitating more efficient and precise legal representation.
                    </div>
                    <br></br>
                    <button
                      onClick={initializeSimulation}
                      className="button is-success"
                    >
                      Start Simulation
                    </button>

                    <br></br>
                    <br></br>
                    {pastIntakes.map((sessionInfo, index) => (
                      <SimInlinePastIntake
                        key={index}
                        sessionInfo={sessionInfo}
                        firestoreId={pastIntakeIds[index]}
                        onSessionSelected={onSessionSelected}
                        onEliminateSession={onEliminateSession}
                      />
                    ))}
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (showFinalReview) {
    return (
      <div className="columns is-mobile">
        <LateralMenu />
        <div className="column">
          <Navbar
            isSignedIn={isSignedIn}
            isLawyer={isLawyer}
            lawyerLawfirm={lawyerLawfirm}
          />
          <div className="dashboardMargins has-background-light">
            <div className="columns is-mobile">
              <div className="column tableSection">
                <div className="mx-6 mt-5">
                  <div className="box">
                    {/* Render summary of all information collected */}
                    <div>
                      
                      <SimListQuestion
                        issueQuestion="Describe your issue"
                        issueAnswer={generalCaseAnswer || ""}
                        intentQuestion="What is your desired outcome?"
                        intentAnswer={idealResult || ""}
                        specificLegalQuestions={questions || []}
                        specificLegalAnswers={answers || []}
                      />
                      <br></br>
                      <button
                    onClick={startNewIntake}
                    className="button is-pulled-right is-success"
                  >
                    Start New Simulation
                  </button>

                  <button
                   onClick={(e) => {
                    e.preventDefault();
                    navigate("/dashboard");
                  }}
                    className="button is-pulled-left is-success"
                  > 

                  Return to Dashboard
                  </button>


                  {/* 
                  
                  Deprecated: Generate Simulation Report button
                  <button
                    onClick={generateSimulationReport}
                    className="button is-pulled-left is-success"
                  >
                   Generate Simulation Report 
                  </button> */}
                  
                      <br></br>
                    </div>
                  </div>
                  


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="columns is-mobile">
      <LateralMenu />
      <div className="column">
        <Navbar
          isSignedIn={isSignedIn}
          isLawyer={isLawyer}
          lawyerLawfirm={lawyerLawfirm}
        />
        <div className="dashboardMargins has-background-light">
          <div className="columns is-mobile">
            <div className="column tableSection">
              <div className="mx-6 mt-5">
                <div>
                  <p className="title">Simulate Intake AI Questions</p>
                </div>
                <br></br>
                <div className="box">
                  {count === 0 && !isLoading && (
                    <div className="column">
                      <label className="label has-text-center" htmlFor="name">
                        Initial Information about case
                      </label>

                      <div className="">
                        <form onSubmit={handleSubmit}>

                        <div className="columns">
                          {/* First Name */}
                          <div className="column">
                            <label className="label has-text-left">First Name</label>
                            <input
                              name="firstName" 
                              className="input mb-2"
                              placeholder="First Name" 
                              id="FirstName"
                              onChange={handleClientDetailsInputChange}
                              readOnly={isReadOnly}
                            />
                          </div>

                          {/* Last Name */}
                          <div className="column">
                            <label className="label has-text-left">Last Name</label>
                            <input
                              name="lastName" 
                              className="input mb-2"
                              placeholder="Last Name" 
                              id="LastName"
                              onChange={handleClientDetailsInputChange}
                              readOnly={isReadOnly}
                            />
                          </div>
                        </div>
                          <label
                            className="label has-text-left"
                            htmlFor="message"
                          >
                            Describe your issue
                          </label>
                          <textarea
                            name="message"
                            className="textarea mb-2"
                            id="issue"
                            onChange={handleClientDetailsInputChange}
                            readOnly={isReadOnly}
                          />
                          <label
                            className="label has-text-left"
                            htmlFor="message"
                          >
                            What is your desired outcome?
                          </label>
                          <textarea
                            name="intent"
                            className="textarea mb-2"
                            id="intent"
                            onChange={handleClientDetailsInputChange}
                            readOnly={isReadOnly}
                          />
                        </form>
                      </div>
                      <div className="has-text-right">
                        <button
                          className="button is-success has-text-white"
                          id="nextButton"
                          onClick={handleSubmit}
                          disabled={!isFormValid}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  )}

                  {isLoading && (
                    <div className="has-text-centered">
                      <div className="box has-text-centered">
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          size="2x"
                          className="mr-4"
                        />
                        Analyzing case and generating questions...
                      </div>
                    </div>
                  )}

                  <br></br>

                  {/* There should be a conditional for when count > 0 to show AI questions. Maybe a section to show legal Subsubsector */}
                  {count > 0 && (
                    <>
                      <form onSubmit={handleSubmit}>
                      <label className="label has-text-center" htmlFor="name">
                          Generated Questions for {legalSubsector}
                        </label>
                        {questionsToShow.length ? (
                          questionsToShow.map((question, index) => (
                            <SimSpecificQuestion
                              key={index}
                              id={index}
                              question={question}
                              handleInputChange={handleInputChange}
                              value={answersToShow[index] || ""}
                              submissionStatus={submissionStatus}
                            />
                          ))
                        ) : (
                          <div>
                            <br></br>
                            <br></br>

                            <div className="box has-text-centered">
                              <FontAwesomeIcon
                                icon={faSpinner}
                                spin
                                size="2x"
                                className="mr-4"
                              />
                              Analyzing case and generating questions...
                            </div>
                            <br></br>
                            <br></br>
                            <br></br>
                          </div>
                        )}
                      </form>
                      <div className="has-text-right">
                        <button
                          className="button is-success has-text-white"
                          id="nextButton"
                          onClick={handleSubmit}
                          disabled={!isFormValid}
                        >
                          Next
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LawyerIntakeSimulation;
