import React, { useEffect, useState } from "react";
import ListPastIntakes from "../components/ListPastIntakes";
import { getAuth } from "firebase/auth";
import { db } from "../config/firebase-config";
import {
  doc,
  addDoc,
  updateDoc,
  arrayUnion,
  collection,
  setDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import NavbarFooter from "../components/NavbarFooter";
import NavbarIntake from "../components/NavbarIntake";
import FooterIntake from "../components/FooterIntake";

import useLocalStorage from "../components/useLocalStorage";

const IntakeSessions = (props) => {
  const navigate = useNavigate();
  const [userId, setUserId] = useLocalStorage("userId", "");
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);

  const [disclaimer, setDisclaimer] = useLocalStorage("disclaimer", "");
  const [clientSessions, setClientSessions] = useState([]);
  const [clientFirestoreIds, setClientFirestoreIds] = useState([]);


  console.log("Lawfirm name TESTING:", lawyerLawfirm);

    const lawyerQuery = query(
      collection(db, "lawyers"),
      where("lawyerLawfirm", "==", lawyerLawfirm),
    );

    getDocs(lawyerQuery).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // Persist disclaimer and hero statement from the lawyer's document
        localStorage.setItem("disclaimer", doc.data().disclaimer || "");
        setDisclaimer(doc.data().disclaimer || "");
  
    // Only one iteration over the querySnapshot is required
    return;
  });
});


  // Load all the intake sessions for the user given its userID
  useEffect(() => {
    // const auth = getAuth();
    // const user = auth.currentUser;
    // console.log("User in IntakeSessions: ", user.uid);
    let clientInfo = [];
    let firestoreIds = [];

    const q = query(collection(db, "leads"), where("userID", "==", userId));
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // Filter sessions that have been eliminated by user
        if (doc.data().eliminatedByUser !== "eliminated") {
          clientInfo.push(doc.data());
          firestoreIds.push(doc.id);
        }
      });

      console.log("User previous intakes", clientInfo);
      console.log("Firestore ids", firestoreIds);
      setClientSessions(clientInfo);
      setClientFirestoreIds(firestoreIds);
    });
  }, []);

  const onEliminateSession = (firestoreIdToEliminate) => {
    console.log("On session eliminated (update UI)");
    // const auth = getAuth();
    // const user = auth.currentUser;
    // console.log("User in IntakeSessions: ", user.uid);
    let clientInfo = [];
    let firestoreIds = [];

    const q = query(collection(db, "leads"), where("userID", "==", userId));
    getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // Filter sessions that have been eliminated by user
        if (doc.data().eliminatedByUser !== "eliminated") {
          clientInfo.push(doc.data());
          firestoreIds.push(doc.id);
        }
      });

      console.log("User previous intakes", clientInfo);
      console.log("Firestore ids", firestoreIds);
      setClientSessions(clientInfo);
      setClientFirestoreIds(firestoreIds);
    });
  };

  const StartNewIntake = async () => {
    // Send user to user home page
    console.log("Start new intake");
    await props.onNewIntake();
    navigate("/intake/disclaimer");
  };

  return (
    <>
      <div className="box dark-green-box">
        <div className="columns is-mobile">
          <div className="box white-box column is-3">
            <NavbarIntake
              isSignedIn={isSignedIn}
              isLawyer={isLawyer}
              lawyerLawfirm={lawyerLawfirm}
              //disclaimer={disclaimer}
            />
          </div>
          <div className="box light-green-box column">
            <div className="columns is-centered">
              <div className="column is-2"></div>

              <div className="column">
                <ListPastIntakes
                  clientSessions={clientSessions}
                  clientFirestoreIds={clientFirestoreIds}
                  onSessionSelected={props.onSessionSelected}
                  onEliminateSession={onEliminateSession}
                />
                <div className="column has-text-centered">
                  <button
                    className="button is-success"
                    onClick={StartNewIntake}
                  >
                    {" "}
                    New Intake
                  </button>
                </div>
              </div>

              <div className="column is-2"></div>
            </div>
          </div>
        </div>
        <FooterIntake />
      </div>
    </>
  );
};

export default IntakeSessions;
