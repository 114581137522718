// Disclaimer.js
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import IntakeSideMenu from "../components/IntakeSideMenu";
import NavbarFooter from "../components/NavbarFooter";
import NavbarIntake from "../components/NavbarIntake";
import FooterIntake from "../components/FooterIntake";
import "./WelcomeIntake.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCheck } from "@fortawesome/free-solid-svg-icons";

import useLocalStorage from "../components/useLocalStorage";

function HalfwayScreen(props) {
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const [firestoreId, setFirestoreId] = useLocalStorage("firestoreId", "");
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);

  // TODO: modify this to make sure that it is updated when followUpQuestions are ready
  const [finalQuestionsReady, setFinalQuestionsReady] = useLocalStorage(
    "finalQuestionsReady",
    true,
  );

  // Enable continue button when followUpQuestionsReady is true (TODO: update this to finalQuestionsReady)
  // useEffect(() => {
  //     console.log("Follow Up Questions Ready: ", followUpQuestionsReady)
  //     if (followUpQuestionsReady) {
  //         setIsFormValid(true);
  //     }

  //     if (followUpQuestionsDocs) {
  //         if (followUpQuestionsDocs.length > 0) {
  //             setIsFormValid(true);
  //         }
  //     }

  // }, [followUpQuestionsReady, followUpQuestionsDocs]);

  useEffect(() => {
    console.log("Follow Up Questions Ready: ", finalQuestionsReady);
    if (finalQuestionsReady) {
      setIsFormValid(true);
    }
  }, [finalQuestionsReady]);

  const handleNextClick = () => {
    console.log("Next Button Clicked");
    navigate("/intake/costprediction"); // Later on, we could use the followupquestionsfromdocs
  };

  const handleBackClick = () => {
    navigate("/intake/documentsupload");
  };

  const handleCaddiClicked = () => {
    console.log("Caddi Button Clicked");
    navigate("/");
  };

  return (
    <>
      <div className="box dark-green-box">
        <div className="columns is-mobile">
          <div className="box white-box column is-3">
            <NavbarIntake
              isSignedIn={isSignedIn}
              isLawyer={isLawyer}
              lawyerLawfirm={lawyerLawfirm}
            />
          </div>
          <div className="box light-green-box column">
            <div className="columns is-centered">
              <div className="column is-2"></div>
              <div
                className="column has-text-centered"
                style={{ marginTop: "10%" }}
              >
                <figure>
                  <img src="/Halfway.png" alt="Placeholder image" width="128" />
                </figure>

                <div className="title is-4 my-5">Almost done!</div>

                {isFormValid ? (
                  <div className=" has-text-centered">
                    <FontAwesomeIcon
                      icon={faCheck}
                      size="2x"
                      style={{ color: "green" }}
                      className="mr-4"
                    />
                    Ready to go!
                  </div>
                ) : (
                  <div className=" has-text-centered">
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      size="2x"
                      className="mr-4"
                    />
                    Analyzing your documents... This might take a couple minutes
                  </div>
                )}
              </div>
              <div className="column is-2"></div>
            </div>

            <NavbarFooter
              handleBackClick={handleBackClick}
              handleNextClick={handleNextClick}
              isFormValid={isFormValid}
            />
          </div>
        </div>
        <FooterIntake />
      </div>
    </>
  );
}

export default HalfwayScreen;
