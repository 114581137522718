import React, { useEffect, useState } from "react";
import axios from "axios";
//import logo from "./logo.svg"
//import "./App.css"
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  getIdToken,
  getIdTokenResult,
  signOut,
} from "firebase/auth";
import { db } from "../config/firebase-config";
import {
  collection,
  where,
  query,
  getDocs,
  updateDoc,
  doc,
  addDoc,
} from "firebase/firestore";
import mixpanel from "mixpanel-browser";
import "./GoogleButton.css";
import LogRocket from "logrocket";

import useLocalStorage from "../components/useLocalStorage";

//initialize logrocket for session tracking
LogRocket.init("gd8syp/caddiapp");

LogRocket.getSessionURL(function (sessionURL) {
  mixpanel.track("LogRocket", { sessionURL: sessionURL });
});

const LoginPageLawyer = (props) => {
  const navigate = useNavigate();
  const [showPopupError, setShowPopupError] = useState("");
  const [verificationEmailMessage, setVerificationEmailMessage] = useState("");
  const [firestoreId, setFirestoreId] = useLocalStorage("firestoreId", "");
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);
  const [isOnboarded, setIsOnboarded] = useLocalStorage("isOnboarded", false);

  const [resendVerificationVisible, setResendVerificationVisible] =
    useState(false);
  const [resendEmailInput, setResendEmailInput] = useState("");
  const [idToken, setIdToken] = useState("");

  LogRocket.identify("lawyer", {
    lawyerLawfirm: lawyerLawfirm,
    firestoreId: firestoreId,
  });
  //const [sessionOwner, setSessionOwner] = useState([])
  let user_id = null;
  let error = "";

  //TODO: fix this so it lets lawyer go to home and back to dashboard
  useEffect(() => {
    console.log("useEffect in LoginPageLawyer");
    console.log("isSignedIn: ", isSignedIn, "isLawyer: ", isLawyer);
    if (isSignedIn && isLawyer) {
      console.log(
        "User is signed in and is a lawyer in useEffect in LoginPageLawyer",
      );
      navigate("/dashboard");
    }
  }, [navigate, isSignedIn, isLawyer]);

  const checkboxHandler = () => {
    setShowPopupError("");
  };

  const checkboxHandlerVerify = () => {
    setResendVerificationVisible(false);
  };

  // Send the idtoken to backend to decode and create cookie. Return the user_id
  const sendIdTokenToBackendForLawyer = async (idtoken) => {
    // Send the idtoken to backend to decode and create cookie
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlLoginLawyer = apiHostname
        ? `https://${apiHostname}/api/loginLawyer`
        : "http://localhost:5001/api/loginLawyer";

      const response = await fetch(apiUrlLoginLawyer, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idtoken: idtoken,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response");
        console.log("user_id from backend: ", data.user_id);

        // TODO: Check if user is a lawyer before changing the sign-in status and navigating to the user-home
        // If query returns empty, then sign out
        const q = query(
          collection(db, "lawyers"),
          where("userID", "==", data.user_id),
        );
        getDocs(q).then((querySnapshot) => {
          if (querySnapshot.empty) {
            console.log("User is NOT a lawyer. Should be log out");
            navigate("/");
            // Show error message
            window.alert(
              "You are not a lawyer. Please log in with your client account.",
            );

            // Sign out from Firebase
            const auth = getAuth();
            signOut(auth)
              .then(() => {
                console.log("User logged out");
                const errorMessage =
                  "You are not a lawyer. Please log in with your client account.";
                setShowPopupError(errorMessage);
              })
              .catch((error) => {
                console.log("Error logging out user");
              });

            // Sign out from backend and destroy session cookie
            const apiHostname = process.env.REACT_APP_API_HOSTNAME;
            const apiUrlLogout = apiHostname
              ? `https://${apiHostname}/api/logout`
              : "http://localhost:5001/api/logout";

            fetch(apiUrlLogout).then((res) => {
              if (res.ok) {
                props.onLogoutLawyer();
                navigate("/");
                console.log("Logging out lawyer");
              } else {
                console.log("Error logging out");
              }
            });
          } else {
            console.log("User is a lawyer (LoginPageLawyer");
            // Extract information from firestore
            querySnapshot.forEach((doc) => {
              console.log(doc.id, " => ", doc.data());
              console.log("doc.data().lawfirm: ", doc.data().lawyerLawfirm);
              console.log("doc.data().userID: ", doc.data().userID);
              setLawyerLawfirm(doc.data().lawyerLawfirm);
              setFirestoreId(doc.id);
              // console.log("isOnboarded in login lawyer: ", doc.data().isOnboarded);
              // setIsOnboarded(doc.data().isOnboarded);

              console.log("About to call props.onLoginLawyer");
              props.onLoginLawyer(data.user_id, doc.data().isOnboarded);
              navigate("/dashboard");

              // Break after first loop
              return;
            });
            // console.log("About to call props.onLoginLawyer")
            // props.onLoginLawyer(data.user_id);
            // navigate("/dashboard");
            mixpanel.identify(data.user_id);
            mixpanel.track("Lawyer Logged in", {
              "Firebase User Id": firestoreId,
            });
          }
        });

        // onLoginLawyer(data.user_id);
        //console.log("firestore id: ", firestoreId);

        // Navigate lawyer to dashboard
        // navigate("/dashboard");

        // mixpanel.identify(data.user_id);
        // mixpanel.track("Lawyer Logged in", {"Firebase User Id": firestoreId});
      } else {
        console.log("Login went wrong");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const loginWithEmail = (e) => {
    e.preventDefault();
    // I should update this with "StyledFirebaseAuth" so I don't have to read the password manually
    const auth = getAuth();
    const emailText = e.target.email.value;
    const passwordText = e.target.password.value;
    e.target.email.value = "";
    e.target.password.value = "";

    // Verify if the email is within the lawyers accounts in Firebase, in case it is, proceed with the login
    const q = query(collection(db, "lawyers"), where("email", "==", emailText));
    getDocs(q).then((querySnapshot) => {
      if (querySnapshot.empty) {
        console.log("Email is not in the lawyers accounts in Firebase");
        // Show error message
        const errorMessage = "This email does not belong to a lawyer account";
        setShowPopupError(errorMessage);
      } else {
        console.log(
          "Email is in the lawyers accounts in Firebase, Let's verify password",
        );

        signInWithEmailAndPassword(auth, emailText, passwordText)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            //console.log("user log in: ", user.uid);
            const idtoken = user.getIdToken().then((idtoken) => {
              //console.log("idtoken: ", idtoken);

              // Send the idtoken to backend to decode and create cookie
              // Check if user is logged in and email is verified (put this in the log in)
              onAuthStateChanged(auth, (user) => {
                if (user) {
                  if (user.emailVerified) {
                    console.log("User is logged in and email is verified.");

                    // Proceed with signed-in user logic
                    user_id = sendIdTokenToBackendForLawyer(idtoken);

                    // TODO: FIX Check if user has Lawyer role
                    // const auth = getAuth();
                    // const currentUser = auth.currentUser;
                    // getIdTokenResult(currentUser)
                    // .then((idTokenResult) => {
                    //   if (!!idTokenResult.claims.lawyer) {
                    //     console.log("User is a lawyer")
                    //   } else {
                    //     console.log("User is NOT a lawyer. Should be log out")
                    //     // Log user out

                    //   }
                    // })
                    // .catch((error) => {
                    //   console.log(error);
                    // });
                  } else {
                    console.log("User is logged in but email is not verified.");
                    // Handle scenario where email is not verified
                    //window.alert("Please verify your email and try again")
                    // const errorMessage = "Please verify your email and try again"
                    // setShowPopupError(errorMessage);
                    // Handle scenario where email is not verified
                    setResendVerificationVisible(true);
                    setResendEmailInput(user.email);
                    setIdToken(idtoken);
                  }
                } else {
                  console.log("User is not logged in.");
                  // Handle scenario where user is not logged in
                }
              });
            });
          })
          .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log("error: ", errorCode, errorMessage);
            //window.alert(errorMessage);
            setShowPopupError(errorMessage);
          });
      }
    });
  };

  const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const accesstoken = credential.accessToken;

        // The signed-in user info.
        const user = result.user;
        console.log("user log in: ", user.uid);

        // Send Firebase ID token to backend
        const idtoken = user.getIdToken().then((idtoken) => {
          console.log("idtoken: ", idtoken);

          // Send the idtoken to backend to decode and create cookie
          user_id = sendIdTokenToBackendForLawyer(idtoken);

          // TODO: FIX Check if user has Lawyer role
          // const auth = getAuth();
          // const currentUser = auth.currentUser;
          // getIdTokenResult(currentUser)
          // .then((idTokenResult) => {
          //   if (!!idTokenResult.claims.lawyer) {
          //     console.log("User is a lawyer")
          //   } else {
          //     console.log("User is NOT a lawyer")
          //   }
          // })
          // .catch((error) => {
          //   console.log(error);
          // });
        });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("error: ", errorCode, errorMessage);
        //window.alert(errorMessage);;
        setShowPopupError(errorMessage);
      });
  };

  const sendVerificationEmail = async () => {
    try {
      const apiKey = "AIzaSyAdxzD6KOOHtNiwXHeF_4S1v2CTbClF24Q"; // Replace with your Firebase Web API Key

      const apiUrl = `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${apiKey}`;

      const requestBody = {
        requestType: "VERIFY_EMAIL",
        idToken: idToken,
      };

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();

      if (response.ok) {
        console.log("Verification email sent:", responseData);
        setVerificationEmailMessage(
          "Verification email sent. Please check your email.",
        );
        setResendVerificationVisible(false);
      } else {
        console.error(
          "Error sending verification email:",
          responseData.error.message,
        );
        setVerificationEmailMessage(
          "Error sending verification email. Please try again.",
        );
      }
    } catch (error) {
      console.error("Error sending verification email:", error.message);
    }
  };

  return (
    <>
      <div className="box dark-green-box">
        <div className="columns is-mobile">
          <div
            className="box white-box column is-4"
            style={{
              background: `url('/SignUpImageCaddi.png')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>

          <div
            className="box light-green-box column"
            style={{ height: "100vh" }}
          >
            <div className="columns">
              <div className="column is-3"></div>
              <div className="column has-text-centered">
                <div className="subtitle is-2 has-text-centered">
                  <img
                    src="/CaddiLogo.png"
                    alt="Caddi Logo"
                    className="mr-2"
                    style={{ height: "30px", width: "auto" }}
                  />
                  Caddi
                </div>
                <br></br>
                <div className="title is-4">
                  Log in to <span className="has-text-success">Caddi</span>
                </div>

                <button
                  type="button"
                  className="login-with-google-btn is-clickable"
                  onClick={loginWithGoogle}
                >
                  <div className="my-1">
                    <img
                      src="https://img.icons8.com/color/48/000000/google-logo.png"
                      alt="google icon"
                      className="mr-2"
                      style={{
                        height: "20px",
                        width: "auto",
                        verticalAlign: "middle",
                        marginBottom: "5px",
                      }}
                    />
                    <span className="">Log in with Google</span>
                  </div>
                </button>

                <br></br>
                <br></br>
                <div className="divider mb-1"></div>

                <form onSubmit={loginWithEmail}>
                  <br></br>
                  <p className="subtitle"> or log in with email</p>
                  <label className="label has-text-left" htmlFor="username">
                    Email
                  </label>
                  <input name="email" className="input" />
                  <label
                    className="label has-text-left mt-2"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input name="password" className="input" type="password" />
                  <div>
                    <br></br>
                    <input
                      type="submit"
                      className="button is-success is-fullwidth"
                      value="Log in with email"
                    />
                  </div>
                </form>
              </div>

              <div className="column is-3"></div>
            </div>
          </div>
        </div>
      </div>

      {showPopupError && (
        <div className="modal is-active">
          <div className="modal-background" onClick={checkboxHandler}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Error Logging in</p>
              <button
                className="delete"
                aria-label="close"
                onClick={checkboxHandler}
              ></button>
            </header>
            <section className="modal-card-body">{showPopupError}</section>
          </div>
        </div>
      )}

      {resendVerificationVisible && (
        <div className="modal is-active">
          <div
            className="modal-background"
            onClick={checkboxHandlerVerify}
          ></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Resend Verification Email</p>
              <button
                className="delete"
                aria-label="close"
                onClick={checkboxHandlerVerify}
              ></button>
            </header>
            <section className="modal-card-body">
              <p className="has-text-left">
                Your email ({resendEmailInput}) is not verified.{" "}
              </p>
              <button
                className="button is-success mt-2"
                onClick={sendVerificationEmail}
              >
                Resend Verification Email
              </button>
              {/* Add a message to the user once they press resend:  verificationEmailMessage */}
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginPageLawyer;
