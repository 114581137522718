import React from "react";

const OverallValue = (props) => {
  const { overallValue } = props;
  let content;

  if (!overallValue) {
    content = "overallValue is not available.";
  } else {
    const { overallValueEstimate, overallValueEstimateReasoningConcise } = overallValue;
    content = (
      <>
        <p><strong>Overall Value Estimate:</strong> ${overallValueEstimate}</p>
        <p><strong>Reasoning:</strong> {overallValueEstimateReasoningConcise}</p>
      </>
    );
  }

  return (
    <>
      <div className="subtitle mb-3">Caddi Revenue Prediction</div>
      <div className="box">
        <div style={{ whiteSpace: "pre-wrap" }}>{content}</div>
      </div>
    </>
  );
};

export default OverallValue;
