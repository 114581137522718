/**
 * Represents a Lawyer Intake Simulation component.
 * @param {Object} props - The props for the component.
 * @returns {JSX.Element} The LawyerIntakeSimulation component.
 */

import React, { useState } from "react";
import "./Dashboard.css";
import { useNavigate, useParams } from "react-router-dom";
import LateralMenu from "../components/LateralMenu";
import mixpanel from "mixpanel-browser";
import Navbar from "../components/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import useLocalStorage from "../components/useLocalStorage";
import SpecificQuestion from "../components/SpecificQuestion";
import { db } from "../config/firebase-config";
import {
  collection,
  where,
  query,
  getDocs,
  getDoc,
  updateDoc,
  doc,
  addDoc,
} from "firebase/firestore";
import { useEffect } from "react";
import SimInlinePastIntake from "../components/SimInlinePastIntake";
import SimListQuestion from "../components/simListQuestion";
//import OpenAI from "openai";
import { OpenAI } from "@langchain/openai";
import { RunnableSequence } from "@langchain/core/runnables";
import { CustomListOutputParser } from "@langchain/core/output_parsers";
import { CommaSeparatedListOutputParser } from "@langchain/core/output_parsers";
import { ListOutputParser } from "@langchain/core/output_parsers";
import { PromptTemplate } from "@langchain/core/prompts";
import SimSpecificQuestion from "../components/SimSpecificQuestion";
import SpecificQuestionsCoPilot from "../components/SpecificQuestionsCoPilot";
import CoPilotListQuestion from "../components/copilotlistQuestion";



//read in the openai key
const openAIApiKey = process.env.REACT_APP_OPENAI_API_KEY;
function LegalCoPilot(props) {
  const [showFinalReview, setShowFinalReview] = useState(false);
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);
  //const [firestoreId, setFirestoreId] = useLocalStorage("firestoreId", "");
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [isLoading, setIsLoading] = useState(false);
  const [pastIntakes, setPastIntakes] = useState([]);
  const [pastIntakeIds, setPastIntakeIds] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [count, setCount] = useState(0);
  // const [questions, setQuestions] = useLocalStorage("questions", []);
  // const [answers, setAnswers] = useLocalStorage("answers", []);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);

  const [questionsToShow, setQuestionsToShow] = useState([]);
  const [answersToShow, setAnswersToShow] = useState([]);
  const [generalCaseAnswer, setGeneralCaseAnswer] = useLocalStorage(
    "generalCaseAnswer",
    "",
  );
  const [idealResult, setIdealResult] = useLocalStorage("idealResult", "");
  const [legalSubsubsector, setLegalSubsubsector] = useLocalStorage(
    "legalSubsubsector",
    "",
  );
  const [DBQuestions, setDBQuestions] = useLocalStorage("DBQuestions", []);
  const [allAnswers, setAllAnswers] = useLocalStorage("allAnswers", []);
  const [simulationFirestoreId, setSimulationFirestoreId] = useLocalStorage(
    "simulationFirestoreId",
    "",
  );
  const navigate = useNavigate();
  const [legalSector, setLegalSector] = useLocalStorage("legalSector", "");
  const [legalSubsector, setLegalSubsector] = useLocalStorage(
    "legalSubsector",
    "",
  );
  const [numScreens, setNumScreens] = useState(4);
  const [numQuestionsPerScreen, setNumQuestionsPerScreen] = useState(3);
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [selectedSessionDetails, setSelectedSessionDetails] = useState(null);
  const [currentScreen, setCurrentScreen] = useState("listIntakes");
  const [isStartScreenVisible, setIsStartScreenVisible] = useState(true);
  const [firstName, setFirstName] = useState("First name");
  const [lastName, setLastName] = useState("Last name");
  const [currentPage, setCurrentPage] = useState('startScreen');
  const [company, setCompany] = useState("Company");
  const [email, setEmail] = useState("Email");
  const [phone, setPhone] = useState("Phone");
  const [address, setAddress] = useState("Address");
  const [zipCode, setZipCode] = useState("Zip Code");
  const [requiredQuestions, setRequiredQuestions] = useState([]);
  const [requiredQuestionsAnswers, setRequiredQuestionsAnswers] = useState([]);
  const [finalQuestions, setFinalQuestions] = useState([]);
  const [finalQuestionsAnswers, setFinalQuestionsAnswers] = useState([]);
  const [notes , setNotes] = useLocalStorage("Notes","");
  //Intialize the simulation here and save it to the firestore. It follows the same structure as the intake form.
  const initializeSimulation = async () => {

    // Example Firestore document creation
    try {
      const newSimulationRef = await addDoc(collection(db, "leads"), {
        // Add your simulation initial data structure here
        lawfirm: lawyerLawfirm,
        created: new Date(),
        isSimulated: true,
        name : "User",
        LastName: "Simulated",
        company: "Example Company",
        createdDate: new Date().toLocaleDateString("en-US", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }),

      });

      console.log("New simulation created with ID: ", newSimulationRef.id);
      // Optionally set state related to the new simulation
      // TODO: save it as something else not to override lawyer
      setSimulationFirestoreId(newSimulationRef.id);
      //clear the questions and answers
      setQuestions([]);
      setAnswers([]);

      console.log("Firestore ID: ", simulationFirestoreId);
      // Proceed to the intake form within the simulation screen
      setIsStartScreenVisible(false); // Hide start screen and show the simulation form/questions
    } catch (error) {
      console.error("Error creating new simulation: ", error);
    }
    handleNextButton();
  };

  useEffect(() => {
    // Fetch past intakes similar to before
    const fetchPastIntakes = async () => {
      const lawyerLawfirmName = lawyerLawfirm; // Assuming you have this value
      const q = query(
        collection(db, "leads"),
        where("isSimulated", "==", true),
        where("eliminated", "==", false),
        //where("eliminated", "==", null)
        
        //where ("eliminated", "==", false),
      );
      const querySnapshot = await getDocs(q);
      const intakes = [];
      const intakeIds = [];
      querySnapshot.forEach((doc) => {
        intakes.push(doc.data());
        intakeIds.push(doc.id);
      });
      setPastIntakes(intakes);
      setPastIntakeIds(intakeIds);
    };

    fetchPastIntakes();
  }, [lawyerLawfirm]); // Add dependencies as necessary

  const onSessionSelected = (sessionInfo, firestoreId) => {
    setSelectedSessionDetails(sessionInfo); // Store selected session details
    setCurrentScreen("intakeDetails"); // Change to the screen where you show or edit the details
    // Optionally, log the selected session
    console.log(
      "Selected session details:",
      sessionInfo,
      "Firestore ID:",
      firestoreId,
    );
  };

  const onEliminateSession = async (simulationFirestoreId) => {
    // Ensure sessionId is correctly passed and is a valid Firestore document ID
    if (!simulationFirestoreId) {
      console.error("Invalid session ID provided for elimination.");
      return;
    }

    try {
      //Made simulations to avoid crossover with other collections
      const docRef = doc(db, "leads", simulationFirestoreId);
      await updateDoc(docRef, {
        eliminated: true, // This field marks the session as eliminated
      });
      console.log("Session eliminated with ID:", simulationFirestoreId);

      // Refresh UI: Filter out eliminated session
      const updatedPastIntakes = pastIntakes.filter(
        (intake) => intake.id !== simulationFirestoreId,
      );
      setPastIntakes(updatedPastIntakes);
      const updatedPastIntakeIds = pastIntakeIds.filter(
        (id) => id !== simulationFirestoreId,
      );
      setPastIntakeIds(updatedPastIntakeIds);
    } catch (error) {
      console.error("Error eliminating session: ", error);
    }
  };

  useEffect(() => {
    console.log("Load Case Details Specific Questions");
    console.log("User FirestoreId: ", simulationFirestoreId);
    console.log("Local Storage: ", localStorage);

    // Remember in this version, it just appends them, it is not divided by versions
    if (questions.length > 0) {
      let startIndex = (count - 1) * numQuestionsPerScreen;
      let endIndex = count * numQuestionsPerScreen;
      console.log("Questions array: ", questions);
      console.log("Questions to Show: ", questions.slice(startIndex, endIndex));

      setQuestionsToShow(questions.slice(startIndex, endIndex));

      if (answers) {
        if (answers.length > 0) {
          let newAnswersToShow = answers.slice(startIndex, endIndex);
          for (let i = 0; i < numQuestionsPerScreen; i++) {
            if (newAnswersToShow[i] === undefined) {
              newAnswersToShow[i] = "";
            }
          }
          console.log("Answers to Show: ", newAnswersToShow);
          setAnswersToShow(newAnswersToShow);

          // TODO: If atleast one answer is filled out, let user continue
          if (newAnswersToShow.some((answer) => answer !== "")) {
            setIsFormValid(true);
          } else {
            setIsFormValid(false);
          }
        }
      }
    }
  }, [count, questions]);

  const getLegalSector = async (answer, intent) => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlGetLegalSectors = apiHostname
        ? `https://${apiHostname}/api/getLegalSector`
        : "http://localhost:5001/api/getLegalSector";

      const response = await fetch(apiUrlGetLegalSectors, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userAnswer: answer,
          userIntent: intent,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response:", data);
        console.log("Legal sector: ", data.legalSector);
        console.log("Legal subsector: ", data.legalSubsector);
        console.log("Legal subsubsector: ", data.legalSubsubsector);
        console.log("DB questions: ", data.DBQuestions);

        // Save sector, subsector, subsubsector and DBQuestions to the local storage
        setLegalSector(data.legalSector);
        setLegalSubsector(data.legalSubsector);
        setLegalSubsubsector(data.legalSubsubsector);
        setDBQuestions(data.DBQuestions);

        console.log("Legal Subsector", legalSubsector);
        // Generate Specific questions from backend
        getSpecificQuestions(
          answer,
          intent,
          data.legalSubsubsector,
          data.DBQuestions,
        );

        const docRef = doc(db, "leads", simulationFirestoreId);
        updateDoc(docRef, {
          legalSector: data.legalSector,
          legalSubsector: data.legalSubsector,
          legalSubsubsector: data.legalSubsubsector,
        }).then(() => {
          console.log(
            "Legal specific questions, sector and subsector updated in Firebase with ID: ",
            simulationFirestoreId,
          );
          console.log("Legal Subsector", legalSubsector);
        });

        return data;
      } else {
        console.log("Legal specific questions went wrong");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleClientDetailsInputChange = () => {
   
    const firstNameInput = document.getElementById("FirstName").value;
    const lastNameInput = document.getElementById("LastName").value;
    const companyInput = document.getElementById("company").value;
    const emailInput = document.getElementById("email").value;
    const phoneInput = document.getElementById("phone").value;
    const addressInput = document.getElementById("address").value;
    const zipCodeInput = document.getElementById("zipCode").value;

    // setGeneralCaseAnswer(issueInput);
    // setIdealResult(intentInput);
    setFirstName(firstNameInput);
    setLastName(lastNameInput);
    setCompany(companyInput);
    setEmail(emailInput);
    setPhone(phoneInput);
    setAddress(addressInput);
    setZipCode(zipCodeInput);

    // Update the form validity based on the input values
    // setIsFormValid(issueInput && intentInput);

    setIsFormValid(true);
  };

  const handleInputChange = (id, value) => {
    // Update the state of answers to show
    let updatedAnswersToShow = [...answersToShow];
    updatedAnswersToShow[id] = value;
    setAnswersToShow(updatedAnswersToShow);

    // Update the state of answers
    let updatedAnswers = answers;
    updatedAnswers[id + (count - 1) * numQuestionsPerScreen] = value;
    setRequiredQuestionsAnswers(updatedAnswers);
    console.log("Updated Answers: ", updatedAnswers);

    // Next button enabled with only one answer
    setIsFormValid(true);
  };

  const handleRequiredQuestionsInputChange = (id, value) => {

    // Update the state of answers to show
    let updatedrequiredQuestionAnswersToShow = [...requiredQuestionsAnswers];
    updatedrequiredQuestionAnswersToShow[id] = value;
    setRequiredQuestionsAnswers(updatedrequiredQuestionAnswersToShow);

    // // Update the state of answers
    // let updated = requiredQuestionsAnswers;
    // updatedAnswers[id + (count - 1) * numQuestionsPerScreen] = value;
    // setRequiredQuestionsAnswers(updatedAnswers);
    // console.log("Updated Answers: ", updatedAnswers);

    // Next button enabled with only one answer
    setIsFormValid(true);
    };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    console.log("Submit button pressed");

    //isloading boolean to show the loading spinner
    console.log("Count: ", count);
    // TODO: Call to selectedDBQuestions
    if (count < numScreens - 1) {
      
      let newCount = count + 1;
      setCount(newCount);
      let startIndex = newCount * numQuestionsPerScreen;
      let endIndex = (newCount + 1) * numQuestionsPerScreen;

      // If no questions in this range, generate them
      console.log("Questions length: ", questions.length);
      let comparisonQuestions = (newCount + 1) * numQuestionsPerScreen;
      console.log("Answers length: ", comparisonQuestions);
      setAnswersToShow([]);
      if (questions.length < (newCount + 1) * numQuestionsPerScreen) {
        setQuestionsToShow([]); // Make it load again
        setAnswersToShow([]); // Empty the textarea for the new answers
        setIsFormValid(false);

        // TODO: before calling getSpecificQuestions, make sure legalSubsubsector has been called before
        await getSpecificQuestions(
          generalCaseAnswer,
          idealResult,
          legalSubsubsector,
          questions,
          allAnswers,
          DBQuestions,
          simulationFirestoreId, // Modify this firestoreId as it is the lawyer's
        );

        //TODO: Show that questions are loading until they are loaded
      } else {
        // Set questions to show to the questions already generated
        setQuestionsToShow(questions.slice(startIndex, endIndex));
        if (answers.length >= (newCount + 1) * numQuestionsPerScreen) {
          setAnswersToShow(answers.slice(startIndex, endIndex)); // Show answers already generated
        } else {
          setAnswersToShow([]);
        }
      }
    } else {
      //TODO: Make sure all fields are filled out to allow the user to submit
      // TODO: Save the answers to the local storage
    }
  };

  const handleSubmitRequiredQuestions = async (e) => {
    e.preventDefault();
    console.log("Submit button pressed");

    //isloading boolean to show the loading spinner

    // TODO: Call to selectedDBQuestions
    if (count < numScreens - 1) {
      
      let newCount = count + 1;
      setCount(newCount);
      let startIndex = newCount * numQuestionsPerScreen;
      let endIndex = (newCount + 1) * numQuestionsPerScreen;

      // If no questions in this range, generate them
      console.log("Questions length: ", questions.length);
      let comparisonQuestions = (newCount + 1) * numQuestionsPerScreen;
      console.log("Answers length: ", comparisonQuestions);
      setAnswersToShow([]);
      if (questions.length < (newCount + 1) * numQuestionsPerScreen) {
        setQuestionsToShow([]); // Make it load again
        setAnswersToShow([]); // Empty the textarea for the new answers
        setIsFormValid(false);

        // TODO: before calling getSpecificQuestions, make sure legalSubsubsector has been called before
        await getSpecificQuestions(
          generalCaseAnswer,
          idealResult,
          legalSubsubsector,
          questions,
          allAnswers,
          DBQuestions,
          simulationFirestoreId, // Modify this firestoreId as it is the lawyer's
        );

        //TODO: Show that questions are loading until they are loaded
      } else {
        // Set questions to show to the questions already generated
        setQuestionsToShow(questions.slice(startIndex, endIndex));
        if (answers.length >= (newCount + 1) * numQuestionsPerScreen) {
          setAnswersToShow(answers.slice(startIndex, endIndex)); // Show answers already generated
        } else {
          setAnswersToShow([]);
        }
      }
    } else {
      //TODO: Make sure all fields are filled out to allow the user to submit
      // TODO: Save the answers to the local storage
    }
    handleNextButton();
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
    };

  const startNewIntake = () => {
    // Reset necessary state variables to their initialv   values
    setCurrentPage('startScreen');
    setCount(0);
    // Reset other states as needed...
  };

  const getSpecificQuestions = async (
    answer,
    intent,
    legalSubsubsector,
    previousQuestions,
    previousAnswers,
    DBQuestions,
    firestoreId,
  ) => {
    console.log(
      "Body for selectedDBQuestion: ",
      answer,
      intent,
      legalSubsubsector,
      previousQuestions,
      previousAnswers,
      DBQuestions,
      count,
      // clientDetailsQuestions,
      // clientDetailsAnswers,
      simulationFirestoreId,
      numScreens,
    );

    //TODO: Check the implementation of this API call vs an alternative call with langchain
    // const openai = new OpenAI();

    // async function main() {
    //   const completion = await openai.chat.completions.create({
    //     messages: [{ role: "system", content: "You are a helpful assistant." }],
    //     model: "gpt-3.5-turbo",
    //   });

    //   console.log(completion.choices[0]);
    // }

    // main();

    //Defines the parser
    const parser = new CustomListOutputParser({ separator: "\n" });

    //const parser = new CommaSeparatedListOutputParser();
    const chain = RunnableSequence.from([
      PromptTemplate.fromTemplate(`
            ###BACKGROUND###:
            
            You are an experienced lawyer specializing in {legal_subsubsector}. 
            Your task is to quickly onboard a new client by developing 3 specific intake questions. 
            These initial questions should be crafted to gather essential information efficiently, 
            leveraging your expertise to guide the onboarding process effectively. 
            
            
            ###Relevant Information###: 

            Consider the following details to inform your questions: 
            - **Client's Issue:** A description in their own words ({clientAnswer}). 
            - **Client's Intent:** What the client wishes to achieve ({clientIntent}).
            - **Client Details:** Insights from the client details section.

            ### Objective: 
            
            Generate 3 targeted questions to facilitate client onboarding. 
            Follow these guidelines: - Incorporate any specific instructions in {aiCustomization} and {focusCustomization}.
            - Focus on the legal area needed ({legal_subsubsector}), the client's situation ({clientAnswer}), 
                and their desired outcome ({clientIntent}). 
            - Avoid overlap with existing database questions ({lawyer_questions})
                and previously answered questions. 
            - Ensure questions are relevant, of high quality, and contribute effectively to 
                understanding the client's case. 
            - Assume the client has no legal expertise. 
            - Build on previous interactions for a cohesive question flow, focusing on unaddressed or evasively answered areas. 
            
            
            ### Guidelines: 
            
            - Aim for comprehensive questions that allow for attorney feedback before a second, more in-depth round of questioning. 
            - Do not number or categorize questions. - Use neutral, direct language suitable for lawyer-client interaction. 
            - Exclude questions on legal knowledge, laws, or statutes, and avoid implying representation. 
            - Focus on areas most relevant to the client's issue and intent. 
            
            
            ### Rules ###: 
            
            Strictly adhere to these rules to ensure effective client onboarding: 
            - DO NOT GIVE YOUR THOUGHTS OR CONTEXTUAL INFORMATION.
            - Questions must be instrumental for the onboarding process. 
            - Avoid redundancy with previously provided or answered information. 
            - Do not request document uploads or discuss legal strategies. 
            - Ensure questions are appropriate and of the highest quality for a legal context. 
            - Exclude redundant, niche, or legally complex inquiries irrelevant to the client's understanding or situation. 
            - Never ask questions with the same starting word or phrase, make these questions unique.
            - NEVER ask compound, two part or multi-part questions.
            - Always make the questions as clear and concise as possible and in a structured format.
            
            
            ### Output ###: 
            
            Give the top 3 questions as comma seperated values only no double quotes i.e. question 1? \n question2? \n question 3? and do not use numbers, 
            or any other indicators other than the newline characters \n for seperating and labelling the questions 
            
            You must always return the three results and make sure they are all serparated by newline characters \n.
            
            DO NOT PROVIDE ANY OTHER INFORMATION OTHER THAN THE QUESTIONS AND ESPECIALLY DO NOT PROVIDE ANY CONTEXTUAL INFORMATION OR YOUR THOUGHT PROCESS.`),

      //TODO: Specify the model for gpt-4
      //TODO: Fix the formatting of the questions to not have the double quotes.
      new OpenAI({
        temperature: 0,
        openAIApiKey: openAIApiKey,
        modelName: "gpt-4-0125-preview",
      }),
      parser,
    ]);

    console.log(parser.getFormatInstructions());

    const response = await chain.invoke({
      legal_subsubsector: legalSubsubsector,
      clientAnswer: answer,
      clientIntent: intent,
      aiCustomization: "customization",
      focusCustomization: "focus",
      lawyer_questions: previousQuestions,
      format_instructions: parser.getFormatInstructions(),
    });

    console.log("Response from LLM", response);

    let questions_list = response;
    let oldQuestions = questions;
    let newQuestions = oldQuestions.concat(questions_list);
    setQuestions(newQuestions);
    setAnswersToShow([]);

    // try {
    //   const apiHostname = process.env.REACT_APP_API_HOSTNAME;
    //   const apiUrlSelectedDBQuestions = apiHostname
    //     ? `https://${apiHostname}/api/selectedDBQuestion`
    //     : "http://localhost:5001/api/selectedDBQuestion";

    //   const response = await fetch(apiUrlSelectedDBQuestions, {
    //     method: "post",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       userAnswer: answer,
    //       userIntent: intent,
    //       legalSubsubsector: legalSubsubsector,
    //       previousQuestions: previousQuestions,
    //       previousAnswers: previousAnswers,
    //       count: count,
    //       DBQuestions: "", // Check how to handle this
    //       firestoreId: "", // Check how to handle this
    //       clientDetailsQuestions: "", // Check how to handle this
    //       clientDetailsAnswers: "", // Check how to handle this
    //     }),
    //   });

    //   if (response.ok) {
    //     const data = await response.json();
    //     console.log("Got back ok response:", data);
    //     console.log("List of new specific questions: ", data.questions);
    //     let questions_list = data.questions;

    //     save questions to localStorage
    //     let oldQuestions = questions;
    //     let newQuestions = oldQuestions.concat(questions_list);
    //     setQuestions(newQuestions);
    //     setAnswersToShow([]);

    //     TODO: Save information from in Firestore for us to see
    //   } else {
    //     console.log("Legal specific questions went wrong");
    //   }
    // } catch (error) {
    //   console.log("error: ", error);
    // }
  };

  const handleCaseDetailsInputChange = () => {
    const issueInput = document.getElementById("issue").value;
    const intentInput = document.getElementById("intent").value;

    setGeneralCaseAnswer(issueInput);
    setIdealResult(intentInput);


    // Update the form validity based on the input initialValueJson
    setIsFormValid(issueInput && intentInput);
    };

  const getClientDetailsQuestions = async () => {
        // Read questions from Firebase
        const docRef = doc(db, "qualitativeDB", "legal");
        getDoc(docRef)
          .then((document) => {
            if (document.exists()) {
              console.log("Document data:", document.data());
              // Set the state of the fields
              if (document.data().questions) {
                console.log(
                  "Sector Questions Required exists: ",
                  document.data().questions,
                );
                setRequiredQuestions(document.data().questions);
              }
            }
          })
          .catch((error) => {
            console.log("Error getting info from Firestore:", error);
          });
    
        // TODO: for when we want to add AI modifications to this part
        // try {
        //     const apiHostname = process.env.REACT_APP_API_HOSTNAME;
        //     const apiUrlGetClientDetailsQuestions = apiHostname
        //       ? `https://${apiHostname}/api/getClientDetailsQuestions`
        //       : "http://localhost:5001/api/getClientDetailsQuestions";
    
        //     const response = await fetch(apiUrlGetClientDetailsQuestions, {
        //       method: "GET",
        //       headers: {
        //         "Content-Type": "application/json"
        //       },
        //     })
    
        //     if (response.ok) {
        //       const data = await response.json();
        //       console.log("Got back ok response:", data);
        //       console.log("List of client details questions: ", data.questions)
        //       setQuestions(data.questions);
        //     } else {
        //       console.log("Client details questions went wrong");
        //     }
        //   } catch (error) {
        //     console.log("error: ", error)
        //   }
      };

  useEffect(() => {
        console.log("Load Client Details");
        console.log("User FirestoreId: ", simulationFirestoreId);
    
        if (requiredQuestions.length) {
          console.log("Questions already loaded: ", requiredQuestions);
          if (requiredQuestionsAnswers.length) {
            console.log("Answers already loaded: ", requiredQuestionsAnswers);
            // If at least one answer is filled, enable form
            if (requiredQuestionsAnswers.some((answer) => answer !== "")) {
              setIsFormValid(true);
            } else {
              setIsFormValid(false);
            }
          }
        } else {
          // TODO: this shouldn't be neccessary anymore
          getClientDetailsQuestions();
        }
      }, [requiredQuestions, requiredQuestionsAnswers]);

  const lawyerQuery = query(
    collection(db, "lawyers"),
    where("lawyerLawfirm", "==", lawyerLawfirm),
  );

  // Execute the query against Firestore
  getDocs(lawyerQuery).then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      // Extract and persist required questions from the lawyer's document
      const finalQuestionsLawfirm = doc.data().finalQuestionsLawfirm || [];
      setFinalQuestions(finalQuestionsLawfirm);
      return;
    });
  });

  useEffect(() => {
    
    console.log("Load Follow Up Questions to Docs");
    console.log("User FirestoreId: ", simulationFirestoreId);

    // Check props to set questions and answers have data (TODO: change to questions.length > 0 and answers.length > 0)
    if (finalQuestions) {
      console.log("Final Questions: ", finalQuestions);
      if (finalQuestionsAnswers) {
        console.log("Final Questions Answers: ", finalQuestionsAnswers);
        setIsFormValid(true);
      }
    }
  }, []);

  const handleBackButton = () => {
        if (currentPage === 'clientDetails') {
          setCurrentPage('startScreen');
        } else if (currentPage === 'caseDetails') {
          setCurrentPage('clientDetails');
        } else if (currentPage === 'requiredQuestions') {
          setCurrentPage('caseDetails');
        } else if (currentPage === 'aiQuestions') {
          setCurrentPage('requiredQuestions');
        }else if (currentPage === 'finalQuestions') {
            setCurrentPage('aiQuestions');
        } else if (currentPage === 'finalReview') {
          setCurrentPage('finalQuestions');
        }
      };

  const handleNextButton = () => {
        if (currentPage === 'startScreen') {
          setCurrentPage('clientDetails');
        } else if (currentPage === 'clientDetails') {
          setCurrentPage('caseDetails');
        } else if (currentPage === 'caseDetails') {
          setCurrentPage('requiredQuestions');
        } else if (currentPage === 'requiredQuestions') {
          setCurrentPage('aiQuestions');
        } else if (currentPage === 'aiQuestions') {
          setCurrentPage('finalQuestions');
        }else if (currentPage === 'finalQuestions') {
            setCurrentPage('finalReview');
          }
      };

  if (currentPage === 'startScreen') {
    return (
      <div className="columns is-mobile">
        <LateralMenu />
        <div className="column">
          <Navbar
            isSignedIn={isSignedIn}
            isLawyer={isLawyer}
            lawyerLawfirm={lawyerLawfirm}
          />
          <div className="dashboardMargins has-background-light">
            <div className="columns is-mobile">
              <div className="column tableSection">
                <div className="mx-6 mt-5">
                  <div className="box has-text-centered">
                    <h1 className="title is-4">AI Co-Pilot</h1>

                    <div className="has-text-left"> 
                     Update this text to reflect how the legal professional would use the AI Co-Pilot to assist them in their work.
                    </div>
                    <br></br>
                    <button
                      onClick={initializeSimulation}
                      className="button is-success"
                    >
                      Start Intake
                    </button>

                    <br></br>
                    <br></br>
                    {pastIntakes.map((sessionInfo, index) => (
                      <SimInlinePastIntake
                        key={index}
                        sessionInfo={sessionInfo}
                        firestoreId={pastIntakeIds[index]}
                        onSessionSelected={onSessionSelected}
                        onEliminateSession={onEliminateSession}
                      />
                    ))}
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }else if (currentPage  === 'clientDetails') {
    return (
        <div className="columns is-mobile">
        <LateralMenu />
        <div className="column">
          <Navbar
            isSignedIn={isSignedIn}
            isLawyer={isLawyer}
            lawyerLawfirm={lawyerLawfirm}
          />
        <div className="dashboardMargins has-background-light">
        <div className="columns is-mobile">
          <div className="column tableSection">
            <div className="mx-6 mt-5">
              <div className="box">
      {/* Initial Client Case Information Form */}
      <label className="label has-text-center" htmlFor="name">
        Initial Information about your client 
      </label>

      <div className="">
        <form >
          {/* Client Details Input Fields */}

          <div className="field is-horizontal">
          <div className="field-label">
            <label
              className="label has-text-left"
              htmlFor="firstName"
            >
              Legal First Name*
            </label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control ">
                <input
                  name="lastName"
                  className="input mb-2"
                  id="FirstName"
                  onChange={handleClientDetailsInputChange}
                  readOnly={isReadOnly}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label">
            <label className="label has-text-left" htmlFor="LastName">
              Legal Last Name*
            </label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  name="name"
                  className="input mb-2"
                  id="LastName"
                  onChange={handleClientDetailsInputChange}
                  readOnly={isReadOnly}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label">
            <label className="label has-text-left" htmlFor="company">
              Company
            </label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  name="company"
                  className="input mb-2"
                  id="company"
                  onChange={handleClientDetailsInputChange}
                  readOnly={isReadOnly}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label">
            <label className="label has-text-left" htmlFor="email">
              Email*
            </label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  name="email"
                  className="input mb-2"
                  id="email"
                  onChange={handleClientDetailsInputChange}
                  readOnly={isReadOnly}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label">
            <label className="label has-text-left" htmlFor="phone">
              Phone*
            </label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  name="phone"
                  className="input mb-2"
                  id="phone"
                  onChange={handleClientDetailsInputChange}
                  readOnly={isReadOnly}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label">
            <label className="label has-text-left" htmlFor="address">
              Address*
            </label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  name="address"
                  className="input mb-2"
                  id="address"
                  onChange={handleClientDetailsInputChange}
                  readOnly={isReadOnly}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label">
            <label className="label has-text-left" htmlFor="zipCode">
              Zip Code*
            </label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  name="zipCode"
                  className="input mb-2"
                  id="zipCode"
                  onChange={handleClientDetailsInputChange}
                  readOnly={isReadOnly}
                />
              </div>
            </div>
          </div>
        </div>

          {/* Notes Section */}
          <label className="label has-text-left">Notes</label>
          <textarea
            className="textarea"
            value = {notes}
            placeholder="Enter notes here..."
            onChange={handleNotesChange}
          ></textarea>
        </form>
      </div>

    <br></br>
      <div className="has-text-right">

        <button
        className="button is-success has-text-white"
        onClick={handleNextButton}
        disabled={!isFormValid}
        >
        Next
        </button>
      </div>
    </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
  );} else if (currentPage  === 'caseDetails') {
        return (
            <div className="columns is-mobile">
            <LateralMenu />
            <div className="column">
              <Navbar
                isSignedIn={isSignedIn}
                isLawyer={isLawyer}
                lawyerLawfirm={lawyerLawfirm}
              />
            <div className="dashboardMargins has-background-light">
            <div className="columns is-mobile">
              <div className="column tableSection">
                <div className="mx-6 mt-5">
                  <div className="box">
          {/* Initial Client Case Information Form */}
          <label className="label has-text-center" htmlFor="name">
            Initial Information about your client 
          </label>
    
          <div className="">
            <form >
              {/* Client Details Input Fields */}
    
              <label
                            className="label has-text-left"
                            htmlFor="message"
                          >
                            Describe your issue
                          </label>
                          <textarea
                            name="message"
                            className="textarea mb-2"
                            id="issue"
                            onChange={handleCaseDetailsInputChange}
                            readOnly={isReadOnly}
                          />
                          <label
                            className="label has-text-left"
                            htmlFor="message"
                          >
                            What is your desired outcome?
                          </label>
                          <textarea
                            name="intent"
                            className="textarea mb-2"
                            id="intent"
                            onChange={handleCaseDetailsInputChange}
                            readOnly={isReadOnly}
                          />
              {/* Notes Section */}
            <label className="label has-text-left">Notes</label>
          <textarea
            className="textarea"
            value={notes}
            placeholder="Enter notes here..."
            onChange={handleNotesChange}
          ></textarea>
        </form>
    
        <br></br>
        {/* Notes Section */}
        
      </div>

    <br></br>
          <div className="has-text-right">
            {/* Navigation Button */}

            <button
            className="button is-success is-pulled-left has-text-white"
            onClick={handleBackButton}
            >
            Back
            </button> 
    
            <button
            className="button is-success has-text-white"
            onClick={handleNextButton}
            disabled={!isFormValid}
            >
            Next
            </button>
          </div>
        </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            </div>
  );} else if (currentPage === 'requiredQuestions') {
    return (
        <div className="columns is-mobile">
        <LateralMenu />
        <div className="column">
          <Navbar
            isSignedIn={isSignedIn}
            isLawyer={isLawyer}
            lawyerLawfirm={lawyerLawfirm}
          />
        <div className="dashboardMargins has-background-light">
        <div className="columns is-mobile">
          <div className="column tableSection">
            <div className="mx-6 mt-5">
              <div className="box">

              <div className="title is-4 has-text-centered">
                    Required Questions
                </div>
                <div className="subtitle is-6 has-text-centered">
                  Please answer the follow questions to the best of your
                  ability.
                </div>
                <div className="">
                  <form >
                  
                    {requiredQuestions.length ? (
                      requiredQuestions.map((question, index) => (
                        <SpecificQuestionsCoPilot
                          key={index}
                          id={index}
                          question={question}
                          handleInputChange={handleRequiredQuestionsInputChange}
                          value={requiredQuestionsAnswers[index] || ""}
                          submissionStatus={submissionStatus}
                        />
                      ))
                    ) : (
                      <div>
                        <br></br>
                        <br></br>
                        <div className="box has-text-centered">
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="mr-4"
                          />
                          Loading questions...
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                      </div>
                    )
                    }
  {/* Notes Section  */}
<label className="label has-text-left">Notes</label>
          <textarea
            className="textarea"
            value = {notes}
            placeholder="Enter notes here..."
            onChange = {handleNotesChange}
          ></textarea>
          <br></br>
        </form>
        
                <div className="has-text-right">
                <button
            className="button is-success is-pulled-left has-text-white"
            onClick={handleBackButton}
            >
            Back
            </button> 
    
            <button
            className="button is-success is-pulled-right has-text-white"
            onClick={handleSubmitRequiredQuestions}
            disabled={!isFormValid}
            >
            Next
            </button>
            <br></br>
            <br></br>
            </div>
            </div>
              </div>
              </div>
            </div>
            </div>
            </div>
            </div>
            </div>
  );} else if (currentPage === 'aiQuestions') {
    return (
      <div className="columns is-mobile">
        <LateralMenu />
        <div className="column">
          <Navbar isSignedIn={isSignedIn} isLawyer={isLawyer} lawyerLawfirm={lawyerLawfirm} />
          <div className="dashboardMargins has-background-light">
            <div className="columns is-mobile">
              <div className="column tableSection">
                <div className="mx-6 mt-5">
                  <div className="box">
                    <label className="label has-text-center" htmlFor="name">
                      AI-Generated Questions
                    </label>
                    <div className="">
                      <form>
                        {questionsToShow.length ? (
                          questionsToShow.map((question, index) => (
                            <SimSpecificQuestion
                              key={index}
                              id={index}
                              question={question}
                              handleInputChange={handleInputChange}
                              value={answersToShow[index] || ""}
                              submissionStatus={submissionStatus}
                            />
                          ))
                        ) : (
                          <div className="box has-text-centered">
                            <FontAwesomeIcon icon={faSpinner} spin size="2x" className="mr-4" />
                            Generating questions based on the case details...
                          </div>
                        )}
                          {/* Notes Section */}
                     <label className="label has-text-left">Notes</label>
          <textarea
            className="textarea"
            value = {notes}
            placeholder="Enter notes here..."
            onChange={handleNotesChange}
          ></textarea>
          <br></br>
        </form>
                      <div className="has-text-right">
                        <button
                          className="button is-success is-pulled-left has-text-white"
                          onClick={handleBackButton}
                        >
                          Back
                        </button>
  
                        <button
                          className="button is-success is-pulled-right has-text-white"
                          onClick={() => {
                            if (count === 3) {
                              handleNextButton(); // If not all questions have been generated/answered, generate/submit answers for the current set
                            } else {
                              handleSubmit(); // If all questions for this section have been handled, move to the next section
                            }
                          }}
                          disabled={!isFormValid}
                        >
                          {count === 3 ? 'Next' : 'Submit'}
                        </button>
                        <br></br>
                        <br></br>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );} else if (currentPage === 'finalQuestions') {
    return (
<div className="columns is-mobile">
        <LateralMenu />
        <div className="column">
          <Navbar
            isSignedIn={isSignedIn}
            isLawyer={isLawyer}
            lawyerLawfirm={lawyerLawfirm}
          />
        <div className="dashboardMargins has-background-light">
        <div className="columns is-mobile">
          <div className="column tableSection">
            <div className="mx-6 mt-5">
              <div className="box">

              <form onSubmit={handleNextButton}>
                  {finalQuestions.length ? (
                    finalQuestions.map((question, index) => (
                      <SpecificQuestionsCoPilot
                        key={index}
                        id={index}
                        question={question}
                        handleInputChange={handleInputChange}
                        value={finalQuestionsAnswers[index] || ""}
                        submissionStatus={submissionStatus}
                      />
                    ))
                  ) : (
                    <div>
                      <br></br>
                      <br></br>
                      <div className="box has-text-centered">
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          size="2x"
                          className="mr-4"
                        />
                        Loading final questions about your case.
                      </div>
                      <br></br>
                      <br></br>
                      <br></br>
                    </div>
                  )}

                    {/* Notes Section  */}
               <label className="label has-text-left">Notes</label>
          <textarea
            className="textarea"
            value={notes}
            placeholder="Enter notes here..."
            onChange={handleNotesChange}
          ></textarea>
          <br></br>
        </form>
        <div className="has-text-right">
                <button
            className="button is-success is-pulled-left has-text-white"
            onClick={handleBackButton}
            >
            Back
            </button> 
    
            <button
            className="button is-success is-pulled-right has-text-white"
            onClick={handleNextButton}
            disabled={!isFormValid}
            >
            Next
            </button>
            <br></br>
            <br></br>
            </div>

              </div>
              
              </div>
            </div>
            </div>
            </div>
            </div>
            </div>
  );} else if (currentPage === 'finalReview') {
        return (
          <div className="columns is-mobile">
            <LateralMenu />
            <div className="column">
              <Navbar
                isSignedIn={isSignedIn}
                isLawyer={isLawyer}
                lawyerLawfirm={lawyerLawfirm}
              />
              <div className="dashboardMargins has-background-light">
                <div className="columns is-mobile">
                  <div className="column tableSection">
                    <div className="mx-6 mt-5">
                      <div className="box">
                        {/* Render summary of all information collected */}
                        <div>
                          
                          <CoPilotListQuestion
                            issueQuestion="Describe your issue"
                            issueAnswer={generalCaseAnswer || ""}
                            intentQuestion="What is your desired outcome?"
                            intentAnswer={idealResult || ""}
                            specificLegalQuestions={questions || []}
                            specificLegalAnswers={answers || []}
                          />
<br></br>
   {/* Notes Section  */}
   <label className="label has-text-left">Notes</label>
          <textarea
            className="textarea"
            value={notes}
            placeholder="Enter notes here..."
            onChange={handleNotesChange}
          ></textarea>
          <br></br>

                          <br></br>
                          <button
                        onClick={startNewIntake}
                        className="button is-pulled-right is-success"
                      >
                        Start New Intake
                      </button>
    
                      <button
                       onClick={(e) => {
                        e.preventDefault();
                        navigate("/dashboard");
                      }}
                        className="button is-pulled-left is-success"
                      > 
    
                      Return to Dashboard
                      </button>
                          <br></br>
                        </div>
                      </div>
                      
    
    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
      
}

export default LegalCoPilot;
