import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const IntakeLinksModal = ({ isOpen, toggleModal, invitationLinkShort, invitationLink }) => {
  const [isCopiedShort, setIsCopiedShort] = useState(false);
  const [isCopiedLong, setIsCopiedLong] = useState(false);
  
  // Function to copy the link to clipboard
  const copyToClipboard = (text, type) => {
    navigator.clipboard.writeText(text).then(() => {
      if(type === 'short'){
        setIsCopiedShort(true);
        setTimeout(() => setIsCopiedShort(false), 2000); // Reset isCopiedShort state after 2 seconds
      } else {
        setIsCopiedLong(true);
        setTimeout(() => setIsCopiedLong(false), 2000); // Reset isCopiedLong state after 2 seconds
      }
    });
  };

  // If the modal is not open, don't render anything
  if (!isOpen) return null;

  return (
    <div className="modal is-active" style={{ zIndex: "2000" }}>
      <div className="modal-background" onClick={toggleModal} style={{ backdropFilter: "blur(5px)", backgroundColor: "transparent" }}></div>
      <div className="modal-card" style={{ overflow: "hidden", borderRadius: "8px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", width: "80vw", maxWidth: "600px", margin: "auto" }}>
        <header className="modal-card-head" style={{ backgroundColor: "white", borderBottom: "none", padding: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <p className="modal-card-title">Copy either for predefined intakes:</p>
          <button className="delete" aria-label="close" onClick={toggleModal} style={{ background: "transparent", border: "none" }}></button>
        </header>
        <section className="modal-card-body" style={{ backgroundColor: "white", padding: "20px", textAlign: "center" }}>
         
          <div className="field has-addons">
            <div className="control is-expanded">
              <input className="input" type="text" readOnly value={invitationLinkShort} />
            </div>
            <div className="control">
              <button className={`button is-success ${isCopiedShort ? 'is-success' : ''}`} onClick={() => copyToClipboard(invitationLinkShort, 'short')}>
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faCopy} />
                </span>
                <span>{isCopiedShort ? 'Copied!' : 'Copy Short Link'}</span>
              </button>
            </div>
          </div>
          <div className="field has-addons">
            <div className="control is-expanded">
              <input className="input" type="text" readOnly value={invitationLink} />
            </div>
            <div className="control">
              <button className={`button is-success ${isCopiedLong ? 'is-success' : ''}`} onClick={() => copyToClipboard(invitationLink, 'long')}>
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faCopy} />
                </span>
                <span>{isCopiedLong ? 'Copied!' : 'Copy Long Link'}</span>
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default IntakeLinksModal;
