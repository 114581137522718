// AcceptModal.js
import React, { useState } from 'react';
import mixpanel from "mixpanel-browser";
import useLocalStorage from "../components/useLocalStorage";
import { useNavigate, useParams } from "react-router-dom";
import { getStorage, ref, getDownloadURL } from "firebase/storage";



const AcceptModal = ({ isOpen, toggleModal, calendarLink  }) => {

  
  const [lastClicked, setLastClicked] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { firebaseId } = useParams();
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage("lawyerLawfirm", "");
    
    

    const [acceptEmailBody, setAcceptEmailBody] =
    useLocalStorage("acceptEmailBody");

  const [acceptEmailHeader, setAcceptEmailHeader] = useState(
    localStorage.getItem("acceptEmailHeader"),
  );

    const [acceptEmailCalendarBody, setAcceptEmailCalendarBody] = useLocalStorage("acceptEmailCalendarBody");

    const [acceptEmailCalendarHeader, setAcceptEmailCalendarHeader] =useState(
        localStorage.getItem("acceptEmailCalendarHeader"),
      );

    const [acceptEmailOnlyBody, setAcceptEmailOnlyBody] = useState(
    localStorage.getItem("acceptEmailOnlyBody"),
    );

    const [acceptEmailOnlyHeader, setAcceptEmailOnlyHeader] = useState(
    localStorage.getItem("acceptEmailOnlyHeader"),
    );

  const [clientInfo, setClientInfo] = useLocalStorage(
    `clientInfo.${firebaseId}`,
    {},
  );

  const handleAction = async (action) => {
    setIsLoading(true); // Start loading
    setLastClicked(action); // Set the last clicked action

    console.log(`Button ${action} pressed`);
    mixpanel.track("Lawyer took a decision about the client", {
      Status: action,
      ClientFirebaseID: firebaseId,
    });

    let subject = "";
    let message = "";
    let email = clientInfo.email;
    let name = clientInfo.name;
    let lastName = clientInfo.LastName;
    const fullName = `${name} ${lastName}`;
    
    let lawyerLawfirmName = lawyerLawfirm.replace(/([A-Z])/g, " $1").trim();

    if (action === "engagement") {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlLetterOfEngagement = apiHostname
        ? `https://${apiHostname}/api/letterOfEngagement`
        : "http://localhost:5001/api/letterOfEngagement";

      try {
        const response = await fetch(apiUrlLetterOfEngagement, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firestoreId: firebaseId,
          }),
        });

        if (response.ok) {
          console.log(`Downloading: LOE`);
          const storage = getStorage();
          let fileRef = ref(
            storage,
            `leads/${firebaseId}/${fullName} EngagementLetter.pdf`,
          );

          let name = clientInfo.name;
            let lawyerLawfirmName = lawyerLawfirm
              .replace(/([A-Z])/g, " $1")
              .trim();

          try {
            // Get the download URL for the document
            const downloadURL = await getDownloadURL(fileRef);

            // Open the download URL in a new window or tab
            window.open(downloadURL, "_blank");
            
            subject = acceptEmailHeader
              .replace("[Name]", name)
              .replace("[Lawfirm Name]", lawyerLawfirmName)
              .replace(/\n/g, "%0D%0A")
              .replace(/"/g, "");

            message = acceptEmailBody
              .replace(/"/g, "")
              .replace("[downloadURL]", downloadURL)
              .replace("[Lawfirm Name]", lawyerLawfirmName)
              .replace("[Name]", name)
              .replace("[Lawfirm Name]", lawyerLawfirmName);

            console.log("Message: ", message);
          } catch (error) {
            console.error("Error downloading the document:", error);
            // Handle errors (e.g., display an error message to the user)
          }
        } else {
          console.error("Error generating the letter of engagement");
          return; // Stop execution if the response is not OK
        }
      } catch (error) {
        console.error("Error calling the letter of engagement API:", error);
        return; // Stop execution if there is an error
      }
    } 
    else {
        if (action === "calendar") {
            let name = clientInfo.name;
            let lawyerLawfirmName = lawyerLawfirm
              .replace(/([A-Z])/g, " $1")
              .trim();

          subject = acceptEmailCalendarHeader
            .replace("[Name]", name)
            .replace("[Lawfirm Name]", lawyerLawfirmName)
            .replace(/\n/g, "%0D%0A")
            .replace(/"/g, "");
  
          message = acceptEmailCalendarBody
            .replace(/"/g, "")
            .replace("[Lawfirm Name]", lawyerLawfirmName)
            .replace("[Name]", name)
            .replace("[Lawfirm Name]", lawyerLawfirmName)
            .replace("[calendarURL]", calendarLink);
        } else if (action === "email") {
            let name = clientInfo.name;
            let lawyerLawfirmName = lawyerLawfirm
              .replace(/([A-Z])/g, " $1")
              .trim();
              
          subject = acceptEmailOnlyHeader   
            .replace("[Name]", name)
            .replace("[Lawfirm Name]", lawyerLawfirmName)
            .replace(/\n/g, "%0D%0A")
            .replace(/"/g, "");
  
          message = acceptEmailOnlyBody
            .replace(/"/g, "")
            .replace("[Lawfirm Name]", lawyerLawfirmName)
            .replace("[Name]", name)
            .replace("[Lawfirm Name]", lawyerLawfirmName);
        }
      }
  
      // Construct the mailto URL
      const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
      // Open the email client in a new window
      window.open(mailtoUrl, "_blank");

      setIsLoading(false); // End loading
      toggleModal(); // Optionally close the modal after action
    
  };

  if (!isOpen) return null;

  return (
    <div className="modal is-active" style={{ zIndex: "2000" }}>
      <div className="modal-background" onClick={() => !isLoading && toggleModal()} style={{ backdropFilter: "blur(5px)", backgroundColor: "transparent" }}></div>
      <div className="modal-card" style={{ overflow: "hidden", borderRadius: "8px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", width: "80vw", maxWidth: "600px", margin: "auto" }}>
        <header className="modal-card-head" style={{ backgroundColor: "white", borderBottom: "none", padding: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <span style={{ fontSize: "1.25em", fontWeight: "bold" }}>
            Choose Action
          </span>
          <button className="delete" aria-label="close" onClick={() => !isLoading && toggleModal()} style={{ background: "transparent", border: "none" }}></button>
        </header>
        <section className="modal-card-body" style={{ backgroundColor: "white", padding: "20px", textAlign: "center", display: "flex", justifyContent: "center" }}>
          {isLoading ? (
            <div>Loading...</div> // Placeholder for loading indication, replace with your loading spinner or image
          ) : (
            <>
              <button className={`button ${lastClicked === 'email' ? 'is-success' : ''} mx-1`} onClick={() => handleAction('email')}>Send Email</button>
              <button className={`button ${lastClicked === 'calendar' ? 'is-success' : ''} mx-1`} onClick={() => handleAction('calendar')}>Send Calendar Invite</button>
              <button className={`button ${lastClicked === 'engagement' ? 'is-success' : ''} mx-1`} onClick={() => handleAction('engagement')}>Send Letter of Engagement</button>
            </>
          )}
        </section>
      </div>
    </div>
  );
};

export default AcceptModal;
