// FilterButton.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

const FilterButton = (props) => (
  <div className="dropdown">
    <div className="dropdown-trigger">
      <button
        className="button mt-1"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        onClick={props.toggleDropdown}
      >
        <span>
          {/* <FontAwesomeIcon icon={faFilter} /> */}
          {props.text}: {props.filterStatus}
        </span>
      </button>
    </div>

    {props.dropdownActive && (
      <div className="dropdown" role="menu" style={{ position: 'absolute', top: '100%', right: "50%", zIndex: 1000 }}>
        <div className="dropdown-content">
          {props.filterOptions.map((option) => (
            <a
              key={option}
              href="#"
              className={`dropdown-item ${props.filterStatus === option ? "is-active" : ""}`}
              onClick={() => props.handleFilterChange(option)}
            >
              {option}
            </a>
          ))}
        </div>
      </div>
    )}
  </div>
);

export default FilterButton;
