// CaseDetails.js

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  getFirestore,
  getDoc,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../config/firebase-config";
import SpecificQuestion from "../components/SpecificQuestion";
import NavbarFooter from "../components/NavbarFooter";
import IntakeSideMenu from "../components/IntakeSideMenu";
import NavbarIntake from "../components/NavbarIntake";
import FooterIntake from "../components/FooterIntake";
import mixpanel from "mixpanel-browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import useLocalStorage from "../components/useLocalStorage";

function CaseDetailsSpecific(props) {
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const [firestoreId, setFirestoreId] = useLocalStorage("firestoreId", "");
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);
  const [submissionStatus, setSubmissionStatus] = useLocalStorage(
    "submissionStatus",
    "Incomplete",
  );

  const [questions, setQuestions] = useLocalStorage("specificQuestions", []);
  const [answers, setAnswers] = useLocalStorage("specificAnswers", []);
  const [count, setCount] = useLocalStorage("specificCount", 0);
  const [questionsToShow, setQuestionsToShow] = useState([]);
  const [answersToShow, setAnswersToShow] = useState([]);

  // const [questionsWithAI, setQuestionsWithAI] = useLocalStorage('specificQuestionsWithAI', {});
  const [generalCaseAnswer, setGeneralCaseAnswer] = useLocalStorage(
    "generalCaseAnswer",
    "",
  );
  const [idealResult, setIdealResult] = useLocalStorage("idealResult", "");
  const [legalSector, setLegalSector] = useLocalStorage("legalSector", "");
  const [legalSubsector, setLegalSubsector] = useLocalStorage(
    "legalSubsector",
    "",
  );
  const [legalSubsubsector, setLegalSubsubsector] = useLocalStorage(
    "legalSubsubsector",
    "",
  );
  const [DBQuestions, setDBQuestions] = useLocalStorage("DBQuestions", {});
  const [docsList, setDocsList] = useLocalStorage("docsList", []);
  const [hourlyRate, setHourlyRate] = useLocalStorage("hourlyRate", "");
  const [flatFeeMatterChosen, setFlatFeeMatterChosen] = useLocalStorage(
    "flatFeeMatterChosen",
    "",
  );
  const [flatFeeValue, setFlatFeeValue] = useLocalStorage("flatFeeValue", "");
  const [clientDetailsQuestions, setClientDetailsQuestions] = useLocalStorage(
    "clientDetailsQuestions",
    [],
  );
  const [clientDetailsAnswers, setClientDetailsAnswers] = useLocalStorage(
    "clientDetailsAnswers",
    [],
  );
  const [finalQuestions, setFinalQuestions] = useLocalStorage(
    "finalQuestionsLawfirm",
    [],
  );

  const [shortIntake, setShortIntake] = useLocalStorage("shortIntake", "");
  const [personalizedIntake, setPersonalizedIntake] = useLocalStorage(
    "personalizedIntake",
    "",
  );

  const [numScreens, setNumScreens] = useState(3);
  const [run, setRun] = useState(true);
  // Add useRef to create a reference to the component's root element
  const componentRef = useRef(null);

  // TODO: Change numQuestionsPerScreen to length
  useEffect(() => {
    console.log("Short Intake value: ", shortIntake);
    console.log("Personalized Intake value: ", personalizedIntake);
    if (shortIntake === "short") {
      setNumScreens(1);
    }
    if (personalizedIntake) {
      // TODO: personalize it in some way
      console.log("Personalized intake");
    }
  }, [shortIntake, personalizedIntake]);

  let numQuestionsPerScreen = 3;

  // Hook to update questions when they change in localStorage in another screen
  useEffect(() => {
    let variableToTrack = "specificQuestions";
    let eventListenerAdded = false;

    const handleStorageChange = (event) => {
      if (count === 0 && run) {
        // Only needed once when count = 0 as the other changes are made here
        window.removeEventListener(
          `storage_${variableToTrack}`,
          handleStorageChange,
        );

        setRun(false);
        setQuestions(event.detail.value);
        console.log(
          `Storage event received for key: ${variableToTrack}. Updated value:`,
          event.detail.value,
        );
      }
    };
    // Listen for changes in localStorage specific to the 'myVariable' key
    if (count === 0 && run && !eventListenerAdded) {
      window.addEventListener(
        `storage_${variableToTrack}`,
        handleStorageChange,
      );
      eventListenerAdded = true;
    }
    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener(
        `storage_${variableToTrack}`,
        handleStorageChange,
      );
    };
  }, []);

  // When log back in, questions and answers are there
  useEffect(() => {
    console.log("Load Case Details Specific Questions");
    console.log("User FirestoreId: ", firestoreId);
    console.log("Local Storage: ", localStorage);

    // Remember in this version, it just appends them, it is not divided by versions
    if (questions.length > 0) {
      let startIndex = count * numQuestionsPerScreen;
      let endIndex = (count + 1) * numQuestionsPerScreen;

      setQuestionsToShow(questions.slice(startIndex, endIndex));

      if (answers) {
        if (answers.length > 0) {
          let newAnswersToShow = answers.slice(startIndex, endIndex);
          for (let i = 0; i < numQuestionsPerScreen; i++) {
            if (newAnswersToShow[i] === undefined) {
              newAnswersToShow[i] = "";
            }
          }
          setAnswersToShow(newAnswersToShow);

          // TODO: If atleast one answer is filled out, let user continue
          if (newAnswersToShow.some((answer) => answer !== "")) {
            setIsFormValid(true);
          } else {
            setIsFormValid(false);
          }
        }
      }
    }

    // Scroll to the top of the page when count changes
    if (componentRef.current) {
      componentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [count, questions]);

  // TODO: hook to update legalSector, legalSubsector, legalSubsubsector when they change in localStorage

  const handleBackClick = () => {
    if (count > 0) {
      setCount(count - 1);
    } else {
      navigate("/intake/legalSector");
    }
  };

  // Function to handle changes in textareas
  const handleInputChange = (id, value) => {
    // Update the state of answers to show
    let updatedAnswersToShow = [...answersToShow];
    updatedAnswersToShow[id] = value;
    setAnswersToShow(updatedAnswersToShow);

    // Update the state of answers
    let updatedAnswers = answers;
    updatedAnswers[id + count * numQuestionsPerScreen] = value;
    setAnswers(updatedAnswers);
    // console.log("Updated Answers: ", updatedAnswers)

    // Next button enabled with only one answer
    setIsFormValid(true);
  };

  const getProjectedPricing = async (
    legalSector,
    legalSubsector,
    legalSubsubsector,
    caseDetailAnswer,
    idealResult,
    lawyerLawfirm,
    previousQuestions,
    previousAnswers,
  ) => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlProjectedPricing = apiHostname
        ? `https://${apiHostname}/api/getProjectedPricing`
        : "http://localhost:5001/api/getProjectedPricing";

      const response = await fetch(apiUrlProjectedPricing, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          legalSector: legalSector,
          legalSubsector: legalSubsector,
          legalSubsubsector: legalSubsubsector,
          caseDetailAnswer: caseDetailAnswer,
          idealResult: idealResult,
          lawyerLawfirm: lawyerLawfirm,
          previousQuestions: previousQuestions,
          previousAnswers: previousAnswers,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response:", data);
        console.log("Hourly Rate: ", data.hourlyRate);
        console.log("Flat Fee Matter Chosen: ", data.flatFeeMatterChosen);
        console.log("Flat Fee Value: ", data.flatFeeValue);

        // Update localStorage
        setHourlyRate(data.hourlyRate);
        setFlatFeeMatterChosen(data.flatFeeMatterChosen);
        setFlatFeeValue(data.flatFeeValue);

        // TODO: Skip this if isReadOnly
        // Save information from user
        const docRef = doc(db, "leads", firestoreId);
        updateDoc(docRef, {
          hourlyRate: data.hourlyRate,
          flatFeeMatterChosen: data.flatFeeMatterChosen,
          flatFeeValue: data.flatFeeValue,
        }).then(() => {
          console.log("Projected Pricing updated with ID: ", firestoreId);
        });

        return data;
      } else {
        console.log("Projected Pricing went wrong");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const getDocumentsToUpload = async (answers) => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlDocsToUpload = apiHostname
        ? `https://${apiHostname}/api/docsToUpload`
        : "http://localhost:5001/api/docsToUpload";

      const response = await fetch(apiUrlDocsToUpload, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          legalSector: legalSector,
          legalSubsector: legalSubsector,
          legalSubsubsector: legalSubsubsector,
          caseDetailAnswer: generalCaseAnswer,
          specificQuestions: questions,
          specificAnswers: answers,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response:", data);
        console.log("Docs list: ", data.docsList);

        // Store data.questions into a list of questions
        let docs_list = data.docsList;

        // If the array is not empty and the first element is equal to "No Documents needed", set docs_list to empty
        if (docs_list.length > 0) {
          if (docs_list[0] === "No Documents needed") {
            docs_list = [];
          }
        }

        console.log("List of docs required: ", docs_list);

        // Save docs list to localStorage
        setDocsList(docs_list);

        // Save information from user
        const docRef = doc(db, "leads", firestoreId);
        updateDoc(docRef, {
          docsNamesList: docs_list,
        }).then(() => {
          console.log("List of docs asked:", docs_list);
        });

        return data;
      } else {
        console.log("Getting list of docs went wrong");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };
  const continueClientIntake = async (firestoreId, count) => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlContinueIntake = apiHostname
        ? `https://${apiHostname}/api/continueIntake`
        : "http://localhost:5001/api/continueIntake";

      const response = await fetch(apiUrlContinueIntake, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firestoreId: firestoreId,
          count: count,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Continue intake response:", data);
        // Handle the response data as needed
      } else {
        console.log("Failed to continue intake process");
      }
    } catch (error) {
      console.error("Error during continue intake process:", error);
    }
  };

  const getSpecificQuestions = async (
    answer,
    intent,
    legalSubsubsector,
    previousQuestions,
    previousAnswers,
    DBQuestions,
    firestoreId,
  ) => {
    console.log(
      "Body for selectedDBQuestion: ",
      answer,
      intent,
      legalSubsubsector,
      previousQuestions,
      previousAnswers,
      DBQuestions,
      count,
      clientDetailsQuestions,
      clientDetailsAnswers,
      firestoreId,
      numScreens
    );

    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlSelectedDBQuestions = apiHostname
        ? `https://${apiHostname}/api/selectedDBQuestion`
        : "http://localhost:5001/api/selectedDBQuestion";

      const response = await fetch(apiUrlSelectedDBQuestions, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userAnswer: answer,
          userIntent: intent,
          legalSubsubsector: legalSubsubsector,
          previousQuestions: previousQuestions,
          previousAnswers: previousAnswers,
          count: count,
          DBQuestions: DBQuestions,
          firestoreId: firestoreId,
          // TODO: pass this value to component
          clientDetailsQuestions: clientDetailsQuestions,
          clientDetailsAnswers: clientDetailsAnswers,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response:", data);
        console.log("List of new specific questions: ", data.questions);
        let questions_list = data.questions;

        // save questions to localStorage
        let oldQuestions = questions;
        let newQuestions = oldQuestions.concat(questions_list);
        setQuestions(newQuestions);
        setAnswersToShow([]);

        // //TODO: Check why this is failing as I am storing the same questions again
        // if (questions_list.length > numQuestionsPerScreen) {
        //   //Get the first numQuestionsPerScreen questions
        //   questions_list = questions_list.slice(0, numQuestionsPerScreen)
        // }

        // let specificQuestions = previousQuestions.concat(questions_list)
        // console.log("Total Specific Legal Questions: ", specificQuestions)

        // TODO: Check if I have to set it here and also update the parent component
        // setQuestions(specificQuestions)
        // setQuestionsToShow(specificQuestions.slice(-numQuestionsPerScreen))
        // setAnswersToShow([])

        // Update this info to the parent component
        // console.log("Specific Legal Questions to update globally: ", specificQuestions)
        // props.onLegalSpecificQuestions(specificQuestions)

        // Save information from user (TODO 3: append new questions)
        const docRef = doc(db, "leads", firestoreId);
        updateDoc(docRef, {
          specificLegalQuestions: newQuestions,
        }).then(() => {
          console.log(
            "Legal specific questions updated in Firebase with ID: ",
            firestoreId,
          );
        });
        return data;
      } else {
        console.log("Legal specific questions went wrong");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const generateIntakeDocs = async (status) => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlClientSubmittedIntake = apiHostname
        ? `https://${apiHostname}/api/clientSubmittedIntake`
        : "http://localhost:5001/api/clientSubmittedIntake";

      const response = await fetch(apiUrlClientSubmittedIntake, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firestoreId: firestoreId,
          status: status,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response:");
      } else {
        console.log("Got bad response");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const getFinalQuestions = async () => {
    // TODO: for now it is hard coded as we don't do doc analysis for intake anymore
    
    setFinalQuestions(finalQuestions);

    // Save information from user
    const docRef = doc(db, "leads", firestoreId);
    updateDoc(docRef, {
      finalQuestions: finalQuestions,
    }).then(() => {
      console.log("Final questions updated in Firebase with ID: ", firestoreId);
    });

    // TODO: in case we want to ask overall questions
    // try {
    //   const apiHostname = process.env.REACT_APP_API_HOSTNAME;
    //   const apiUrlGetFinalQuestions = apiHostname
    //     ? `https://${apiHostname}/api/getFinalQuestions`
    //     : "http://localhost:5001/api/getFinalQuestions";

    //   const response = await fetch(apiUrlGetFinalQuestions, {
    //     method: "post",
    //     headers: {
    //       "Content-Type": "application/json"
    //     },
    //     body: JSON.stringify({
    //       // Send list of docs uploaded by user, might be useful later
    //       docsListAddedUser: docsListAddedUser,
    //     })
    //   })

    //   if (response.ok) {
    //     const data = await response.json();
    //     console.log("Final Questions: ", data.finalQuestions);

    //     // TODO: update parent component with final questions
    //     // props.onFinalQuestions(data.finalQuestions);
    //     setFinalQuestions(data.finalQuestions);

    //     // Save information from user
    //     const docRef = doc(db, "leads", firestoreId);
    //     updateDoc(docRef, {
    //       finalQuestions: data.finalQuestions,
    //     }).then(() => {
    //         console.log("Final questions updated in Firebase with ID: ", firestoreId);
    //     })
    //     return data;

    //   } else {
    //     console.log("Final questions went wrong");
    //   }
    // } catch (error) {
    //   console.log("error: ", error)
    // }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // TODO: Update this so it modifies answers and if one has not being answered, it saves it as an empty string
    let allAnswers = answers;
    for (let i = 0; i < (count + 1) * numQuestionsPerScreen; i++) {
      if (allAnswers[i] === undefined || allAnswers[i] === null) {
        allAnswers[i] = "";
      }
    }
    setAnswers(allAnswers);

    // Access all the answers from the 'answers' state
    // const specificAnswers = Object.values(answers);
    // const specificAnswers = answersToShow;
    // console.log("All Answers: ", specificAnswers)

    // Set states for specific questions and answers
    console.log("Specific Legal Answers to update globally: ", allAnswers);
    // props.onLegalSpecificAnswers(specificAnswers);

    // Modify this to not save in case it isReadOnly
    if (submissionStatus === "Incomplete") {
      // Save information from user
      const docRef = doc(db, "leads", firestoreId);
      updateDoc(docRef, {
        specificLegalAnswers: allAnswers,
      }).then(() => {
        console.log(
          "Case details specific questions updated with ID: ",
          firestoreId,
        );
      });
    }

    if (count < numScreens - 1) {
      let newCount = count + 1;
      setCount(newCount);
      let startIndex = newCount * numQuestionsPerScreen;
      let endIndex = (newCount + 1) * numQuestionsPerScreen;

      // If no questions in this range, generate them
      if (questions.length < (newCount + 1) * numQuestionsPerScreen) {
        setQuestionsToShow([]); // Make it load again
        setAnswersToShow([]); // Empty the textarea for the new answers
        await getSpecificQuestions(
          generalCaseAnswer,
          idealResult,
          legalSubsubsector,
          questions,
          allAnswers,
          DBQuestions,
          firestoreId,
        );
        // After getSpecificQuestions resolves, call continueClientIntake
        await continueClientIntake(firestoreId, count);
        setIsFormValid(false);
      } else {
        // Set questions to show to the questions already generated
        setQuestionsToShow(questions.slice(startIndex, endIndex));
        if (answers.length >= (newCount + 1) * numQuestionsPerScreen) {
          setAnswersToShow(answers.slice(startIndex, endIndex)); // Show answers already generated
        } else {
          setAnswersToShow([]);
        }
      }
    } else {
      //TODO: Make sure all fields are filled out to allow the user to submit

      //TODO: Show that questions are loading until they are loaded

      // Send answers to backend to Generate List of Documents to upload
      if (!docsList.length) {
        getDocumentsToUpload(allAnswers);
      }

      if (finalQuestions.length === 0) {
        console.log("Getting final questions");
        getFinalQuestions();
      }

      // TODO: hourlyRate was saved before. Check if there is flatFee
      // TODO: make it not run again
      if (!flatFeeValue) {
        console.log("Calling backend to get projected pricing");
        getProjectedPricing(
          legalSector,
          legalSubsector,
          legalSubsubsector,
          generalCaseAnswer,
          idealResult,
          lawyerLawfirm,
          questions,
          allAnswers,
        );
      }

      if (submissionStatus === "Incomplete") {
        let status = "middle";
        generateIntakeDocs(status);
      }

      // TODO: update this so in case of shortIntake, it skips docs
      if (shortIntake === "short") {
        navigate("/intake/costprediction");
      } else {
        navigate("/intake/documentsupload");
      }

      mixpanel.track("User Submitted Case Details Specifics in Intake", {
        "Firebase User Id": firestoreId,
      });
    }
  };

  const handleCaddiClicked = () => {
    console.log("Caddi Button Clicked");
    navigate("/");
  };

  return (
    <>
      <div className="box dark-green-box">
        <div className="columns is-mobile">
          <div className="box white-box column is-3">
            <NavbarIntake
              isSignedIn={isSignedIn}
              isLawyer={isLawyer}
              lawyerLawfirm={lawyerLawfirm}
            />
          </div>
          <div className="box light-green-box column">
            <div className="columns is-centered">
              <div className="column is-2"></div>

              <div className="column">
                <div
                  className="title is-4 has-text-centered"
                  ref={componentRef}
                >
                  {legalSubsubsector} - Case Intake
                </div>
                <div className="subtitle is-6 has-text-centered">
                  Please answer the following questions to the best of your
                  ability.
                </div>

                <form onSubmit={handleSubmit}>
                  {questionsToShow.length ? (
                    questionsToShow.map((question, index) => (
                      <SpecificQuestion
                        key={index}
                        id={index}
                        question={question}
                        handleInputChange={handleInputChange}
                        value={answersToShow[index] || ""}
                        submissionStatus={submissionStatus}
                      />
                    ))
                  ) : (
                    <div>
                      <br></br>
                      <br></br>
                      <div className="box has-text-centered">
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          size="2x"
                          className="mr-4"
                        />
                        Analyzing case and generating questions... Please
                        refresh page if waiting for over a minute.
                      </div>
                      <br></br>
                      <br></br>
                      <br></br>
                    </div>
                  )}
                </form>
              </div>

              <div className="column is-2"></div>
            </div>

            <NavbarFooter
              handleBackClick={handleBackClick}
              handleNextClick={handleSubmit}
              isFormValid={isFormValid}
            />
          </div>
        </div>
        <FooterIntake />
      </div>
    </>
  );
}

export default CaseDetailsSpecific;
