import React from "react";

const ClientDetails = (props) => {
  return (
    <>
      <p className="subtitle mb-3">Contact Info</p>
      <div className="">
        <p className="subsubtitle">
          <span className="has-text-grey-light">Company: </span>
          {props.company || ""}
        </p>

        <p className="subsubtitle">
          <span className="has-text-grey-light">Phone: </span>
          {props.phone || ""}
        </p>

        <p className="subsubtitle">
          <span className="has-text-grey-light">Email: </span>
          {props.email || ""}
        </p>

        <p className="subsubtitle">
          <span className="has-text-grey-light">Address: </span>
          {props.address || ""}
        </p>
      </div>
    </>
  );
};

export default ClientDetails;
