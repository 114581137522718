import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { db } from "../config/firebase-config"; 
import mixpanel from "mixpanel-browser"; 
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  doc,
  updateDoc,
  limit
} from "firebase/firestore";
import "./InvitationBox.css";


const InvitationBox = ({ isOpen, onRequestClose, lawyerLawfirm }) => {
  const [activeTab, setActiveTab] = React.useState('clientInquiry');

  const [clientType, setClientType] = useState("Potential New Client"); // New state for client type
  const [searchFirstName, setSearchFirstName] = useState("");
  const [searchLastName, setSearchLastName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [firestoreId, setFirestoreId] = useState(""); 
  const [searchCompany, setSearchCompany] = useState("");
  const [matter, setMatter] = useState("");
  const [customization, setCustomization] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isFormValid, setIsFormValid] = useState(true);
  const [showCreateInquiry, setShowCreateInquiry] = useState(true);
  const [showPersonalizeIntake, setShowPersonalizeIntake] = useState(true);
  const [clientSearch, setClientSearch] = useState("");
  const [clientSearchResults, setClientSearchResults] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [selectedAreas, setSelectedAreas] = useState({
    generalInfo: false,
    clientDetails: false,
    caseDetails: false,
    aiFollowUpQuestions: true,
    documents: true,
    pricingScreen: false,
    finalQuestions: true,
  });

  const [selectedAreasNames, setSelectedAreasNames] = useState({
    generalInfo: "Contact Information",
    clientDetails: "Context (Parties involved...)",
    caseDetails: "Legal Sector Questions",
    aiFollowUpQuestions: "AI Follow Up Questions",
    documents: "Upload Documents",
    pricingScreen: "Pricing Screen",
    finalQuestions: "Questions to Law firm",
  });


  // useEffect hook for live search
  useEffect(() => {
    const handleSearch = async () => {
      if (!clientSearch.trim()) {
        setClientSearchResults([]); // Clear results if search is empty
        return;
      }

      console.log("Live search for:", clientSearch);

      const clientsRef = collection(db, "leads");
      let queries = [];
      const searchTerms = clientSearch.trim().split(" ");

      if (searchTerms.length === 1) {
        const term = searchTerms[0];
        queries.push(query(clientsRef, where("name", "==", term), limit(3)));
        queries.push(query(clientsRef, where("LastName", "==", term), limit(3)));
      } else if (searchTerms.length > 1) {
        const [firstName, lastName] = searchTerms;
        queries.push(query(clientsRef, where("name", "==", firstName), where("LastName", "==", lastName), limit(3)));
      }

      let results = [];
      try {
        for (let q of queries) {
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            results.push({ ...data, docId: doc.id });
          });
        }

        console.log("Live search results: ", results);
        setClientSearchResults(results);
        setShowResults(true);
      } catch (error) {
        console.error("Error searching clients: ", error);
      }
    };

    handleSearch();
  }, [clientSearch]); // Dependency array includes clientSearch to trigger the effect on its change


  const handleAreaToggle = (area) => {
    setSelectedAreas((prevSelectedAreas) => ({
      ...prevSelectedAreas,
      [area]: !prevSelectedAreas[area],
    }));
  };

  const handleTogglePersonalizeIntake = () => {
    setShowPersonalizeIntake(!showPersonalizeIntake);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const sendInvitationByEmail = () => {
    console.log("Sending invitation via email...");
    console.log("Email:", email);

    // TODO: Logic to send invitation using the email provided (and then enable the button)

    // After sending the invitation, close the modal
    onRequestClose();
  };

  const sendInvitationByPhone = () => {
    console.log("Sending invitation via phone...");
    console.log("Phone Number:", phoneNumber);

    // TODO: Logic to send invitation using the phone number provided (and then enable the button)

    // After sending the invitation, close the modal
    onRequestClose();
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
  
    // Update state based on the input field's id
    switch (id) {
      case "FirstName":
        setSearchFirstName(value);
        break;
      case "LastName":
        setSearchLastName(value);
        break;
      case "email":
        setSearchEmail(value);
        // Validate email format only for the email input
        if (value.includes("@")) {
          console.log("Email has the right format");
          setIsFormValid(true);
        } else {
          console.log("Email does not have the right format");
          setIsFormValid(false);
        }
        break;
      case "company":
        setSearchCompany(value);
        break;
      case "Matter":
        // Assuming you have a state for Matter, if not, you should add one
        setMatter(value);
        break;
      case "focusCustomization":
      case "aiCustomization":
        // Assuming you handle these similarly, adjust as necessary
        setCustomization(prev => ({ ...prev, [id]: value }));
        break;
      default:
        console.log("No matching id for input change");
    }
  };
  


  const handleSearch = async () => {
    console.log("Search button clicked");

    setShowResults(false); // Reset results display on new search
    const searchTerms = clientSearch.trim().split(" "); // Trim leading/trailing spaces and split by space
    const clientsRef = collection(db, "leads");
    let queries = [];

    // Check if searchTerms includes one or two names
    if (searchTerms.length === 1) {
        // Apply search for both first and last names
        const term = searchTerms[0];
        queries.push(query(clientsRef, where("name", "==", term), limit(3)));
        queries.push(query(clientsRef, where("LastName", "==", term), limit(3)));
    } else if (searchTerms.length > 1) {
        // Assume the first two entries are first and last names
        const [firstName, lastName] = searchTerms;
        queries.push(query(clientsRef, where("name", "==", firstName), where("LastName", "==", lastName), limit(3)));
    }

    try {
        let results = [];
        for (let q of queries) {
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                // Add a 'docId' property if you need to uniquely identify or use these documents later
                results.push({ ...data, docId: doc.id });
            });
        }

        console.log("Search results: ", results);
        setClientSearchResults(results);
        setShowResults(true); // Display results after search
    } catch (error) {
        console.error("Error searching clients: ", error);
        // Handle error (e.g., show an error message)
    }
};


const handleClientSelect = (client) => {

  setSearchFirstName(client.name); // Update state for first name field
  setSearchLastName(client.LastName); // Update state for last name field
  setSearchEmail(client.email); // Update state for email field
  setSearchCompany(client.company); // Update state for company field
  setFirestoreId(client.docId); // Set the Firestore ID for the selected client
  setMatter(client.matter); // Update state for matter field
  setSelectedClient(client); // Keep track of the selected client for highlighting
};




const handleSubmit = async (e) => {
  e.preventDefault();

  let name = document.getElementById("FirstName").value || "";
  let LastName = document.getElementById("LastName").value || "";
  let company = document.getElementById("company").value || "";
  let email = document.getElementById("email").value || "";
  let focusCustomization = document.getElementById("focusCustomization")?.value || "";
  let aiCustomization = document.getElementById("aiCustomization")?.value || "";

  
  const secondIntake = clientType === "Existing Client";


  // Function to handle the invitation logic
  const handleInvitation = async (id, isNew) => {
    let personalizedIntakeLink = `caddi.app/intake/${lawyerLawfirm}/${id}`;
    // Define the message content here
    const subject = `${lawyerLawfirm} has invited you to fill an intake form`;
    const message = `Hi ${name},\n\nThank you for reaching out to ${lawyerLawfirm}. Please fill the following intake form so we can learn more about your request. Click on the following link to start: ${personalizedIntakeLink}`;
    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;

    window.open(mailtoUrl, "_blank");

    mixpanel.track("Lead created", {
      "Firebase User Id": id,
      Lawfirm: lawyerLawfirm,
      "Intake Link": personalizedIntakeLink,
    });

    // Update Firestore document with intakeLink, only if not a new document
    if (!isNew) {
      const docRefFirestore = doc(db, "leads", id);
      await updateDoc(docRefFirestore, {
        intakeLink: personalizedIntakeLink,
      }).then(() => {
        console.log("Lead updated with intakeLink: ", personalizedIntakeLink);
      }).catch((error) => {
        console.error("Error updating lead with intakeLink: ", error);
      });
    }
  };

  if (firestoreId) {
    // Update existing document with the provided Firestore ID
    const docRef = doc(db, "leads", firestoreId);
    await updateDoc(docRef, {
      name,
      LastName,
      company,
      email,
      customization: {
        focusCustomization,
        aiCustomization,
        screenSelectedAreas: selectedAreas,
        secondIntake: secondIntake // Use determined value
      },
      lawfirm: lawyerLawfirm,
    }).then(() => {
      console.log("Lead updated with Firestore ID: ", firestoreId);
      handleInvitation(firestoreId, false);
    }).catch((error) => {
      console.error("Error updating lead: ", error);
    });
  } else {
    // Create a new document in Firestore and use its ID
    const docRef = await addDoc(collection(db, "leads"), {
      name,
      LastName,
      company,
      email,
      customization: {
        focusCustomization,
        aiCustomization,
        screenSelectedAreas: selectedAreas,
        secondIntake: secondIntake // Use determined value
      },
      lawfirm: lawyerLawfirm,
      submissionStatus: "Waiting",
      createdDate: new Date().toISOString(),
    });
    console.log("Lead saved with Firestore ID: ", docRef.id);
    setFirestoreId(docRef.id); // Set the Firestore ID state with the new document ID for future reference
    handleInvitation(docRef.id, true);
  }
};


 
  return (
    <>
      {isOpen && (
        <div className="modal is-active is-clipped">
          <div className="modal-background" onClick={onRequestClose} style={{ backdropFilter: "blur(5px)", backgroundColor: "transparent" }}></div>
          <div className="modal-card" style={{ overflow: "hidden", borderRadius: "8px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", width: "80vw", maxWidth: "600px", margin: "auto" }}>
            <header className="modal-card-head" style={{ backgroundColor: "white", borderBottom: "none", padding: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <p className="modal-card-title">Create Intake </p>
              <button className="delete" aria-label="close" onClick={onRequestClose} style={{ background: "transparent", border: "none" }}></button>
            </header>

            <div className="modal-card-body" style={{ backgroundColor: "white", padding: '20px', overflowY: 'auto', maxHeight: '90%' }}>
              
              {/* Tabs for switching views */}
              <div className="tabs is-boxed is-fullwidth">
                <ul>
                  <li className={activeTab === 'clientInquiry' ? 'is-active' : ''} onClick={() => setActiveTab('clientInquiry')}>
                    <a style={{ cursor: "pointer" }}>Existing Client & Inquiry</a>
                  </li>
                  <li className={activeTab === 'personalizeIntake' ? 'is-active' : ''} onClick={() => setActiveTab('personalizeIntake')}>
                    <a style={{ cursor: "pointer" }}>Personalize Intake</a>
                  </li>
                </ul>
              </div>

           {/* Tab content */}
           {activeTab === 'clientInquiry' && (
                <>
                   {/* Existing Client Section */}
              <div className="box">
              <p className="title is-5" >
                1. Choose Existing Client Type:
                </p>
                <div className="buttons has-addons">
                  <button className={`button ${clientType === "Potential New Client" ? "is-success" : ""}`} onClick={() => setClientType("Potential New Client")}>
                    Potential New Client
                  </button>
                  <button className={`button ${clientType === "Existing Client" ? "is-success" : ""}`} onClick={() => setClientType("Existing Client")}>
                    Existing Client
                  </button>
                </div>
  
                {clientType === "Existing Client" && (
                  <>
                    <div className="field has-addons">
                      <div className="control is-expanded">
                        <input
                          type="text"
                          className="input"
                          placeholder="Search client by name"
                          value={clientSearch}
                          onChange={(e) => setClientSearch(e.target.value)}
                        />
                      </div>
                      <div className="control">
                        <button className="button is-success" onClick={handleSearch}>Search</button>
                      </div>
                    </div>
                    {showResults && clientSearchResults.map((client, index) => (
                   <div 
                   key={index} 
                   className={`box ${selectedClient && selectedClient.docId === client.docId ? 'is-selected' : ''}`} 
                   style={{ backgroundColor: selectedClient && selectedClient.docId === client.docId ? 'rgba(72, 201, 176, 0.6)' : '' }} 
                   onClick={() => handleClientSelect(client)}
                 >
                      <p><strong>Name:</strong> {client.name} {client.LastName}</p>
                      <p><strong>Sector:</strong> {client.legalSubsubsector || 'N/A'}</p>
                    </div>
                  ))}
                                    </>
                )}
              </div>
                  
                  {/* Create Inquiry Section */}
              <div className="box">
                <p className="title is-5">
                  2. Create Inquiry
        
                </p>
                {showCreateInquiry && (
                  <div>
                    <div className="field is-horizontal">
                  <div className="field-label">
                    <label className="label has-text-left" htmlFor="Company">
                      First Name
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                      <input
                          className="input"
                          type="text"
                          id="FirstName"
                          value={searchFirstName}
                          onChange={handleInputChange}
                          placeholder="First Name"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label">
                    <label className="label has-text-left" htmlFor="Company">
                      Last Name
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                      <input
                        className="input"
                        type="text"
                        id="LastName"
                        value={searchLastName} // Bind to state
                        onChange={handleInputChange}
                        placeholder = "Last Name"
                      />
                      </div>
                    </div>
                  </div>
                </div>

                    <div className="field is-horizontal">
                  <div className="field-label">
                    <label className="label has-text-left" htmlFor="Company">
                      Company
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                      <input
                        className="input"
                        type="text"
                        id="company"
                        value={searchCompany} // Bind to state
                        onChange={handleInputChange}
                        placeholder="Company Name"
                      />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label">
                    <label className="label has-text-left" htmlFor="email">
                      Email*
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                      <input
                        className="input"
                        type="text"
                        id="email"
                        value={searchEmail} // Bind to state
                        onChange={handleInputChange}
                        placeholder="Email Address"
                      />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label">
                    <label className="label has-text-left" htmlFor="Company">
                      Matter
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                      <input
                        className="input"
                        type="text"
                        id="Matter"
                        value= {matter} // Bind to state
                        onChange={handleInputChange}
                        placeholder="Matter Description" //TODO: This shoudl be improved to have an example for the lawyer to better understand the matter
                      />
                      </div>
                    </div>
                  </div>
                </div>


                  </div>
                )}
              </div>

                </>
              )}

              {activeTab === 'personalizeIntake' && (
                <>
                  
              {/* Personalize Intake Section */}
              <div className="box">
                <p className="title is-5">
                  3. Personalize Intake
                 
                </p>
                {showPersonalizeIntake && (
                  <div style={{ height: "auto"}}>


                  <div className="field is-horizontal">
                      <div className="field-label">
                        <label className="label has-text-left" htmlFor="">
                          Focus
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="focusCustomization"
                              className="input mb-2"
                              id="focusCustomization"
                              onChange={handleInputChange}
                              placeholder = "Contract Review, Employment Dispute, etc."
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label">
                        <label className="label has-text-left" htmlFor="">
                          AI Customization
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="aiCustomization"
                              className="input mb-2"
                              id="aiCustomization"
                              onChange={handleInputChange}
                              placeholder = "Types of questions, styles, etc." //TODO: This is really an amphorous field, we should have a better example from Aashrit or delete if this cannot be found
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>
                    {Object.keys(selectedAreas).map((area) => (
                      <div className="field" key={area}>
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            checked={selectedAreas[area]}
                            onChange={() => handleAreaToggle(area)}
                          />
                          &nbsp; {selectedAreasNames[area]}
                        </label>
                      </div>
                    ))}
                  </div>


                )}
              </div>

                </>
              )}

            </div>

            {/* Send Button */}
            <div className="modal-card-foot" style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
              <button
                className="button is-success"
                onClick={handleSubmit}
                disabled={!isFormValid}
              >
                Send
              </button>
            </div>

          </div>
        </div>
      )}
    </>
  );
};

export default InvitationBox;