import React, { useState } from "react";
import axios from "axios";
//import logo from "./logo.svg"
//import "./App.css"
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
} from "firebase/auth";
// import { adminAuth } from "./firebase-config";
import mixpanel from "mixpanel-browser";

import "./GoogleButton.css";
import useLocalStorage from "../components/useLocalStorage";
import { send } from "process";

const LoginPage = (props) => {
  const navigate = useNavigate();
  const [showPopupError, setShowPopupError] = useState("");
  const [verificationEmailMessage, setVerificationEmailMessage] = useState("");
  const [verificationEmailVisible, setVerificationEmailVisible] =
    useState(false);
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [forgotPasswordMessage, setForgotPasswordMessage] = useState("");
  const [firestoreId, setFirestoreId] = useLocalStorage("firestoreId", "");

  const [resendVerificationVisible, setResendVerificationVisible] =
    useState(false);
  const [resendEmailInput, setResendEmailInput] = useState("");
  const [idToken, setIdToken] = useState("");

  //const [sessionOwner, setSessionOwner] = useState([])
  let user_id = null;
  let error = "";

  const checkboxHandler = () => {
    setShowPopupError("");
  };

  const checkboxHandlerVerify = () => {
    setResendVerificationVisible(false);
  };

  // Send the idtoken to backend to decode and create cookie. Return the user_id
  const sendIdTokenToBackend = async (idtoken) => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlLogin = apiHostname
        ? `https://${apiHostname}/api/login`
        : "http://localhost:5001/api/login";

      const response = await fetch(apiUrlLogin, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idtoken: idtoken,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Got back ok response");
        //console.log("user_id: ", data.user_id);
        // Change the sign-in status and navigate to the user-home
        props.onLogin(data.user_id);

        //TODO: navigate to screen where user selects the intake or new
        navigate("/intake/sessions");
        // navigate("/intake/disclaimer");

        mixpanel.identify(data.user_id);
        mixpanel.track("User Logged in", { "Firebase User Id": firestoreId });
        return data.user_id;
      } else {
        console.log("Login went wrong");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const loginWithEmail = (e) => {
    e.preventDefault();
    // I should update this with "StyledFirebaseAuth" so I don't have to read the password manually
    const auth = getAuth();
    const emailText = e.target.email.value;
    const passwordText = e.target.password.value;
    e.target.email.value = "";
    e.target.password.value = "";
    signInWithEmailAndPassword(auth, emailText, passwordText)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        //console.log("user log in: ", user.uid);
        const idtoken = user.getIdToken().then((idtoken) => {
          //console.log("idtoken: ", idtoken);

          // Send the idtoken to backend to decode and create cookie
          // Check if user is logged in and email is verified (put this in the log in)
          onAuthStateChanged(auth, (user) => {
            if (user) {
              if (user.emailVerified) {
                console.log("User is logged in and email is verified.");
                // Proceed with signed-in user logic
                user_id = sendIdTokenToBackend(idtoken);
              } else {
                console.log("User is logged in but email is not verified.");
                // Handle scenario where email is not verified
                //window.alert("Please verify your email and try again")
                // const errorMessage = "Please verify your email and try again"
                // setShowPopupError(errorMessage);
                // Handle scenario where email is not verified
                setResendVerificationVisible(true);
                setResendEmailInput(user.email);
                setIdToken(idtoken);
              }
            } else {
              console.log("User is not logged in.");
              // Handle scenario where user is not logged in
            }
          });
        });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("error: ", errorCode, errorMessage);
        //window.alert(errorMessage);
        setShowPopupError(errorMessage);
      });
  };

  const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const accesstoken = credential.accessToken;

        // The signed-in user info.
        const user = result.user;
        console.log("user log in: ", user.uid);

        // Send Firebase ID token to backend
        const idtoken = user.getIdToken().then((idtoken) => {
          //console.log("idtoken: ", idtoken);

          // Send the idtoken to backend to decode and create cookie
          user_id = sendIdTokenToBackend(idtoken);
        });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("error: ", errorCode, errorMessage);
        //window.alert(errorMessage);;
        setShowPopupError(errorMessage);
      });
  };

  const handleSignUp = () => {
    console.log("Sign Up Button Clicked");
    navigate("/intake/signup");
    mixpanel.track(
      "User went from submission confirmation to sign up to Caddie",
    );
  };

  const sendVerificationEmail = async () => {
    try {
      const apiKey = "AIzaSyAdxzD6KOOHtNiwXHeF_4S1v2CTbClF24Q"; // Replace with your Firebase Web API Key

      // const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;

      const apiUrl = `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${apiKey}`;

      const requestBody = {
        requestType: "VERIFY_EMAIL",
        idToken: idToken,
      };

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();

      if (response.ok) {
        console.log("Verification email sent:", responseData);
        setVerificationEmailMessage(
          "Verification email sent. Please check your email.",
        );
        setResendVerificationVisible(false);
      } else {
        console.error(
          "Error sending verification email:",
          responseData.error.message,
        );
        setVerificationEmailMessage(
          "Error sending verification email. Please try again.",
        );
      }
    } catch (error) {
      console.error("Error sending verification email:", error.message);
    }
  };

  // const resendVerificationEmail = () => {
  //   const auth = getAuth();
  //   const user = auth.currentUser;

  //   if (user) {
  //     user.sendEmailVerification()
  //       .then(() => {
  //         setVerificationEmailMessage("Verification email sent. Please check your email.");
  //       })
  //       .catch((error) => {
  //         console.error("Error sending verification email: ", error.message);
  //         setVerificationEmailMessage("Error sending verification email. Please try again.");
  //       });
  //   }
  // };

  // const handleVerificationEmailSubmit = () => {
  //   // Add logic to handle the submitted email for verification
  //   console.log("Email submitted for verification: ", emailInput);
  //   // Add any additional logic or API calls here
  //   setVerificationEmailVisible(false);
  //   setEmailInput("");
  // };

  return (
    <>
      <div className="box dark-green-box">
        <div className="columns is-mobile">
          <div
            className="box white-box column is-4"
            style={{
              background: `url('/SignUpImageCaddi.png')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>

          <div
            className="box light-green-box column"
            style={{ height: "100vh" }}
          >
            <div className="columns">
              <div className="column is-3"></div>
              <div className="column has-text-centered">
                <div className="subtitle is-2 has-text-centered">
                  <img
                    src="/CaddiLogo.png"
                    alt="Caddi Logo"
                    className="mr-2"
                    style={{ height: "30px", width: "auto" }}
                  />
                  Caddi
                </div>
                <br></br>
                <div className="title is-4">
                  Log in to <span className="has-text-success">Caddi</span>
                </div>

                <button
                  type="button"
                  className="login-with-google-btn is-clickable"
                  onClick={loginWithGoogle}
                >
                  <div className="my-1">
                    <img
                      src="https://img.icons8.com/color/48/000000/google-logo.png"
                      alt="google icon"
                      className="mr-2"
                      style={{
                        height: "20px",
                        width: "auto",
                        verticalAlign: "middle",
                        marginBottom: "5px",
                      }}
                    />
                    <span className="">Log in with Google</span>
                  </div>
                </button>

                <br></br>
                <br></br>
                <div className="divider mb-1"></div>

                <form onSubmit={loginWithEmail}>
                  <br></br>
                  <p className="subtitle"> or log in with email</p>
                  <label className="label has-text-left" htmlFor="username">
                    Email
                  </label>
                  <input name="email" className="input" />
                  <label
                    className="label has-text-left mt-2"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input name="password" className="input" type="password" />
                  <div>
                    <br></br>
                    <input
                      type="submit"
                      className="button is-success is-fullwidth"
                      value="Log in with email"
                    />
                  </div>
                </form>
              </div>

              <div className="column is-3">
                {/*Button to let you go to sign up*/}
                <button
                  className="button is-success is-pulled-right mr-6"
                  id="nextButton"
                  onClick={handleSignUp}
                >
                  {" "}
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPopupError && (
        <div className="modal is-active">
          <div className="modal-background" onClick={checkboxHandler}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Error Logging in</p>
              <button
                className="delete"
                aria-label="close"
                onClick={checkboxHandler}
              ></button>
            </header>
            <section className="modal-card-body">{showPopupError}</section>
          </div>
        </div>
      )}

      {resendVerificationVisible && (
        <div className="modal is-active">
          <div
            className="modal-background"
            onClick={checkboxHandlerVerify}
          ></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Resend Verification Email</p>
              <button
                className="delete"
                aria-label="close"
                onClick={checkboxHandlerVerify}
              ></button>
            </header>
            <section className="modal-card-body">
              <p className="has-text-left">
                Your email ({resendEmailInput}) is not verified.{" "}
              </p>
              <button
                className="button is-success mt-2"
                onClick={sendVerificationEmail}
              >
                Resend Verification Email
              </button>
              {/* Add a message to the user once they press resend:  verificationEmailMessage */}
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginPage;
