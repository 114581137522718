// Disclaimer.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  getFirestore,
  getDoc,
} from "firebase/firestore";
import { db } from "../config/firebase-config";
import IntakeSideMenu from "../components/IntakeSideMenu";
import mixpanel from "mixpanel-browser";
import NavbarIntake from "../components/NavbarIntake";
import NavbarFooter from "../components/NavbarFooter";
import FooterIntake from "../components/FooterIntake";
import "./WelcomeIntake.css";

import useLocalStorage from "../components/useLocalStorage";

function CostPrediction(props) {
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const [firestoreId, setFirestoreId] = useLocalStorage("firestoreId", "");
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);
  const [submissionStatus, setSubmissionStatus] = useLocalStorage(
    "submissionStatus",
    "Incomplete",
  );

  const [hourlyRate, setHourlyRate] = useLocalStorage("hourlyRate", "");
  const [flatFeeMatterChosen, setFlatFeeMatterChosen] = useLocalStorage(
    "flatFeeMatterChosen",
    "",
  );
  const [flatFeeValue, setFlatFeeValue] = useLocalStorage("flatFeeValue", "");

  const [shortIntake, setShortIntake] = useLocalStorage("shortIntake", "");

  const isReadOnly = submissionStatus === "Submitted";

  useEffect(() => {
    console.log("Loading Cost Screen");
  }, []);

  // TODO: Check if it's needed to add hook to update the hourly rate

  const checkboxHandler = () => {
    const checkbox = document.querySelector("#agree");
    if (!isFormValid) {
      checkbox.checked = true;
      // Enable Next Button
      setIsFormValid(true);
    } else {
      checkbox.checked = false;
      // Disable Next Button
      setIsFormValid(false);
    }

    // TODO give option to user to finish intake if they don't agree
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/intake/finalquestions");
  };

  const handleCaddiClicked = () => {
    console.log("Caddi Button Clicked");
    navigate("/");
  };

  const handleBackClick = () => {
    // TODO: update this so in case of shortIntake, it skips docs
    if (shortIntake === "short") {
      navigate("/intake/casedetailsspecific");
    } else {
      navigate("/intake/halfway");
    }
  };

  return (
    <>
      <div className="box dark-green-box">
        <div className="columns is-mobile">
          <div className="box white-box column is-3">
            <NavbarIntake
              isSignedIn={isSignedIn}
              isLawyer={isLawyer}
              lawyerLawfirm={lawyerLawfirm}
            />
          </div>
          <div className="box light-green-box column">
            <div className="columns is-centered">
              <div className="column is-2"></div>
              <div className="column">
                <div className="title is-4 has-text-centered mt-2">
                  Estimated cost of your request
                </div>
                <div className="subtitle is-6">
                  Please note that all numbers are not final and represent a
                  rough fee for our services. Each case is different and numbers
                  may vary depending on your specific situation.
                </div>

                {/* Here Hourly rate */}
                <p className="has-text-weight-bold">Hourly Rate</p>
                <div className="divider"></div>
                <p>
                  <span className="has-text-weight-bold has-text-success title is-2">
                    {" "}
                    {hourlyRate || "TBD"}{" "}
                  </span>{" "}
                  per hour
                </p>

                <br></br>
                <br></br>

                {/* Here Flat fee if there is */}
                {flatFeeMatterChosen ? (
                  <>
                    <p className="has-text-weight-bold">Flat Fees</p>
                    <div className="divider"></div>
                    <div className="columns is-mobile">
                      <div className="column is-9">
                        <p>{flatFeeMatterChosen || "N.A."}</p>
                        {/* <p>{"C Corp Formation"}</p> */}
                      </div>
                      <div className="column">
                        <p>
                          <span className=""> ${flatFeeValue || "N.A."} </span>
                        </p>
                        {/* <p>{"$3500 - $4800"}</p> */}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                  </>
                )}

                <br></br>
                <br></br>
                <br></br>
                <br></br>

                {/* Here goes check box */}
                <div className="has-text-centered">
                  <input
                    type="checkbox"
                    id="agree"
                    onChange={checkboxHandler}
                  />
                  <label htmlFor="agree">
                    {" "}
                    I acknowledge these prices as estimates for provided
                    services and that the final pricing is subject to changes.
                  </label>
                </div>
              </div>
              <div className="column is-2"></div>
            </div>
            <NavbarFooter
              handleBackClick={handleBackClick}
              handleNextClick={handleSubmit}
              isFormValid={isFormValid}
            />
          </div>
        </div>
        <FooterIntake />
      </div>
    </>
  );
}

export default CostPrediction;
