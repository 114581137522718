import React, { useState, useEffect } from "react";
import "./NavbarFooter.css";
import { useLocation } from "react-router-dom";

const NavbarFooter = (props) => {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [continueButtonText, setContinueButtonText] = useState("Continue");
  const totalPages = 10;

  useEffect(() => {
    let currentScreen = location.pathname;
    // switch (currentScreen) {
    //   case "/intake/disclaimer":
    //     setCurrentPage(1)
    //     break;
    //   case "/intake/generalinformation":
    //     setCurrentPage(2)
    //     break;
    //   case "/intake/casedetails":
    //     setCurrentPage(3)
    //     break;
    //   case "/intake/clientdetails":
    //     setCurrentPage(4)
    //     break;
    //   case "/intake/legalSector":
    //     setCurrentPage(5)
    //     break;
    //   case "/intake/casedetailsspecific":
    //     setCurrentPage(6)
    //     break;
    //   case "/intake/documentsupload":
    //     setCurrentPage(7)
    //     break;
    //   case "/intake/halfway":
    //     setCurrentPage(8)
    //     break;
    //   case "/intake/finalquestions":
    //     setCurrentPage(9)
    //     break;
    //   case "/intake/reviewandsecurity":
    //     setCurrentPage(10)
    //     break;
    //   default:
    //     setCurrentPage(1)
    // }

    if (currentScreen === "/intake/reviewandsecurity") {
      setContinueButtonText("Submit");
    } else {
      setContinueButtonText("Continue");
    }
  }, [location.pathname]);

  // Generate circles to represent each page
  // const renderPageCircles = () => {
  //   const circles = [];
  //   for (let i = 1; i <= totalPages; i++) {
  //     circles.push(
  //       <span
  //         key={i}
  //         className={`circle ${i === currentPage ? "active" : ""}`}
  //       ></span>
  //     );
  //   }
  //   return circles;
  // };

  // Calculate progress percentage for the progress bar
  // const progressPercentage = (currentPage / totalPages) * 100;

  // TODO: Check if form is valid
  // if (props.isFormValid) {
  //   //console.log("Form is valid");
  // }

  return (
    // <div className="section">
    //   <div className="divider">
    //     <br></br>
    //     <p className="has-text-weight-bold has-text-centered">For customer support call: <a href="tel:+19296060056"> 929-606-0056</a> </p>
    //     <br></br>
    //   </div>
    // </div>
    <>
      <div className="columns">
        <div className="column is-2"></div>
        <div className="column">
          <div className="divider"></div>
          <div
            className="button"
            style={{ border: "none", background: "rgba(255, 255, 255, 0)" }}
            id="backButton"
            onClick={props.handleBackClick}
          >
            {" "}
            {"<  Back"}
          </div>

          {/* <div className="column page-progress has-text-centered">
          {renderPageCircles()}
        </div> */}

          <button
            className="button is-success has-text-white is-pulled-right"
            id="nextButton"
            onClick={props.handleNextClick}
            disabled={!props.isFormValid}
          >
            {continueButtonText}
          </button>
        </div>
        <div className="column is-2"></div>
      </div>

      {/* <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div> */}
    </>
  );
};

export default NavbarFooter;
