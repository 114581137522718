// CaseDetails.js

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  getFirestore,
  getDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../config/firebase-config";
import mixpanel from "mixpanel-browser";
import NavbarFooter from "../components/NavbarFooter";
import IntakeSideMenu from "../components/IntakeSideMenu";
import NavbarIntake from "../components/NavbarIntake";
import SectorCard from "../components/SectorCard";
import FooterIntake from "../components/FooterIntake";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import useLocalStorage from "../components/useLocalStorage";

function LegalSectorConfirmation(props) {
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const [firestoreId, setFirestoreId] = useLocalStorage("firestoreId", "");
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);

  const [legalSector, setLegalSector] = useState(
    localStorage.getItem("legalSector") || "",
  );
  const [legalSubsector, setLegalSubsector] = useState(
    localStorage.getItem("legalSubsector") || "",
  );
  const [legalSubsubsector, setLegalSubsubsector] = useState(
    localStorage.getItem("legalSubsubsector") || "",
  );

  const [newLegalSubsubsector, setNewLegalSubsubsector] = useState(
    localStorage.getItem("legalSector") || "",
  );
  // const [legalSubsubsectorExists, setLegalSubsubsectorExists] = useState("");
  const [lawfirmLegalSectors, setLawfirmLegalSectors] = useLocalStorage(
    "lawfirmLegalSectors",
    [],
  );
  const [lawfirmLegalSectorsFinal, setLawfirmLegalSectorsFinal] = useState([]);
  const [hourlyRate, setHourlyRate] = useLocalStorage("hourlyRate", "");
  const [showPopup, setShowPopup] = useState(false);

  // Read legal subsector lawfirm practices
  useEffect(() => {
    console.log("Load Legal Sector Confirmation Screen");
    console.log("User FirestoreId: ", firestoreId);

    // Set it globally here not to have to pass it down

    // TODO: Reading lawfirms info should be done from the first screen and passed to all components
    if (lawfirmLegalSectors.length > 0) {
      // if (props.listLegalSectorsLawyer.length > 0) {
      //   setLawfirmLegalSectors(props.listLegalSectorsLawyer);
    } else {
      // Use law firm name to load the subsectors that the law firm practices
      if (lawyerLawfirm) {
        console.log("Lawyer Lawfirm: ", lawyerLawfirm);
        // Get the lawfirm document which name matches the lawyerLawfirm
        const lawfirmRef = collection(db, "lawyers");
        const q = query(
          lawfirmRef,
          where("lawyerLawfirm", "==", lawyerLawfirm),
        );
        getDocs(q).then((querySnapshot) => {
          // TODO: Only take the first document that matches
          querySnapshot.forEach((doc) => {
            console.log("Lawfirm document: ", doc.id, " => ", doc.data());
            // Set the state of the fields
            if (doc.data().listLegalSectors) {
              console.log("Legal Subsector exists");
              setLawfirmLegalSectors(doc.data().listLegalSectors);

              if (doc.data().hourlyRate) {
                console.log("Hourly Rate exists");
                setHourlyRate(doc.data().hourlyRate);
              }
            }
          });
        });
        // }
      }
    }
  }, []);

  // Hook to update legalSector when it changes in localStorage
  useEffect(() => {
    let variableToTrack = "legalSector";

    const handleStorageChange = (event) => {
      setLegalSector(event.detail.value);
      console.log(
        `Storage event received for key: ${variableToTrack}. Updated value:`,
        event.detail.value,
      );
    };
    // Listen for changes in localStorage specific to the 'myVariable' key
    window.addEventListener(`storage_${variableToTrack}`, handleStorageChange);
    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener(
        `storage_${variableToTrack}`,
        handleStorageChange,
      );
    };
  }, []);

  // Loading until we have Legal Subsector
  useEffect(() => {
    if (legalSector) {
      // setLegalSubsubsectorExists(legalSubsubsector);
      setIsFormValid(true);
      setNewLegalSubsubsector(legalSector);
      console.log(
        "Legal Sector: ",
        legalSector,
        " Legal Subsector: ",
        legalSubsector,
        " Legal Subsubsector: ",
        legalSubsubsector,
      );
    }
  }, [legalSector]);

  const handleBackClick = () => {
    navigate("/intake/clientdetails");
  };

  const handleLegalSubsectorChange = (e) => {
    console.log("Legal Subsector changed");

    // TODO: Modify this so it updates newLegalSubsubsector if user selects a different one
    //setnewLegalSubsubsector(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // TODO: In case legal subsector changed, save it in firebase
    if (newLegalSubsubsector !== legalSector) {
      console.log("Legal Subsubsector changed, saving it in Firebase");
      const docRef = doc(db, "leads", firestoreId);
      updateDoc(docRef, {
        legalSectorByUser: newLegalSubsubsector,
      }).then(() => {
        console.log("Legal Subsubsector updated with ID: ", firestoreId);
      });
    }

    // TODO: If selectedSector is Other, show a popup alerting user the law firm does not specialize in this area and ask if they want to continue
    if (selectedSector === "Other") {
      console.log("Submitted with legal Sector:", newLegalSubsubsector);
      setShowPopup(true);
    } else {
      navigate("/intake/casedetailsspecific");
    }

    mixpanel.track("User Confirmed Legal Subsector in Intake", {
      "Firebase User Id": firestoreId,
    });
  };

  const checkboxHandler = () => {
    setShowPopup(!showPopup);
  };

  const handleCaddiClicked = () => {
    console.log("Caddi Button Clicked");
    navigate("/");
  };

  const handleButtonClick = (sector) => {
    console.log("Sector clicked by user: ", sector);
    setNewLegalSubsubsector(sector);
  };

  const finishIntake = () => {
    console.log("Finish intake");
    navigate("/");
    mixpanel.track(
      "User Finished Intake as Law firm does not specialize in their legal issue",
      { "Firebase User Id": firestoreId },
    );
  };

  const continueIntake = () => {
    console.log("Continue intake");
    navigate("/intake/casedetailsspecific");
    mixpanel.track(
      "User Continued Intake eventhough Law firm does not specialize in their legal issue",
      { "Firebase User Id": firestoreId },
    );
  };

  useEffect(() => {
    let lawfirmLegalSectorsFinalToUpdate = lawfirmLegalSectors;
    // Add Other as a legal sector
    if (!lawfirmLegalSectorsFinalToUpdate.includes("Other")) {
      lawfirmLegalSectorsFinalToUpdate.push("Other");
    }
    setLawfirmLegalSectorsFinal(lawfirmLegalSectorsFinalToUpdate);
    console.log("Lawfirm Legal Sectors: ", lawfirmLegalSectorsFinalToUpdate);
  }, [lawfirmLegalSectors]);

  // Retrieve selectedSector and newLegalSubsubsector from localStorage if they exist
  useEffect(() => {
    const storedSelectedSector = localStorage.getItem("selectedSector");
    const storedNewLegalSubsubsector = localStorage.getItem(
      "newLegalSubsubsector",
    );

    // Set values only if they exist in localStorage
    if (storedSelectedSector) {
      setNewLegalSubsubsector(storedSelectedSector);
    }

    if (storedNewLegalSubsubsector) {
      setNewLegalSubsubsector(storedNewLegalSubsubsector);
    }
  }, []);

  // Initialize selectedSector with newLegalSubsubsector
  let selectedSector = newLegalSubsubsector;

  // If newLegalSubsubsector is not in the list, set selectedSector to "Other"
  if (!lawfirmLegalSectorsFinal.includes(newLegalSubsubsector)) {
    selectedSector = "Other";
  }

  // Save selectedSector and newLegalSubsubsector to localStorage
  useEffect(() => {
    localStorage.setItem("selectedSector", selectedSector);
    localStorage.setItem("newLegalSubsubsector", newLegalSubsubsector);
  }, [selectedSector, newLegalSubsubsector]);

  return (
    <>
      <div className="box dark-green-box">
        <div className="columns is-mobile">
          <div className="box white-box column is-3">
            <NavbarIntake
              isSignedIn={isSignedIn}
              isLawyer={isLawyer}
              lawyerLawfirm={lawyerLawfirm}
            />
          </div>
          <div className="box light-green-box column">
            <div className="columns is-centered">
              <div className="column is-2"></div>
              <div className="column">
                <div className="title is-4 has-text-centered">
                  Confirm your case type
                </div>
                <div className="subtitle is-6 has-text-centered mt-1">
                  <p className="">
                    Based on your answers, your issue belongs to the Legal
                    Sector:{" "}
                  </p>
                  {legalSector ? (
                    <span className="subtitle is-5 has-text-weight-bold">
                      {legalSector}
                    </span>
                  ) : (
                    <div className="box has-text-centered">
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        size="2x"
                        className="mr-4"
                      />
                      Analyzing legal sector...
                    </div>
                  )}
                </div>

                {/* User should be able to change legal Subsubsector and continue */}
                {/* {lawfirmLegalSectors.length ? (
                          <div className="container">{rows}</div>
                        ):(
                          <p>No legal sectors pre selected for Law firm</p>
                        )} */}

                <br></br>
                <p className="title is-5 has-text-centered">
                  {" "}
                  {lawyerLawfirm} areas of specialization area:{" "}
                </p>
                {/* Loading for when waiting for legalSector */}
                {lawfirmLegalSectors.length < 2 ? (
                  <div className="box has-text-centered">
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      size="2x"
                      className="mr-4"
                    />
                    Loading legal sector...
                  </div>
                ) : (
                  <div className="columns is-multiline">
                    {lawfirmLegalSectorsFinal.map((sector, index) => (
                      <div key={index} className="column is-half">
                        <SectorCard
                          sector={sector}
                          selectedSector={selectedSector}
                          handleButtonClick={handleButtonClick}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="column is-2"></div>
            </div>

            <NavbarFooter
              handleBackClick={handleBackClick}
              handleNextClick={handleSubmit}
              isFormValid={isFormValid}
            />
          </div>
        </div>
        <FooterIntake />

        {showPopup && (
          <div className="modal is-active">
            <div className="modal-background" onClick={checkboxHandler}></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">
                  {lawyerLawfirm} does not specializes in this type of cases
                </p>
              </header>
              <section className="modal-card-body">
                You are welcome to continue the intake process as we might refer
                you to a lawyer specialized in your case type or you can end the
                intake now.
              </section>
              <footer className="modal-card-foot">
                <div className="columns">
                  <div className="column">
                    <button
                      className="button has-text-success"
                      onClick={checkboxHandler}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="column">
                    <button
                      className="button is-success"
                      onClick={finishIntake}
                    >
                      Finish
                    </button>
                  </div>
                  <div className="column">
                    <button
                      className="button is-success"
                      onClick={continueIntake}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default LegalSectorConfirmation;
