import React from "react";

const AdditionalLegalServices = ({ additionalServices }) => {
  const hasAdditionalServices = additionalServices && Object.keys(additionalServices).length > 0;

  // Generate service details content
  const serviceDetailsContent = hasAdditionalServices ? (
    Object.entries(additionalServices).map(([serviceKey, serviceDetails]) => (
      <p key={serviceKey} className="subsubtitle">
        {serviceDetails.name}: ${serviceDetails.value}
      </p>
    ))
  ) : (
    <p>No additional services to offer.</p>
  );

  return (
    <>
      <div className="subtitle mb-3">Additional Legal Services</div>
      <div className="box">
        <div style={{ whiteSpace: "pre-wrap" }}>
          {serviceDetailsContent}
        </div>
      </div>
    </>
  );
};

export default AdditionalLegalServices;
