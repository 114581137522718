// Your React component where you want to use the HTML content
import React, { useState, useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { db } from "../config/firebase-config";
import {
  collection,
  addDoc,
  arrayUnion,
  doc,
  updateDoc,
} from "firebase/firestore";

import useLocalStorage from "../components/useLocalStorage";

// import css
import './landingPage.css';
import './css/normalize.css';
import './css/webflow.css';
import './css/caddi-v1-2fa6e78753c11714081ab2fdc2abcb.webflow.css';
import './ScheduleDemo.css';

import caddiLogo from './images/Caddi-Logo.svg';
import logoImage from './images/logo.svg';
import image9183_1 from './images/Frame-9183_1.svg';
import image9183 from './images/Frame-9183.svg';
import image9191_2 from './images/Frame-9191_2.svg';
import image9191 from './images/Frame-9191.svg';
import image9191_1 from './images/Frame-9191_1.svg';
import image9205 from './images/Frame-9205.svg';
import networkIcon from './images/Network-Icon.svg';
import group9123 from './images/Group-9123.svg';
import image9173 from './images/Frame-9173.svg';
import image9173_2 from './images/Frame-9173_2.svg';
import image9173_1 from './images/Frame-9173_1.svg';
import group63 from './images/Group-63.svg';
import group98 from './images/Group-98.svg';


const ScheduleDemo = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [name , setName] = useState("");
    const [addedWaitlist, setAddedWaitlist] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    // let selectedLanguage = selectedLanguage
    // selectedLanguage = "EN"
    const [lawfirmName, setLawfirmName] = useState("");
    const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
    const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);

    let loginScreen = "/loginLawyer";
    let logoutScreen = "/logout";
    // let signInText = "Sign In Lawyer";

    const logOutButtonStyles = {
        color: "white",
        backgroundColor: "black",
      };

    if (isLawyer) {
    logoutScreen = "/logoutLawyer";
    }


    const [isMenuExpanded, setIsMenuExpanded] = useState(false);
    const navbarHeight = '120px';
    const toggleMenu = () => {
        setIsMenuExpanded(current => !current);
      };


      useEffect(() => {
        const handleOutsideClick = (event) => {
          const navbar = document.querySelector('.navbar4_component');
          if (isMenuExpanded && navbar && !navbar.contains(event.target)) {
            setIsMenuExpanded(false);
          }
        };
    
        document.addEventListener('click', handleOutsideClick);
        return () => {
          document.removeEventListener('click', handleOutsideClick);
        };
      }, [isMenuExpanded]);
    
      useEffect(() => {
        const handleMenuAnimation = () => {
          const menuIcon = document.querySelector('.menu-icon4');
          const topLine = document.querySelector('.menu-icon4_line-top');
          const middleLine = document.querySelector('.menu-icon4_line-middle');
          const bottomLine = document.querySelector('.menu-icon4_line-bottom');
    
          if (isMenuExpanded) {
            menuIcon.classList.add('open');
            topLine.style.transform = 'rotate(45deg) translate(6px, 6px)';
            middleLine.style.opacity = '0';
            bottomLine.style.transform = 'rotate(-45deg) translate(6px, -6px)';
          } else {
            menuIcon.classList.remove('open');
            topLine.style.transform = 'rotate(0) translate(0, 0)';
            middleLine.style.opacity = '1';
            bottomLine.style.transform = 'rotate(0) translate(0, 0)';
          }
        };
    
        handleMenuAnimation();
      }, [isMenuExpanded]);
      
    
    const submitDemoRequest = async (e) => {
        
        
    //     const docRef = doc(db, "waitlist", "homepage");
    // updateDoc(docRef, {
    //   email: arrayUnion(email),
    // }).then((docRef) => {
    //   console.log("Contact information saved with email: ", email);
    // });


        e.preventDefault();
        mixpanel.track("Demo Request", {
          email: email,
          name: name,
        });
        const docRef = await addDoc(collection(db, "waitlist"), {
          email: email,
          name: name,
          lawfirmName: lawfirmName,
          timestamp: new Date(),
        });
        console.log("Document written with ID: ", docRef.id);
        setAddedWaitlist(true);
        setSubmitted(true);
      }

  return (
    <>
    <div className="page-wrapper">
    <div
        data-animation="default"
        className="navbar4_component w-nav"
        data-easing2="ease"
        fs-scrolldisable-element="smart-nav"
        data-easing="ease"
        data-collapse="all"
        data-w-id="e13ab862-2b2a-b56e-232c-9e2d2f0da996"
        role="banner"
        data-duration={400}
        data-doc-height={1}
    >
        <div className="navbar4_container">
        <a
            href="/"
            aria-current="page"
            className="navbar4_logo-link w-nav-brand w--current"
        >
            <img
            src={logoImage}
            loading="lazy"
            alt=""
            className="navbar4_logo"
            />
        </a>
        <div className="navbar4_wrapper">
            
        <div className="navbar-item">
                        <a className="button is-navbar-button desktop w-button" href='/'>
                        Return Home
                        </a>
                    </div>
           
                    <div className={`navbar4_menu-wrapper-inner ${isMenuExpanded ? 'expand' : ''}`}>
  <div
    className="dropdown-menu"
    style={{
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#0C1210',
      zIndex: 1000,
      top: '100%',
      overflow: 'auto',
      left: 0,
      opacity: isMenuExpanded ? 1 : 0,
      visibility: isMenuExpanded ? 'visible' : 'hidden',
      transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
    }}
  >
    <div className="button-container" style={{ textAlign: 'center' }}>
      <a href="/loginLawyer" className="button is-navbar-button desktop w-button">
        Sign In Lawyer
      </a>
      <br></br>
      <br></br>
      <a href="/intake/login" className="button is-navbar-button desktop w-button">
        Back to Intake
      </a>
    </div>
  </div>
</div>
      <div className="navbar4_menu-button w-nav-button" onClick={toggleMenu}>
        <div className="menu-icon4">
          <div className="menu-icon4_wrapper">
            <div className="menu-icon4_line-top" />
            <div className="menu-icon4_line-middle">
              <div className="menu-icon_line-middle-top" />
              <div className="menu-icon_line-middle-base" />
            </div>
            <div className="menu-icon4_line-bottom" />
          </div>
        </div>
      </div>

        </div>
        </div>
    </div>
    <div className="styles---glowing-button w-embed">

    </div>
    <div className="global-styles w-embed">
    
    </div>
    <main className="main-wrapper">
        <section className="section_hero">
        <div className="padding-global">
            <div className="container-large">
            <div className="hero_component">
                <div className="hero_content">
                
                <h1
                    data-w-id="301326fa-a9f6-d3f0-1b47-9b6b4c42ac69"
                    style={{ opacity: 100 }}
                >
                    Sign up for  
                    <span className="text-color-primary"> demo</span>
                    
                </h1>
                <p
                    data-w-id="6233d2b3-6ab8-4946-1015-4655c118456a"
                    style={{ opacity: 100 }}
                    className="hero_para"
                >
                    Make your law practice more efficient than ever. Caddi is
                    designed to convert more clients without spending time on
                    non-billable hours — all with the help of AI
                </p>
                
                </div>
                <div
                data-w-id="bd87e84f-d41e-8853-5454-50243a8d17eb"
                style={{ opacity: 100 }}
                className="hero_media-wrap"
                >

<div className="form-container" >
    <div className="form-description" style={{ color: '#FFFFFF', marginBottom: '20px' }}>
    </div>
    <p> Name </p>
    <input
      type="name"
    //   placeholder="Name"
    onChange={(e) => setName(e.target.value)}
      className="input-field"
    />
    <p> E-mail </p>
    <input
      type="email"
    //   placeholder="E-mail"
    onChange={(e) => setEmail(e.target.value)}
      className="input-field"
    />
     <p> Law Firm Name </p>
    <input
      type="lawfirmName"
      onChange={(e) => setLawfirmName(e.target.value)}
    //   placeholder="Law Firm Name"
      className="input-field"
    />
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
            data-w-id="a3f5c7ba-60a8-f0fe-c907-797341d9f454"
            style={{ opacity: 100 }}
            className="button-group hero_button-group centered-button"
        >
            <a href="#" className="button w-button" onClick={submitDemoRequest}>
            {submitted ? 'Submitted' : 'Submit For a Demo'}
            </a>
        </div>
    </div>
                <br></br>
                <br></br>
  </div>
                
                </div>
            </div>
            </div>
        </div>
        </section>
        
        
       
        <footer className="section_footer">
        <div className="padding-global">
            <div className="container-large">
            <div className="cta_component">
                <div className="cta_content">
                <div className="margin-bottom margin-small">
                    
                </div>
                <div
                    data-w-id="8e9d675a-bed5-a489-4400-1e1b28b4f91c"
                    style={{ opacity: 100 }}
                    className="cta_title-wrapper"
                >
                    <h2
                    data-w-id="465688af-52a3-f61f-5a93-6c18a40f5d5b"
                    style={{ opacity: 100 }}
                    className="cta_title"
                    >
                    Ready to integrate
                    <span className="text-color-primary">&nbsp;Caddi?</span>
                    </h2>
                    <a
                    data-w-id="681358bb-b1f6-15bb-2f5c-8197d64bb838"
                    style={{ opacity: 100 }}
                    href="#"
                    className="cta_link w-inline-block"
                    >
                    <div className="cta_link-icon w-embed">
                        <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 30 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            d="M0.12281 11.8623H25.7253L22.8455 13.8742L9.63006 0.658708H15.9814L29.4335 14.1898L15.9419 27.7208H9.63006L22.8455 14.5054L25.7253 16.5173H0.12281V11.8623Z"
                            fill="currentcolor"
                            style={{
                            fill: "color(display-p3 0.2272 0.176 0.0696)",
                            fillOpacity: 1
                            }}
                        />
                        </svg>
                    </div>
                    </a>
                </div>
                </div>
                <img
                src={group63}
                loading="lazy"
                alt=""
                className="cta_content-shape"
                />
            </div>
            <div className="footer_component">
                <div
                id="w-node-cdce71c5-2429-936d-b2d3-e8f9498df05c-acad4190"
                className="footer_first-widget"
                >
                <div
                    data-w-id="0658bd67-3455-1283-5e54-57fae1654e8b"
                    style={{ opacity: 100 }}
                    className="footer_widget-divider"
                />
                <div
                    data-w-id="e9433552-9a37-4f2c-6c77-864210b14f96"
                    style={{ opacity: 100 }}
                    className="footer_first-widget-inner"
                >
                    <div className="footer_first-widget-inner-top">
                    <a
                        href="index.html"
                        aria-current="page"
                        className="footer_link w-inline-block w--current"
                    >
                        <img
                        src={group98}
                        loading="lazy"
                        alt=""
                        className="footer_logo"
                        />
                    </a>
                    <div>Client Intake Solutions</div>
                    <a
                        data-w-id="ef09367e-d98f-2e97-f54a-b77e1f533bff"
                        href="#"
                        className="social-link w-inline-block"
                    >
                        <div className="social-link-inner">
                        <div className="social-icon-wrap">
                            <div className="social-icon w-embed">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                aria-hidden="true"
                                role="img"
                                className="iconify iconify--bx"
                                width="100%"
                                height="100%"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                cx="4.983"
                                cy="5.009"
                                r="2.188"
                                fill="currentColor"
                                />
                                <path
                                d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118c1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783c-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"
                                fill="currentColor"
                                />
                            </svg>
                            </div>
                        </div>
                        <div>LinkedIn</div>
                        </div>
                    </a>
                    </div>
                    <a
                    href="index.html"
                    aria-current="page"
                    className="footer_botton-link w-inline-block w--current"
                    >
                    <img
                        src={caddiLogo}
                        loading="lazy"
                        alt=""
                        className="footer_botton-logo"
                    />
                    </a>
                </div>
                <div
                    data-w-id="b866dce2-9a15-2ab2-0bb2-da92f10a44bb"
                    style={{ opacity: 100 }}
                    className="footer_widget-divider"
                />
                </div>
                <div
                id="w-node-_21477a02-ad59-152c-b614-92b952da8b22-acad4190"
                className="footer_menu-widget"
                >
                <div className="footer_menu-widget-inner">
                    <div
                    data-w-id="2d8b5427-32cd-bff8-de09-235e5cd0032b"
                    style={{ opacity: 100 }}
                    className="footer_menu-wrap"
                    >
                    <div className="footer_menu-title">Product</div>
                    <ul role="list" className="footer_menu-list w-list-unstyled">
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Benefits
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Custom
                        </a>
                        </li>
                    </ul>
                    </div>
                    <div
                    data-w-id="5e357b4c-5ad5-7f60-5518-ffa3814902d1"
                    style={{ opacity: 100 }}
                    className="footer_menu-wrap"
                    >
                    <div className="footer_menu-title">Company</div>
                    <ul role="list" className="footer_menu-list w-list-unstyled">
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Investors
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Careers
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            About Us
                        </a>
                        </li>
                    </ul>
                    </div>
                    <div
                    data-w-id="c4537b5b-f1f0-4cca-47ba-86d436b33369"
                    style={{ opacity: 100 }}
                    className="footer_menu-wrap"
                    >
                    <div className="footer_menu-title">Practice Area</div>
                    <ul role="list" className="footer_menu-list w-list-unstyled">
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Personal Injury
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Employment Law
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Business Law
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Immigration
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Real Estate Law
                        </a>
                        </li>
                    </ul>
                    </div>
                    <div
                    data-w-id="c0e307b4-2271-d334-68c3-312b61532f41"
                    style={{ opacity: 100 }}
                    className="footer_menu-wrap"
                    >
                    <div className="footer_menu-title">Resources</div>
                    <ul role="list" className="footer_menu-list w-list-unstyled">
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Data Privacy
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            FAQ
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Blog
                        </a>
                        </li>
                    </ul>
                    </div>
                </div>
                <div className="footer_menu-widget-inner bottom">
                    <div
                    data-w-id="af731701-cf50-3af2-09a1-006fb140a461"
                    style={{ opacity: 100 }}
                    className="footer_menu-wrap"
                    >
                    <ul role="list" className="footer_menu-list w-list-unstyled">
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Website &amp; Platform Terms of Use
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Privacy Policy
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Cookies Policy
                        </a>
                        </li>
                    </ul>
                    </div>
                    <div
                    data-w-id="af731701-cf50-3af2-09a1-006fb140a488"
                    style={{ opacity: 100 }}
                    className="copyright-wrap"
                    >
                    <div className="copyright">©2024 Caddi Technologies LLC</div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </footer>
    </main>
    </div>
    </>

);
};


export default ScheduleDemo;