// Your React component where you want to use the HTML content
import React, { useState, useEffect, useRef} from "react";
import mixpanel from "mixpanel-browser";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { db } from "../config/firebase-config";
import {
  collection,
  addDoc,
  arrayUnion,
  doc,
  updateDoc,
} from "firebase/firestore";

import useLocalStorage from "../components/useLocalStorage";

// import css
import './landingPage.css';
import './css/normalize.css';
import './css/webflow.css';
import './css/caddi-v1-2fa6e78753c11714081ab2fdc2abcb.webflow.css';

import caddiLogo from './images/Caddi-Logo.svg';
import logoImage from './images/logo.svg';
import image9183_1 from './images/Frame-9183_1.svg';
import image9183 from './images/Frame-9183.svg';
import image9191_2 from './images/Frame-9191_2.svg';
import image9191 from './images/Frame-9191.svg';
import image9191_1 from './images/Frame-9191_1.svg';
import image9205 from './images/Frame-9205.svg';
import networkIcon from './images/Network-Icon.svg';
import group9123 from './images/Group-9123.svg';
import image9173 from './images/Frame-9173.svg';
import image9173_2 from './images/Frame-9173_2.svg';
import image9173_1 from './images/Frame-9173_1.svg';
import group63 from './images/Group-63.svg';
import group98 from './images/Group-98.svg';


const LandingPageHTML = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [addedWaitlist, setAddedWaitlist] = useState(false);
    const sectionClientRef = useRef(null);

    // let selectedLanguage = selectedLanguage
    // selectedLanguage = "EN"
    const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
      "lawyerLawfirm",
      "",
    );
    const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
    const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);

    let loginScreen = "/loginLawyer";
    let logoutScreen = "/logout";
    // let signInText = "Sign In Lawyer";

    const logOutButtonStyles = {
        color: "white",
        backgroundColor: "black",
      };

    if (isLawyer) {
    logoutScreen = "/logoutLawyer";
    }
    

    // To import scripts (not needed for now)
    // useEffect(() => {
    //     // Dynamically create script elements
    //     const script2 = document.createElement('script');
    //     script2.src = './webflow.js';
    //     script2.type = 'text/javascript';
    
    //     // Append script elements to the document's head
    //     document.head.appendChild(script2);
    
    //     // Cleanup function to remove the script elements when component unmounts
    //     return () => {
    //       document.head.removeChild(script2);
    //     };
    //   }, []); // Empty dependency array ensures the effect runs only once
    
    // Handle the click for the request demo button
    // const requestDemo = () => {
    //     console.log("Request demo button clicked");
    //     setReque
    // }

    
    // // Expand burger menu on click (TODO: fix this because it doesn't work)
    // const expandMenu = () => {
    //     const menu = document.querySelector('.navbar4_menu-wrapper-inner');
    //     menu.classList.toggle('expand');
    // }


    useEffect(() => {
        // Function to handle the smooth scroll
        const handleScrollToSection = (event) => {
          event.preventDefault();
          if (sectionClientRef.current) {
            sectionClientRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        };

        // Select the "Learn More" button by ID and add the event listener
        const learnMoreButton = document.getElementById('learnMoreButton');
        if (learnMoreButton) {
          learnMoreButton.addEventListener('click', handleScrollToSection);
        }

        // Cleanup function to remove the event listener
        return () => {
          if (learnMoreButton) {
            learnMoreButton.removeEventListener('click', handleScrollToSection);
          }
        };
      }, []);

    const [isMenuExpanded, setIsMenuExpanded] = useState(false);
    const navbarHeight = '120px';
    const toggleMenu = () => {
        setIsMenuExpanded(current => !current);
      };


      useEffect(() => {
        const handleOutsideClick = (event) => {
          const navbar = document.querySelector('.navbar4_component');
          if (isMenuExpanded && navbar && !navbar.contains(event.target)) {
            setIsMenuExpanded(false);
          }
        };
    
        document.addEventListener('click', handleOutsideClick);
        return () => {
          document.removeEventListener('click', handleOutsideClick);
        };
      }, [isMenuExpanded]);
    
      useEffect(() => {
        const handleMenuAnimation = () => {
          const menuIcon = document.querySelector('.menu-icon4');
          const topLine = document.querySelector('.menu-icon4_line-top');
          const middleLine = document.querySelector('.menu-icon4_line-middle');
          const bottomLine = document.querySelector('.menu-icon4_line-bottom');
    
          if (isMenuExpanded) {
            menuIcon.classList.add('open');
            topLine.style.transform = 'rotate(45deg) translate(6px, 6px)';
            middleLine.style.opacity = '0';
            bottomLine.style.transform = 'rotate(-45deg) translate(6px, -6px)';
          } else {
            menuIcon.classList.remove('open');
            topLine.style.transform = 'rotate(0) translate(0, 0)';
            middleLine.style.opacity = '1';
            bottomLine.style.transform = 'rotate(0) translate(0, 0)';
          }
        };
    
        handleMenuAnimation();
      }, [isMenuExpanded]);
      
      


  return (
    <>
    <div className="page-wrapper">
    <div
        data-animation="default"
        className="navbar4_component w-nav"
        data-easing2="ease"
        fs-scrolldisable-element="smart-nav"
        data-easing="ease"
        data-collapse="all"
        data-w-id="e13ab862-2b2a-b56e-232c-9e2d2f0da996"
        role="banner"
        data-duration={400}
        data-doc-height={1}
    >
        <div className="navbar4_container">
        <a
            href="/"
            aria-current="page"
            className="navbar4_logo-link w-nav-brand w--current"
        >
            <img
            src={logoImage}
            loading="lazy"
            alt=""
            className="navbar4_logo"
            />
        </a>
        <div className="navbar4_wrapper">
            <a  className="button is-navbar-button desktop w-button" href="/scheduleDemo">
            Request demo
            </a>

            {/* Dynamic buttons to sign in, go to dashboard or back to intake */}
            {!isSignedIn && (
                <div id="navbarMenu" className="navbar-menu">
                <div className="navbar-end">
                    {lawyerLawfirm && (
                    <div className="navbar-item">
                        <a className="button is-navbar-button desktop w-button" href={`/intake/${lawyerLawfirm}`}>
                        Back to Intake
                        </a>
                    </div>
                    )}

                    <div className="navbar-item">
                    <a href={loginScreen} className={`button is-navbar-button desktop w-button`}>
                        Sign In Lawyer
                    </a>
                    </div>
                </div>
                </div>
            )}

            {isSignedIn && (
                <div id="navbarMenu" className="navbar-menu">
                <div className="navbar-end">
                    {isLawyer && (
                    <div className="navbar-item">
                        <a className="button is-navbar-button desktop w-button" href="/dashboard">
                        Dashboard
                        </a>
                    </div>
                    )}
                    {!isLawyer && (
                    <div className="navbar-item">
                        <a className="button is-navbar-button desktop w-button" href={`/intake/sessions`}>
                        Continue Intake
                        </a>
                    </div>
                    )}
                    <div className="navbar-item">
                    <a
                        className="button is-navbar-button desktop w-button"
                        style={logOutButtonStyles}
                        href={logoutScreen}
                    >
                        Logout
                    </a>
                    </div>
                </div>
                </div>
            )}

<div className={`navbar4_menu-wrapper-inner ${isMenuExpanded ? 'expand' : ''}`}>
  <div
    className="dropdown-menu"
    style={{
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#0C1210',
      zIndex: 1000,
      top: '100%',
      overflow: 'auto',
      left: 0,
      opacity: isMenuExpanded ? 1 : 0,
      visibility: isMenuExpanded ? 'visible' : 'hidden',
      transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
    }}
  >
    <div className="button-container" style={{ textAlign: 'center' }}>
      <a href="/loginLawyer" className="button is-navbar-button desktop w-button">
        Sign In Lawyer
      </a>
      <br></br>
      <br></br>
      <a href="/intake/login" className="button is-navbar-button desktop w-button">
        Back to Intake
      </a>
    </div>
  </div>
</div>
      <div className="navbar4_menu-button w-nav-button" onClick={toggleMenu}>
        <div className="menu-icon4">
          <div className="menu-icon4_wrapper">
            <div className="menu-icon4_line-top" />
            <div className="menu-icon4_line-middle">
              <div className="menu-icon_line-middle-top" />
              <div className="menu-icon_line-middle-base" />
            </div>
            <div className="menu-icon4_line-bottom" />
          </div>
        </div>
      </div>




        </div>
        </div>
    </div>
    <div className="styles---glowing-button w-embed">

    </div>
    <div className="global-styles w-embed">
    
    </div>
    <main className="main-wrapper">
        <section className="section_hero">
        <div className="padding-global">
            <div className="container-large">
            <div className="hero_component">
                <div className="hero_content">
                <div
                    data-w-id="2e52a8da-0d76-a13c-64a4-292b2d864347"
                    style={{ opacity: 100 }}
                    className="glowing-wrapper glowing-wrapper-active tagline"
                > 
                    <div className="glowing-wrapper-animations">
                    <div className="glowing-wrapper-glow" />
                    <div className="glowing-wrapper-mask-wrapper">
                        <div className="glowing-wrapper-mask" />
                    </div>
                    </div>
                    <div className="glowing-wrapper-borders-masker">
                    <div className="glowing-wrapper-borders" />
                    </div>
                    <div className="glowing-wrapper-button">
                    AI Client Intake for Law Firms
                    </div>
                </div>
                <h1
                    data-w-id="301326fa-a9f6-d3f0-1b47-9b6b4c42ac69"
                    style={{ opacity: 100 }}
                >
                    Increase 
                    <span className="text-color-primary"> conversion rates.</span>
                    <span> Streamline</span>
                    <span className="text-color-primary"> first consultations.</span>
                </h1>
                <p
                    data-w-id="6233d2b3-6ab8-4946-1015-4655c118456a"
                    style={{ opacity: 100 }}
                    className="hero_para"
                >
                    Make your law practice more efficient than ever. Caddi is
                    designed to convert more clients without spending time on
                    non-billable hours — all with the help of AI
                </p>
                <div
                    data-w-id="a3f5c7ba-60a8-f0fe-c907-797341d9f454"
                    style={{ opacity: 100 }}
                    className="button-group hero_button-group"
                >
                    <a href="/scheduleDemo" className="button w-button">
                    Request demo
                    </a>
                    <a href="#" className="button is-link is-icon w-inline-block" id="learnMoreButton">
                    <div>Learn More</div>
                    <div className="button-arrow w-embed" >
                        <svg
                        width={14}
                        height={9}
                        viewBox="0 0 14 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            d="M1 1.41406L7 7.41406L13 1.41406"
                            stroke="#F5C155"
                            style={{
                            stroke: "color(display-p3 0.9608 0.7569 0.3333)",
                            strokeOpacity: 1
                            }}
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        </svg>
                    </div>
                    </a>
                </div>
                </div>
                <div
                data-w-id="bd87e84f-d41e-8853-5454-50243a8d17eb"
                style={{ opacity: 100 }}
                className="hero_media-wrap"
                >
                <img
                    src={image9183_1}
                    loading="lazy"
                    alt=""
                    className="hero_media desktop"
                />
                <img
                    src={image9183}
                    loading="lazy"
                    alt=""
                    className="hero_media mobile"
                />
                </div>
            </div>
            </div>
        </div>
        </section>
        <section className="section_integration">
        <div className="padding-global">
            <div className="container-large">
            <div className="padding-section-large padding-top">
                <div className="margin-bottom margin-small">
                <div className="max-width-xlarge align-center text-align-center">
                    <div className="margin-bottom margin-small">
                    <div
                        data-w-id="4d4757d3-ecb6-14cb-a426-be7dcfd3d3d3"
                        style={{ opacity: 100 }}
                        className="glowing-wrapper glowing-wrapper-active tagline"
                    >
                        <div className="glowing-wrapper-animations">
                        <div className="glowing-wrapper-glow" />
                        <div className="glowing-wrapper-mask-wrapper">
                            <div className="glowing-wrapper-mask" />
                        </div>
                        </div>
                        <div className="glowing-wrapper-borders-masker">
                        <div className="glowing-wrapper-borders" />
                        </div>
                        <div className="glowing-wrapper-button">
                        Workflow Integrations
                        </div>
                    </div>
                    </div>
                    <h2
                    data-w-id="24237ca1-d8b2-7f2b-2816-2acc16ea28ac"
                    style={{ opacity: 100 }}
                    >
                    Caddi integrates within
                    <span className="text-color-primary">&nbsp;your workflow</span>
                    </h2>
                </div>
                </div>
                <div className="w-layout-grid integration_grid">
                <div
                    id="w-node-_374de02f-8d83-eae9-1b8a-23befe6bef30-acad4190"
                    data-w-id="374de02f-8d83-eae9-1b8a-23befe6bef30"
                    style={{ opacity: 100 }}
                    className="integration_card"
                >
                    <img
                    src={image9191_2}
                    loading="lazy"
                    alt=""
                    className="integration_card-thumbnail"
                    />
                    <div className="integration_card-body">
                    <div className="integration_card-shape w-embed">
                        <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 11 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            d="M5.25832 9.70049C2.63432 9.70049 0.5 7.56474 0.5 4.93899C0.5 2.31324 2.63432 0.17749 5.25832 0.17749C7.88232 0.17749 10.0166 2.31324 10.0166 4.93899C10.0166 7.56474 7.88232 9.70049 5.25832 9.70049ZM5.25832 3.08552C4.2364 3.08552 3.40609 3.91639 3.40609 4.93899C3.40609 5.9616 4.2364 6.79246 5.25832 6.79246C6.28024 6.79246 7.11055 5.9616 7.11055 4.93899C7.11055 3.91639 6.28024 3.08552 5.25832 3.08552Z"
                            fill="#F5C155"
                            style={{
                            fill: "color(display-p3 0.9608 0.7569 0.3333)",
                            fillOpacity: 1
                            }}
                        />
                        </svg>
                    </div>
                    <h3 className="integration_card-title">
                        Automate Intaking Clients
                    </h3>
                    <p>
                        We can intake clients from any source you do currently -
                        whether that be a website, email, or a direct call.
                    </p>
                    </div>
                </div>
                <div
                    id="w-node-_41c47c6d-614c-7985-1c39-e31eaeef8645-acad4190"
                    data-w-id="41c47c6d-614c-7985-1c39-e31eaeef8645"
                    style={{ opacity: 100 }}
                    className="integration_card-divider"
                />
                <div
                    id="w-node-_978372b0-b0cc-6bc5-2b47-d509ce67f293-acad4190"
                    data-w-id="978372b0-b0cc-6bc5-2b47-d509ce67f293"
                    style={{ opacity: 100 }}
                    className="integration_card"
                >
                    <img
                    src={image9191}
                    loading="lazy"
                    alt=""
                    className="integration_card-thumbnail"
                    />
                    <div className="integration_card-body">
                    <div className="integration_card-shape w-embed">
                        <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 11 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            d="M5.25832 9.70049C2.63432 9.70049 0.5 7.56474 0.5 4.93899C0.5 2.31324 2.63432 0.17749 5.25832 0.17749C7.88232 0.17749 10.0166 2.31324 10.0166 4.93899C10.0166 7.56474 7.88232 9.70049 5.25832 9.70049ZM5.25832 3.08552C4.2364 3.08552 3.40609 3.91639 3.40609 4.93899C3.40609 5.9616 4.2364 6.79246 5.25832 6.79246C6.28024 6.79246 7.11055 5.9616 7.11055 4.93899C7.11055 3.91639 6.28024 3.08552 5.25832 3.08552Z"
                            fill="#F5C155"
                            style={{
                            fill: "color(display-p3 0.9608 0.7569 0.3333)",
                            fillOpacity: 1
                            }}
                        />
                        </svg>
                    </div>
                    <h3 className="integration_card-title">
                        Guidance for Front-Desk
                    </h3>
                    <p>
                        Guide your front-desk to get more details from important
                        client calls. Caddi can help you get the right questions
                        answered.
                    </p>
                    </div>
                </div>
                <div
                    id="w-node-_04db09fc-9f33-301b-90ae-b14efa44608d-acad4190"
                    data-w-id="04db09fc-9f33-301b-90ae-b14efa44608d"
                    style={{ opacity: 100 }}
                    className="integration_card-divider"
                />
                <div
                    id="w-node-eafe1ddf-5777-bcb1-cd31-35d9bac3583f-acad4190"
                    data-w-id="eafe1ddf-5777-bcb1-cd31-35d9bac3583f"
                    style={{ opacity: 100 }}
                    className="integration_card"
                >
                    <img
                    src={image9191_1}
                    loading="lazy"
                    alt=""
                    className="integration_card-thumbnail"
                    />
                    <div className="integration_card-body">
                    <div className="integration_card-shape w-embed">
                        <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 11 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            d="M5.25832 9.70049C2.63432 9.70049 0.5 7.56474 0.5 4.93899C0.5 2.31324 2.63432 0.17749 5.25832 0.17749C7.88232 0.17749 10.0166 2.31324 10.0166 4.93899C10.0166 7.56474 7.88232 9.70049 5.25832 9.70049ZM5.25832 3.08552C4.2364 3.08552 3.40609 3.91639 3.40609 4.93899C3.40609 5.9616 4.2364 6.79246 5.25832 6.79246C6.28024 6.79246 7.11055 5.9616 7.11055 4.93899C7.11055 3.91639 6.28024 3.08552 5.25832 3.08552Z"
                            fill="#F5C155"
                            style={{
                            fill: "color(display-p3 0.9608 0.7569 0.3333)",
                            fillOpacity: 1
                            }}
                        />
                        </svg>
                    </div>
                    <h3 className="integration_card-title">
                        Prepared for the First Call
                    </h3>
                    <p>
                    Walk into your first meetings with all the insights about your client. Caddi has you covered.
                    </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <section className="section_client">
        <div className="padding-global">
            <div className="container-large">
            <div className="padding-section-large padding-top">
                <div className="margin-bottom margin-xlarge">
                <div className="max-width-large">
                    <div className="margin-bottom margin-small">
                    <div
                        data-w-id="70604bab-7c35-4210-1ec7-26e766e0b19d"
                        style={{ opacity: 100 }}
                        className="glowing-wrapper glowing-wrapper-active tagline"
                    >
                        <div className="glowing-wrapper-animations">
                        <div className="glowing-wrapper-glow" />
                        <div className="glowing-wrapper-mask-wrapper">
                            <div className="glowing-wrapper-mask" />
                        </div>
                        </div>
                        <div className="glowing-wrapper-borders-masker">
                        <div className="glowing-wrapper-borders" />
                        </div>
                        <div className="glowing-wrapper-button">For Clients</div>
                    </div>
                    </div>
                    <h2
                    data-w-id="f3169a16-3d50-c74a-c4ce-0287e308d492"
                    style={{ opacity: 100 }}
                    >
                    AI-driven custom intake forms.{" "}
                    <span className="text-color-primary">
                        Never miss a client again.
                    </span>
                    </h2>
                </div>
                </div>
                <div className="w-layout-grid client_grid">
                <div
                    id="w-node-f3169a16-3d50-c74a-c4ce-0287e308d497-acad4190"
                    data-w-id="f3169a16-3d50-c74a-c4ce-0287e308d497"
                    style={{ opacity: 100 }}
                    className="client_card"
                >
                    <div className="client_card-body">
                    <h3 className="integration_card-title">
                        Smart Client Intake
                    </h3>
                    <p>
                        Be the first to answer potential clients for the best first
                        impression. Keep your clients engaged while getting all the
                        information you need.
                    </p>
                    </div>
                    <img
                    src={image9173}
                    loading="lazy"
                    alt=""
                    className="client_card-thumbnail"
                    />
                </div>
                <div
                    data-w-id="9f8852d6-d068-413d-7ab2-cd5c26132b97"
                    style={{ opacity: 100 }}
                    className="client_card _2"
                >
                    <div className="client_card-body v2">
                    <h3 className="integration_card-title">
                        Secured Client Data
                    </h3>
                    <p>
                        We understand how sensitive client information can be. Caddi
                        is SOC2 secured with X encryption.
                    </p>
                    </div>
                    <img
                    src={image9205}
                    loading="lazy"
                    alt=""
                    className="client_card-thumbnail absolute"
                    />
                </div>
                </div>
                <div className="section-divider" />
            </div>
            </div>
        </div>
        </section>
        <section ref={sectionClientRef} className="section_client">
        <div className="padding-global">
            <div className="container-large">
            <div className="padding-section-large padding-top">
                <div className="margin-bottom margin-xlarge">
                <div className="max-width-xlarge">
                    <div className="margin-bottom margin-small">
                    <div
                        data-w-id="74ad06de-982a-32fa-f2b9-49b478412f73"
                        style={{ opacity: 100 }}
                        className="glowing-wrapper glowing-wrapper-active tagline"
                    >
                        <div className="glowing-wrapper-animations">
                        <div className="glowing-wrapper-glow" />
                        <div className="glowing-wrapper-mask-wrapper">
                            <div className="glowing-wrapper-mask" />
                        </div>
                        </div>
                        <div className="glowing-wrapper-borders-masker">
                        <div className="glowing-wrapper-borders" />
                        </div>
                        <div className="glowing-wrapper-button">For Lawyers</div>
                    </div>
                    </div>
                    <h2
                    data-w-id="1094f0d5-5b9d-4b92-1934-2322dfa8d443"
                    style={{ opacity: 100 }}
                    >
                    Your own customizable client platform.
                    <span className="text-color-primary">
                        &nbsp;See pitfalls before you find them.
                    </span>
                    </h2>
                </div>
                </div>
                <div className="w-layout-grid client_grid">
                <div
                    id="w-node-_1094f0d5-5b9d-4b92-1934-2322dfa8d448-acad4190"
                    data-w-id="1094f0d5-5b9d-4b92-1934-2322dfa8d448"
                    style={{ opacity: 100 }}
                    className="client_card"
                >
                    <div className="client_card-body">
                    <h3 className="integration_card-title">
                        Vet Clients in Seconds
                    </h3>
                    <p>
                        Focus your time on real clients and forget about hours
                        burned deciding if a client is right. Filter out spam,
                        scams, window shoppers, and those looking for free advice.
                    </p>
                    </div>
                    <img
                    src={image9173_2}
                    loading="lazy"
                    alt=""
                    className="client_card-thumbnail"
                    />
                </div>
                <div
                    data-w-id="1094f0d5-5b9d-4b92-1934-2322dfa8d44f"
                    style={{ opacity: 100 }}
                    className="client_card _2"
                >
                    <div className="client_card-body v2">
                    <h3 className="integration_card-title">
                        Refer &amp; Network
                    </h3>
                    <p>
                        Not the right client for your specialty but have someone in
                        mind? Become part of a referral network in one-click.
                    </p>
                    </div>
                    <img
                    src={networkIcon}
                    loading="lazy"
                    alt=""
                    className="client_card-thumbnail absolute"
                    />
                </div>
                <div
                    data-w-id="54a32497-670c-ae43-f250-201de5fde351"
                    style={{ opacity: 100 }}
                    className="client_card _2"
                >
                    <div className="client_card-body v2">
                    <h3 className="integration_card-title">
                        Auto Info Gathering
                    </h3>
                    <p>
                        Closed the case with your client? We can take the next steps
                        of getting the followup information ready for you to review.
                    </p>
                    </div>
                    <img
                    src={group9123}
                    loading="lazy"
                    alt=""
                    className="client_card-thumbnail absolute"
                    />
                </div>
                <div
                    id="w-node-e6e1f1ac-930b-b177-54d8-224cce19b48a-acad4190"
                    data-w-id="e6e1f1ac-930b-b177-54d8-224cce19b48a"
                    style={{ opacity: 100 }}
                    className="client_card"
                >
                    <div className="client_card-body">
                    <h3 className="integration_card-title">
                        Caddi Case Analysis
                    </h3>
                    <p>
                        With Caddi's case analysis, you can convert more leads into
                        paying clients. Moreover it reduces your 30min free
                        consultations to 5min by having all the client and matter
                        information ready before the meeting.
                    </p>
                    </div>
                    <img
                    src={image9173_1}
                    loading="lazy"
                    alt=""
                    className="client_card-thumbnail"
                    />
                </div>
                </div>
                <div className="section-divider" />
            </div>
            </div>
        </div>
        </section>
        <section className="section_client">
        <div className="padding-global">
            <div className="container-large">
            <div className="padding-section-large">
                <div className="meet_content">
                <h2
                    data-w-id="6124a70b-16d7-2e67-6f65-4b0b188f4a35"
                    style={{ opacity: 100 }}
                    className="meet-title"
                >
                    We strive to
                    <span className="text-color-primary"> eliminate the manual work
                    </span>
                    &nbsp;of client intake.
                </h2>
                <h2
                    data-w-id="cdaca5c0-6f55-4102-82fb-5c62a8ff53ab"
                    style={{ opacity: 100 }}
                >
                    Al-powered intake driving higher conversation rates and ensuring
                    productive first meetings.
                </h2>
                </div>
            </div>
            </div>
        </div>
        </section>
        <footer className="section_footer">
        <div className="padding-global">
            <div className="container-large">
            <div className="cta_component">
                <div className="cta_content">
                <div className="margin-bottom margin-small">
                    <div
                    data-w-id="29526d87-490b-50ff-fbbe-3fda0838a711"
                    style={{ opacity: 100 }}
                    className="glowing-wrapper glowing-wrapper-active tagline"
                    >
                    <div className="glowing-wrapper-animations">
                        <div className="glowing-wrapper-glow" />
                        <div className="glowing-wrapper-mask-wrapper">
                        <div className="glowing-wrapper-mask" />
                        </div>
                    </div>
                    <div className="glowing-wrapper-borders-masker">
                        <div className="glowing-wrapper-borders" />
                    </div>
                    <div className="glowing-wrapper-button">Setup a Demo</div>
                    </div>
                </div>
                <div
                    data-w-id="8e9d675a-bed5-a489-4400-1e1b28b4f91c"
                    style={{ opacity: 100 }}
                    className="cta_title-wrapper"
                >
                    <h2
                    data-w-id="465688af-52a3-f61f-5a93-6c18a40f5d5b"
                    style={{ opacity: 100 }}
                    className="cta_title"
                    >
                    Ready to integrate
                    <span className="text-color-primary">&nbsp;Caddi?</span>
                    </h2>
                    <a
                    data-w-id="681358bb-b1f6-15bb-2f5c-8197d64bb838"
                    style={{ opacity: 100 }}
                    href="#"
                    className="cta_link w-inline-block"
                    >
                    <div className="cta_link-icon w-embed">
                        <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 30 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            d="M0.12281 11.8623H25.7253L22.8455 13.8742L9.63006 0.658708H15.9814L29.4335 14.1898L15.9419 27.7208H9.63006L22.8455 14.5054L25.7253 16.5173H0.12281V11.8623Z"
                            fill="currentcolor"
                            style={{
                            fill: "color(display-p3 0.2272 0.176 0.0696)",
                            fillOpacity: 1
                            }}
                        />
                        </svg>
                    </div>
                    </a>
                </div>
                </div>
                <img
                src={group63}
                loading="lazy"
                alt=""
                className="cta_content-shape"
                />
            </div>
            <div className="footer_component">
                <div
                id="w-node-cdce71c5-2429-936d-b2d3-e8f9498df05c-acad4190"
                className="footer_first-widget"
                >
                <div
                    data-w-id="0658bd67-3455-1283-5e54-57fae1654e8b"
                    style={{ opacity: 100 }}
                    className="footer_widget-divider"
                />
                <div
                    data-w-id="e9433552-9a37-4f2c-6c77-864210b14f96"
                    style={{ opacity: 100 }}
                    className="footer_first-widget-inner"
                >
                    <div className="footer_first-widget-inner-top">
                    <a
                        href="index.html"
                        aria-current="page"
                        className="footer_link w-inline-block w--current"
                    >
                        <img
                        src={group98}
                        loading="lazy"
                        alt=""
                        className="footer_logo"
                        />
                    </a>
                    <div>Client Intake Solutions</div>
                    <a
                        data-w-id="ef09367e-d98f-2e97-f54a-b77e1f533bff"
                        href="#"
                        className="social-link w-inline-block"
                    >
                        <div className="social-link-inner">
                        <div className="social-icon-wrap">
                            <div className="social-icon w-embed">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                aria-hidden="true"
                                role="img"
                                className="iconify iconify--bx"
                                width="100%"
                                height="100%"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                cx="4.983"
                                cy="5.009"
                                r="2.188"
                                fill="currentColor"
                                />
                                <path
                                d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118c1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783c-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"
                                fill="currentColor"
                                />
                            </svg>
                            </div>
                        </div>
                        <div>LinkedIn</div>
                        </div>
                    </a>
                    </div>
                    <a
                    href="index.html"
                    aria-current="page"
                    className="footer_botton-link w-inline-block w--current"
                    >
                    <img
                        src={caddiLogo}
                        loading="lazy"
                        alt=""
                        className="footer_botton-logo"
                    />
                    </a>
                </div>
                <div
                    data-w-id="b866dce2-9a15-2ab2-0bb2-da92f10a44bb"
                    style={{ opacity: 100 }}
                    className="footer_widget-divider"
                />
                </div>
                <div
                id="w-node-_21477a02-ad59-152c-b614-92b952da8b22-acad4190"
                className="footer_menu-widget"
                >
                <div className="footer_menu-widget-inner">
                    <div
                    data-w-id="2d8b5427-32cd-bff8-de09-235e5cd0032b"
                    style={{ opacity: 100 }}
                    className="footer_menu-wrap"
                    >
                    <div className="footer_menu-title">Product</div>
                    <ul role="list" className="footer_menu-list w-list-unstyled">
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Benefits
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Custom
                        </a>
                        </li>
                    </ul>
                    </div>
                    <div
                    data-w-id="5e357b4c-5ad5-7f60-5518-ffa3814902d1"
                    style={{ opacity: 100 }}
                    className="footer_menu-wrap"
                    >
                    <div className="footer_menu-title">Company</div>
                    <ul role="list" className="footer_menu-list w-list-unstyled">
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Investors
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Careers
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            About Us
                        </a>
                        </li>
                    </ul>
                    </div>
                    <div
                    data-w-id="c4537b5b-f1f0-4cca-47ba-86d436b33369"
                    style={{ opacity: 100 }}
                    className="footer_menu-wrap"
                    >
                    <div className="footer_menu-title">Practice Area</div>
                    <ul role="list" className="footer_menu-list w-list-unstyled">
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Personal Injury
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Employment Law
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Business Law
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Immigration
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Real Estate Law
                        </a>
                        </li>
                    </ul>
                    </div>
                    <div
                    data-w-id="c0e307b4-2271-d334-68c3-312b61532f41"
                    style={{ opacity: 100 }}
                    className="footer_menu-wrap"
                    >
                    <div className="footer_menu-title">Resources</div>
                    <ul role="list" className="footer_menu-list w-list-unstyled">
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Data Privacy
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            FAQ
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Blog
                        </a>
                        </li>
                    </ul>
                    </div>
                </div>
                <div className="footer_menu-widget-inner bottom">
                    <div
                    data-w-id="af731701-cf50-3af2-09a1-006fb140a461"
                    style={{ opacity: 100 }}
                    className="footer_menu-wrap"
                    >
                    <ul role="list" className="footer_menu-list w-list-unstyled">
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Website &amp; Platform Terms of Use
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Privacy Policy
                        </a>
                        </li>
                        <li className="footer_menu-list-item">
                        <a href="#" className="footer_menu-link">
                            Cookies Policy
                        </a>
                        </li>
                    </ul>
                    </div>
                    <div
                    data-w-id="af731701-cf50-3af2-09a1-006fb140a488"
                    style={{ opacity: 100 }}
                    className="copyright-wrap"
                    >
                    <div className="copyright">©2024 Caddi Technologies LLC</div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </footer>
    </main>
    </div>
    </>

);
};


export default LandingPageHTML;