import React, { useState } from "react";
import mixpanel from "mixpanel-browser";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { db } from "../config/firebase-config";
import {
  collection,
  addDoc,
  arrayUnion,
  doc,
  updateDoc,
} from "firebase/firestore";
import "./Home.css";

import useLocalStorage from "../components/useLocalStorage";

const Home = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [addedWaitlist, setAddedWaitlist] = useState(false);
  // let selectedLanguage = selectedLanguage
  // selectedLanguage = "EN"
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const joinWaitlist = () => {
    console.log("Join Waitlist, email: ", email);
    mixpanel.track("User joined waitlist", { Email: email });

    // Give feedback to user
    setAddedWaitlist(true);

    // Erase the email from user
    setEmail("");

    // TODO: send an alert for us to contact this person back

    // TODO: send an email to the user to confirm they joined the waitlist

    // Add email to waitlist in Firebase
    const docRef = doc(db, "waitlist", "homepage");
    updateDoc(docRef, {
      email: arrayUnion(email),
    }).then((docRef) => {
      console.log("Contact information saved with email: ", email);
    });
  };

  return (
    <>
      <Navbar
        isSignedIn={isSignedIn}
        isLawyer={isLawyer}
        lawyerLawfirm={lawyerLawfirm}
      />
      <section
        className="Introduction section is-medium has-background-black"
        id="Welcome"
      >
        <div className="columns is-vcentered">
          <div className="column is-3"></div>

          <div className="column has-text-centered has-text-white">
            <p className="title is-1 mx-6 has-text-white">
              <span>Unpacking client needs with AI</span>
            </p>
            <p className=" is-size-5 mt-2">
              <span>Caddi helps you source and vet clients.</span>
            </p>
            <p className=" is-size-5">
              <span>
                See the value behind each case without the manual work.
              </span>
            </p>

            <div className="field is-horizontal mt-4">
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <div className="input-button-container is-flex">
                      <input
                        className="input ml-6"
                        type="email"
                        placeholder="Your email"
                        value={email}
                        onChange={handleEmailChange}
                      />
                      <button
                        className="button is-success ml-1 mr-6"
                        onClick={joinWaitlist}
                      >
                        Join Waitlist
                      </button>
                    </div>
                    {addedWaitlist && (
                      <p className="has-text-success mt-1">
                        Thank you for joining the waitlist!
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="column is-3"></div>
        </div>
        <br></br>

        <div className="columns is-centered">
          <div className="column is-1"> </div>
          <figure className="image">
            <img src="/DashboardImage.png" alt="LogoIntro" />
          </figure>
          <div className="column is-1"> </div>
        </div>
      </section>
    </>
  );
};

export default Home;
