import React, { useState, useEffect } from "react";
import {
  addDoc,
  collection,
  updateDoc,
  arrayUnion,
  doc,
} from "firebase/firestore";
import { db, storage } from "../config/firebase-config";
import mixpanel from "mixpanel-browser";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";

const FileUploader = (props) => {
  const [loading, setLoading] = useState(false);
  const [imageFlag, setImageFlag] = useState(false);
  //const [showPopupUploaded, setShowPopupUploaded] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false); // Track if a file has been uploaded
  const [uploadedFileName, setUploadedFileName] = useState("");

  const DocumentTitle = props.DocumentTitle;
  let sessionToUpdateRef = doc(db, "leads", props.firestoreId);

  // Check if the file has been uploaded and when user returns to this page, the file is still there and it does not call the backend to analyse it
  useEffect(() => {
    // TODO: Change this so it detects the document that was uploaded before.
    const storedDocumentName = localStorage.getItem(
      `uploadedDocument_${props.firestoreId}_${DocumentTitle}`,
    );

    if (storedDocumentName) {
      setFileUploaded(true);
      setUploadedFileName(storedDocumentName);
    }

    // Check in localStorage if followUpQuestions are ready
    const followUpQuestionsReady = localStorage.getItem(
      `followUpQuestions_${props.firestoreId}_${DocumentTitle}`,
    );
    if (followUpQuestionsReady) {
      //props.onFormIsValid(true)
      props.onFollowUpQuestionsDocs(props.DocumentTitle, []);
    }
  }, [props.firestoreId]);

  const handleFileChange = async (event) => {
    //let sessionCode = props.sessionCode
    const fileInput = event.target;

    setLoading(true);

    if (fileInput.files.length > 0) {
      //const fileName = document.querySelector('#fileUploader .file-name');
      //fileName.textContent = fileInput.files[0].name;
      const fileName = fileInput.files[0].name;

      // Set the fileUploaded and uploadedFileName state when a file is successfully uploaded
      setFileUploaded(true);
      setUploadedFileName(fileInput.files[0].name);
      props.onFormIsValid(true);
      props.onUserUploadedDocs(true);

      // Save docs as uploaded by user just as a backup
      let file = fileInput.files[0];
      const storageRef = ref(storage, `leads/${props.firestoreId}/${fileName}`);
      uploadBytes(storageRef, file)
        .then((snapshot) => {
          console.log("Uploaded a file!");
        })
        .catch((error) => {
          console.error("Error uploading file in Firebase Storage: ", error);
        });

      console.log("About to call backend to analyze file");

      // FileIngestion: Send the file to the backend to get the text from file
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlFileUploaded = apiHostname
        ? `https://${apiHostname}/api/fileIngestion`
        : "http://localhost:5001/api/fileIngestion";

      const formData = new FormData();
      formData.append("file", fileInput.files[0]);

      fetch(apiUrlFileUploaded, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          //console.log("response gotten after sending file:", data.answer);
          console.log(
            "text extracted from file (frontend):",
            data.textFromFile,
          );
          console.log("lease comes from image:", data.image_flag);
          console.log("Vectorstore identifier: ", data.identifier);

          //props.onFileUploaded(true);
          // Make upload lease button stop loading
          setLoading(false);
          //setShowPopupUploaded(true);
          if (data.image_flag) {
            setImageFlag(true);
          }

          // MissingInfo: Send the file to the backend to get name, missingInfo and pendingDocs
          const apiUrlMissingInfo = apiHostname
            ? `https://${apiHostname}/api/TagsMissingDocsAndInfo`
            : "http://localhost:5001/api/TagsMissingDocsAndInfo";

          fetch(apiUrlMissingInfo, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              textFromFile: data.textFromFile,
              docsList: props.docsList,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("missing info in doc: ", data.missingInfo);
              console.log("missing documents: ", data.pendingDocs);
              console.log("Document type: ", data.document);

              // // Make call to set States (// Do NOT do this again for the pending documents)
              // if (!props.noMoreFollowUpQuestions); {
              //   //props.onInfoMissingDocs(props.DocumentTitle, data.missingInfo, data.pendingDocs, data.document)

              //   // TODO: correct this to append the info from the following docs
              //   updateDoc(sessionToUpdateRef, {
              //     // textFromDocument: data.textFromFile,
              //     // DocumentComesFromImage: (data.imageFlag),
              //     //missingInfo: arrayUnion(...data.missingInfo), //Failing as it is not an array
              //     //pendingDocs: arrayUnion(...data.pendingDocs), //Failing as it is not an array
              //     //documentType: arrayUnion(data.document),
              //   })
              //   .then(() => {
              //     console.log("Missing info and docs from Docs uploaded in database Firebase")
              //   })
              //   .catch((error) => {
              //     console.error("Error uploading File and Follow Up questions from Docs in database Firebase: ", error);
              //   });

              // }
            });

          // FollowUpQuestionsDocIndividual: Send the file to the backend to get the followUpQuestions
          const apiUrlFollowUpQuestionsDocIndividual = apiHostname
            ? `https://${apiHostname}/api/followUpQuestionsDocIndividual`
            : "http://localhost:5001/api/followUpQuestionsDocIndividual";

          fetch(apiUrlFollowUpQuestionsDocIndividual, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              textFromFile: data.textFromFile,
              context: props.generalCaseAnswer,
              specificLegalQuestions: props.specificQuestions,
              specificLegalAnswers: props.specificAnswers,
              firestoreId: props.firestoreId,
              identifier: data.identifier,
              legalSector: props.legalSector,
              legalSubsector: props.legalSubsector,
              legalSubsubsector: props.legalSubsubsector,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log(
                "follow up questions for user: ",
                data.followUpQuestionUnAnswered,
              );
              console.log(
                "questions that can be answered with doc: ",
                data.followUpQuestionsAnsweredQueries,
              );
              console.log(
                "follow up questions answered by docs: ",
                data.followUpQuestionsAnsweredResults,
              );
              console.log("Document Type: ", data.documentType);
              console.log("Legal Tags: ", data.legalTags);

              let followUpQuestion = data.followUpQuestionUnAnswered;
              // Check whether data.followUpQuestionUnAnswered only has one element and is an empty string

              if (followUpQuestion) {
                if (
                  data.followUpQuestionUnAnswered.length === 1 &&
                  data.followUpQuestionUnAnswered[0] === ""
                ) {
                  console.log("No follow up questions for this document");
                }
              } else {
                console.log("Follow Up Question empty or undefined");
              }

              // Make call to set States (// Do NOT do this again for the pending documents)
              if (!props.noMoreFollowUpQuestions);
              {
                props.onFollowUpQuestionsDocs(
                  props.DocumentTitle,
                  data.followUpQuestionUnAnswered,
                );
                props.onDocsUploadedByUser(
                  props.DocumentTitle,
                  data.documentType,
                );

                // Save in localStorage that followUpQuestions are ready
                localStorage.setItem(
                  `followUpQuestions_${props.firestoreId}_${DocumentTitle}`,
                  true,
                );

                let file_name =
                  data.documentType + "_" + Math.floor(Math.random() * 100);

                // TODO: fix this as it is saving the whole array as a single element in the Firestore array
                updateDoc(sessionToUpdateRef, {
                  // followUpQuestionsDocs: arrayUnion(data.followUpQuestionUnAnswered),
                  // followUpQuestionsDocsAIQueries: arrayUnion(data.followUpQuestionsAnsweredQueries),
                  // followUpQuestionsDocsAIResults: arrayUnion(data.followUpQuestionsAnsweredResults),
                  followUpQuestionsDocs: arrayUnion(
                    ...data.followUpQuestionUnAnswered,
                  ),
                  followUpQuestionsDocsAIQueries: arrayUnion(
                    ...data.followUpQuestionsAnsweredQueries,
                  ),
                  followUpQuestionsDocsAIResults: arrayUnion(
                    ...data.followUpQuestionsAnsweredResults,
                  ),
                  UploadedDocsType: arrayUnion(data.documentType),
                  UploadedDocsName: arrayUnion(file_name),
                  UploadedDocsLegalTags: arrayUnion(data.legalTags),
                })
                  .then(() => {
                    console.log(
                      "Follow up questions from Docs uploaded in database Firebase",
                    );
                  })
                  .catch((error) => {
                    console.error(
                      "Error uploading File and Follow Up questions from Docs in database Firebase: ",
                      error,
                    );
                  });

                // Upload file to Firebase Storage using the name predicted by the AI. Make sure file is not being overwritten
                let fileToUpload = fileInput.files[0];
                const storageRef = ref(
                  storage,
                  `leads/${props.firestoreId}/${file_name}`,
                );
                uploadBytes(storageRef, fileToUpload)
                  .then((snapshot) => {
                    console.log("Uploaded a blob or file!");
                  })
                  .catch((error) => {
                    console.error(
                      "Error uploading file in Firebase Storage: ",
                      error,
                    );
                  });
              }
            });
        })
        .catch((error) => {
          console.error(error);
        });

      localStorage.setItem(
        `uploadedDocument_${props.firestoreId}_${DocumentTitle}`,
        fileName,
      );

      mixpanel.track(
        "User Uploaded a Document in Intake",
        { "Firebase User Id": props.firestoreId },
        { "Document Title": props.DocumentTitle },
      );
    }
    //};
  };

  // Function to handle document elimination
  const handleDeleteDocument = () => {
    // Soft delete implemented
    setFileUploaded(false);
    setUploadedFileName("");

    // Delete from localStorage
    localStorage.removeItem(
      `uploadedDocument_${props.firestoreId}_${DocumentTitle}`,
    );
    // TODO: delete in Firebase store too
  };

  return (
    <>
      <div className="divider"></div>
      <div className="columns is-vcentered my-2">
        <div className="column">
          <div className="subtitle is-5 has-text-left">{DocumentTitle}</div>
        </div>
        <div className="column is-1"></div>
        <div className="column is-3">
          <div className="file" id="fileUploader">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                name="resume"
                onChange={handleFileChange}
                id="uploadFileButton"
              />
              <span
                className={`file-cta ${fileUploaded ? "has-background-dark" : "has-background-success"} has-text-white`}
              >
                <span className="file-label">
                  {fileUploaded ? "Modify" : "Upload"}
                </span>
              </span>
            </label>
          </div>
        </div>

        {/* New button for document elimination */}
        {fileUploaded && (
          <>
            <button
              className="button is-danger"
              onClick={() => handleDeleteDocument()}
            >
              Eliminate
            </button>
          </>
        )}
      </div>
      {fileUploaded && (
        <div className="box has-text-left">
          {/* Add doc icon here */}
          <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: "10px" }} />
          {uploadedFileName}
        </div>
      )}
    </>
  );
};

export default FileUploader;
