// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// import { initializeAdminApp, getAuth as getAdminAuth } from "firebase-admin/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAdxzD6KOOHtNiwXHeF_4S1v2CTbClF24Q",
  authDomain: "intakeai.firebaseapp.com",
  projectId: "intakeai",
  storageBucket: "intakeai.appspot.com",
  messagingSenderId: "56119145599",
  appId: "1:56119145599:web:c6a0d5241e1fab74fdbb4a",
  measurementId: "G-G6XX11WGT5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
// const adminApp = initializeAdminApp(firebaseConfig);
// const adminAuth = getAdminAuth(adminApp);

export { db, storage, auth };
