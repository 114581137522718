import React from "react";

const SimSpecificQuestion = ({
  question,
  id,
  handleInputChange,
  value,
  submissionStatus,
  
}) => {
 
  const isReadOnly = submissionStatus === "Submitted";

  return (
    <div key={id}>
    
      <label className="label has-text-left" htmlFor={`question-${id}`}>
        {question}
      </label>
      <textarea
        name={`question-${id}`}
        className="textarea mb-2"
        id={`question-${id}`}
        onChange={(e) => handleInputChange(id, e.target.value)}
        value={value}
        readOnly={isReadOnly}
      />
    </div>
  );
};

export default SimSpecificQuestion;
