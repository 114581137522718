import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import "./ShowDoc.css";

const ShowDoc = (props) => {
  const handleDocumentDownload = async (documentName) => {
    console.log(`Downloading: ${documentName}`);

    // TODO: Use the documentName generated by AI instead of hard coding it
    const storage = getStorage();
    let fileRef = ref(storage, `leads/${props.firebaseId}/${documentName}`);

    try {
      // Get the download URL for the document
      const downloadURL = await getDownloadURL(fileRef);
      // Open the download URL in a new window or tab
      window.open(downloadURL, "_blank");
    } catch (error) {
      console.error("Error downloading the document:", error);
      // Handle errors (e.g., display an error message to the user)
    }
  };

  return (
    <>
      <div className="box">
        <div className="document-header ">
          <p className="subsubtitle">{props.docName}</p>
          <div
            key={props.index}
            className="button"
            onClick={() => handleDocumentDownload(props.docName)}
          >
            <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: "5px" }} />
            Download
          </div>
        </div>
        <div className="divider"></div>
        <p className="subsubtitle ">{props.summary || ""}</p>
      </div>
    </>
  );
};

export default ShowDoc;
